import React, { useEffect } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ScrolltoTop from "../../frontend/components/ScrolltoTop";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const originalBackground = document.body.style.background;
    document.body.style.background = "none";

    return () => {
      document.body.style.background = originalBackground;
      window.scroll(0,0)
    };
  }, [location]);


  return (
    <div>
      <ScrolltoTop />
      <header>
        <Header />
      </header>
      <div>
        <aside>
          <Sidebar />
        </aside>
        <div className="main-container float-start w-full md:ps-48 lg:ps-80 mt-20">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
