import React, { useEffect, useRef, useState } from "react";


import {
  eye,
  accprofile,
  accSubscription,
  acctoken,
  accdollar,
} from "../../../assets";
import { useCountries } from "use-react-countries";
import timezoneOptions from "timezones-list";
import "react-phone-input-2/lib/bootstrap.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { BaseApiUrl } from "../../../utilities/enviroment";
import UserApiClient from "../../../utilities/apiClients";
import { loginSuccess } from "../../../redux/actions";
import Footer from "../../../frontend/components/Footer";
import { Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";

const AccountProfile = () => {
  const [subData, setSubData] = useState({});
  const [InvoicData, setInvoicData] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const user = useSelector((state) => state.user.user);
  const dateString = user.date_joined;
  const date = new Date(dateString);
  const [userData, setuserData] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    number: user.number,
    state: user.state,
    address: user.address,
    zip_code: user.zip_code,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { countries } = useCountries();
  const [imageSrc, setImageSrc] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const resetBtn = useRef();
  const [PasswordData, setPasswordData] = useState({});
  const [currentPassword, setCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleCurrentPassword = () => setCurrentPassword((show) => !show);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const validatePassword = (password) => {
    if (!password) {
      return 'Password is required.';;
    } else if (password.length < 8) {
      return 'Password must be at least 8 characters.';
    } else if (!/[A-Z]/.test(password)) {
      return 'Password must contain at least one uppercase letter.';
    } else if (!/[^A-Za-z0-9]/.test(password)) {
      return 'Password must contain at least one special character.';
    } else if (/\s/.test(password)) {
      return 'Password cannot contain spaces.';
    } else {
      return true;
    }
  };

  const handleChangePassSubmit = async (e) => {
    e.preventDefault();
    if (!PasswordData.old_password || !PasswordData.new_password || !PasswordData.confirm_password) {
      Swal.fire('Please fill all the fields required.', '', 'warning', 3000)
      return;
    }
    if (PasswordData.new_password !== PasswordData.confirm_password) {
      Swal.fire('New and Confirm password should be same.', '', 'warning', 3000)
      return;
    }
    const valid = validatePassword(PasswordData.new_password)
    if (valid !== true) {
      Swal.fire(valid, '', 'warning', 3000)
    }
    await UserApiClient.put(`api/v1/user/change-password/`, { ...PasswordData }, true).then(async res => {
      if (res.success) {
        Swal.fire(res.data.message, '', 'success', 3000)
        resetBtn.current?.click();
      }
    })
  }
  const dropdownData = [
    {
      label: "Country",
      name: "Select Your Country",
      icon: <i className="fa-solid fa-globe"></i>,
      options: countries,
    },
    {
      label: "Timezone",
      name: "Select Timezone",
      icon: <i className="fa-solid fa-clock"></i>,
      options: timezoneOptions,
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({
    country: user.country,
    timezone: user.timezone,
  });

  const toggleDropdown = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleOptionClick = (menuIndex, option) => {
    const menu = dropdownData[menuIndex]; // Get the current menu
    setSelectedOptions({
      ...selectedOptions,
      [menu.label.toLowerCase()]: option.name, // Use the label as the key (in lowercase)
    });
    setOpenIndex(null);
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);

      setuserData((prevState) => ({
        ...prevState,
        profile_pic: file,
      }));
      setSelectedFile(file);
    }
  };

  const handlePassChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({
      ...PasswordData,
      [name]: value,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setuserData({
      ...userData,
      [name]: value,
    });
  };

  const handleReset = (e) => {
    setuserData(user);
    setSelectedOptions({
      country: user.country,
      timezone: user.timezone,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !userData.first_name ||
      !userData.last_name ||
      !userData.number ||
      !userData.state ||
      !userData.address ||
      !selectedOptions.country ||
      !userData.zip_code ||
      !selectedOptions.timezone
    ) {
      Swal.fire({
        title: "",
        text: "Please fill all the fields",
        timer: 2000,
        icon: "warning",
      });
      return;
    }
    const formData = new FormData();
    for (const key in userData) {
      if (key === "profile_pic" && !selectedFile) {
        continue;
      }
      formData.append(key, userData[key]);
    }
    for (const keyOptions in selectedOptions) {
      formData.append(keyOptions, selectedOptions[keyOptions]);
    }
    UserApiClient.patch(`api/v1/user/update/${user.id}/`, formData, true).then(
      (res) => {
        if (res.success) {
          Swal.fire({
            title: "",
            text: "Profile updated.",
            timer: 3000,
            icon: "success",
          });
          dispatch(loginSuccess({ ...user, ...res.data }));
          window.location.reload();
        }
      }
    );
  };

  useEffect(() => {
    setImageSrc(
      (user.profile_pic.includes("http") ? "" : BaseApiUrl) + user.profile_pic
    );
  }, [user, userData]);

  // Format the date to show only the date (no time)
  const formattedDate = date.toLocaleDateString(); // e.g., "11/25/2024"

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const email = user.email;
  const email_username = email.split("@")[0];

  const fetchSubData = async () => {
    await UserApiClient.get("api/v1/get_token/", {}, true).then(async (res) => {
      if (res.success) {
        setSubData(res.data);
      }
    });
  };

  const fetchInvoiceData = async () => {
    await UserApiClient.get("api/v1/user/invoices/", {}, true).then(
      async (res) => {
        if (res.success) {
          setInvoicData(res.data);
        }
      }
    );
  };

  useEffect(() => {
    fetchSubData();
    fetchInvoiceData();
  }, []);

  const openViewModal = (data) => {
    setSelectedInvoice(data);
    setShowViewModal(true);
  };

  const closeModals = () => {
    setShowViewModal(false);
    setSelectedInvoice({});
  };


  const deleteAccount = () => {
    Swal.fire({
      title: 'Are You Sure You Want To Delete Your Account?',
      text: "This action is irreversible. All your data and progress will be permanently deleted.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      focusCancel: true,
      customClass: {
        confirmButton: 'bg-red-600 hover:bg-red-700',
        cancelButton: 'bg-blue-500 hover:bg-blue-600'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your account has been deleted.',
          'success'
        )
        // Place your deletion logic here
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your account is safe :)',
          'error'
        )
      }
    });
  }

  const cancelSubscription = () => {
    Swal.fire({
      title: 'Are You Sure You Want To Cancel Your Subscription?',
      text: "You can reactivate your subscription anytime. Access continues until the end of the current billing period.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, cancel it!',
      cancelButtonText: 'No, keep it!',
      reverseButtons: true,
      focusCancel: true,
      customClass: {
        confirmButton: 'bg-red-600 hover:bg-red-700',
        cancelButton: 'bg-blue-500 hover:bg-blue-600'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Cancelled!',
          'Your subscription has been cancelled. You can reactivate it anytime.',
          'success'
        )
      }
    });
  }

  return (
    <>
      <div className="mt-20 flex flex-col font-josefin-sans ">
        <div className="bg-[#990013]">
          <div className="flex items-center p-[30px]">
            <img
              loading="lazy"
              onClick={handleImageClick}
              src={imageSrc ||
                (user.profile_pic?.includes("http")
                  ? user.profile_pic
                  : `${BaseApiUrl.replace(/\/$/, '')}${user.profile_pic}`) // Remove trailing slash from BaseApiUrl if present
              }
              alt="accountProfileImage"
              className="w-32 h-w-32 md:w-44 md:h-44 lg:w-[285px] lg:h-[285px] xl:w-72 xl:h-72 2xl:w-[300px] 2xl:h-[300px] rounded-full"
            />
            <div className="flex items-start flex-col ml-20">
              <p className=" text-3xl mb-5 font-bold text-white text-center">
                Profile Info
              </p>
              <p className="text-xl text-white font-bold flex justify-center items-center  capitalize">
                {user.username ? user.username : email_username}
              </p>
              <p className="text-md text-white font-bold flex justify-center items-center mt-2 ">
                {user.email}
              </p>
              <p className="text-base text-white lg:text-xl font-bold text-left">
                Joined At : {formattedDate}
              </p>
            </div>
          </div>

          {/* <div className='flex justify-center'>
            <div className='bg-white rounded-2xl lg:w-[800px] xl:w-[1200px] h-[345px] lg:h-[398px] xl:h-[438px] lg:mt-48 mt-32 z-10 relative'>
              <div className='px-3 md:px-10 py-5 xl:py-12'>
                <div className='flex justify-between w-full'>

                  <p className=' lg:text-xl text-right font-bold w-[20%] xl:w-[38%]'>Edit Profile</p>
                </div>

                <div className='flex justify-center items-center gap-5 md:gap-20 mt-3 xl:mt-10'>
                  <p className=' lg:text-xl font-bold '>0 Your Generations</p>
                  <p className=' lg:text-xl font-bold '>0 Likes</p>
                  <p className=' lg:text-xl font-bold '>0 Followers</p>
                </div>
              </div>
              <div className='mt-4 xl:mt-0 flex border border-red-800 justify-between rounded-bl-xl rounded-br-xl'>
                <button className='bg-red-800 w-full md:text-2xl h-16 rounded-bl-xl text-center text-white'>
                  Public Generation
                </button>
                <button className='bg-white w-full md:text-2xl rounded-br-xl text-center text-red-800'>
                  Most Liked
                </button>
              </div>
            </div>
          </div> */}
          <div className="flex flex-wrap items-center gap-2 py-2 bg-white text-black">
            <button
              onClick={() => handleTabClick(1)}
              className={`flex items-center justify-center px-4 py-3 mx-2 w-full sm:w-1/6 font-semibold text-sm sm:text-lg rounded-lg transition-colors duration-300 ${activeTab === 1 ? "bg-[#990013] text-white" : ""
                }`}
            >
              <img
                className={`w-6 mr-2 ${activeTab === 1 ? "" : "invert"}`}
                src={accprofile}
                alt="accprofile"
              />
              Profile
            </button>

            <button
              onClick={() => handleTabClick(2)}
              className={`flex items-center justify-center px-4 py-3 mx-2 w-full sm:w-1/6 font-semibold text-sm sm:text-lg rounded-lg transition-colors duration-300 ${activeTab === 2 ? "bg-[#990013] text-white" : ""
                }`}
            >
              <img
                className={`w-6 mr-2 ${activeTab === 2 ? "" : "invert"}`}
                src={accSubscription}
                alt="accSubscription"
              />
              Subscription
            </button>

            <button
              onClick={() => handleTabClick(3)}
              className={`flex items-center justify-center px-4 py-3 mx-2 w-full sm:w-1/6 font-semibold text-sm sm:text-lg rounded-lg transition-colors duration-300 ${activeTab === 3 ? "bg-[#990013] text-white" : ""
                }`}
            >
              <img
                className={`w-6 mr-2 ${activeTab === 3 ? "" : "invert"}`}
                src={acctoken}
                alt="acctoken"
              />
              Token
            </button>

            <button
              onClick={() => handleTabClick(4)}
              className={`flex items-center justify-center px-4 py-3 mx-2 w-full sm:w-1/6 font-semibold text-sm sm:text-lg rounded-lg transition-colors duration-300 ${activeTab === 4 ? "bg-[#990013] text-white" : ""
                }`}
            >
              <img
                className={`w-6 mr-2 ${activeTab === 4 ? "" : "invert"}`}
                src={accdollar}
                alt="accdollar"
              />
              Payment History
            </button>
          </div>
        </div>
        {/* <div className='bg-black h-[55vh]'>

                </div> */}

        {activeTab === 1 && (
          <div className="p-10 bg-white mt-[2px]">
            <div className="flex flex-col items-center md:items-start">
              {/* <label className="text-zinc-500 font-semibold  text-[22px]">
                Upload Image
              </label>
              <img
                loading="lazy"
                src={imageSrc}
                className="w-[100px] h-[100px] rounded-full object-cover"
                alt="User Avatar"
                onClick={handleImageClick}
              /> */}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                name="profile_pic"
                onChange={handleFileChange}
              />
            </div>


            <div className="border-2 p-5">
              <h3 className="text-gray-500 text-lg">Personal Information</h3>

              <div className="flex justify-center md:justify-between flex-wrap gap-5 py-5">
                <TextField
                  id="outlined-first-name"
                  label="First Name"
                  placeholder="First Name"
                  variant="outlined"
                  className="md:w-[48%] w-full"
                  value={userData.first_name}
                  onChange={handleChange}
                  name="first_name"
                  InputProps={{
                    maxLength: 255,
                    startAdornment: (
                      <InputAdornment position="start"><i className="fa-solid fa-user"></i></InputAdornment>
                    )
                  }}
                />

                <TextField
                  id="last-name"
                  label="Last Name"
                  placeholder="Last Name"
                  variant="outlined"
                  className="md:w-[48%] w-full"
                  value={userData.last_name}
                  onChange={handleChange}
                  name="last_name"
                  InputProps={{
                    maxLength: 255,
                    startAdornment: (
                      <InputAdornment position="start"><i className="fa-solid fa-user"></i></InputAdornment>
                    )
                  }}
                />

                <TextField
                  id="phone"
                  label="Phone Number"
                  type="number"
                  placeholder="Your Phone Number"
                  variant="outlined"
                  className="md:w-[48%] w-full"
                  value={userData.number}
                  onChange={handleChange}
                  name="number"
                  InputProps={{
                    inputProps: {
                      min: 1000000000,
                      max: 9999999999
                    },
                    startAdornment: (
                      <InputAdornment position="start"><i className="fa-solid fa-phone"></i></InputAdornment>
                    )
                  }}
                />
                <TextField
                  id="state"
                  label="State"
                  placeholder="Your State"
                  type="text"
                  variant="outlined"
                  className="md:w-[48%] w-full"
                  value={userData.state}
                  onChange={handleChange}
                  name="state"
                  InputProps={{
                    maxLength: 255,
                    startAdornment: (
                      <InputAdornment position="start"><i className="fa-solid fa-flag-usa"></i></InputAdornment>
                    )
                  }}
                />
                <TextField
                  id="zip-code"
                  label="Zip Code"
                  placeholder="12456"
                  type="number"
                  variant="outlined"
                  className="md:w-[48%] w-full"
                  value={userData.zip_code}
                  onChange={handleChange}
                  name="zip_code"
                  InputProps={{
                    inputProps: {
                      min: 100000,
                      max: 999999
                    },
                    startAdornment: (
                      <InputAdornment position="start"><i className="fa-solid fa-location-dot"></i></InputAdornment>
                    )
                  }}
                />
                <TextField
                  id="address"
                  label="Address"
                  placeholder="Your Address"
                  type="text"
                  variant="outlined"
                  className="md:w-[48%] w-full"
                  value={userData.address}
                  onChange={handleChange}
                  name="address"
                  InputProps={{
                    maxLength: 1000,
                    startAdornment: (
                      <InputAdornment position="start"><i className="fa-solid fa-address-book"></i></InputAdornment>
                    )
                  }}
                />
                {dropdownData.map((menu, index) => (
                  <Box key={index} className="md:w-[48%] w-full">
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id={`${menu.label}`} sx={{ backgroundColor: "white", padding: "2px" }}>{menu.label}</InputLabel>
                      <Select
                        labelId={`${menu.label}`}
                        variant="outlined"
                        value={selectedOptions[menu.label.toLowerCase()] || ''}
                        onChange={(event) => handleOptionClick(index, event.target.value)}
                        displayEmpty
                        input={<OutlinedInput startAdornment={<InputAdornment position="start">{menu.icon}</InputAdornment>} />}
                        renderValue={selected => (
                          <div className={`flex justify-between items-center  ${selected ? "text-black" : "text-zinc-300"}`}>
                            {selected || `Select ${menu.label} here...`}
                          </div>
                        )}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 250,  // Set maximum height of the dropdown to control its size
                              overflow: 'auto' // Enable scrolling within the dropdown
                            }
                          },
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null
                        }}
                      >
                        {menu.options.map((option, optionIndex) => (
                          <MenuItem key={optionIndex} value={option}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                ))}
              </div>

              <div className="flex justify-between ">
                <div className="w-[48%]">
                  <button
                    className="rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 font-semibold text-white text-lg p-2 md:p-4"
                    onClick={handleSubmit}
                  >
                    Update Profile
                  </button>
                </div>
                <div className="w-[48%]">
                  <button
                    className="rounded-lg w-full mt-6 bg-stone-300 hover:bg-stone-400 font-semibold text-black text-lg p-2 md:p-4"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>

            <br />

            <div className="border-2 p-5">
              <form>
                <h3 className="text-gray-500 text-lg">Change Password</h3>
                <div className="flex-wrap lg:flex items-center justify-between p-10 mb-10 mt-5 rounded-lg shadow-lg bg-[#d5d5d5]">
                  <div className="flex-wrap lg:flex gap-4">
                    <div className="logo flex items-center justify-center">
                      <i className="fa-solid fa-lock text-red-600 text-4xl"></i>
                    </div>
                    <div className="flex flex-col items-center text-center lg:text-left lg:items-start gap-2">
                      <h2 className="text-gray-900  text-xl md:text-2xl font-semibold">
                        Change Your Password
                      </h2>
                      <p className="text-gray-800 text-sm">
                        Your account is currently using a passwordless sign-in method. To set up a standard email/password login, please create a secure password below.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center md:justify-between flex-wrap gap-5 py-5">
                  <TextField
                    id="outlined-Current-password"
                    label="Current Password"
                    placeholder="Current Password"
                    variant="outlined"
                    className="md:w-[48%] w-full"
                    value={PasswordData.old_password}
                    onChange={handlePassChange}
                    type={currentPassword ? 'text' : 'password'}
                    name="old_password"
                    InputProps={{
                      maxLength: 255,
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            aria-label={
                              currentPassword ? 'hide the password' : 'display the password'
                            }
                            onClick={handleCurrentPassword}
                            edge="start"
                          >
                            {currentPassword ? <i className="fa-solid fa-eye-slash w-10"></i> : <i className="fa-solid fa-eye w-10"></i>}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div className="flex justify-center md:justify-between flex-wrap gap-5 py-5">
                  <TextField
                    id="outlined-new-password"
                    label="New Password"
                    placeholder="New Password"
                    variant="outlined"
                    className="md:w-[48%] w-full"
                    value={PasswordData.new_password}
                    onChange={handlePassChange}
                    type={showPassword ? 'text' : 'password'}
                    name="new_password"
                    InputProps={{
                      maxLength: 255,
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            aria-label={
                              showPassword ? 'hide the password' : 'display the password'
                            }
                            onClick={handleClickShowPassword}
                            edge="start"
                          >
                            {showPassword ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    id="outlined-new-password"
                    label="Confirm New Password"
                    placeholder="Confirm New Password"
                    variant="outlined"
                    className="md:w-[48%] w-full"
                    value={PasswordData.confirm_password}
                    onChange={handlePassChange}
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="confirm_password"
                    InputProps={{
                      maxLength: 255,
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            aria-label={
                              showConfirmPassword ? 'hide the password' : 'display the password'
                            }
                            onClick={handleClickShowConfirmPassword}
                            edge="start"
                          >
                            {showConfirmPassword ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>

                <div className="flex justify-between ">
                  <div className="w-[48%]">
                    <button
                      type="button"
                      className="rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 font-semibold text-white text-lg p-2 md:p-4"
                      onClick={handleChangePassSubmit}
                    >
                      Set New Password
                    </button>
                  </div>
                  <div className="w-[48%]">
                    <button
                      ref={resetBtn}
                      type="reset"
                      className="rounded-lg w-full mt-6 bg-stone-300 hover:bg-stone-400 font-semibold text-black text-lg p-2 md:p-4"
                      onClick={() => setPasswordData({})}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <br />

            <div className="border-2 p-5">
              <h3 className="text-gray-500 text-lg">Delete Account</h3>
              <div className="flex-wrap lg:flex items-center justify-between p-10 mb-10 mt-5 rounded-lg shadow-lg bg-[#d5d5d5]">
                <div className="flex-wrap lg:flex gap-4">
                  <div className="logo flex items-center justify-center">
                    <i className="fa-solid fa-trash text-red-600 text-4xl"></i>
                  </div>
                  <div className="flex flex-col items-center text-center lg:text-left lg:items-start gap-2">
                    <h2 className="text-gray-900 text-xl md:text-2xl font-semibold">
                      Account Deletion
                    </h2>
                    <p className="text-gray-800 text-sm">
                      You have the option to permanently delete your account. Please note, this action is irreversible, and you will lose access to your account and all associated data.
                    </p>

                  </div>
                </div>
                <div className="text-center lg:text-right">
                  <button
                    onClick={deleteAccount}
                    className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out"
                  >
                    Delete Account
                  </button>
                </div>
              </div>
            </div>

          </div>
        )}
        {activeTab === 2 && (
          <>
            {subData.subscription_type === "free" ? (
              <>
                <div className="flex-wrap lg:flex items-center justify-between p-10 my-20 mx-10 rounded-lg shadow-lg bg-[#d5d5d5]">
                  <div className="flex-wrap lg:flex gap-4">
                    <div className="logo flex items-center justify-center">
                      <i className="fa-solid fa-heart-circle-plus text-red-600 text-4xl" />
                    </div>
                    <div className="flex flex-col items-center text-center lg:text-left lg:items-start gap-2">
                      <h2 className="text-gray-900 text-2xl font-semibold">
                        Unlock Premium Features with a Subscription
                      </h2>
                      <p className="text-gray-800 text-sm">
                        Gain access to advanced AI tools for creating distinctive visuals and characters. Elevate your artistic expressions by subscribing today!
                      </p>
                    </div>
                  </div>
                  <div className="text-center lg:text-right">
                    <button
                      onClick={() => navigate("/priceplan")}
                      className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out"
                    >
                      Explore Plans
                    </button>
                  </div>
                </div>
              </>

            ) : (
              <>
                <div className="p-10 justify-center items-center bg-white mt-[2px]">
                  <h2 className="text-xl font-bold">Subscription Details</h2>
                  <div className="space-y-2">
                    <div className="flex flex-wrap justify-between border rounded-lg p-3">
                      <span className="font-bold text-gray-700">
                        Subscription:
                      </span>
                      <span className="text-gray-800 capitalize">
                        {subData.subscription_config?.plan ||
                          subData.subscription_type?.replaceAll("_", " ")}
                      </span>
                    </div>
                    {subData.subscription_type !== "free" &&
                      subData.added_by_admin === false ? (
                      <>
                        <div className="flex flex-wrap justify-between border rounded-lg p-3">
                          <span className="font-bold text-gray-700">
                            Status:
                          </span>
                          <span className="text-gray-800">
                            {subData.plan_status}
                          </span>
                        </div>
                        <div className="flex flex-wrap justify-between border rounded-lg p-3">
                          <span className="font-bold text-gray-700">
                            Subscription Email:
                          </span>
                          <span className="text-gray-800">
                            {subData.billing_email}
                          </span>
                        </div>
                        <div className="flex flex-wrap justify-between border rounded-lg p-3">
                          <span className="font-bold text-gray-700">
                            Restriction Start:
                          </span>
                          <span className="text-gray-800">
                            {subData.restriction_start}
                          </span>
                        </div>
                        <div className="flex flex-wrap justify-between border rounded-lg p-3">
                          <span className="font-bold text-gray-700">
                            Restriction End:
                          </span>
                          <span className="text-gray-800">
                            {subData.restriction_end}
                          </span>
                        </div>
                        <div className="flex flex-wrap justify-between border rounded-lg p-3">
                          <span className="font-bold text-gray-700">
                            Renewal Date:
                          </span>
                          <span className="text-gray-800">
                            {subData.renewal_date}
                          </span>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <br />

                  <div className="border-2 p-5">
                    <h3 className="text-gray-500 text-lg">Cancel Subsription</h3>
                    <div className="flex-wrap lg:flex items-center justify-between p-10 mb-10 mt-5 rounded-lg shadow-lg bg-[#d5d5d5]">
                      <div className="flex-wrap lg:flex gap-4">
                        <div className="logo flex items-center justify-center">
                          <i className="fa-solid fa-ban text-red-600 text-4xl"></i>
                        </div>
                        <div className="flex flex-col items-center text-center lg:text-left lg:items-start gap-2">
                          <h2 className="text-gray-900 text-xl md:text-2xl font-semibold">
                            Subscription Cancellation
                          </h2>
                          <p className="text-gray-800 text-sm">
                            Cancel your subscription at any time to stop future payments. Your access continues until the end of the current billing period. Reactivate anytime.
                          </p>
                        </div>
                      </div>
                      <div className="text-center lg:text-right">
                        <button
                          onClick={cancelSubscription}
                          className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {activeTab === 3 && (
          <>
            {subData.subscription_type === "free" ? (
              <>
                <div className="flex-wrap lg:flex items-center justify-between p-10 my-20 mx-10 rounded-lg shadow-lg bg-[#d5d5d5]">
                  <div className="flex-wrap lg:flex gap-4">
                    <div className="logo flex items-center justify-center">
                      <i className="fa-solid fa-heart-circle-plus text-red-600 text-4xl" />
                    </div>
                    <div className="flex flex-col items-center text-center lg:text-left lg:items-start gap-2">
                      <h2 className="text-gray-900 text-2xl font-semibold">
                        Activate Your Subscription for Exclusive AI Capabilities
                      </h2>
                      <p className="text-gray-800 text-sm">
                        Join now to unlock special token privileges and begin crafting sophisticated images and characters with our cutting-edge AI technology.
                      </p>
                    </div>
                  </div>
                  <div className="text-center lg:text-right">
                    <button
                      onClick={() => navigate("/priceplan")}
                      className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out"
                    >
                      Unlock Full Access
                    </button>
                  </div>
                </div>
              </>

            ) : (
              <>
                <div className="p-10 justify-center items-center bg-white mt-1">
                  <h2 className="text-xl font-bold">Token Details</h2>
                  <div className="space-y-2">
                    <div className="flex flex-wrap justify-between border rounded-lg p-3">
                      <span className="font-bold text-gray-700">Token:</span>
                      <span className="text-gray-800">{(Math.floor(subData.token *100)/100).toFixed(2)}</span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {activeTab === 4 && (
          <>
            {subData.subscription_type === "free" ? (
              <>
                <div className="flex-wrap lg:flex items-center justify-between p-10 my-20 mx-10 rounded-lg shadow-lg bg-[#d5d5d5]">
                  <div className="flex-wrap lg:flex gap-4">
                    <div className="logo flex items-center justify-center">
                      <i className="fa-solid fa-heart-circle-plus text-red-600 text-4xl" />
                    </div>
                    <div className="flex flex-col items-center text-center lg:text-left lg:items-start gap-2">
                      <h2 className="text-gray-900 text-2xl font-semibold">
                        Your Payment History
                      </h2>
                      <p className="text-gray-800 text-sm">
                        Easily review your transactions and manage your subscription details.
                      </p>
                    </div>
                  </div>

                </div>
              </>


            ) : (
              <>
                <div className="p-10 justify-center items-center bg-white mt-1">
                  <div className="overflow-x-auto rounded-lg">
                    <table className="w-full text-left text-gray-500 border border-stone-300">
                      <thead className="text-xl text-black bg-stone-300">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 whitespace-nowrap"
                          >
                            Sr.
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 whitespace-nowrap"
                          >
                            Invoice ID
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 whitespace-nowrap"
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 whitespace-nowrap"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 whitespace-nowrap"
                          >
                            Amount
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 whitespace-nowrap"
                          >
                            Created At
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 whitespace-nowrap"
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {InvoicData.map((item, index) => (
                          <tr
                            key={index}
                            className="bg-white text-black text-xl font-normal border-b border-stone-300 capitalize"
                          >
                            <td className="px-6 py-4 whitespace-nowrap">
                              {index + 1}.
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {item.invoice_id}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {item.invoice_type}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {item.invoice_status}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {item.amount}/{item.currency}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {item.created_at}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <img
                                loading="lazy"
                                src={eye}
                                alt="eye"
                                className="cursor-pointer"
                                onClick={() => openViewModal(item)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <Footer />

      {/* View Modal */}
      {showViewModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-5 rounded-md w-96">
            <h2 className="text-xl font-bold">View Invoice</h2>
            <div className="m-2">
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">ID:</strong>{" "}
                {selectedInvoice.invoice_id}
              </p>
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">Amount:</strong>{" "}
                {selectedInvoice.amount}
              </p>
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">Invoice Type:</strong>{" "}
                {selectedInvoice.invoice_type}
              </p>
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">Invoice Status:</strong>{" "}
                {selectedInvoice.invoice_status}
              </p>
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">Currency:</strong>{" "}
                {selectedInvoice.currency}
              </p>
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">Created At:</strong>{" "}
                {selectedInvoice.created_at}
              </p>
              {selectedInvoice.billing_address &&
                Object.entries(selectedInvoice.billing_address).map(
                  ([key, value]) =>
                    key &&
                    value && (
                      <p
                        key={key}
                        className="p-2 m-1 flex flex-wrap justify-between border rounded-lg capitalize"
                      >
                        <strong className="mb-1">
                          {key.replaceAll("_", " ")}:
                        </strong>{" "}
                        {value}
                      </p>
                    )
                )}
              <div className="mt-4 flex justify-end">
                <button
                  className="bg-gray-500 text-white p-2 rounded"
                  onClick={closeModals}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountProfile;