import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import SignUp from '../frontend/components/SignUp';
import LandingPage from '../frontend/frontendPages/LandingPage';
import ComingSoon from '../frontend/components/ComingSoon/ComingSoon';
import Layout from '../admin/layouts/Layout';

import Dashbord from '../admin/AdminPages/dashbord';
import { PrivateRouteAdmin, PrivateRouteUser } from './PrivateRoute.js';
import BlogList from '../admin/AdminPages/blogs/blogListPage';
import CreateBlog from '../admin/AdminPages/blogs/blogListPage/CreateBlog';
import EditBlog from '../admin/AdminPages/blogs/blogListPage/EditBlog';
import EditHelpBlog from '../admin/AdminPages/helpDesk/helpBlogPage/EditHelpBlog';
import SingleBlog from '../admin/AdminPages/blogs/blogListPage/SingleBlog';
import SingleBlogUser from '../frontend/frontendPages/Blog/SingleBlogUser';
import Category from '../admin/AdminPages/blogs/categoryPage';
import AddCategory from '../admin/AdminPages/blogs/categoryPage/AddCategory';
import AllUser from '../admin/AdminPages/users/allUsersPage';
import AllUserType from '../admin/AdminPages/users/allUserType.js';
import AllUserTrash from '../admin/AdminPages/users/allUserTrash.js';
import ActiveUser from '../admin/AdminPages/users/activeUserPage';
import InactiveUser from '../admin/AdminPages/users/inactiveUsersPage';
import AddUser from '../admin/AdminPages/users/allUsersPage/AddUser';
import UserProfile from '../admin/AdminPages/users/UserProfile';
import Faqs from '../admin/AdminPages/faqs';
import Adsupport from '../admin/AdminPages/support.jsx';
import Template from '../admin/AdminPages/template.js';
import Referal from '../admin/AdminPages/referal/index.js';
import Referalapproved from '../admin/AdminPages/referal/approved.js';
import Referaldecline from '../admin/AdminPages/referal/decline.js';
import Withdrawals from '../admin/AdminPages/withdrawals/index.js';
import Withdrawalsapproved from '../admin/AdminPages/withdrawals/approved.js';
import Withdrawalsdecline from '../admin/AdminPages/withdrawals/decline.js';
import Affiliated from '../admin/AdminPages/affiliated.js';
import Banners from '../admin/AdminPages/banners.js';
import Staff from '../admin/AdminPages/staff.js';
import Notification from '../admin/AdminPages/Notification.js';
import AddFaqs from '../admin/AdminPages/faqs/AddFaqs';
import Invoice from '../admin/AdminPages/invoice';
import CreateInvoice from '../admin/AdminPages/invoice/CreateInvoice';
// import Users from '../admin/AdminPages/users.js';
import Userdetail from '../admin/AdminPages/userdetail.js';
import OurStaff from '../admin/AdminPages/ourStaff';
import AddStaff from '../admin/AdminPages/ourStaff/AddStaff';
import EditStaff from '../admin/AdminPages/ourStaff/EditStaff';
import MainCategory from '../admin/AdminPages/helpDesk/mainCategoryPage';
import AddMainCategory from '../admin/AdminPages/helpDesk/mainCategoryPage/AddMainCategory';
import SubCategory from '../admin/AdminPages/helpDesk/subCategoryPage';
import AddSubCategory from '../admin/AdminPages/helpDesk/subCategoryPage/AddSubCategory';
import HelpBlog from '../admin/AdminPages/helpDesk/helpBlogPage';
import CreateHelpBlog from '../admin/AdminPages/helpDesk/helpBlogPage/CreateHelpBlog';
import SingleHelpBlog from '../admin/AdminPages/helpDesk/helpBlogPage/SingleHelpBlog';
import LayoutDesbord from '../frontend/layouts/LayoutDesbord';
import Blog from '../frontend/frontendPages/Blog';
import Categories from '../frontend/frontendPages/Category.js';
import SingleBlogs from '../frontend/frontendPages/Blog/SingleBlogs';
import General from '../admin/AdminPages/settings/generalPage';
import SeoSetting from '../admin/AdminPages/settings/seoSetting';
import LoginAdmin from '../admin/AdminPages/login';
// import RegisterAdmin from '../admin/AdminPages/RegisterAdmin';
import Login from '../frontend/components/Login';
import ResetPassword from '../frontend/components/Reset';
import ResetForgetPass from '../frontend/components/ResetForgetPass';
import LandingPageFrontend from '../dashboard/dashboardPages/landingPage';
import AccountProfile from '../dashboard/dashboardPages/accountProfile';
import PricePlan from '../frontend/frontendPages/PricePlan';
import Payment from '../frontend/frontendPages/Payment.jsx';
import LayoutFrontend from '../dashboard/layouts/LayoutFrontend';
import Seodata from '../dashboard/layouts/Seodata.js';
import Discover from '../dashboard/dashboardPages/discover';
import MyCreation from '../dashboard/dashboardPages/mycreation.js';
import MyGeneration from '../dashboard/dashboardPages/mygenerations.js';
import Generate from '../dashboard/dashboardPages/replicate.js';
import ImageView from '../dashboard/dashboardPages/discover/ImageView';
import Gallery from '../dashboard/dashboardPages/gallery';
import Girls from '../dashboard/dashboardPages/girls';
import Saved from '../dashboard/dashboardPages/girls/Saved';
import ByEromanticai from '../dashboard/dashboardPages/girls/ByEromanticai';
import Generations from '../dashboard/dashboardPages/generations';
import Modelverification from '../dashboard/dashboardPages/modelVerification';
import Modality from '../dashboard/dashboardPages/modality';
import Creators from '../dashboard/dashboardPages/creators';
import CreatorsProfile from '../dashboard/dashboardPages/creators/CreatorsProfile';
import ModelverificationNextPage from '../dashboard/dashboardPages/modelVerification/ModelverificationNextPage';
import SiteDetail from '../admin/AdminPages/settings/seoSetting/SiteDetail';
import SeoContent from '../admin/AdminPages/settings/seoSetting/SeoContent';
import Support from '../dashboard/dashboardPages/support';
import SupportCategory from '../dashboard/dashboardPages/support/SupportCategory';
import CategoryDiscription from '../dashboard/dashboardPages/support/CategoryDiscription';
import CookiePolicy from '../frontend/extrapage/CookiePolicy';
import PrivacyPolicy from '../frontend/extrapage/PrivacyPolicy';
import TermsOfService from '../frontend/extrapage/TermsOfService';
import Dreamchat from '../dashboard/dashboardPages/DreamChat.js';
import CreateDreamGF from '../dashboard/dashboardPages/CreateDreamGF/index.js';
// import Aidreamchat from '../dashboard/dashboardPages/CreateDreamGF/Aidreamchat.js';
import Aidreamchats from '../dashboard/dashboardPages/CreateDreamGF/Aidreamchats.js';
import AiNewChat from '../dashboard/dashboardPages/CreateDreamGF/AiChat.js';
// import Aidreamstatic from '../dashboard/dashboardPages/CreateDreamGF/AidreamStatic.js';
import AidreamGFchat from '../dashboard/dashboardPages/CreateDreamGF/AidreamGFchat.js';
import GoogleCallBack from '../frontend/components/GoogleCallBack.jsx';
import Referrals from '../dashboard/dashboardPages/referrals';
import BecomeAffiliate from '../dashboard/dashboardPages/referrals/BecomeAffiliate';
import AffiliateSignupPage from '../dashboard/dashboardPages/referrals/AffiliateSignupPage';
import UserNotifications from '../dashboard/dashboardPages/notifications'
import { useDispatch, useSelector } from 'react-redux';
import { logout, loginSuccess } from '../redux/actions.js';
import UserApiClient from '../utilities/apiClients.js';
import TemplatePages from '../admin/AdminPages/pages/index.js';
import ShowTemplatePages from '../frontend/frontendPages/TemplateShow.js';
import SitemapList from '../admin/AdminPages/sitemaps/index.jsx';
import ViewGeneratedImage from '../dashboard/components/ViewGeneratedImage.js';
import AllModels from '../dashboard/dashboardPages/allmodels.js';


const MainRoute = () => {
    const user = useSelector((state) => state.user);
    const [validation, setValidation] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const hasVisited = localStorage.getItem("hasVisited") === "true";
    const token = localStorage.getItem("token")

    useEffect(() => {
        if (!user.isAuthenticated && !token) {
            dispatch(logout());
        }
        else {
            UserApiClient.post('api/v1/user/', {}, true, true, undefined).then(async res => {
                if (res.success) {
                    dispatch(loginSuccess(res.data));
                    setValidation(user.isAuthenticated);
                } else {
                    dispatch(logout());
                }
            })
        }
        // setValidation( token );
    }, []);

    useEffect(() => {
        setValidation(user.isAuthenticated);
    }, [user])

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top whenever the URL changes
    }, [location]);

    const routes = [
        // Frontend Routes
        { path: "/", element: <Seodata><LayoutFrontend><LandingPage /></LayoutFrontend></Seodata> },
        { path: "/login", element: <Seodata><Login /></Seodata> },
        { path: "/google-callback", element: <Seodata><GoogleCallBack /></Seodata> },
        { path: "/ResetPassword", element: <Seodata><ResetPassword /></Seodata> },
        { path: "/reset-forget-pass/", element: <Seodata><ResetForgetPass /></Seodata> },
        { path: "/join", element: <Seodata><SignUp /></Seodata> },
        { path: "/join/referral", element: <Seodata><SignUp /></Seodata> },
        { path: "/blogs", element: <Seodata><LayoutFrontend><Blog /></LayoutFrontend></Seodata> },
        { path: "/category", element: <Seodata><LayoutFrontend><Categories /></LayoutFrontend></Seodata> },
        { path: "/singleblogs", element: <Seodata><LayoutDesbord><SingleBlogs /></LayoutDesbord></Seodata> },
        { path: "/comingsoon", element: <Seodata><ComingSoon /></Seodata> },
        { path: "/priceplan", element: <Seodata><LayoutFrontend><PricePlan /></LayoutFrontend></Seodata> },
        { path: "/template/:template", element: <Seodata><LayoutFrontend><ShowTemplatePages /></LayoutFrontend></Seodata> },
        { path: "/payment", element: <Seodata><LayoutFrontend><Payment /></LayoutFrontend></Seodata> },
        { path: "/terms-of-service", element: <Seodata><LayoutFrontend><TermsOfService /></LayoutFrontend></Seodata> },
        { path: "/privacy-policy", element: <Seodata><LayoutFrontend><PrivacyPolicy /></LayoutFrontend></Seodata> },
        { path: "/cookie-policy", element: <Seodata><LayoutFrontend><CookiePolicy /></LayoutFrontend></Seodata> },

        // Admin Routes
        { path: "/admin", element: <LoginAdmin /> },
        { path: "/admin/dashboard", element: <PrivateRouteAdmin><Layout><Dashbord /></Layout></PrivateRouteAdmin> },
        { path: "/admin/blogList", element: <PrivateRouteAdmin><Layout><BlogList /></Layout></PrivateRouteAdmin> },
        { path: "/admin/createBlog", element: <PrivateRouteAdmin><Layout><CreateBlog /></Layout></PrivateRouteAdmin> },
        { path: "/admin/EditBlog/:blogId", element: <PrivateRouteAdmin><Layout><EditBlog /></Layout></PrivateRouteAdmin> },
        { path: "/admin/singleBlog", element: <PrivateRouteAdmin><Layout><SingleBlog /></Layout></PrivateRouteAdmin> },
        { path: "/admin/singleBlog/:blogId", element: <PrivateRouteAdmin><Layout><SingleBlog /></Layout></PrivateRouteAdmin> },
        { path: "/singleBlog/:slug", element: <LayoutFrontend><SingleBlogUser /></LayoutFrontend> },
        { path: "/admin/category", element: <PrivateRouteAdmin><Layout><Category /></Layout></PrivateRouteAdmin> },
        { path: "/admin/addCategory", element: <PrivateRouteAdmin><Layout><AddCategory /></Layout></PrivateRouteAdmin> },
        { path: "/admin/allUser", element: <PrivateRouteAdmin><Layout><AllUser /></Layout></PrivateRouteAdmin> },
        { path: "/admin/allUserType", element: <PrivateRouteAdmin><Layout><AllUserType /></Layout></PrivateRouteAdmin> },
        { path: "/admin/allUserTrash", element: <PrivateRouteAdmin><Layout><AllUserTrash /></Layout></PrivateRouteAdmin> },
        { path: "/admin/activeUser", element: <PrivateRouteAdmin><Layout><ActiveUser /></Layout></PrivateRouteAdmin> },
        { path: "/admin/inactiveUser", element: <PrivateRouteAdmin><Layout><InactiveUser /></Layout></PrivateRouteAdmin> },
        { path: "/admin/addUser", element: <PrivateRouteAdmin><Layout><AddUser /></Layout></PrivateRouteAdmin> },
        { path: "/admin/userProfile", element: <PrivateRouteAdmin><Layout><UserProfile /></Layout></PrivateRouteAdmin> },
        { path: "/admin/faqs", element: <PrivateRouteAdmin><Layout><Faqs /></Layout></PrivateRouteAdmin> },
        { path: "/admin/support", element: <PrivateRouteAdmin><Layout><Adsupport /></Layout></PrivateRouteAdmin> },
        { path: "/admin/affiliated", element: <PrivateRouteAdmin><Layout><Affiliated /></Layout></PrivateRouteAdmin> },
        { path: "/admin/banners", element: <PrivateRouteAdmin><Layout><Banners /></Layout></PrivateRouteAdmin> },
        { path: "/admin/staff", element: <PrivateRouteAdmin><Layout><Staff /></Layout></PrivateRouteAdmin> },
        { path: "/admin/notification", element: <PrivateRouteAdmin><Layout><Notification /></Layout></PrivateRouteAdmin> },
        { path: "/admin/withdrawals", element: <PrivateRouteAdmin><Layout><Withdrawals /></Layout></PrivateRouteAdmin> },
        { path: "/admin/withdrawalsApproved", element: <PrivateRouteAdmin><Layout><Withdrawalsapproved /></Layout></PrivateRouteAdmin> },
        { path: "/admin/withdrawalsDeclined", element: <PrivateRouteAdmin><Layout><Withdrawalsdecline /></Layout></PrivateRouteAdmin> },
        { path: "/admin/referal", element: <PrivateRouteAdmin><Layout><Referal /></Layout></PrivateRouteAdmin> },
        { path: "/admin/referalApproved", element: <PrivateRouteAdmin><Layout><Referalapproved /></Layout></PrivateRouteAdmin> },
        { path: "/admin/referalDeclined", element: <PrivateRouteAdmin><Layout><Referaldecline /></Layout></PrivateRouteAdmin> },
        { path: "/admin/template", element: <PrivateRouteAdmin><Layout><Template /></Layout></PrivateRouteAdmin> },
        { path: "/admin/template/:template", element: <PrivateRouteAdmin><Layout><TemplatePages /></Layout></PrivateRouteAdmin> },
        { path: "/admin/addFaqs", element: <PrivateRouteAdmin><Layout><AddFaqs /></Layout></PrivateRouteAdmin> },
        { path: "/admin/addFaqs", element: <PrivateRouteAdmin><Layout><AddFaqs /></Layout></PrivateRouteAdmin> },
        { path: "/admin/invoice", element: <PrivateRouteAdmin><Layout><Invoice /></Layout></PrivateRouteAdmin> },
        { path: "/admin/createInvoice", element: <PrivateRouteAdmin><Layout><CreateInvoice /></Layout></PrivateRouteAdmin> },
        { path: "/admin/ourStaff", element: <PrivateRouteAdmin><Layout><OurStaff /></Layout></PrivateRouteAdmin> },
        { path: "/admin/userdetail/:id", element: <PrivateRouteAdmin><Layout><Userdetail /></Layout></PrivateRouteAdmin> },
        { path: "/admin/addStaff", element: <PrivateRouteAdmin><Layout><AddStaff /></Layout></PrivateRouteAdmin> },
        { path: "/staff/edit_staff/:staffId", element: <PrivateRouteAdmin><Layout><EditStaff /></Layout></PrivateRouteAdmin> },
        { path: "/admin/mainCategory", element: <PrivateRouteAdmin><Layout><MainCategory /></Layout></PrivateRouteAdmin> },
        { path: "/admin/addMainCategory", element: <PrivateRouteAdmin><Layout><AddMainCategory /></Layout></PrivateRouteAdmin> },
        { path: "/admin/subCategory", element: <PrivateRouteAdmin><Layout><SubCategory /></Layout></PrivateRouteAdmin> },
        { path: "/admin/addSubCategory", element: <PrivateRouteAdmin><Layout><AddSubCategory /></Layout></PrivateRouteAdmin> },
        { path: "/admin/helpBlog", element: <PrivateRouteAdmin><Layout><HelpBlog /></Layout></PrivateRouteAdmin> },
        { path: "/admin/EditHelpBlog/:blogId", element: <PrivateRouteAdmin><Layout><EditHelpBlog /></Layout></PrivateRouteAdmin> },
        { path: "/admin/createHelpBlog", element: <PrivateRouteAdmin><Layout><CreateHelpBlog /></Layout></PrivateRouteAdmin> },
        { path: "/admin/singleHelpBlog", element: <PrivateRouteAdmin><Layout><SingleHelpBlog /></Layout></PrivateRouteAdmin> },
        { path: "/admin/generalPage", element: <PrivateRouteAdmin><Layout><General /></Layout></PrivateRouteAdmin> },
        { path: "/admin/seoSetting", element: <PrivateRouteAdmin><Layout><SeoSetting /></Layout></PrivateRouteAdmin> },
        { path: "/admin/SiteDetail", element: <PrivateRouteAdmin><Layout><SiteDetail /></Layout></PrivateRouteAdmin> },
        { path: "/admin/SeoContent", element: <PrivateRouteAdmin><Layout><SeoContent /></Layout></PrivateRouteAdmin> },

        // User Routes
        { path: "/dashboard", element: <PrivateRouteUser><LayoutFrontend><LandingPageFrontend /></LayoutFrontend></PrivateRouteUser> },
        { path: "/DreamChat", element: <PrivateRouteUser><LayoutFrontend><Dreamchat /></LayoutFrontend></PrivateRouteUser> },
        { path: "/CreateDreamGF", element: <PrivateRouteUser><LayoutFrontend><CreateDreamGF /></LayoutFrontend></PrivateRouteUser> },
        { path: "/BoyFriend", element: <PrivateRouteUser><LayoutFrontend><CreateDreamGF /></LayoutFrontend></PrivateRouteUser> },
        { path: "/aichat/:CharachterId", element: <PrivateRouteUser><LayoutFrontend><AiNewChat /></LayoutFrontend></PrivateRouteUser> },
        { path: "/aichat", element: <PrivateRouteUser><LayoutFrontend><AiNewChat /></LayoutFrontend></PrivateRouteUser> },
        { path: "/AidreamGFChat", element: <PrivateRouteUser><LayoutFrontend><Aidreamchats /></LayoutFrontend></PrivateRouteUser> },
        { path: "/AidreamGFchats/:id", element: <PrivateRouteUser><LayoutFrontend><AidreamGFchat /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/accountProfile", element: <PrivateRouteUser><LayoutFrontend><AccountProfile /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/referrals", element: <PrivateRouteUser><LayoutFrontend><Referrals /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/Affiliate", element: <PrivateRouteUser><LayoutFrontend><BecomeAffiliate /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/affiliate-signup", element: <LayoutFrontend><AffiliateSignupPage /></LayoutFrontend> },
        { path: "/dashboard/discover", element: <PrivateRouteUser><LayoutFrontend><Discover /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/mycreation", element: <PrivateRouteUser><LayoutFrontend><MyCreation /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/allmodels", element: <PrivateRouteUser><LayoutFrontend><AllModels /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/aigenerations", element: <PrivateRouteUser><LayoutFrontend><MyGeneration /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/generate/:assistant_id", element: <PrivateRouteUser><LayoutFrontend><Generate /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/ImageView/:id", element: <PrivateRouteUser><LayoutFrontend><ImageView /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/gallery", element: <PrivateRouteUser><LayoutFrontend><Gallery /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/girls", element: <PrivateRouteUser><LayoutFrontend><Girls /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/Saved", element: <PrivateRouteUser><LayoutFrontend><Saved /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/ByEromanticai", element: <PrivateRouteUser><LayoutFrontend><ByEromanticai /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/generations", element: <PrivateRouteUser><LayoutFrontend><Generations /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/generatedImage/:id", element: <PrivateRouteUser><LayoutFrontend><ViewGeneratedImage /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/modelVerification", element: <PrivateRouteUser><LayoutFrontend><Modelverification /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/modality", element: <PrivateRouteUser><LayoutFrontend><Modality /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/creators", element: <PrivateRouteUser><LayoutFrontend><Creators /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/CreatorsProfile", element: <PrivateRouteUser><LayoutFrontend><CreatorsProfile /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/ModelverificationNextPage", element: <PrivateRouteUser><LayoutFrontend><ModelverificationNextPage /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/support", element: <PrivateRouteUser><LayoutFrontend><Support /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/SupportCategory", element: <PrivateRouteUser><LayoutFrontend><SupportCategory /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/CategoryDiscription", element: <PrivateRouteUser><LayoutFrontend><CategoryDiscription /></LayoutFrontend></PrivateRouteUser> },
        { path: "/dashboard/Notifications", element: <PrivateRouteUser><LayoutFrontend>< UserNotifications /></LayoutFrontend></PrivateRouteUser> },

    ]

    const openUrlsList = routes.filter(route =>
        !route.element.type.name.includes('PrivateRouteUser') &&
        !route.element.type.name.includes('PrivateRouteAdmin')
    ).map(route => ({ label: route.path, value: route.path }));
    // console.log(openUrlsList)
    routes.push({ path: "/admin/sitemaps", element: <PrivateRouteAdmin><Layout><SitemapList openUrlsList={openUrlsList} /></Layout></PrivateRouteAdmin> })

    return (
        <Routes>
            <>
                {routes.map((route, index) => (
                    <Route key={index} path={route.path} element={route.element} />
                ))}
            </>
        </Routes>
    );
};

export default MainRoute;
