import React, {useState} from 'react'
import { IoSearchOutline } from 'react-icons/io5'
import { bigtits,mediumtits,GiantTits,smalltits, info } from '../../assets'

const SearchExtension = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedIndextwo, setSelectedIndextwo] = useState(null);
  // Sample data for images and their titles
  const images = [
    { src: smalltits, title: 'Small Tits' },
    { src: mediumtits, title: 'Medium Saggy Tits 2' },
    { src: bigtits, title: 'Big Boobs' },
    { src: GiantTits, title: 'Huge Boobs' },
  ];
   const imagestwo = [
  ];
    return (
        <>
            <div className='flex justify-center gap-5 pt-10 md:pt-20'>
                <p className='font-semibold text-3xl xl:text-4xl pb-5'>Extensions</p>
                <img loading ='lazy' src={info} alt="infoBlack" className='h-6' />
            </div>
            <div className='rounded-xl py-6 px-6 '>
                <div className='px-5 py-2 flex border border-black w-full items-center rounded-full'>
                    <IoSearchOutline color='black' size={23} /> <input type="search" placeholder='Search Extension' className='bg-[#c6c5c5] focus:outline-none w-full text-black text-xl ps-3' />
                </div>
                <div
                  style={{
                    height: '30rem',
                    overflowY: 'scroll',
                    scrollbarWidth: 'thin', // For Firefox
                    scrollbarColor: '#c0c0c0 #f0f0f0', // For Firefox
                    borderRadius: '0.75rem', // Rounded corners
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                    padding: '1rem', // Padding inside the div
                  }}
                  className="scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 hover:scrollbar-thumb-gray-500"
                >
                <div className='bg-zinc-300 rounded-full w-32 my-5 py-2 font-semibold text-xl text-center text-gray-600'>
                    Boobs
                </div>
                 <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12'>
                  {images.map((item, index) => (
                  <div
                      key={index}
                      className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 ${selectedIndex === index ? 'bg-red-600 border-4 border-red-600' : ''}`}
                      onClick={() => setSelectedIndex(index)}
                    >
                      {/* Image element */}
                      <img loading ='lazy'
                        src={item.src}
                        alt={item.title}
                        className='w-full h-full object-cover cursor-pointer rounded-lg'
                      />

                      {/* Title overlay */}
                      <div
                        className={`absolute bottom-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 rounded-b-lg ${selectedIndex === index ? 'bg-red-600' : ''}`}
                        style={{ left: "0.01rem" }}
                      >
                        {item.title}
                      </div>

                  </div>
                ))}

                </div>
                <div className='bg-zinc-300 rounded-full w-32 my-5 py-2 font-semibold text-xl text-center text-gray-600'>
                    Ass
                </div>
                 <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12'>
                 <p className="text-black"> More Extensions On the Way.. </p>
                  {imagestwo.map((item, index) => (
                     <div
                    key={index}
                    className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 ${selectedIndextwo === index ? 'bg-red-600 border-4 border-red-600' : ''}`}                    onClick={() => setSelectedIndextwo(index)}
                    >
                      {/* Image element */}
                      <img loading ='lazy'
                      src={item.src}
                      alt={item.title}
                      className='w-full h-full object-cover cursor-pointer rounded-lg'
                    />

                      {/* Title overlay */}
                      <div
                        className={`absolute bottom-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 rounded-b-lg ${selectedIndextwo === index ? 'bg-red-600' : ''}`}                      style={{ left: "0.01rem" }}
                    >
                        {item.title}
                      </div>
                    </div>
                  ))}
                </div>
                </div>
            </div>
        </>
    )
}

export default SearchExtension