import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import AIGirlfriend from './AIGirlfriend';
import AIBoyfriend from './AIBoyfriend';

const CreateDreamGF = () => {
    const [activeComponent, setActiveComponent] = useState('');
    const navigate = useNavigate();
    const { location } = window;

    useEffect(() => {
        if (location.pathname === '/CreateDreamGF') {
            setActiveComponent('AIGirlfriend');
        } else if (location.pathname === '/BoyFriend') {
            setActiveComponent('AIBoyfriend');
        }
    }, [location.pathname]);

    const handleComponentChange = (component) => {
        setActiveComponent(component);
        navigate(component === 'AIGirlfriend' ? '/CreateDreamGF' : '/BoyFriend');
    };

    return (
        <div className=' px-5 xl:px-20 pb-20 pt-10 text-white font-josefin-sans' >
            <p className='text-black text-xl md:text-2xl py-2'>Generate New</p>
            <div className='w-full rounded-tr-xl rounded-br-xl rounded-tl-xl rounded-bl-xl flex justify-between lg:text-xl bg-red-600'>
                <button
                    className={`w-full flex rounded-tl-xl rounded-bl-xl items-center justify-center gap-10 md:gap-5  xl:px-10 py-3 ${activeComponent === 'AIBoyfriend' ? 'bg-red-600 hover:border-red-800' : 'bg-red-800'} md:justify-center  md:ps-0`}
                    onClick={() => handleComponentChange('AIGirlfriend')}
                >
                    <p>AI Girlfriend</p>
                </button>
                 <button
                    className={`w-full flex rounded-tr-xl rounded-br-xl items-center justify-center gap-10 md:gap-5  xl:px-10 py-3 ${activeComponent === 'AIGirlfriend' ? 'bg-red-600 hover:border-red-800' : 'bg-red-800'} md:justify-center  md:ps-0`}
                    onClick={() => handleComponentChange('AIBoyfriend')}
                >
                    <p>AI Boyfriend</p>
                </button>

            </div>
            {/* {activeComponent === 'AIBoyfriend' ?
                <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                    <span className='font-bold'>Looking for a simple chat?</span> Try our Basic AI Boyfriend for casual conversations. <br />
                    <span className='font-bold'>
                        Seeking a deeper connection?
                    </span>
                    Choose the Advanced AI Boyfriend for a more personalized and immersive experience.</p>
                :
                <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                    <span className='font-bold'>Looking for a simple chat?</span> Try our Basic AI Girlfriend for casual conversations. <br />
                    <span className='font-bold'>
                        Seeking a deeper connection?
                    </span>
                    Choose the Advanced AI Girlfriend for a more personalized and immersive experience.</p>
            } */}
            {activeComponent === 'AIGirlfriend' ? <AIGirlfriend /> : <AIBoyfriend />}
        </div>
    );
};

export default CreateDreamGF;
