import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteImg, eye } from '../../../assets';
import UserApiClient from '../../../utilities/apiClients';
import PagenameSearchBox from '../../components/PagenameSearchBox';
import AdminPagination from '../AdminPagination';


const Withdraw = () => {
    const navigate = useNavigate();
    const [affiliates, setAffiliates] = useState([]);
    const [activeTab, setActiveTab] = useState(1);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [referralDetails, setReferralDetails] = useState({});
    const [searchQuery, setSearchQuery] = useState(null);
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(null);
    const [pageSize, setPageSize] = useState(null);
    const [pageLinks, setPageLinks] = useState({ "next": null, "prev": null });

    const handleTabClick = async (tabNumber, change = false) => {
        setSearchQuery(null);
        if (activeTab != tabNumber || change) {
            setActiveTab(tabNumber);
        }
    };

    const fetchData = async (pageLink) => {
        await UserApiClient.get(pageLink && pageLink.includes("?page") ?
            `${pageLink}${"&search=" + (searchQuery != null ? searchQuery : '')}${"&status=" + (activeTab == "1" ? "pending" : activeTab == "2" ? "completed" : "cancelled")}${pageSize != null ? '&page_size=' + pageSize : ''}` :
            `api/v1/admin/user/withdrawals/${"?search=" + (searchQuery != null ? searchQuery : '')}${"&status=" + (activeTab == "1" ? "pending" : activeTab == "2" ? "completed" : "cancelled")}${pageSize != null ? '&page_size=' + pageSize : ''}`,
            {}, true).then(async res => {
                if (res.success) {
                    setPageLinks({ 'next': res.data.next, 'prev': res.data.previous });
                    setAffiliates(res.data.results);
                }
            })
    };

    useEffect(() => {
        fetchData();
    }, [activeTab]);

    const handleApprovedReject = async (e, status) => {
        if (referralDetails.id) {
            const data = {
                "is_paid": status,
                "status": status ? 'completed' : "cancelled",
            }
            await UserApiClient.patch(`api/v1/admin/user/withdrawals/${referralDetails.id}/`, data, true).then(async res => {
                if (res.success) {
                    await handleTabClick(1, true);
                }
            })
            closePopup();
        }
    };

    const openPopup = (data) => {
        setReferralDetails(data); // Set referral details
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setReferralDetails({});
    };

    const handlePrevNextPage = async (btn) => {
        if (btn === 'next') {
            fetchData(pageLinks.next);
        } else if (btn === 'prev') {
            fetchData(pageLinks.prev);
        }
    };

    useEffect(() => {
        if (searchQuery != null) {
            const timer = setTimeout(() => {
                setDebouncedSearchQuery(searchQuery);
            }, 700);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [searchQuery]);

    useEffect(() => {
        if (debouncedSearchQuery != null) {
            fetchData()
        }
    }, [debouncedSearchQuery]);

    useEffect(() => {
        if (pageSize != null) {
            fetchData()
        }
    }, [pageSize]);



    return (
        <>
            <div className='h-full bg-white px-7 pb-20 font-josefin-sans'>
                {/* Temporary removal of PagenameSearchBox */}
                <PagenameSearchBox
                    title="Withdrawal List"
                    placename="Search by email"
                    inputValue={searchQuery}
                    onSearchChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                />

                <div className="flex justify-between w-full">
                    <div className="flex space-x-4">
                        <button
                            onClick={() => handleTabClick(1)}
                            className={`px-4 py-2 m-2  font-semibold text-lg rounded-lg transition-colors duration-300 ${activeTab === 1 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'
                                }`}
                        >
                            Requests
                        </button>
                        <button
                            onClick={() => handleTabClick(2)}
                            className={`px-4 py-2 m-2  font-semibold text-lg rounded-lg transition-colors duration-300 ${activeTab === 2 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'
                                }`}
                        >
                            Approved
                        </button>
                        <button
                            onClick={() => handleTabClick(3)}
                            className={`px-4 py-2 m-2  font-semibold text-lg rounded-lg transition-colors duration-300 ${activeTab === 3 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'
                                }`}
                        >
                            Rejected
                        </button>
                    </div>
                    <div className="space-x-4">
                        <select className="py-1.5 px-3 focus:outline-none text-lg rounded" onChange={(e) => setPageSize(e.target.value)}>
                            <option value='20'>20</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                            <option value='500'>500</option>
                            <option value='1000'>1000</option>
                        </select>
                    </div>
                </div>
                <div className="rounded-lg overflow-x-auto sm:rounded-lg">
                    <table className="w-full text-left text-gray-500 border border-stone-300">
                        <thead className="text-xl text-black bg-stone-300">
                            <tr>
                                <th scope="col" className="px-6 py-3">ID</th>
                                <th scope="col" className="px-6 py-3">Email</th>
                                <th scope="col" className="px-6 py-3">Amount</th>
                                <th scope="col" className="px-6 py-3">Created At</th>
                                <th scope="col" className="px-6 py-3">Status</th>
                                <th scope="col" className="px-6 py-3 text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {affiliates.map((item) => (
                                <tr key={item.id} className="bg-white text-black text-xl font-normal border-b border-stone-300">
                                    <td className="px-6 py-4 whitespace-nowrap">#{item.id}</td>
                                    <td className="px-6 py-4 whitespace-nowrap cursor-pointer" onClick={() => navigate(`/admin/userdetail/${item.user_id}`)}>{item.user}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{item.amount}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{item.created_at}</td>
                                    <td className="px-6 py-4 whitespace-nowrap capitalize">
                                        {item.status}
                                    </td>
                                    <td className="px-6 py-4 flex gap-5 justify-center items-center">
                                        <img loading='lazy' src={eye} alt="view" className='hover:cursor-pointer' onClick={(e) => openPopup(item)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <AdminPagination pageLinks={pageLinks} handlePrevNextPage={handlePrevNextPage} />

            </div>

            {/* Modal */}
            {isPopupOpen && referralDetails && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-lg w-2/3 md:w-1/3">
                        <div className='flex justify-between relative'>
                            <h3 className="text-xl font-semibold mb-4">Referral Details</h3>
                            <button className="bg-red-500 text-white h-7 w-7 rounded-full absolute right-[-20px] bottom-[35px] " onClick={closePopup}> x </button>
                        </div>

                        {/* Referral Details */}
                        <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                            <strong className='mb-1'>ID:</strong> {referralDetails.id}
                        </p>
                        <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                            <strong className='mb-1'>Email:</strong> {referralDetails.user}
                        </p>
                        <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                            <strong className='mb-1'>Created At:</strong> {referralDetails.created_at}
                        </p>
                        <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                            <strong className='mb-1'>Paid:</strong> {referralDetails.is_paid ? 'Paid' : 'Unpaid'}
                        </p>
                        <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                            <strong className='mb-1'>Status:</strong> {referralDetails.status}
                        </p>
                        <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                            <strong className='mb-1'>Amount Requested:</strong> {referralDetails.amount}
                        </p>
                        <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                            <strong className='mb-1'>Description:</strong> {referralDetails.description}
                        </p>
                        {referralDetails.status == "pending" ? (
                            <div className="mt-4 flex flex-col md:flex-row flex-wrap gap-1 justify-between items-center">
                                <button
                                    className="bg-yellow-600 text-white  w-[48%] py-2 px-4 rounded"
                                    onClick={(e) => handleApprovedReject(e, false)}
                                >
                                    Reject
                                </button>
                                <button
                                    className="bg-green-600 text-white  w-[48%] py-2 px-4 rounded"
                                    onClick={(e) => handleApprovedReject(e, true)}
                                >
                                    Approve
                                </button>
                            </div>

                        ) : ''}
                    </div>
                </div>
            )}
        </>
    );
};

export default Withdraw;
