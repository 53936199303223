import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputBox from '../../components/InputBox';
import PagenameSearchBox from '../../components/PagenameSearchBox';
import UserApiClient from '../../../utilities/apiClients';

const AddFaqs = () => {
    const [question, setQuestion] = useState('');
    const [selectedPage, setSelectedPage] = useState(null);
    const [answer, setAnswer] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const routesList = [
        { name: 'Login', path: '/login' },
        { name: 'Join', path: '/join' },
        { name: 'Blogs', path: '/blogs' },
        { name: 'Single Blogs', path: '/singleblogs' },
        { name: 'Coming Soon', path: '/' },
        { name: 'Price Plan', path: '/priceplan' },
        { name: 'Terms of Service', path: '/terms-of-service' },
        { name: 'Privacy Policy', path: '/privacy-policy' },
        { name: 'Cookie Policy', path: '/cookie-policy' },
        { name: 'Dashboard', path: '/dashboard' },
        { name: 'Dashboard Account Profile', path: '/dashboard/accountProfile' },
        { name: 'Dashboard Referrals', path: '/dashboard/referrals' },
        { name: 'Dashboard Discover', path: '/dashboard/discover' },
        { name: 'Dashboard Image View', path: '/dashboard/ImageView' },
        { name: 'Dashboard Gallery', path: '/dashboard/gallery' },
        { name: 'Dashboard Girls', path: '/dashboard/girls' },
        { name: 'Dashboard Saved', path: '/dashboard/Saved' },
        { name: 'Dashboard By Eromanticai', path: '/dashboard/ByEromanticai' },
        { name: 'Dashboard Generations', path: '/dashboard/generations' },
        { name: 'Dashboard Model Verification', path: '/dashboard/modelVerification' },
        { name: 'Dashboard Modality', path: '/dashboard/modality' },
        { name: 'Dashboard Creators', path: '/dashboard/creators' },
        { name: 'Dashboard Creators Profile', path: '/dashboard/CreatorsProfile' },
        { name: 'Dashboard Model Verification Next Page', path: '/dashboard/ModelverificationNextPage' },
        { name: 'Dashboard Support', path: '/dashboard/support' },
        { name: 'Dashboard Support Category', path: '/dashboard/SupportCategory' },
        { name: 'Dashboard Category Description', path: '/dashboard/CategoryDiscription' },
    ].filter(route => !route.path.startsWith('/admin')); // Filter out admin routes

    const navigate = useNavigate();

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (name, path) => {
        setSelectedPage(path);
        setIsOpen(false);
    };

    const handleAddFAQ = async () => {
        if (!question || !answer) {
            setError('Please fill out all fields.');
            return;
        }

        await UserApiClient.post(`api/v1/admin/faq/`, { question, answer, status: 0 }, true).then(async res => {
            if (res.success) {
                setSuccess('FAQ added successfully!');
                setQuestion('');
                setSelectedPage(null);
                setAnswer('');
                navigate('/admin/faqs');
            }
        })
    };

    return (
        <div className='h-full bg-white px-7 pb-20 font-josefin-sans'>
            <PagenameSearchBox title='Add FAQ' btnname='Back to FAQs list' btnfunction={() => navigate('/admin/faqs')} />
            {error && <div className="text-red-500 mb-4">{error}</div>}
            {success && <div className="text-green-500 mb-4">{success}</div>}
            <div>
                <InputBox
                    title='Question'
                    placeholderName='What is the waiting period for accessing the platform after making a purchase?'
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                />
                {/* <label className='text-zinc-500 font-semibold text-[22px]'>Select Page</label>
                <div className="relative inline-block text-left w-[100%] mt-[10px]">
                    <div>
                        <button
                            type="button"
                            className="bg-white w-[100%] text-gray-400 px-2 py-3 border border-zinc-300 rounded-lg"
                            onClick={toggleDropdown}
                        >
                            <div className={`flex justify-between text-lg items-center px-5 ${selectedPage ? 'text-black' : 'text-zinc-300'}`}>
                                {selectedPage || 'Select Categories here...'}
                                <svg
                                    className="ml-2 h-5 w-5 inline-block"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4.25 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 01.02-1.06z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                        </button>
                    </div>

                    {isOpen && (
                        <div className="absolute w-full z-10 mt-2  bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                {routesList.map((route) => (
                                    <button
                                        key={route.path}
                                        className="block px-4 py-2 text-lg text-gray-700 hover:bg-gray-300 w-full text-start hover:text-gray-900"
                                        onClick={() => handleOptionClick(route.name, route.path)}
                                    >
                                        {route.name}
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                </div> */}
                <div className='pt-6'>
                    <label className='text-zinc-500 font-semibold text-[22px]'>Answer</label>
                    <textarea
                        className='bg-white focus:outline-none text-[22px] w-full break-before px-2 py-3 border mt-[10px] border-zinc-300 h-48 rounded-lg placeholder:text-zinc-300'
                        placeholder='If you choose to pay with a credit card (CCBill), you will gain access to the plan almost immediately...'
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                    />
                </div>
            </div>
            <button onClick={handleAddFAQ} className='rounded-lg w-full text-lg mt-10 bg-red-800 hover:bg-red-700 font-semibold text-white p-4'>
                Add FAQ
            </button>
        </div>
    );
};

export default AddFaqs;
