import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteImg, eye, rename, tableUser1, tableUser2 } from '../../../../assets'
import PagenameSearchBox from '../../../components/PagenameSearchBox'

const ActiveUser = () => {
    const [checked, setChecked] = useState(true)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showViewModal, setShowViewModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)

    const navigate = useNavigate()

    const handleBlog = () => {
        navigate('/admin/addUser')
    }

    const handleChange = () => {
        setChecked(!checked)
    }

    const openEditModal = (user) => {
        setSelectedUser(user)
        setShowEditModal(true)
    }

    const openViewModal = (user) => {
        setSelectedUser(user)
        setShowViewModal(true)
    }

    const openDeleteModal = (user) => {
        setSelectedUser(user)
        setShowDeleteModal(true)
    }

    const closeModals = () => {
        setShowEditModal(false)
        setShowViewModal(false)
        setShowDeleteModal(false)
    }

    return (
        <>
            <div className='h-full bg-white px-7 pb-20 font-josefin-sans'>
                {/* Search Bar and Heading */}
                <PagenameSearchBox 
                    title='User List' 
                    placename='Search User' 
                    btnname='Add User' 
                    btnfunction={handleBlog} 
                />

                {/* User Table */}
                <div className="relative overflow-x-auto sm:rounded-lg mt-10 font-josefin-sans">
                    <table className="w-full text-left text-gray-500 border border-stone-300 table-fixed sm:table-auto">
                        <thead className="text-xl text-black bg-stone-300">
                            <tr>
                                <th scope="col" className=" px-6 py-3 w-1/5">User</th>
                                <th scope="col" className=" px-6 py-3 w-1/5">Name</th>
                                <th scope="col" className=" px-6 py-3 w-1/5">Generations</th>
                                <th scope="col" className=" px-6 py-3 w-1/5">Plan</th>
                                <th scope="col" className=" px-6 py-3 w-1/5">Status</th>
                                <th scope="col" className=" px-6 py-3 text-center w-1/5">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-white font-normal text-black text-xl border-b border-stone-300">
                                <td className="px-6 flex items-center py-4 text-gray-900 whitespace-nowrap">
                                    <img loading ='lazy' src={tableUser2} alt="user2" onClick={() => openViewModal({ name: 'Gagandeep Singh', email: 'gagan.pyt@gmail.com' })} className='hover:cursor-pointer w-9 h-9 rounded-full' />
                                    <span className="ml-2">Gagandeep Singh</span>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap truncate w-1/5">gagan.pyt@gmail.com</td>
                                <td className="px-6 py-4 whitespace-nowrap">21929</td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className='rounded-full border text-base w-20 py-1 border-[#FFD700] bg-[#FFD7004A] text-[#FFD700]'>
                                        <p className='text-center'>Gold</p>
                                    </div>
                                </td>
                                <td className="px-6 py-4">
                                    <label className="inline-flex items-center cursor-pointer ">
                                        <input type="checkbox" className="sr-only peer" onChange={handleChange} checked={checked} />
                                        <div className="relative w-11 h-6 bg-red-500 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-500" />
                                    </label>
                                </td>
                                <td className="px-6 py-4">
                                    <div className='flex gap-2 justify-center items-center'>
                                        <img loading ='lazy' src={eye} alt="view" className='hover:cursor-pointer' onClick={() => openViewModal({ name: 'Gagandeep Singh', email: 'gagan.pyt@gmail.com' })} />
                                        <img loading ='lazy' src={rename} alt="rename" className='hover:cursor-pointer' onClick={() => openEditModal({ name: 'Gagandeep Singh', email: 'gagan.pyt@gmail.com' })} />
                                        <img loading ='lazy' src={DeleteImg} alt="delete" className='hover:cursor-pointer' onClick={() => openDeleteModal({ name: 'Gagandeep Singh', email: 'gagan.pyt@gmail.com' })} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Edit Modal */}
            {showEditModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-5 rounded-md w-96">
                        <h2 className="text-xl font-bold">Edit User</h2>
                        <input
                            type="text"
                            placeholder="Enter name"
                            className="w-full mt-4 p-2 border border-gray-300 rounded"
                            defaultValue={selectedUser?.name}
                        />
                        <input
                            type="email"
                            placeholder="Enter email"
                            className="w-full mt-4 p-2 border border-gray-300 rounded"
                            defaultValue={selectedUser?.email}
                        />
                        <div className="mt-4 flex justify-end gap-4">
                            <button className="bg-gray-500 text-white p-2 rounded" onClick={closeModals}>Cancel</button>
                            <button className="bg-blue-500 text-white p-2 rounded" onClick={closeModals}>Save</button>
                        </div>
                    </div>
                </div>
            )}

            {/* View Modal */}
            {showViewModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-5 rounded-md w-96">
                        <h2 className="text-xl font-bold">View User</h2>
                        <p className="mt-4"><strong>Name:</strong> {selectedUser?.name}</p>
                        <p className="mt-2"><strong>Email:</strong> {selectedUser?.email}</p>
                        <div className="mt-4 flex justify-end">
                            <button className="bg-gray-500 text-white p-2 rounded" onClick={closeModals}>Close</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Delete Modal */}
            {showDeleteModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-5 rounded-md w-96">
                        <h2 className="text-xl font-bold text-red-500">Delete User</h2>
                        <p>Are you sure you want to delete {selectedUser?.name}?</p>
                        <div className="mt-4 flex justify-end gap-4">
                            <button className="bg-gray-500 text-white p-2 rounded" onClick={closeModals}>Cancel</button>
                            <button className="bg-red-500 text-white p-2 rounded" onClick={closeModals}>Delete</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ActiveUser
