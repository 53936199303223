import React, { useEffect, useRef, useState } from 'react';
import UserApiClient from '../../utilities/apiClients';
import { useParams } from 'react-router-dom';

const InnerComponent = ({ template }) => {
    const [htmlData, setHtmlData] = useState('');

    useEffect(() => {
        UserApiClient.get(`api/v1/admin/template/${template}/`, {}, false).then(async res => {
            if (res.success) {
                setHtmlData(res.data);
            }
        })
    }, [template])
    return (
        <>
            {htmlData && <div dangerouslySetInnerHTML={{ __html: htmlData }} />}
        </>
    )
}

const ShowTemplatePages = () => {
    const innerComponentRef = useRef();
    const {template} = useParams();
    // const handleclick = () => {

    //     if (innerComponentRef.current) {
    //         console.log(innerComponentRef.current.innerHTML);
    //     }
    // }

    return (
        <>
            {/* <button onClick={handleclick}>button</button> */}
            <div ref={innerComponentRef}>
                <InnerComponent template={template} />
            </div>
        </>
    );
}


export default ShowTemplatePages;