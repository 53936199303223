import React, { useState } from 'react'
import { img1 } from '../../../assets';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const Modelverification = () => {

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
    };
    const navigate = useNavigate()

    const handleNextPage = () => {
        navigate('/dashboard/ModelverificationNextPage')
    }

    return (
        <>
            <div className='mt-20 lg:px-20 px-2 pb-20 bg-black pt-10 text-white font-josefin-sans'>
                <p className='text-3xl lg:text-4xl text-white font-medium text-center lg:mt-10'>Model verification</p>
                <div className="flex flex-col justify-center items-center w-full mt-5 md:mt-10">
                    <div className="flex justify-between mb-2 w-[90%] lg:w-[80%] xl:w-[50%] 2xl:w-[40%]">
                        <span className="text-left text-xl font-medium text-red-600">Selfie with consent</span>
                        <span className="text-right text-xl font-medium text-white">Verification</span>
                    </div>
                    <div>
                        <ol className="flex items-center w-[350px] md:w-[600px] m-auto ">
                            <li className="flex w-full items-center text-red-600 after:content-[''] after:w-full after:h-1 after:border-b after:border-red-600 after:border-4 after:inline-block">
                                <span className="flex items-center justify-center w-3 h-3 bg-red-600 rounded-full lg:h-6 lg:w-6 shrink-0"></span>
                            </li>
                            <li className="flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block">
                            </li>
                            <span className="flex items-center justify-center w-3 h-3 bg-gray-100 rounded-full lg:h-6 lg:w-6 shrink-0"></span>
                        </ol>
                    </div>
                </div>

                <div className="w-full px-5 sm:px-8 md:px-10 xl:px-20 py-7 xl:py-10 md:h-[650px] lg:h-[700px] xl:h-[900px] border-2 border-zinc-600 bg-[#6161614A] rounded-xl mt-6 md:mt-9">
                    <div className="flex flex-col sm:flex-row justify-center gap-6 xl:gap-10 2xl:gap-32">
                        <div className="flex flex-col justify-center gap-2 md:gap-3 lg:gap-6 lg:h-[550px] xl:h-full">
                            <p className=" text-2xl md:text-3xl lg:text-4xl font-normal text-center">Selfie with consent</p>
                            <div className=" md:w-80 xl:w-[484px] h-[300px] sm:h-[480px] md:h-[450px] xl:h-[650px] relative">
                                <img loading ='lazy' src={img1} alt="img1" className="w-full h-full object-cover rounded-2xl" />
                                <div className="text-lg sm:text-xl md:text-2xl xl:text-[41px] text-black bg-white absolute bottom-4 sm:bottom-6 md:bottom-8 xl:bottom-12 w-full p-2 sm:p-4 md:p-6 xl:p-8 rounded-xl">
                                    <p className="text-center font-kaushan-script">Consent for eromantic.ai</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col justify-start gap-2 md:gap-3 lg:gap-6 lg:h-[550px] xl:h-full pt-5 md:pt-20 w-full sm:w-[800px]">
                            <p className="text-base sm:text-lg md:text-xl xl:text-2xl font-semibold text-center w-full h-28 md:h-36 lg:h-32 xl:h-40">
                                If you are a model or pornstar and want to use our 'replicate face' feature, you must satisfy the below requirements.
                            </p>
                            <p className="text-3xl lg:text-4xl font-normal text-center h-24 sm:h-32 md:h-28 xl:h-52">2257 Model Release</p>
                            <p className="text-base sm:text-lg md:text-xl xl:text-2xl font-semibold text-center w-full h-16 sm:h-20 md:h-32 xl:h-40">
                                We are required by law to collect a signed 2257 model release form that certifies your consent.
                                <span className="text-red-700">Download form</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-center mt-6 sm:mt-9">
                        <button onClick={handleNextPage} className="bg-red-800 text-white text-base sm:text-lg md:text-xl xl:text-2xl font-medium rounded-full px-6 sm:px-8 md:px-10 py-2">
                            Next to upload Document
                        </button>
                    </div>
                </div>


                <p className='text-2xl lg:text-4xl text-white font-medium text-center mt-5 md:mt-10'>Images for face replication</p>

                <p className='text-lg md:text-xl lg:text-2xl text-white font-medium text-center mt-4'>To be approved, they must accurately represent the verified individual while ensuring that no other people are visible in the picture, focusing solely on the model. Be aware that our algorithms will rely on a single image as a reference. Therefore, we highly recommend providing a frontal headshot for optimal results.</p>

                <div className="flex justify-center items-center w-full md:h-[260px] lg:h-[320px] xl:h-[392px] border-2 border-zinc-600 bg-[#6161614A] rounded-xl py-3 px-5 mt-6 md:mt-9">
                    <div >
                        <label
                            htmlFor="dropzone-file"
                            className="flex flex-col items-center justify-center w-full md: rounded-lg cursor-pointer px-2 py-3"
                        >
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                {selectedImage ? (
                                    <img loading ='lazy'
                                        src={selectedImage}
                                        alt="Selected"
                                        className="w-full object-cover mb-4"
                                    />
                                ) : (<AiOutlinePlusCircle color='#616161' size={100} />)}
                                <input
                                    id="dropzone-file"
                                    type="file"
                                    className="hidden"
                                    onChange={handleImageChange}
                                />
                                <p className="mt-6 text-2xl text-zinc-600">
                                    {selectedImage ? 'Change Featured Image' : 'Add new image'}
                                </p>
                            </div>
                        </label>
                    </div>
                </div>


            </div>
        </>
    )
}

export default Modelverification