import React from 'react';

const CategoriesPage = () => {
  return (
    <div className="p-3 border-b">

<div className="flex items-center mt-[2rem] lg:mt-5 mb-10 w-full relative justify-center">
              <img
                loading="lazy"
                src="/assets/img/Rectangledream.png"
                alt="Icon"
                className="w-full h-auto rounded-xl"
              />
              <div className="absolute text-center">
                <div className="grid justify-center p-4 mr-4 sm:mr-6 lg:mr-10">
                  <div className="custombuttonsize text-white sm:text-xl lg:text-2xl xl:text-[100px] font-medium text-center">
                        Categories{" "}
                    {/* {/ <span className="text-red-500"> AI Girlfriend</span> /} */}
                  </div>
                  {/* <div className="text-center mt-1 lg:mt-10">
                    <button
                      className="custombuttonsize bg-red-800 text-white font-medium rounded-2xl p-2 sm:p-4 lg:px-6 py-1"
                    >
                      Create Your Dream Virtual AI Girlfriend
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/4">
          <h4 className="m-0 font-bold text-lg">Nude</h4>
        </div>
        <div className="w-full md:w-3/4">
          <div className="flex justify-between">
            {/* {/ Each link as a box /} */}
            <div className="p-1 w-1/2 md:w-1/4 lg:w-1/6">
              <a href="/categories/topless-girls" title="Topless" target="_blank" className="block text-center md:text-left my-2">
                Topless
              </a>
              <a href="/categories/topless-girls" title="Topless" target="_blank" className="block text-center md:text-left my-2">
                Topless
              </a>
              <a href="/categories/topless-girls" title="Topless" target="_blank" className="block text-center md:text-left my-2">
                Topless
              </a>
              <a href="/categories/topless-girls" title="Topless" target="_blank" className="block text-center md:text-left my-2">
                Topless
              </a>
            </div>
            <div className="p-1 w-1/2 md:w-1/4 lg:w-1/6">
              <a href="/categories/girls-pussy" title="Bend over showing pussy" target="_blank" className="block text-center md:text-left my-2">
                Bend over showing pussy
              </a>
              <a href="/categories/topless-girls" title="Topless" target="_blank" className="block text-center md:text-left my-2">
                Topless
              </a>
              <a href="/categories/topless-girls" title="Topless" target="_blank" className="block text-center md:text-left my-2">
                Topless
              </a>
            </div>
            {/* {/ Additional links formatted similarly /} */}
            <div className="p-1 w-1/2 md:w-1/4 lg:w-1/6">
              <a href="/categories/nude-girls" title="Nude Girl" target="_blank" className="block text-center md:text-left my-2">
                Nude Girl
              </a>
              <a href="/categories/topless-girls" title="Topless" target="_blank" className="block text-center md:text-left my-2">
                Topless
              </a>
              <a href="/categories/topless-girls" title="Topless" target="_blank" className="block text-center md:text-left my-2">
                Topless
              </a>
              <a href="/categories/topless-girls" title="Topless" target="_blank" className="block text-center md:text-left my-2">
                Topless
              </a>
            </div>
            {/* {/ Continue adding all other links here /} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesPage;
