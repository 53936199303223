import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import UserApiClient from "../../../utilities/apiClients";
import { DeleteImg, eye } from "../../../assets";
import {
  lock,
  multiUser,
  lockWhite,
  multiUserWhite,
  admininfo,
  adminsitemap,
  seoContant,
  homeMeta
} from "../../../assets";
import Swal from "sweetalert2";
import Dropdown_ct from "../../components/category_dropdown";

const SitemapList = ({ openUrlsList }) => {
  const [activeComponent, setActiveComponent] = useState("Seo Setting");
  const [sitemaps, setSitemaps] = useState([]);
  const [metaContentData, setMetaContentData] = useState([]);
  const [newSitemap, setNewSitemap] = useState(null);
  const [selectedMetaPage, setSelectedMetaPage] = useState(null);
  const [seoContent, setSeoContent] = useState({});
  const [currentMetaContent, setCurrentMetaContent] = useState({});
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [infoModel, setInfoModel] = useState({
    head: false,
    title: false,
    footer: false,
  });
  const [metaContent, updateMetaContent] = useState({
    title: "",
    description: "",
    keywords: "",
    page: "",
  });

  const [infoTooltips, setInfoTooltips] = useState({
    title: false,
    description: false,
    keywords: false,
    page: false,
  });

  const input = useRef();

  const [sitemapfile, setSitemapFile] = useState(null)
  const handleFileChange = (e) => {
    setSitemapFile(e.target.files[0])
  }

  const handleSitemapFile = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => { };
      reader.readAsDataURL(file);
      setNewSitemap(file);
    }
  };

  const fetchSecoContent = async () => {
    await UserApiClient.get("api/v1/admin/sco-content/", true).then(
      async (res) => {
        if (res.success) {
          setSeoContent(res.data);
        }
      }
    );
  };

  const fetchNotification = async () => {
    await UserApiClient.get("api/v1/sitemap/", false).then(async (res) => {
      if (res.success) {
        setSitemaps(res.data);
      }
    });
  };

  // const handleSubmitSitmap = async () => {
  //   if (!sitemapfile) {
  //     console.error("No sitemap file selected!");
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append('sitemap', sitemapfile);

  //   try {

  //     const response = await axios.post('https://test-xml-5nil.onrender.com/sitemap', formData);
  //     console.log("Sitemap uploaded successfully:", response.data);

  //   } catch (error) {
  //     if (error.response) {
  //       console.error("Error response:", error.response.data);
  //     } else if (error.request) {
  //       console.error("No response received:", error.request);
  //     } else {
  //       console.error("Error:", error.message);
  //     }
  //   }
  // };


  // const handleAddNotification = async () => {
  //   if (newSitemap) {
  //     await UserApiClient.post(
  //       "api/v1/sitemap/",
  //       { file: newSitemap },
  //       false
  //     ).then(async (res) => {
  //       if (res.success) {
  //         handleSubmitSitmap()
  //         await fetchNotification();
  //         if (input?.current) {
  //           input.current.value = null;
  //         }
  //         setNewSitemap(null);
  //       }
  //     });
  //   }
  // };

  // const handleRemove = async (id) => {
  //   await Swal.fire({
  //     title: "Are you sure?",
  //     text: `You won't be able to revert this!`,
  //     icon: "question",
  //     showCancelButton: true,
  //     cancelButtonColor: "grey",
  //     confirmButtonColor: "#d33",
  //     confirmButtonText: `Yes!`,
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       await UserApiClient.delete(`api/v1/sitemap/${id}/`, {}, false).then(
  //         async (res) => {
  //           if (res.success) {
  //             await fetchNotification();
  //             if (input?.current) {
  //               input.current.value = null;
  //             }
  //             setNewSitemap(null);
  //           }
  //         }
  //       );
  //     }
  //   });
  // };

  useEffect(() => {
    fetchNotification();
    fetchSecoContent();
    fetchMetaContent();
  }, []);

  useEffect(() => {
    updateMetaContent({ ...metaContent, page: selectedMetaPage })
  }, [selectedMetaPage]);

  const buttonData = [
    // {
    //   imgd: adminsitemap,
    //   name: "Sitemaps",
    // },
    {
      imgd: seoContant,
      name: "Seo Setting",
    },
    {
      imgd: homeMeta,
      name: "Home Meta",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    await UserApiClient.put("api/v1/admin/sco-content/", seoContent, true).then(
      async (res) => {
        if (res.success) {
          await fetchSecoContent();
          await Swal.fire(res.data.message, "", "success");
        }
      }
    );
  };

  const handleCombinedChange = (e) => {
    handleSitemapFile(e);
    handleFileChange(e);
  };

  const fetchMetaContent = async () => {
    await UserApiClient.get("api/v1/admin/meta-content/", {}, false).then(async (res) => {
      if (res.success) {
        setMetaContentData(res.data);
      }
    });
  };

  const handleSubmitMetaContent = async (e) => {
    e.preventDefault();
    if (!metaContent.title || !metaContent.description || !metaContent.keywords || !metaContent.page) {
      await Swal.fire({ title: 'alert!', text: 'Please fill all the fields.', timer: 3000, icon: 'warning' });
      return;
    }
    await UserApiClient.post("api/v1/admin/meta-content/", metaContent, true).then(async (res) => {
      if (res.success) {
        updateMetaContent({});
        await fetchMetaContent();
        await Swal.fire("Meta Content Added.", "", "success");
      }
    }
    );
  };

  const handleDeleteMetaContent = async (id) => {
    await Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert this!`,
      icon: "question",
      showCancelButton: true,
      cancelButtonColor: "grey",
      confirmButtonColor: "#d33",
      confirmButtonText: `Yes!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await UserApiClient.delete(`api/v1/admin/meta-content/${id}/`, {}, true).then(
          async (res) => {
            if (res.success) {
              await fetchMetaContent();
            }
          }
        );
      }
    });
  };

  const openDetailModal = (data) => {
    setCurrentMetaContent(data);
    setDetailModalOpen(true);
  };
  const closeDetailModal = () => {
    setDetailModalOpen(false);
  };

  return (
    <div className="mb-20">
      <div className="flex justify-center ms-10 items-center mb-5 mt-12 gap-2">
        {buttonData.map((item, index) => (
          <button
            key={index}
            className={`flex justify-center items-center gap-2 rounded-lg ${activeComponent === item.name
              ? "bg-black text-white"
              : "bg-white hover:bg-stone-300"
              } text-black text-lg py-2 px-5 lg:w-52`}
            onClick={() => setActiveComponent(item.name)}
          >
            <img
              loading="lazy"
              src={item.imgd}
              alt={item.name}
              className={`w-6 ${activeComponent === item.name ? "invert" : ""}`}
            />
            {/* {activeComponent === item.name ? <img loading='lazy' src={item.imga} alt={item.name} /> : <img loading='lazy' src={item.imgd} alt={item.name} />} */}
            {item.name}
          </button>
        ))}
      </div>

      { 
      // activeComponent === "Sitemaps" ? (
      //   <div className="flex flex-col items-center py-8 px-4">
      //     <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 mb-6">
      //       Sitemaps
      //     </h1>

      //     {/* Add New Notification */}
      //     <div className="w-full max-w-2xl mb-8 w-100">
      //       <h2 className=" text-lg md:text-xl font-semibold text-gray-700 mb-4">
      //         Add New Sitemap
      //       </h2>
      //       <div className="bg-white p-6 rounded-lg shadow-md">
      //         <input
      //           type="file"
      //           accept=".xml"
      //           className="w-full p-1 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      //           ref={input}
      //           onChange={handleCombinedChange}
      //         />
      //         <button
      //           onClick={handleAddNotification}
      //           className=" w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
      //         >
      //           Add Sitemap
      //         </button>
      //       </div>
      //     </div>

      //     {/* Display Current Notifications */}
      //     <div className="w-full max-w-2xl mb-8">
      //       <h2 className="text-lg md:text-xl font-semibold text-gray-700 mb-4">
      //         Sitemap List
      //       </h2>
      //       <div className="bg-white p-6 rounded-lg shadow-md">
      //         <ul className="space-y-4">
      //           {sitemaps.map((item) => (
      //             <li
      //               key={item.id}
      //               className="flex justify-between flex-wrap items-center p-2 bg-gray-100 rounded-lg"
      //             >
      //               <a
      //                 className="text-gray-800 break-words"
      //                 href={item.file}
      //                 target="_blank"
      //               >
      //                 {item.file_name || item.file}
      //               </a>
      //               <img
      //                 loading="lazy"
      //                 src={DeleteImg}
      //                 alt="Delete"
      //                 className="cursor-pointer"
      //                 onClick={() => handleRemove(item.id)}
      //               />
      //             </li>
      //           ))}
      //         </ul>
      //       </div>
      //     </div>
      //   </div>
      // ) : 
      activeComponent === "Seo Setting" ? (
        <form className="px-6 py-10" onSubmit={handleSubmit}>
          <p className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 mb-6 text-center">
            SEO Content
          </p>

          <div className="flex items-start gap-2">
            <label
              htmlFor="head"
              className="block text-zinc-600 font-semibold text-lg mb-2"
            >
              Head
            </label>
            <img
              loading="lazy"
              src={admininfo}
              alt="infoBlack"
              className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
              onMouseEnter={() => setInfoModel({ ...infoModel, head: true })}
              onMouseLeave={() => setInfoModel({ ...infoModel, head: false })}
            />
          </div>
          <div className="relative">
            {infoModel.head && (
              <span className="absolute w-80 left-20 bottom-4 bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                Insert critical SEO tags such as meta tags, alt attributes for
                images, and other headers that support crawling and indexing.
              </span>
            )}
          </div>
          <textarea
            required={true}
            value={seoContent.head}
            onChange={(e) =>
              setSeoContent({ ...seoContent, head: e.target.value })
            }
            id="head"
            name="head"
            className="border border-zinc-300 text-base font-normal focus:outline-none focus:ring-2 focus:ring-red-300 focus:border-transparent p-3 w-full h-28 mt-1 rounded-lg transition duration-200 ease-in-out"
            placeholder="Enter the header content here..."
          ></textarea>

          <div className="flex items-start gap-2">
            <label
              htmlFor="title"
              className="block text-zinc-600 font-semibold text-lg mb-2"
            >
              Title
            </label>
            <img
              loading="lazy"
              src={admininfo}
              alt="infoBlack"
              className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
              onMouseEnter={() => setInfoModel({ ...infoModel, title: true })}
              onMouseLeave={() => setInfoModel({ ...infoModel, title: false })}
            />
          </div>
          <div className="relative">
            {infoModel.title && (
              <span className="absolute w-60 left-20 bottom-4 bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                Tailor the title to reflect the main topics and keywords your
                page targets. This will boost relevance in search results.
              </span>
            )}
          </div>
          <textarea
            required={true}
            value={seoContent.title}
            onChange={(e) =>
              setSeoContent({ ...seoContent, title: e.target.value })
            }
            id="title"
            name="title"
            className="border border-zinc-300 text-base font-normal focus:outline-none focus:ring-2 focus:ring-red-300 focus:border-transparent p-3 w-full h-28 mt-1 rounded-lg transition duration-200 ease-in-out"
            placeholder="Enter the title content here..."
          ></textarea>

          <div className="flex items-start gap-2">
            <label
              htmlFor="footer"
              className="block text-zinc-600 font-semibold text-lg mb-2"
            >
              Footer
            </label>
            <img
              loading="lazy"
              src={admininfo}
              alt="infoBlack"
              className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
              onMouseEnter={() => setInfoModel({ ...infoModel, footer: true })}
              onMouseLeave={() => setInfoModel({ ...infoModel, footer: false })}
            />
          </div>
          <div className="relative">
            {infoModel.footer && (
              <span className="absolute w-60 left-24 bottom-4 bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                Use this space to incorporate important links and information,
                optimizing your site's navigation and SEO at the bottom of the
                page.
              </span>
            )}
          </div>
          <textarea
            required={true}
            value={seoContent.footer}
            onChange={(e) =>
              setSeoContent({ ...seoContent, footer: e.target.value })
            }
            id="footer"
            name="footer"
            className="border border-zinc-300 text-base font-normal focus:outline-none focus:ring-2 focus:ring-red-300 focus:border-transparent p-3 w-full h-28 mt-1 rounded-lg transition duration-200 ease-in-out"
            placeholder="Enter the footer content here..."
          ></textarea>

          <button
            type="submit"
            className="rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-500 focus:ring-opacity-50 font-semibold text-white text-lg p-4 transition duration-300"
          >
            Save
          </button>
        </form>
      ) : (
        <div className="my-10">
          <form className="px-6 py-10" onSubmit={handleSubmitMetaContent}>
            <p className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 mb-6 text-center">
              META Content
            </p>

            {/* Title Field */}
            <div className="flex items-start gap-2">
              <label
                htmlFor="title"
                className="block text-zinc-600 font-semibold text-lg mb-2"
              >
                Title
              </label>
              {/* Info Tooltip for Title */}
              <img
                loading="lazy"
                src={admininfo} // Make sure to replace this with a valid image URL
                alt="infoBlack"
                className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                onMouseEnter={() =>
                  setInfoTooltips({ ...infoTooltips, title: true })
                }
                onMouseLeave={() =>
                  setInfoTooltips({ ...infoTooltips, title: false })
                }
              />
            </div>
            {infoTooltips.title && (
              <div className="relative">
                <span className="absolute w-60 left-20 bottom-4 bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                  Meta Title: "Create a clear, engaging title that includes
                  important keywords for better visibility."
                </span>
              </div>
            )}
            <textarea
              required
              value={metaContent.title}
              onChange={(e) =>
                updateMetaContent({ ...metaContent, title: e.target.value })
              }
              id="title"
              name="title"
              className="border border-zinc-300 text-base font-normal focus:outline-none focus:ring-2 focus:ring-red-300 focus:border-transparent p-3 w-full h-28 mt-1 rounded-lg transition duration-200 ease-in-out"
              placeholder="Enter the title content here..."
            ></textarea>

            {/* Description Field */}
            <div className="flex items-start gap-2 mt-6">
              <label
                htmlFor="description"
                className="block text-zinc-600 font-semibold text-lg mb-2"
              >
                Description
              </label>
              {/* Info Tooltip for Description */}
              <img
                loading="lazy"
                src={admininfo} // Make sure to replace this with a valid image URL
                alt="infoBlack"
                className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                onMouseEnter={() =>
                  setInfoTooltips({ ...infoTooltips, description: true })
                }
                onMouseLeave={() =>
                  setInfoTooltips({ ...infoTooltips, description: false })
                }
              />
            </div>
            {infoTooltips.description && (
              <div className="relative">
                <span className="absolute w-60 left-36 bottom-4 bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                  Meta Description: "Write a brief, compelling description to
                  attract clicks and improve rankings."
                </span>
              </div>
            )}
            <textarea
              required
              value={metaContent.description}
              onChange={(e) =>
                updateMetaContent({ ...metaContent, description: e.target.value })
              }
              id="description"
              name="description"
              className="border border-zinc-300 text-base font-normal focus:outline-none focus:ring-2 focus:ring-red-300 focus:border-transparent p-3 w-full h-28 mt-1 rounded-lg transition duration-200 ease-in-out"
              placeholder="Enter the description content here..."
            ></textarea>

            {/* Keywords Field */}
            <div className="flex items-start gap-2 mt-6">
              <label
                htmlFor="keywords"
                className="block text-zinc-600 font-semibold text-lg mb-2"
              >
                Keywords
              </label>
              {/* Info Tooltip for Keywords */}
              <img
                loading="lazy"
                src={admininfo} // Make sure to replace this with a valid image URL
                alt="infoBlack"
                className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                onMouseEnter={() =>
                  setInfoTooltips({ ...infoTooltips, keywords: true })
                }
                onMouseLeave={() =>
                  setInfoTooltips({ ...infoTooltips, keywords: false })
                }
              />
            </div>
            {infoTooltips.keywords && (
              <div className="relative">
                <span className="absolute w-60 left-32 bottom-4 bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                  Meta Keywords: "Add 5-10 relevant keywords separated by commas
                  to help categorize your content.
                </span>
              </div>
            )}
            <textarea
              required
              value={metaContent.keywords}
              onChange={(e) =>
                updateMetaContent({ ...metaContent, keywords: e.target.value })
              }
              id="keywords"
              name="keywords"
              className="border border-zinc-300 text-base font-normal focus:outline-none focus:ring-2 focus:ring-red-300 focus:border-transparent p-3 w-full h-28 mt-1 rounded-lg transition duration-200 ease-in-out"
              placeholder="Enter keywords here, separated by commas..."
            ></textarea>

            {/* Page Select Field */}
            <div className="flex items-start gap-2 mt-6">
              <label
                className="block text-zinc-600 font-semibold text-lg mb-2"
              >
                Select Page
              </label>
              {/* Info Tooltip for Keywords */}
              <img
                loading="lazy"
                src={admininfo} // Make sure to replace this with a valid image URL
                alt="infoBlack"
                className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                onMouseEnter={() =>
                  setInfoTooltips({ ...infoTooltips, page: true })
                }
                onMouseLeave={() =>
                  setInfoTooltips({ ...infoTooltips, page: false })
                }
              />
            </div>
            {infoTooltips.page && (
              <div className="relative">
                <span className="absolute w-60 left-36 bottom-4 bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                  Page: Choose the page where you wish to put these meta data.
                </span>
              </div>
            )}
            <Dropdown_ct
              onChange={setSelectedMetaPage}
              options={openUrlsList}
              placeHolder={'Select page here.....'}
            />

            {/* Save Button */}
            <button
              type="submit"
              className="rounded-lg w-full mt-6 bg-red-800 hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-500 focus:ring-opacity-50 font-semibold text-white text-lg p-4 transition duration-300"
            >
              Save
            </button>
          </form>

          <div className="w-[90%] mx-auto">
            <div className="overflow-x-auto">
              <table className="w-full text-left text-gray-500 border border-stone-300">
                <thead className="text-xl text-black bg-stone-300">
                  <tr>
                    <th scope="col" className="px-2 2xl:px-6 py-3">Sr.</th>
                    <th scope="col" className="px-2 2xl:px-6 py-3">Title</th>
                    <th scope="col" className="px-2 2xl:px-6 py-3">Desc</th>
                    <th scope="col" className="px-2 2xl:px-6 py-3">Page</th>
                    <th scope="col" className="px-2 2xl:px-6 py-3">Created At</th>
                    <th scope="col" className="px-2 2xl:px-6 py-3 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {metaContentData.map((item, index) => (
                    <tr key={item.id} className="bg-white text-xl font-normal text-black border-b border-stone-300">
                      <td className="px-2 2xl:px-6 py-4">{index + 1}.</td>
                      <td className="px-2 2xl:px-6 py-4">{item.title.slice(0, 15)}.</td>
                      <td className="px-2 2xl:px-6 py-4">{item.description.slice(0, 15)}</td>
                      <td className="px-2 2xl:px-6 py-4">{item.page}</td>
                      <td className="px-2 2xl:px-6 py-4">{item.created_at}</td>
                      <td className="px-2 2xl:px-6 py-4 flex-wrap flex gap-5 justify-center items-center">
                        <img loading='lazy'
                          src={eye}
                          alt="eye"
                          className="cursor-pointer"
                          onClick={() => openDetailModal(item)}
                        />
                        <img loading='lazy'
                          src={DeleteImg}
                          alt="eye"
                          className="cursor-pointer"
                          onClick={() => handleDeleteMetaContent(item.id)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {detailModalOpen && (
        <div className="fixed inset-0 bg-gray-700 bg-opacity-75 flex justify-center items-center z-50">
          <div className="relative bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold">Details</p>
              <div className="cursor-pointer z-50" onClick={closeDetailModal}>
                <svg className="fill-current text-gray-700" width="18" height="18" viewBox="0 0 18 18">
                  <path d="M14.59 3.41L11.18 6.82L14.59 10.23L13.18 11.64L9.77 8.23L6.36 11.64L4.95 10.23L8.36 6.82L4.95 3.41L6.36 2L9.77 5.41L13.18 2L14.59 3.41Z" />
                </svg>
              </div>
            </div>
            <div className="space-y-2">
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">ID:</strong> {currentMetaContent.id}
              </p>
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">Title:</strong> {currentMetaContent.title}
              </p>
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">Description:</strong> {currentMetaContent.description}
              </p>
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">Page:</strong> {currentMetaContent.page}
              </p>
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">Created At:</strong> {currentMetaContent.created_at}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SitemapList;
