import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { updateMetaTags } from "../../components/SetMetadata"; // Adjust the import path as needed
import axios from "axios";
import { BaseApiUrl } from "../../../utilities/enviroment";

import Footer from "../../components/Footer";

const BASE_URL = BaseApiUrl + "api/"; 

const SingleBlog = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const location = useLocation();

  const [blog, setBlog] = useState(location.state?.slug || null);


  useEffect(() => {
    if (!blog) {
      const fetchBlog = async () => {
        try {
          const response = await axios.get(
            `${BASE_URL}details_blog/${slug}/`
          );
          setBlog(response.data);

          // Define meta tags
          const metaTags = {
            title: response.data.meta_title,
            description: response.data.meta_description,
            keywords: response.data.meta_keywords,
          };

          // Update meta tags
          updateMetaTags(metaTags);
        } catch (error) {
          console.error("Error fetching blog:", error);
        }
      };

      fetchBlog();
    }
  }, [blog, slug]);

  if (!blog) {
    return <div>Loading...</div>;
  }

  const handleBlog = () => {
    navigate("/blogs");
  };

  return (
    <div className="">
      <style>
        {`
          p, ol{
            color: #1f2937;
            font-size: 16px;
            line-height: 1.5; /* Adjusted for better readability */
          }

          .blog-title {
            font-size: 24px;
            text-align: center;
            color: #f1f1f1;
            padding-bottom: 20px;
          }
          h3 , h2{
            color: #990013;
          }
          h2 {
            color: #990013;
          }
          li {
            color: #1f2937;
            font-size: 14px;
          }

        `}
      </style>
      <div className=" flex justify-center px-5 xl:px-20">
        <div className="text-justify px-8 py-5 mt-16 font-josefin-sans max-w-[90%] mx-auto rounded-lg shadow-lg">
          <div className="flex justify-evenly items-baseline py-5">
            <button
              onClick={handleBlog}
              className="px-3 py-1 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] absolute text-[14px] md:text-lg left-0 top-20 md:top-24 md:left-24 transition-shadow duration-300 rounded-lg bg-[#99000B] text-white font-light cursor-pointer"
            >
              <i className="fa-solid fa-chevron-left fa-xs me-1"></i>
              Blog List
            </button>
          <p className="text-[24px] font-bold text-center text-[#990013] lg:text-[28px] xl:text-[32px] pb-5">
            {blog.title}
          </p>
          </div>
          <div className="flex justify-center">
            <img
              loading="lazy"
              src={blog.image}
              alt="LandingHeroImg"
              className="object-cover rounded-2xl max-w-full" // Make sure the image scales
              style={{ maxWidth: "70%" }} // Ensure the image scales within the max width
            />
          </div>
          <div
            className="md:text-lg lg:text-[22px] font-semibold text-slate-100 py-3 md:leading-8 lg:leading-[3rem]"
            dangerouslySetInnerHTML={{ __html: blog.description }}
          />
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default SingleBlog;
