// metaUtils.js
export function updateMetaTags(metaTags) {
    // Function to set or replace meta tags
    const setMetaTag = (name, content) => {
        if (name === 'title') {
            let headTitle = document.querySelector('head title');
            headTitle.textContent = content;
            return;
        }
        let metaTag = document.querySelector(`meta[name="${name}"]`);
        if (metaTag) {
            // Update existing meta tag
            metaTag.setAttribute('content', content);
        } else {
            // Create new meta tag
            metaTag = document.createElement('meta');
            metaTag.setAttribute('name', name);
            metaTag.setAttribute('content', content);
            document.head.appendChild(metaTag);
        }
    };

    // Function to remove a meta tag
    const removeMetaTag = (name) => {
        const metaTag = document.querySelector(`meta[name="${name}"]`);
        if (metaTag) {
            document.head.removeChild(metaTag);
        }
    };

    // Remove old meta tags
    const metaNames = ['title', 'description', 'keywords'];
    metaNames.forEach(name => removeMetaTag(name));

    // Set new meta tags
    Object.entries(metaTags).forEach(([name, content]) => {
        setMetaTag(name, content);
    });
}