import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DeleteImg,
  eye,
  rename,
  tableUser1,
  tableUser2,
} from "../../../assets";
import PagenameSearchBox from "../../components/PagenameSearchBox";
import UserApiClient from "../../../utilities/apiClients";
import Swal from "sweetalert2";
import AdminPagination from "../AdminPagination";

const AllUserType = () => {
  const [userList, setUserList] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [pageLinks, setPageLinks] = useState({ "next": null, "prev": null });
  const [searchQuery, setSearchQuery] = useState(null);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(null);
  const [pageSize, setPageSize] = useState(null);

  const navigate = useNavigate();

  const handleStatusChange = async (e, id) => {
    await Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert this!`,
      icon: "question",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await UserApiClient.patch(`api/v1/admin/user/${id}/`, { is_active: !e.target.checked }, true).then(async (res) => {
          if (res.success) {
            await handleTabClick(1, true);
            Swal.fire(`User ${e.target.checked ? "Inactivate" : "Activated"}.`, "", "success", 3000);
          }
        });
      } else {
        e.target.checked = e.target.checked;
      }
    });
  };

  const openEditModal = (user) => {
    setSelectedUser(user);
    setShowEditModal(true);
  };

  const openViewModal = (user) => {
    setSelectedUser(user);
    setShowViewModal(true);
  };

  const closeModals = () => {
    setShowEditModal(false);
    setShowViewModal(false);
  };

  const handlePrevNextPage = async (btn) => {
    if (btn === 'next') {
      fetchData(activeTab, pageLinks.next);
    } else if (btn === 'prev') {
      fetchData(activeTab, pageLinks.prev);
    }
  };

  // Handle tab click to change the active tab
  const handleTabClick = async (tabNumber, change = false) => {
    setSearchQuery('')
    if (activeTab != tabNumber || change) {
      // await fetchData(tabNumber);
      setActiveTab(tabNumber);
    }
  };

  const fetchData = async (status, pageLink) => {
    await UserApiClient.get(pageLink && pageLink.includes("?page") ?
      `${pageLink}${"&search=" + (searchQuery != null ? searchQuery : '')}${"&sub=" + activeTab}${pageSize != null ? '&page_size=' + pageSize : ''}` :
      `api/v1/admin/users/${"?search=" + (searchQuery != null ? searchQuery : '')}${"&sub=" + activeTab}${pageSize != null ? '&page_size=' + pageSize : ''}`,
      {},
      true
    ).then(async (res) => {
      if (res.success) {
        setPageLinks({ 'next': res.data.next, 'prev': res.data.previous });
        setUserList(res.data.results);
      }
    });
  };

  const handleExportCSV = async (csvData) => {
    // Create a Blob from CSV data
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'EromanticAi-UserDetails.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const handleExportTableToCSV = () => {
    const headers = ["ID", "Email", "First Name", "Last Name", "Username", "Address", "Country", "Date Joined", "Is Active", "Is Affiliated", "Is Requesting Affiliate", "Is Staff", "Is Superuser", "Last Login", "Phone Number", "Profile Pic", "Promotion Plan", "Ref Commission", "Ref Commission Raise", "Ref Source", "Ref Website", "Req Affiliate", "State", "Subscription Type", "Timezone", "Token Commission", "Token Commission Raise", "User ID", "Zip Code"];

    const rows = userList.map((item) => [
      item.id,
      item.email,
      item.first_name || "",
      item.last_name || "",
      item.username || "",
      item.address || "",
      item.country || "",
      item.date_joined,
      item.is_active ? "Active" : "Inactive",
      item.is_affiliated ? "Yes" : "No",
      item.is_requesting_affiliate ? "Yes" : "No",
      item.is_staff ? "Yes" : "No",
      item.is_superuser ? "Yes" : "No",
      item.last_login || "N/A",
      item.number || "N/A",
      item.profile_pic || "N/A",
      item.promotion_plan || "N/A",
      item.ref_commission,
      item.ref_commission_raise,
      item.ref_source || "N/A",
      item.ref_website || "N/A",
      item.req_affiliate ? "Yes" : "No",
      item.state || "N/A",
      item.subscription_type,
      item.timezone || "N/A",
      item.token_commission,
      item.token_commission_raise,
      item.user_id,
      item.zip_code || "N/A",
    ]);
    // Create CSV string
    const csvContent = [headers, ...rows].map((row) => row.join(",")).join("\n");
    handleExportCSV(csvContent);
  }

  const handleExportAllToCSV = async () => {
    await UserApiClient.get(`api/v1/admin/users/?no_pagination=true`, {}, true).then(async res => {
      if (res.success) {
        const header = Object.keys(res.data[0]);
        const rows = res.data.map(row =>
          header.map(fieldName => JSON.stringify(row[fieldName], (key, value) => value === null ? '' : value)).join(',')
        );

        const csvData = [header.join(','), ...rows].join('\r\n');
        handleExportCSV(csvData);
      } else {
        Swal.fire('Failed to get users data.', '', 'error');
      }
    })
  }


  useEffect(() => {
    fetchData();
  }, [activeTab]);

  useEffect(() => {
    if (searchQuery != null) {
      const timer = setTimeout(() => {
        setDebouncedSearchQuery(searchQuery);
      }, 700);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [searchQuery]);

  useEffect(() => {
    if (debouncedSearchQuery != null) {
      fetchData()
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (pageSize != null) {
      fetchData()
    }
  }, [pageSize]);


  return (
    <>
      <div className="h-full bg-white px-7 pb-20 font-josefin-sans">
        {/* Search Bar and Heading */}
        <PagenameSearchBox
          title="User Type"
          placename="Search by email"
          inputValue={searchQuery}
          onSearchChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
        />

        {/* User Table */}
        <div className="flex justify-center gap-4 lg:gap-8 lg:justify-between w-full flex-wrap items-center">
          <div className="flex items-center space-x-4">
            <button
              onClick={() => handleTabClick(0)}
              className={`px-4 py-2 m-2 font-semibold text-lg rounded-lg transition-colors duration-300 ${activeTab === 0
                ? "bg-[#000] text-white"
                : "bg-gray-200 text-gray-600"
                }`}
            >
              Non Subscribed
            </button>
            <button
              onClick={() => handleTabClick(1)}
              className={`px-4 py-2 m-2 font-semibold text-lg rounded-lg transition-colors duration-300 ${activeTab === 1
                ? "bg-[#000] text-white"
                : "bg-gray-200 text-gray-600"
                }`}
            >
              Subscribed Users
            </button>
          </div>
          <div className="flex items-start space-x-4">
            <select className="py-1.5 px-3 focus:outline-none text-lg rounded" onChange={(e) => setPageSize(e.target.value)}>
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
              <option value='500'>500</option>
              <option value='1000'>1000</option>
            </select>
            <button
              className=" bg-red-700 text-white py-2 px-4 rounded"
              onClick={handleExportTableToCSV}
            >
              Export Table
            </button>
            <button
              className=" bg-red-700 text-white py-2 px-4 rounded"
              onClick={handleExportAllToCSV}
            >
              Export All
            </button>
          </div>
        </div>
        <div className="relative overflow-x-auto rounded-lg mt-10 font-josefin-sans">
          <table className="w-full text-left text-gray-500 border border-stone-300 table-auto">
            <thead className="text-xl text-black bg-stone-300">
              <tr>
                <th scope="col" className=" px-6 py-3 w-1/5">
                  ID
                </th>
                <th scope="col" className=" px-6 py-3 w-1/5">
                  User
                </th>
                {/* <th scope="col" className=" px-6 py-3 w-1/5">Name</th> */}
                <th scope="col" className=" px-6 py-3 w-1/5">
                  Affiliate Id
                </th>
                <th scope="col" className=" px-6 py-3 w-1/5">
                  Sub./token Commission
                </th>
                <th scope="col" className=" px-6 py-3 w-1/5">
                  Plan
                </th>
                <th scope="col" className=" px-6 py-3 text-center w-1/5">
                  Join Date
                </th>
                <th scope="col" className=" px-6 py-3 w-1/5">
                  Status
                </th>
                <th scope="col" className=" px-6 py-3 text-center w-1/5">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {userList.map((item, index) => (
                <tr
                  className="bg-white font-normal text-black text-xl border-b border-stone-300"
                  key={item.id}
                >
                  <td className="px-6 py-4 whitespace-nowrap">#{item.id}</td>
                  <td
                    onClick={() => navigate(`/admin/userdetail/${item.id}`)}
                    className="px-6 flex items-center py-4 text-gray-900 whitespace-nowrap cursor-pointer"
                  >
                    {/* <img loading ='lazy' src={tableUser2} alt="user2" onClick={() => openViewModal({ name: 'Gagandeep Singh', email: 'gagan.pyt@gmail.com' })} className='hover:cursor-pointer w-9 h-9 rounded-full' /> */}
                    <span className="ml-2">{item.email}</span>
                  </td>
                  {/* <td onClick={() => navigate('/admin/userProfile')} className="px-6 py-4 whitespace-nowrap truncate w-1/5">gagan.pyt@gmail.com</td> */}
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item.affiliate_id || "Not created yet."}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item.ref_commission}/{item.ref_commission_raise} - {item.token_commission}/{item.token_commission_raise}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap capitalize">
                    {/* <div className='rounded-full border text-base w-20 py-1 border-[#FFD700] bg-[#FFD7004A] text-[#FFD700]'> */}
                    <p className="">{item.subscription_type?.replaceAll('_', ' ')}</p>
                    {/* </div> */}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <p className="">{item.date_joined?.split('T')[0]}</p>
                  </td>
                  <td className="px-6 py-4">
                    <label className="inline-flex items-center cursor-pointer ">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        onChange={(e) => handleStatusChange(e, item.id)}
                        checked={item.is_active}
                      />
                      <div className="relative w-11 h-6 bg-red-500 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-500" />
                    </label>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex gap-5 justify-center items-center">
                      <img
                        loading="lazy"
                        src={eye}
                        alt="view"
                        className="hover:cursor-pointer"
                        onClick={() => openViewModal(item)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <AdminPagination pageLinks={pageLinks} handlePrevNextPage={handlePrevNextPage} />

      </div>

      {/* Edit Modal */}
      {showEditModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-5 rounded-md w-96">
            <h2 className="text-xl font-bold">Edit User</h2>
            <input
              type="text"
              placeholder="Enter name"
              className="w-full mt-4 p-2 border border-gray-300 rounded"
              defaultValue={selectedUser?.name}
            />
            <input
              type="email"
              placeholder="Enter email"
              className="w-full mt-4 p-2 border border-gray-300 rounded"
              defaultValue={selectedUser?.email}
            />
            <div className="mt-4 flex justify-end gap-4">
              <button
                className="bg-gray-500 text-white p-2 rounded"
                onClick={closeModals}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white p-2 rounded"
                onClick={closeModals}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* View Modal */}
      {showViewModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-5 rounded-md w-[500px] relative landscape:h-[350px] overflow-auto" style={{scrollbarWidth:"none",scrollBehavior:"smooth"}}>
            <h2 className="text-xl font-bold">View User</h2>
            <div className="m-2">
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">ID:</strong> {selectedUser?.id}
              </p>
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">Affiliate Id:</strong>{" "}
                {selectedUser?.affiliate_id || "Not created yet."}
              </p>
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">Email:</strong> {selectedUser?.email}
              </p>
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">Date Joined:</strong>{" "}
                {selectedUser?.date_joined.split("T")[0]}
              </p>
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">Referred by:</strong>{" "}
                {selectedUser?.ref_by || "Unreferred"}
              </p>
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">Referral Commission:</strong>{" "}
                {selectedUser?.ref_commission}/
                {selectedUser?.ref_commission_raise}
              </p>
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">Token Commission:</strong>{" "}
                {selectedUser?.token_commission}/
                {selectedUser?.token_commission_raise}
              </p>
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">Balance:</strong>{" "}
                {selectedUser?.balance}
              </p>
              <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                <strong className="mb-1">Subscription Type:</strong>{" "}
                {selectedUser?.subscription_type}
              </p>
              <div className="mt-4 flex justify-end">
                <button
                  className="bg-gray-500 text-white p-2 rounded"
                  onClick={closeModals}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AllUserType;
