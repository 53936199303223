import React, { useState } from 'react';
import { AiFillLike } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

// Define a mapping for the colors and background colors
const colorClasses = {
    amber: {
        border: 'border-amber-600 text-amber-600',
        bg: 'bg-[#CD7F324A]',
    },
    stone: {
        border: 'border-stone-300 text-stone-300',
        bg: 'bg-[#C0C0C04A]',
    },
    yellow: {
        border: 'border-yellow-400 text-yellow-400',
        bg: 'bg-[#FFD7004A]',
    },
};

const ProfileCard = ({ color, type, imges }) => {
    const { border, bg } = colorClasses[color] || colorClasses.amber;
    const [isFollowing, setIsFollowing] = useState(false);

    const navigate = useNavigate();

    const handleProfile = () => {
        navigate('/dashboard/CreatorsProfile');
    }

    const handleFollowToggle = () => {
        setIsFollowing(!isFollowing);
    };

    return (
        <div className={`w-60 bg-[#6161614A] border ${border} rounded-lg p-4 flex flex-col items-center text-white relative`}>
            <div className={`absolute top-2 right-2 border ${border} ${bg} px-2 py-1 rounded-full text-xs`}>
                {type}
            </div>
            <img loading ='lazy'
                src={imges}
                alt="Profile"
                className="w-24 h-24 rounded-full object-cover hover:cursor-pointer"
                onClick={handleProfile}
            />
            <p className="mt-4 text-lg font-semibold">@gagan2058</p>
            <p className="text-gray-400">3522 Likes</p>
            <button
                className={`mt-4 w-full bg-[#61616166] border ${border} rounded-lg px-3 py-1`}
                onClick={handleFollowToggle}
            >
                <p className='text-center flex justify-center items-center gap-2'>
                    <AiFillLike color='white' /><span>{isFollowing ? 'Unfollow' : 'Follow'}</span>
                </p>
            </button>
        </div>
    );
};

export default ProfileCard;
