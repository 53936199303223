import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserApiClient from "../../../utilities/apiClients";
import { mountainGift, eyes, hair, occupation, personality, Bodyicon, Relationshipicon, Location, age, bookGift, alert, drinksGift, dildGift, flowersGift, necklaceGift, yachtGift, beachTrip, linjriGift, purseGift, blindfoldGift, dressGift, resortGift, playbutton, handcuffs, dinner } from "../../../assets";
import Swal from "sweetalert2";
import axios from "axios";
import { Helmet } from "react-helmet";
import { PiUserSoundFill } from "react-icons/pi";
import { IoVolumeHighSharp } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BaseApiUrl } from "../../../utilities/enviroment";
import CircularWithValueLabel from "./CircularWithValueLabel";

const getCurrentTime = () => {
    const now = new Date();
    return now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

const DetailSection = ({ icon, label, value }) => (
    <div className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out">
        <div className="flex items-center w-full gap-2">
            <img loading="lazy" className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md" src={icon} alt={`${label} icon`} />
            <div className="flex flex-wrap w-[80%] justify-between">
                <h3 className="text-gray-800 font-semibold text-base">{label}:</h3>
                <p className="text-gray-700 text-sm">{value}</p>
            </div>
        </div>
    </div>
);

const GiftImageData = [
    // { url: bananaGift, title: "Banana Gift", token: "" },
    // { url: ropesGift, title: "Ropes Gift", token: "", id: "" },
    // { url: beachGift, title: "Beach Gift", token: "", id: "" },
    // { url: dinnerGift, title: "Dinner Gift", token: "2T", id: "" },
    // { url: lockGift, title: "Lock Gift", token: "2T", id: "" },
    { url: bookGift, title: "Book", token: "1T", id: "1" },
    { url: drinksGift, title: "Drinks", token: "2T", id: "2" },
    { url: mountainGift, title: "Mountain", token: "4T", id: "3" },
    { url: dildGift, title: "Dildo", token: "2T", id: "4" },
    { url: flowersGift, title: "Flowers", token: "1T", id: "5" },
    { url: necklaceGift, title: "Necklace", token: "2T", id: "6" },
    { url: yachtGift, title: "Yacht", token: "8T", id: "7" },
    { url: beachTrip, title: "Beach", token: "5T", id: "8" },
    { url: purseGift, title: "Purse", token: "3T", id: "9" },
    { url: blindfoldGift, title: "Blindfold", token: "1T", id: "10" },
    { url: dressGift, title: "Dress", token: "4T", id: "11" },
    { url: resortGift, title: "Resort", token: "7T", id: "12" },
    { url: handcuffs, title: "Handcuffs", token: "2T", id: "13" },
    { url: dinner, title: "Dinner", token: "2T", id: "14" },
    { url: linjriGift, title: "Lingerie", token: "3T", id: "15" },
];

const Aidreamchat = () => {
    const [isFirstContainerOpen, setIsFirstContainerOpen] = useState(true);
    const [isThirdContainerOpen, setIsThirdContainerOpen] = useState(true);
    const [CharacterData, setCharacterData] = useState({});
    const [CharacterList, setCharacterList] = useState([]);
    const [ChatHistory, setChatHistory] = useState([]);
    const [ChatMessage, setChatMessage] = useState([]);
    const [chatTime, setChatTime] = useState('');
    const [CharacterImages, setCharacterImages] = useState([]);
    const [activeTab, setActiveTab] = useState('photo');
    const [GiftInputMsg, setGiftInputMsg] = useState('');
    const [UserInput, setUserInput] = useState('');
    const [IsGenerating, setIsGenerating] = useState(false);
    const [IsRecording, setIsRecording] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errorisOpen, setErrorIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedGift, setselectedGift] = useState({});
    const [speakingIndex, setSpeakingIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingupscale, setIsLoadingupscale] = useState({});
    const [Chatupscale, setchatupscale] = useState({});
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const { CharachterId } = useParams();
    const navigate = useNavigate();
    const scrollbarRef = useRef();
    const UserInputRef = useRef();
    const BASE_URL = BaseApiUrl;
    const audioRefs = useRef([]);

    const fetchCharacters = async () => {
        await UserApiClient.get('assistant_api/character/fetch/', {}, true).then(async (res) => {
            if (res.success) {
                setCharacterList(res.data);
                if (res.data.length === 0) {
                    await Swal.fire({
                        title: "warning!!!",
                        text: "You dont have any creation yext!!!",
                        icon: "warning",
                        timer: 3000,
                        showConfirmButton: true
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            navigate("/CreateDreamGF")
                        }
                    })
                }
            }
        }
        );
    }

    const fetchChatHistory = async () => {
        await UserApiClient.get(`assistant_api/fetch_history/?thread_id=${CharacterData.thread_id}`, {}, true, false, false).then(async (res) => {
            if (res.success) {
                setChatMessage([]);
                setChatHistory(res.data);
            }
        }
        );
    }

    const fetchCharacterImages = async () => {
        await UserApiClient.get(`assistant_api/character/image/?assistant_id=${CharacterData.assistant}&thread_id=${CharacterData.thread_id}`, {}, true, true, false).then(async (res) => {
            if (res.success) {
                setCharacterImages(res.data);
            }
        }
        );
    }

    const handleSelectCharacter = async (itemData) => {
        if (itemData.thread_id && (CharacterData.thread_id !== itemData.thread_id)) {
            setCharacterData(itemData);
        }
        if (isMobile) {
            setIsFirstContainerOpen(false);
        }
    }

    const handleRecording = () => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        const recognition = new SpeechRecognition();
        recognition.onstart = () => setIsRecording(true);
        recognition.onresult = (event) => setUserInput(event.results[0][0].transcript);
        recognition.onerror = (event) => console.error("Error occurred in recognition: " + event.error);
        recognition.onend = () => setIsRecording(false);
        recognition.start();
    }

    const handleSendMessage = async () => {
        if ((UserInput && UserInput !== '') || selectedGift.id) {
            const imageRequestKeywords = [
                "image", "picture", "pic", "selfie", "send", "see", "photo", "photograph", "visual", "screenshot", "drawing",
                "artwork", "graphic", "illustration", "show me", "send me", "provide", 'Gift',
                "share", "can I see", "want to see", "a picture of", "can you show me a photo of"
            ];
            const isImageRequest = imageRequestKeywords.some(keyword =>
                UserInput.toLowerCase().includes(keyword)
            );
            if (isImageRequest || selectedGift.id !== undefined) {
                setIsLoadingImage(true);
            } else {
                setIsGenerating(true);
            }
            setChatMessage((prev) => [
                ...prev,
                {
                    text: selectedGift.id ? GiftInputMsg : UserInput,
                    sender: "user",
                    time: getCurrentTime(),
                    giftid: selectedGift.id,
                },
            ]);
            setIsPopupOpen(false);
            setGiftInputMsg('');
            setselectedGift({});
            setUserInput('');
            await UserApiClient.post(`assistant_api/bot_chat/`, {
                "assistant_id": CharacterData.assistant,
                "message": !selectedGift.id ? UserInput : GiftInputMsg !== '' ? GiftInputMsg : selectedGift.title,
                "giftid": selectedGift.id || '',
            }, true, false, false).then(async res => {
                setIsGenerating(false);
                setIsLoadingImage(false);
                if (res.success) {
                    setChatTime(res.data);
                    setChatMessage((prev) => [
                        ...prev,
                        {
                            text: res.data.response_message,
                            img_url: res.data.img_url,
                            sender: "bot",
                            time: getCurrentTime(),
                            thread_id: res.data.thread_id,
                            upscale_status: res.data.upscale_status,
                        },
                    ]);
                }
            })
        }
    }

    const handleResetChat = async () => {
        await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            // cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                await UserApiClient.delete(`assistant_api/chat_delete/`, { 'thread_id': CharacterData.thread_id }, true).then(async (res) => {
                    if (res.success) {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your chat has been deleted.",
                            icon: "success",
                            timer: 2000,
                        });
                        await fetchChatHistory();
                    }
                }
                );
            }
        });
    }

    const handleUpscaleImage = async (img_url, thread_id, index) => {
        setIsLoadingupscale((prev) => ({ ...prev, [img_url]: true }));
        const img_url_split = img_url.split('/');
        await UserApiClient.post(`assistant_api/chat/image/upscale/`, { 'thread_id': thread_id, 'img_url': img_url }, true, false, false).then(async (res) => {
            if (res.success) {
                toast.success("1.00 token has been deducted.", {
                    position: "top-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                fetchChatHistory();
                setIsLoadingupscale((prev) => ({ ...prev, [img_url]: false }));
                // setchatupscale(res.data.old_url);
                setchatupscale((prev) => ({ ...prev, [res.data.old_url]: res.data.old_url }));
            } else {
                setIsLoadingupscale((prev) => ({ ...prev, [img_url]: false }));
            }
        });
    }

    const toggleFirstContainer = () => {
        setIsFirstContainerOpen(!isFirstContainerOpen);
    };

    const toggleThirdContainer = () => {
        setIsThirdContainerOpen(!isThirdContainerOpen);
    };

    const handleChatScoll = () => {
        setTimeout(() => {
            if (scrollbarRef.current) {
                scrollbarRef.current.scrollTop = scrollbarRef.current.scrollHeight;
            }
        }, 100);
    };

    useEffect(() => {
        const sub_type = localStorage.getItem('subscription_type')
        if (sub_type !== 'free') {
            fetchCharacters();
        } else {
            setErrorIsOpen(true);
        }
    }, [CharachterId]);

    useEffect(() => {
        handleChatScoll();
    }, [ChatHistory, ChatMessage, IsGenerating]);

    useEffect(() => {
        if (CharacterData.thread_id) {
            fetchChatHistory();
            fetchCharacterImages();
        }
    }, [CharacterData]);

    useEffect(() => {
        CharacterList.map(item => {
            if (item.assistant === CharachterId) {
                setCharacterData(item);
            }
        })
        if (!CharachterId && CharacterList.length > 0) {
            navigate(`/aichat/${CharacterList[0].assistant}`);
        }
    }, [CharacterList]);

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsFirstContainerOpen(false);
            setIsThirdContainerOpen(false);
            setIsMobile(window.innerWidth <= 768);
        }
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (!IsGenerating) {
            UserInputRef.current?.focus();
        }
    }, [IsGenerating, CharacterData])

    // Speeking

    const Voices = CharacterData.Breast_Size
        ? [
            { src: playbutton, title: "Natural", tab: "Voice", id: "LcfcDJNUP1GQjkzn1xUU", },
            { src: playbutton, title: "Formal", tab: "Voice", id: "mBoEKrpgGV9T9hW3aApV", },
            { src: playbutton, title: "Casual", tab: "Voice", id: "z9fAnlkpzviPz146aGWa", },
            { src: playbutton, title: "Emotional", tab: "Voice", id: "oWAxZDx7w5VEj9dCyTzz", },
            { src: playbutton, title: "Romantic", tab: "Voice", id: "pM7QPMisGYZVwLR9oQj6", },
            { src: playbutton, title: "Seductive", tab: "Voice", id: "HnuJZzPKTgcHaCYl1KRF", },
            { src: playbutton, title: "Warm", tab: "Voice", id: "thfYL0Elyru2qqTtNQsE", },
            { src: playbutton, title: "Loved", tab: "Voice", id: "pFZP5JQG7iQjIQuC4Bku", },
            { src: playbutton, title: "Charming", tab: "Voice", id: "EaBs7G1VibMrNAuz2Na7", },
            { src: playbutton, title: "Gentle", tab: "Voice", id: "eVItLK1UvXctxuaRV2Oq", },
        ]
        : [
            { src: playbutton, title: "Natural", tab: "Voice", id: "GBv7mTt0atIp3Br8iCZE", },
            { src: playbutton, title: "Formal", tab: "Voice", id: "ChTmpsMNHelv22TdNq0c", },
            { src: playbutton, title: "Casual", tab: "Voice", id: "tcO8jJ1XXzdQ4pzViV9c", },
            { src: playbutton, title: "Emotional", tab: "Voice", id: "xrNwYO0xeioXswMCcFNF", },
            { src: playbutton, title: "Romantic", tab: "Voice", id: "CwhRBWXzGAHq8TQ4Fs17", },
            { src: playbutton, title: "Seductive", tab: "Voice", id: "TX3LPaxmHKxFdv7VOQHJ", },
            { src: playbutton, title: "Warm", tab: "Voice", id: "mCQMfsqGDT6IDkEKR20a", },
            { src: playbutton, title: "Loved", tab: "Voice", id: "29vD33N1CtxCmqQRPOHJ", },
            { src: playbutton, title: "Charming", tab: "Voice", id: "iP95p4xoKVk53GoZ742B", },
            { src: playbutton, title: "Gentle", tab: "Voice", id: "fnTkWBxNUMwv63t10YIq", },
        ];

    const findVoiceIdByTitle = (title) => {
        const voice = Voices.find((voice) => voice.title === title);
        return voice ? voice.id : null;
    };
    const removeContentBetweenAsterisks = (text) => {
        return text.replace(/\*\*.*?\*\*/g, "");
    };

    const handlePlayAudiomsgs = async (item, index) => {
        setSpeakingIndex((prevIndex) => (prevIndex === index ? null : index));
        const msgreplce = removeContentBetweenAsterisks(item.ai_response || item.text);
        const text = msgreplce.replaceAll("**", "");
        const voicetitle = CharacterData.Voice;
        const lindyVoice = CharacterData.assistant === "asst_YvJS8Z4bpgkkvV6ziJgq9Gv8";
        const voiceId = lindyVoice == true ? "3nhkXfQl69VupBWanRWn" : findVoiceIdByTitle(voicetitle);
        const apiKey = "sk_fd230ee022b2e4b901f2c72cb37ac59964573c9325d74248";
        const voiceSettings = { stability: 0.5, similarity_boost: 0.75, };
        try {
            setIsLoading(true);
            setActiveIndex(index); // Track active audio being fetched

            const response = await fetch(
                `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}/stream`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "xi-api-key": apiKey,
                    },
                    body: JSON.stringify({
                        text,
                        voice_settings: voiceSettings,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error(`Failed to fetch audio stream: ${response.statusText}`);
            }

            // Convert the response to a Blob and create an audio URL
            const audioBlob = await response.blob();
            const audioUrl = URL.createObjectURL(audioBlob);

            // Set the audio source and play
            if (audioRefs.current[index]) {
                audioRefs.current[index].src = audioUrl;
                audioRefs.current[index].play();
                audioRefs.current[index].onended = () => {
                    setSpeakingIndex(null);  // Reset speakingIndex when audio is finished
                };
            }
        } catch (error) {
            console.error("Error fetching or playing audio:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePlayAudiomsg = async (item, index) => {
        try {
            const authToken = localStorage.getItem("token");
            const response = await axios.post(`${BASE_URL}assistant_api/audio/token/credit/`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200 || response.status === 201) {
                await handlePlayAudiomsgs(item, index);
                toast.success("1.00 token has been deducted.", {
                    position: "top-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "You don't have enough Tokens!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "ok",
            })
            console.error("Error fetching message history:", error);
        }
    }
    // Speeking

    return (
        <>
            <Helmet>
                <title>The Best AI Girlfriend Chatbot Online - Eromantic.ai</title>
                <meta
                    name="description"
                    content="Are you looking for AI generated girl to chat with? At Eromantic.ai you can make custom AI gf/bf chatbots and enjoy their company!"
                />
                <meta
                    name="keywords"
                    content="AI Girlfriend, Virtual Girlfriend, Anime Companions, Interactive AI, NSFW-friendly"
                />
                {/* You can add more meta tags if needed */}
            </Helmet>
            <ToastContainer />
            <div className="flex h-[calc(100vh-100px)] md:h-[calc(100vh-1px)]">
                {/* First Container */}
                <div className="">
                    <div className={`w-full h-full basis-2/5 transform transition-all duration-300 ease-in-out  
                ${isMobile ? "absolute z-50 bg-gray-200" : ""} ${isFirstContainerOpen ? "" : "hidden"}`}>
                        <div className="flex p-4 pt-6 w-full items-center justify-between font-semibold text-white/70">
                            <span className="text-xl text-[#484848]">Messages</span>
                            <button className="flex justify-center items-center border text-sm h-8 w-8 rounded-2xl border-none bg-[#0000009c]" onClick={toggleFirstContainer}>
                                <span className="iconfont-custom icon-pickuprightIcon text-3xl font-light text-white/70"></span>
                            </button>
                        </div>
                        {/* </div> */}

                        <div className="flex-grow items-center justify-center overflow-y-auto custom-scrollbar" style={{ overflowX: "hidden", scrollbarWidth: "none", scrollbarColor: "#81050559 transparent", }}>
                            <ul className="w-full px-4 pt-0 h-[calc(100vh-200px)] md:h-[calc(100vh-91px)]">
                                {CharacterList.map((item, index) => (
                                    <li key={index} onClick={() => { if (!IsGenerating && !IsRecording) handleSelectCharacter(item) }} className="flex items-center justify-center gap-2 border-[#000] text-black rounded-xl bg-[#0e0e0e3d] min-h-[5rem] my-[10px] p-2 cursor-pointer">
                                        <div className="w-16 h-16">
                                            <img loading="lazy" src={item.img_url} alt="userProfile" className="w-16 h-16 rounded-full object-cover object-top" />
                                        </div>
                                        <div className="px-2"> <div className="flex items-center justify-between">
                                            <p className="text-lg">{item.name} </p>
                                            <p className="text-lg">
                                                {item.last_msg == null ? ""
                                                    : item.thread_id === chatTime.thread_id
                                                        ? new Date(chatTime.chat_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                                        : new Date(item.last_msg?.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false, })}
                                                {/* {item.last_msg == null ? "" : new Date(item.last_msg?.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                                <span className="inline-block w-3 h-3 bg-green-600 rounded-full mx-3 mb-[2px] border-2 border-green-600 shadow-md shadow-green-800"></span>
                                            </p>
                                        </div>
                                            <div style={{ width: "15rem" }}>
                                                {item.last_msg ? <span className="text-sm">{item.last_msg?.text?.slice(0, 30) + "..."}</span> : <span className="text-sm">No message available.</span>}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Main Content */}
                <div className={`${isMobile ? "p-0" : "p-4"} w-full h-full flex flex-col relative`} >
                    <div className="absolute top-0 left-0 right-0 bottom-0 -z-10 blur-sm" style={{ backgroundImage: `url(${CharacterData.img_url})`, backgroundSize: 'cover', backgroundPosition: 'center', }}></div>
                    {/* <img loading ='lazy' alt="background" className="absolute h-full w-full  object-cover object-center"
                    src={blurredImageUrl} style={{ color: "transparent", filter: "blur(10px)" }} /> */}
                    {/* Header section */}
                    <div className={`${isMobile ? "fixed bg-gradient-to-r from-[#c6bcbd] to-[#9a8f8f] overflow-hidden p-[10px] z-30" : "relative"} z w-full flex items-center justify-between`}>
                        <div className="flex items-center">
                            {!isFirstContainerOpen && (
                                <button className="flex justify-center items-center border text-sm h-8 w-8 mr-2 rounded-2xl border-none bg-[#0000009c]"
                                    onClick={toggleFirstContainer}>
                                    <span className="iconfont-custom icon-pickupLiftIcon text-3xl font-light text-white/70"></span>
                                </button>
                            )}
                            <img loading="lazy" alt="avatar" className="object-cover object-top w-12 h-12 rounded-full mr-2" src={CharacterData.img_url} />
                            <div className="text-base text-white">
                                <span className="font-medium text-lg capitalize line-clamp-1">{CharacterData.name}</span>
                            </div>
                        </div>

                        {/* Dropdown button */}
                        <div className="flex items-center">
                            <div className="relative flex h-7 w-7 items-center justify-center rounded-full bg-white hover:bg-gray-200 focus-visible:shadow-none group" onClick={handleResetChat}>
                                <i className="fa-solid fa-rotate"></i>
                                <span className="absolute w-[80px] right-0 transform -translate-x-1/2 bg-black text-white text-xs rounded-md px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity">
                                    Reset chat
                                </span>
                            </div>
                            {!isThirdContainerOpen && (
                                <button className="flex justify-center items-center border text-sm h-8 w-8 ml-2 rounded-2xl border-none bg-[#0000009c]"
                                    onClick={toggleThirdContainer}>
                                    <span className="iconfont-custom icon-pickuprightIcon text-3xl font-light text-white/70"></span>
                                </button>
                            )}
                        </div>
                    </div>

                    {/* Chat container */}
                    <div className="h-[calc(100vh-225px)] md:h-[calc(100vh-170px)] overflow-y-auto py-5 mt-[5rem] mb-[4rem] md:my-5 px-4" ref={scrollbarRef} style={{ scrollbarWidth: "none" }}>
                        <div>
                            {/* For Prev History */}
                            {ChatHistory.map((item, index) =>
                                <>
                                    {item.user_message &&
                                        <div className="flex justify-end my-2">
                                            <div key={index} className={`userchat max-w-[60%] border-[#88ceff] flex p-2.5 pl-4`}>
                                                {item.giftid ? (
                                                    <div key={index} className="">
                                                        {GiftImageData.map(giftItem => (
                                                            item.giftid === giftItem.id &&
                                                            <div className="flex flex-col flex-wrap justify-center items-center">
                                                                <img src={giftItem.url} className="w-20" alt={giftItem.title} />
                                                                {item.user_message}
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    item.user_message
                                                )}
                                            </div>
                                        </div>}
                                    {item.ai_response &&
                                        <>
                                            <div className="flex justify-start my-2">
                                                <div key={"#" + index} className={`aichat max-w-[60%] border-[#88ceff] flex p-2.5 pl-4`}>
                                                    {/* {item.ai_response} */}
                                                    {item.ai_response && item.ai_response.includes("**") ? (
                                                        <span>
                                                            {item.ai_response
                                                                .split(/\*\*(.*?)\*\*/)
                                                                .map((part, idx) =>
                                                                    idx % 2 === 1 ? (
                                                                        <span
                                                                            key={idx}
                                                                            style={{
                                                                                fontSize: "14px",
                                                                                fontStyle: "italic",
                                                                                color: "[#5c4242]",
                                                                            }}
                                                                        >
                                                                            *{part}*
                                                                        </span>
                                                                    ) : (
                                                                        <span key={idx}>{part}</span>
                                                                    )
                                                                )}
                                                        </span>
                                                    ) : (
                                                        <span>{item.ai_response}</span>
                                                    )}
                                                    {/* Audio */}
                                                    <div className="">
                                                        <div className={`text-red-500 ml-2 bg-[#ffffff] p-[5px] rounded-full shadow-lg ${speakingIndex === index ? "disable" : ""}`}
                                                            style={speakingIndex === index ? { pointerEvents: 'none', opacity: 0.5 } : {}}
                                                            onClick={() => speakingIndex !== index && handlePlayAudiomsg(item, index)} >
                                                            {speakingIndex === index ? (
                                                                <PiUserSoundFill />
                                                            ) : (
                                                                <IoVolumeHighSharp />
                                                            )}
                                                        </div>
                                                        <audio ref={(el) => (audioRefs.current[index] = el)}
                                                            className={`audio-player mt-4 w-full ${activeIndex === index && !isLoading ? "hidden" : "hidden"}`} controls />
                                                    </div>
                                                    {/* Audio */}
                                                </div>
                                            </div>
                                            {/* {item.img_url &&
                                                <div className="relative">
                                                    <div className={`w-[50%] h-[50%] absolute top-[25%] left-0 flex items-center justify-center transform -translate-x-0 -translate-y-0 ${isLoadingupscale == item.img_url ? "block" : "hidden"}`}>
                                                        <div className="loader"></div>comment this
                                                        <CircularWithValueLabel isLoadingAIResponse={isLoadingupscale} loadingText="Loading.." />
                                                    </div>
                                                    <div className={`${isLoadingupscale == item.img_url ? "hidden" : "block"}`}>
                                                        {item.upscale_status || Chatupscale.upscale_status ?comment this
                                                        {item.upscale_status || item.img_url == Chatupscale.old_url ?
                                                            null
                                                            :
                                                            <button onClick={() => handleUpscaleImage(item.img_url, item.thread_id)} className={`absolute top-3 left-4 px-4 rounded-full text-white font-semibold bg-gradient-to-r from-[#990013] to-red-500 shadow-xl transition-all duration-300 transform hover:scale-110`}>
                                                                Upscale<i className="fa-solid fa-arrow-up-wide-short ml-1"></i>
                                                            </button>
                                                        }
                                                    </div>
                                                    <img loading='lazy' onLoad={handleChatScoll} src={item.img_url} onClick={() => { setSelectedImage(item.img_url); setIsModalOpen(true); }}
                                                        alt="img"
                                                        className={`w-[50%] my-2 rounded-xl ${isLoadingupscale == item.img_url ? "opacity-20 pointer-events-none" : "opacity-100"}`}
                                                    />
                                                </div>
                                                } */}
                                            {item.img_url &&
                                                <div className="relative">
                                                    <div className={`w-[50%] h-[50%] absolute top-[25%] left-0 flex items-center justify-center transform -translate-x-0 -translate-y-0 ${isLoadingupscale[item.img_url] ? "block" : "hidden"}`}>
                                                        <CircularWithValueLabel isLoadingAIResponse={isLoadingupscale} loadingText="Loading.." percentageShow={true} />
                                                    </div>

                                                    {/* Button & Image Display */}
                                                    <div className={`${isLoadingupscale[item.img_url] ? "hidden" : "block"}`}>
                                                        {
                                                            // Check if item.img_url is already in Chatupscale (assume it is an array)
                                                            (Array.isArray(Chatupscale) && Chatupscale.includes(item.img_url)) || item.upscale_status ? (
                                                                // If img_url is found or upscale_status is true, don't show the button
                                                                null
                                                            ) : (
                                                                // If img_url is not found and there's no upscale_status, show the "Upscale" button
                                                                <button
                                                                    onClick={() => handleUpscaleImage(item.img_url, item.thread_id, index)}
                                                                    className={`absolute top-3 left-4 px-4 rounded-full text-white font-semibold bg-gradient-to-r from-[#990013] to-red-500 shadow-xl transition-all duration-300 transform hover:scale-110`}>
                                                                    Upscale<i className="fa-solid fa-arrow-up-wide-short ml-1"></i>
                                                                </button>
                                                            )
                                                        }
                                                    </div>

                                                    {/* Image Display */}
                                                    <img
                                                        loading='lazy'
                                                        onLoad={handleChatScoll}
                                                        src={item.img_url}
                                                        onClick={() => { setSelectedImage(item.img_url); setIsModalOpen(true); }}
                                                        alt="img"
                                                        className={`w-[50%] my-2 rounded-xl ${isLoadingupscale[item.img_url] ? "opacity-20 pointer-events-none" : "opacity-100"}`}
                                                    />
                                                </div>
                                            }

                                            {/* {item.img_url &&
                                                    <div className="relative">
                                                        <div className={`w-[50%] h-[50%] absolute top-[25%] left-0 flex items-center justify-center transform -translate-x-0 -translate-y-0 ${isLoadingupscale[item.img_url] ? "block" : "hidden"}`}>
                                                            <CircularWithValueLabel isLoadingAIResponse={isLoadingupscale} loadingText="Loading.." />
                                                        </div>
                                                        <div className={`${isLoadingupscale[item.img_url] ? "hidden" : "block"}`}>
                                                            {item.upscale_status || Chatupscale.includes(item.img_url) ?
                                                                null
                                                                :
                                                                (
                                                                <button onClick={() => handleUpscaleImage(item.img_url, item.thread_id, index)} className={`absolute top-3 left-4 px-4 rounded-full text-white font-semibold bg-gradient-to-r from-[#990013] to-red-500 shadow-xl transition-all duration-300 transform hover:scale-110`}>
                                                                    Upscale<i className="fa-solid fa-arrow-up-wide-short ml-1"></i>
                                                                </button>
                                                                )
                                                            }
                                                        </div>
                                                        <img loading='lazy' onLoad={handleChatScoll} src={item.img_url} onClick={() => { setSelectedImage(item.img_url); setIsModalOpen(true); }}
                                                            alt="img"
                                                            className={`w-[50%] my-2 rounded-xl ${isLoadingupscale[item.img_url] ? "opacity-20 pointer-events-none" : "opacity-100"}`}
                                                        />
                                                    </div>
                                                } */}


                                        </>
                                    }
                                </>
                            )}
                            {/* For Current History */}
                            {ChatMessage.map((item, index) =>
                                item.sender === 'user' ? (
                                    <div className="flex justify-end my-2">
                                        <div key={index} className={`userchat max-w-[60%] border-[#88ceff] flex p-2.5 pl-4`}>
                                            {item.giftid ? (
                                                <div key={index} className="">
                                                    {GiftImageData.map(giftItem => (
                                                        item.giftid === giftItem.id &&
                                                        <div className="flex flex-col flex-wrap justify-center items-center">
                                                            <img src={giftItem.url} className="w-20" alt={giftItem.title} />
                                                            {item.text}
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                item.text
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="flex justify-start my-2">
                                            <div key={index} className={`aichat max-w-[60%] border-[#88ceff] flex p-2.5 pl-4`}>
                                                {/* {item.text} */}
                                                {item.text && item.text.includes("**") ? (
                                                    <span>
                                                        {item.text
                                                            .split(/\*\*(.*?)\*\*/)
                                                            .map((part, idx) =>
                                                                idx % 2 === 1 ? (
                                                                    <span key={idx}
                                                                        style={{
                                                                            fontSize: "14px",
                                                                            fontStyle: "italic",
                                                                            color: "[#5c4242]",
                                                                        }}
                                                                    >
                                                                        *{part}*
                                                                    </span>
                                                                ) : (
                                                                    <span key={idx}>{part}</span>
                                                                )
                                                            )}
                                                    </span>
                                                ) : (
                                                    <span>{item.text}</span>
                                                )}
                                                {/* Audio */}
                                                <div className="">
                                                    <div className={`text-red-500 ml-2 bg-[#ffffff] p-[5px] rounded-full shadow-lg ${speakingIndex === index ? "disable" : ""}`}
                                                        style={speakingIndex === index ? { pointerEvents: 'none', opacity: 0.5 } : {}}
                                                        onClick={() => speakingIndex !== index && handlePlayAudiomsg(item, index)} >
                                                        {speakingIndex === index ? (
                                                            <PiUserSoundFill />
                                                        ) : (
                                                            <IoVolumeHighSharp />
                                                        )}
                                                    </div>
                                                    <audio ref={(el) => (audioRefs.current[index] = el)}
                                                        className={`audio-player mt-4 w-full ${activeIndex === index && !isLoading ? "hidden" : "hidden"}`} controls />
                                                </div>
                                                {/* Audio */}
                                            </div>
                                        </div>
                                        {/* {item.img_url &&
                                            <div className="relative">
                                                <div className={`w-[50%] h-[50%] absolute top-[25%] left-0 flex items-center justify-center transform -translate-x-0 -translate-y-0 ${isLoadingupscale == item.img_url ? "block" : "hidden"}`}>
                                                    <div className="loader"></div> comment this
                                                    <CircularWithValueLabel isLoadingAIResponse={isLoadingupscale} loadingText="Loading.." />
                                                </div>
                                                <div className={`${isLoadingupscale == item.img_url ? "hidden" : "block"}`}>
                                                    {item.upscale_status || Chatupscale.upscale_status ? comment this
                                                    {item.upscale_status || item.img_url == Chatupscale.old_url ?
                                                        null
                                                        :
                                                        <button onClick={() => handleUpscaleImage(item.img_url, item.thread_id)} className={`absolute top-3 left-4 px-4 rounded-full text-white font-semibold bg-gradient-to-r from-[#990013] to-red-500 shadow-xl transition-all duration-300 transform hover:scale-110`}>
                                                            Upscale<i className="fa-solid fa-arrow-up-wide-short ml-1"></i>
                                                        </button>
                                                    }
                                                </div>
                                                <img loading='lazy' onLoad={handleChatScoll} src={item.img_url} onClick={() => { setSelectedImage(item.img_url); setIsModalOpen(true); }}
                                                    alt="img"
                                                    className={`w-[50%] my-2 rounded-xl ${isLoadingupscale == item.img_url ? "opacity-20 pointer-events-none" : "opacity-100"}`}
                                                />
                                            </div>} */}
                                        {item.img_url &&
                                            <div className="relative">
                                                <div className={`w-[50%] h-[50%] absolute top-[25%] left-0 flex items-center justify-center transform -translate-x-0 -translate-y-0 ${isLoadingupscale[item.img_url] ? "block" : "hidden"}`}>
                                                    <CircularWithValueLabel isLoadingAIResponse={isLoadingupscale} loadingText="Loading.." percentageShow={true} />
                                                </div>
                                                <div className={`${isLoadingupscale[item.img_url] ? "hidden" : "block"}`}>
                                                    {item.upscale_status || item.img_url === Chatupscale ?
                                                        null
                                                        :
                                                        <button onClick={() => handleUpscaleImage(item.img_url, item.thread_id)} className={`absolute top-3 left-4 px-4 rounded-full text-white font-semibold bg-gradient-to-r from-[#990013] to-red-500 shadow-xl transition-all duration-300 transform hover:scale-110`}>
                                                            Upscale<i className="fa-solid fa-arrow-up-wide-short ml-1"></i>
                                                        </button>
                                                    }
                                                </div>
                                                <img loading='lazy' onLoad={handleChatScoll} src={item.img_url} onClick={() => { setSelectedImage(item.img_url); setIsModalOpen(true); }}
                                                    alt="img"
                                                    className={`w-[50%] my-2 rounded-xl ${isLoadingupscale[item.img_url] ? "opacity-20 pointer-events-none" : "opacity-100"}`}
                                                />
                                            </div>
                                        }

                                    </>
                                )
                            )}
                            {/* For Thinking..... */}
                            {IsGenerating && (
                                <div className={`aichat justify-between max-w-[20%] my-2 border-[#88ceff] flex p-2.5 pl-6`} >
                                    <div className="loaderdots"></div>
                                </div>
                            )}
                            {isLoadingImage && (
                                <><CircularWithValueLabel isLoadingAIResponse={isLoadingImage} percentageShow={true} /></>
                            )}
                        </div>
                    </div>

                    {/* Bottom action buttons */}
                    <div className={`${isMobile ? "fixed bottom-[110px]" : ""} px-[10px] md:p-0 flex space-x-2`}>
                        <button onClick={() => { if (!isLoadingImage && !IsGenerating && !IsRecording) setIsPopupOpen(!isPopupOpen) }} className="rounded-full flex h-12 w-12 justify-center bg-[#bdb1b1] items-center border text-lg border-none">
                            <i className="fa-solid fa-gift text-white"></i>
                        </button>
                        <div className="relative flex-1 flex items-center overflow-hidden rounded-full px-4 p-1 bg-[#bdb1b1]">
                            <input className="w-full border-0 bg-transparent pr-1 text-white placeholder:text-white placeholder:opacity-80 focus:outline-none focus:ring-0 resize-none"
                                value={UserInput} onChange={(e) => setUserInput(e.target.value)} onKeyPress={(e) => { if (e.key === "Enter") { handleSendMessage(); } }}
                                disabled={IsGenerating || IsRecording} ref={UserInputRef} placeholder="Start typing..." autoFocus aria-label="Type your message" />
                            <div className="flex items-center relative z-subtitle h-10 w-10 cursor-pointer" onClick={() => { if (!isLoadingImage && !IsGenerating && !IsRecording) handleSendMessage() }}>
                                <i className="fa-solid fa-paper-plane" style={{ color: "#fff", fontSize: "22px" }} aria-label="Send message"></i>
                            </div>
                        </div>
                        <div className="relative flex h-12 w-12 items-center justify-center rounded-full bg-[#bdb1b1] cursor-pointer">
                            {IsRecording ? (
                                <div className="w-15 h-15 rounded-full bg-red-600 recordingChatMsg"></div>
                            ) : (
                                <i className="fa-solid fa-microphone text-2xl text-white" aria-label="Record message" onClick={() => { if (!isLoadingImage && !IsGenerating) handleRecording() }}></i>
                            )}
                        </div>
                    </div>
                </div>

                {/* Third Container */}
                <div className={`h-[calc(100vh-40px)] md:h-[calc(100vh-1px)] w-full basis-2/5 
                ${isMobile ? "absolute z-50 bg-gray-200" : ""} ${isThirdContainerOpen ? " " : "hidden"}`}
                >
                    <div className="relative  flex h-full w-full flex-col">
                        <img loading="lazy" alt="background" className="-z-5 absolute object-cover h-[80%] xl:h-[70%] xl w-full" src={CharacterData.img_url} style={{ color: "transparent" }}
                        />
                        <div className="sticky left-0 top-0 w-full px-4 pt-6 pb-0">
                            <button
                                onClick={toggleThirdContainer}
                                className="flex justify-center items-center border text-sm h-8 w-8 rounded-2xl border-none bg-[#0000009c]">
                                <span className="iconfont-custom icon-pickupLiftIcon text-3xl font-light text-white/70" />
                            </button>
                        </div>
                        <div
                            className="h-full w-full overflow-auto pt-[25rem]"
                            style={{ scrollbarWidth: "none" }}
                        >
                            <div className="relative flex min-h-full w-full flex-col overflow-hidden rounded-t-3xl bg-[#272e34]">
                                <div className="w-full bg-[#bfb4b480] px-4 pb-2 pt-4  border-0 border-b-2 border-b-white">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div className=" h-24 w-24  rounded-full  ">
                                                <div className="relative shrink-0 grow-0 rounded-full h-24 w-24" style={{ padding: 0 }}>
                                                    <img loading="lazy" alt="avatar" width={24} height={24} decoding="async" data-nimg={1} className="relative h-full w-full rounded-full object-cover object-top" 
                                                    src={CharacterData.img_url} style={{ color: "transparent" }}/>
                                                </div>
                                            </div>
                                            <div className=" ml-3 text-white">
                                                <span className="text-3xl font-medium">
                                                    {CharacterData.name}
                                                </span>
                                                <div className="flex items-center whitespace-nowrap font-light">
                                                    {/* <span>589k Likes</span> */}
                                                </div>
                                                <span className="text-xs text-white">
                                                    Created by {CharacterData.user || "Eromantic AI"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="m-2 mt-4 font-robotoLight text-xs text-white ">
                                        {CharacterData.Summary}
                                    </div>
                                    <div className="m-2 flex flex-wrap">
                                        {CharacterData.Hobbies && Object.entries(CharacterData.Hobbies).map(([key, value]) => (
                                            <span key={key} className="mb-2 mr-4 rounded-full  bg-gray-100/20 px-2 py-1 text-xs font-light text-white">
                                                {value}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <div className="grow bg-[#bfb4b480] px-4 pb-8 pt-4 h-[22rem] overflow-scroll mb-[60px] mo:mb-0" style={{ scrollbarWidth: "none" }}>
                                    <div>
                                        <div
                                            className="flex gap-14"
                                            role="tablist"
                                            aria-orientation="horizontal"
                                        >
                                            {/* Photo Tab Button */}
                                            <button
                                                className={`mb-3 py-1 text-lg font-semibold focus:outline-none ${activeTab === "photo"
                                                    ? "border-purple-8 text-[#000000ba] border-b-[3px] font-bold"
                                                    : "border-black-7 text-white"
                                                    }`}
                                                onClick={() => setActiveTab('photo')}
                                                // aria-selected={activeTab === "photo"}
                                                role="tab"
                                                type="button"
                                            >
                                                Photo
                                            </button>

                                            {/* {/ About Tab Button /} */}
                                            <button
                                                className={`mb-3  py-1 text-lg font-semibold focus:outline-none ${activeTab === "about"
                                                    ? "border-purple-8 text-[#000000ba] border-b-[3px] font-bold"
                                                    : "border-black-7 text-white"
                                                    }`}
                                                onClick={() => setActiveTab('about')}
                                                // aria-selected={activeTab === "about"}
                                                role="tab"
                                                type="button"
                                            >
                                                About
                                            </button>
                                        </div>

                                        <div>
                                            {/* Show Photo content when activeTab is 'photo' */}
                                            {activeTab === "photo" && (
                                                <div className="min-h-32 grid h-auto w-full grid-cols-3">
                                                    {ChatMessage.map((item, index) => (
                                                        item.img_url &&
                                                        <div key={index} className="relative h-0 w-full pb-[100%]">
                                                            <button
                                                                onClick={() => { setSelectedImage(item.img_url); setIsModalOpen(true); }}
                                                                className="rounded flex justify-center items-center border text-sm absolute left-0 top-0 h-full w-full border-none p-1"
                                                            >
                                                                <div className="relative flex items-center justify-center overflow-hidden rounded-md h-full w-full bg-white/10">
                                                                    <div className="relative h-full w-full">
                                                                        <img
                                                                            loading="lazy"
                                                                            src={item.img_url}
                                                                            alt="Chat img"
                                                                            className="absolute inset-0 z-overlay aspect-[2/3] h-full w-full object-cover object-center"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    ))}
                                                    {CharacterImages.length > 0 || ChatHistory.length > 0 ? (
                                                        CharacterImages.map(item => (
                                                            item.image_file_name.map((img, index) => (
                                                                <div key={index} className="relative h-0 w-full pb-[100%]">
                                                                    <button
                                                                        onClick={() => { setSelectedImage(img); setIsModalOpen(true); }}
                                                                        className="rounded flex justify-center items-center border text-sm absolute left-0 top-0 h-full w-full border-none p-1"
                                                                    >
                                                                        <div className="relative flex items-center justify-center overflow-hidden rounded-md h-full w-full bg-white/10">
                                                                            <div className="relative h-full w-full">
                                                                                <img
                                                                                    loading="lazy"
                                                                                    src={img}
                                                                                    alt="Chat img"
                                                                                    className="absolute inset-0 z-overlay aspect-[2/3] h-full w-full object-cover object-center"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            ))
                                                        ))
                                                    ) : (
                                                        <div className="col-span-3 flex items-center justify-center h-32 text-black-500">
                                                            No image found
                                                        </div>
                                                    )}
                                                    {ChatHistory.map((item, index) => (
                                                        item.img_url &&
                                                        <div key={index} className="relative h-0 w-full pb-[100%]">
                                                            <button
                                                                onClick={() => { setSelectedImage(item.img_url); setIsModalOpen(true); }}
                                                                className="rounded flex justify-center items-center border text-sm absolute left-0 top-0 h-full w-full border-none p-1"
                                                            >
                                                                <div className="relative flex items-center justify-center overflow-hidden rounded-md h-full w-full bg-white/10">
                                                                    <div className="relative h-full w-full">
                                                                        <img
                                                                            loading="lazy"
                                                                            src={item.img_url}
                                                                            alt="Chat img"
                                                                            className="absolute inset-0 z-overlay aspect-[2/3] h-full w-full object-cover object-center"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                            {/* Show About content when activeTab is 'about' */}
                                            {activeTab === "about" && (
                                                <div id="about-content" className="text-black space-y-2">
                                                    <DetailSection icon={Location} label="Ethnicity" value={CharacterData.Ethnicity} />
                                                    <DetailSection icon={age} label="Age" value={CharacterData.age} />
                                                    <DetailSection icon={eyes} label="Eyes" value={CharacterData.Eyes} />
                                                    <DetailSection icon={hair} label="Hair" value={`${CharacterData.hair_color} - ${CharacterData.hair_Style}`} />
                                                    <DetailSection icon={Bodyicon} label="Body Type" value={CharacterData.Body_Type} />
                                                    <DetailSection icon={personality} label="Personality" value={CharacterData.Personality} />
                                                    <DetailSection icon={eyes} label="Voice" value={CharacterData.Voice} />
                                                    <DetailSection icon={occupation} label="Occupation" value={CharacterData.Occupation} />
                                                    <DetailSection icon={Relationshipicon} label="Relationship" value={CharacterData.Relationship} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal (fullscreen image view) */}
            {isModalOpen && selectedImage && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
                    <div className="relative max-w-full max-h-full p-4">
                        <button onClick={() => { setIsModalOpen(false); setSelectedImage(null); }} className="absolute top-8 right-8 text-white text-md w-8 h-8 bg-[#808080] p-[5px] font-bold rounded-full">
                            X
                        </button>
                        <img loading='lazy'
                            className="max-w-full max-h-screen object-contain"
                            src={selectedImage}
                            alt="Fullscreen View"
                        />
                    </div>
                </div>
            )}

            {/* Gift Popup */}
            {isPopupOpen && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 w-[85%] sm:w-[80%] md:w-[60%] lg:w-[25%]">
                        {/* Image Grid */}
                        <div className={`grid grid-cols-2 md:grid-cols-3 gap-2 mb-4 overflow-y-auto ${selectedGift.id ? "max-h-[150px] mo:max-h-[300px]" : "max-h-[300px]"}`} style={{ scrollbarWidth: "thin" }}>
                            {GiftImageData.map((item, index) => (
                                <div key={index} className="relative flex justify-center bg-gray-300 h-20 rounded-lg cursor-pointer" onClick={() => setselectedGift(item)}>
                                    <img loading='lazy' src={item.url} alt={item.title} className="w-full h-full p-2 object-contain rounded-lg" />
                                    <div className="absolute w-full bottom-0 flex justify-between bg-[#12121242] rounded-lg px-2 py-1">
                                        <p className="text-center text-sm text-white ">{item.title}</p>
                                        <p className="text-center text-sm text-white ">{item.token}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Input Field */}
                        <input
                            type="text"
                            value={GiftInputMsg}
                            onChange={(e) => setGiftInputMsg(e.target.value)}
                            placeholder="Send a message with your gift"
                            className="w-full p-2 px-1 text-black placeholder:text-black placeholder:opacity-80 resize-none"
                        />

                        {/* Display Selected Image */}
                        {selectedGift.id && (
                            <div className="mt-4">
                                <p className="mb-2">Selected Gift:</p>
                                <img loading='lazy' src={selectedGift.url} alt="Selected" className="w-full h-32 object-contain rounded-lg" />
                            </div>
                        )}

                        {/* Close Button */}
                        <div className="flex justify-evenly">
                            <button onClick={() => setIsPopupOpen(false)} className="mt-4 bg-red-500 w-[40%] text-white px-4 py-2 rounded-full">
                                Close
                            </button>
                            <button onClick={() => handleSendMessage()} className="mt-4 bg-[#76b77c] w-[40%] text-white px-4 py-2 rounded-full">
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            )}


            {errorisOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-lg">
                    <div className="modal-dialog modal-lg bg-white rounded-lg overflow-hidden shadow-xl max-w-[16rem] mo:max-w-xl w-full">
                        {/* Modal Content */}
                        <div className="modal-wrap flex-wrap mo:flex relative">
                            {/* Image Container - 50% Width */}
                            <div className="w-full mo:w-1/2">
                                {/* Desktop Image */}
                                <img
                                    src={alert}
                                    alt="Modal Img"
                                    className="login-img hidden md:block w-full h-full object-cover"
                                />
                                {/* Mobile Image */}
                                <img
                                    src={alert}
                                    alt="Modal Img Mobile"
                                    className="login-img-mob sm:hidden w-full"
                                />
                            </div>

                            {/* Content Container - 50% Width */}
                            <div className="modal-content p-2 w-full mo:w-1/2 grid justify-center">
                                {/* Modal Header */}
                                <div className="modal-header flex justify-between items-start">
                                    <h4 className="modal-title text-uppercase text-xl"></h4>
                                    <button
                                        type="button"
                                        className="btn-close text-xl text-[#990013]"
                                        onClick={() => { setErrorIsOpen(false); navigate('/'); }}
                                    >
                                        <i className="fa-regular fa-circle-xmark"></i>
                                    </button>
                                </div>

                                {/* Modal Body */}
                                <div className="modal-body justify-center">
                                    <div className="container-fluid">
                                        <div className="row mb-4">
                                            <div className="col-12 ps-4">
                                                <h2 className="text-[#990013] font-bold text-uppercase text-2xl">
                                                    PLEASE UPGRADE
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-12 ps-4">
                                                <span className="text-muted text-gray-900 text-md">
                                                    Upgrade to a premium membership to unlock access to all features!
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row justify-center p-3 gap-1">
                                            <div className="col-12">
                                                <button onClick={() => navigate('/priceplan')} className="btn btn-primary w-full py-3 text-white bg-[#990012ab] hover:bg-[#990013] rounded-md">
                                                    Upgrade
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Modal Overlay */}
                        {/* <div
                          className="fixed inset-0"
                          onClick={errorCloseModal}
                        ></div> */}
                    </div>
                </div>
            )}
        </>
    );
};

export default Aidreamchat;
