import React, { useState, useRef, useEffect } from 'react';
import UserApiClient from '../../../utilities/apiClients';

const Accordion = () => {
    const [faqData, setFaqData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const contentRefs = useRef([]); // Create a reference array to store each content element
    const [isLoading, setIsLoading] = useState(true);

    // Function to toggle the active index for the accordion
    const toggleCollapse = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    // Function to get the maxHeight based on the active index
    const getMaxHeight = (index) => {
        if (contentRefs.current[index]) {
            return activeIndex === index ? `${contentRefs.current[index].scrollHeight}px` : '0px';
        }
        return '0px';
    };

    // Fetch the FAQ data from the API
    const fetchData = async () => {
        try {
            const res = await UserApiClient.get('api/v1/admin/faq/', {}, false);
            if (res.success && res.data && Array.isArray(res.data.results)) {
                setFaqData(res.data.results.reverse());
            } else {
                console.error('Invalid or no data received:', res);
            }
        } catch (error) {
            console.error('Error fetching FAQ data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch FAQ data when the component mounts
    useEffect(() => {
        fetchData();
    }, []);

    // // Log the FAQ data when it updates
    // useEffect(() => {
    //     console.log("FAQ data updated:", faqData);
    // }, [faqData]);

    if (isLoading) {
        return <div>Loading FAQs...</div>;
    }

    return (
        <>
            <div>
                <h2 className="text-[#2b2a2a] font-semibold font-manrope text-2xl">
                    FAQ
                </h2>
                <p className="font-manrope text-xl text-[#99000b] mb-[30px]">
                    You have questions? We have answers.
                </p>
            </div>
            <div className="accordion-group" data-accordion="default-accordion">
                {faqData.map((faq, index) => (
                    <div key={faq.id} className={`accordion py-2 ${activeIndex === index ? 'active' : ''}`}>
                        <div
                            className={`accordion pb-4 border-b-[2px] border-solid border-[#6b7280] ${activeIndex === index ? 'active' : ''}`}
                            id={`faq-heading-${index}`}
                        >
                            <button
                                className="accordion-toggle group inline-flex items-center justify-between leading-8 text-gray-600 w-full transition duration-500 hover:text-red-600 accordion-active:text-red-600"
                                onClick={() => toggleCollapse(index)} // Toggle based on the current index
                                aria-controls={`faq-collapse-${index}`}
                            >
                                <h5 className='text-[#2b2a2a] font-bold text-xl'>{faq.question}</h5>
                                <svg
                                    className={`text-gray-900 transition-transform duration-500 group-hover:text-red-600 accordion-active:text-red-600 ${activeIndex === index ? 'rotate-180' : ''}`}
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                                        stroke="currentColor"
                                        strokeWidth="1.6"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                            <div
                                id={`faq-collapse-${index}`}
                                className="accordion-content w-full px-0 overflow-hidden pr-4 transition-max-height duration-500 ease-in-out"
                                ref={(el) => (contentRefs.current[index] = el)} // Dynamically assign ref to each element
                                style={{ maxHeight: getMaxHeight(index) }}
                                aria-labelledby={`faq-heading-${index}`}
                            >
                                <p className="text-base text-gray-600 leading-6">{faq.answer}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Accordion;