import React, { useState, useRef } from "react";
import {
  newstep1,
  newstep2,
  newstep3,
  newstep4,
  newstep5,
} from "../../../assets";

const Characters = () => {
  const [openIndex, setOpenIndex] = useState(0);
  const contentRefs = useRef([]);
  const contentImages = [newstep1, newstep2, newstep3, newstep4, newstep5];

  const questions = [
    {
      step: "Step 1",
      text: "Create Account",
      disc: (
        <ol>
          <li style={{ marginTop: "10px" }}>
            1. Click the Join Now button to get started through the registration process.
          </li>
          <li style={{ marginTop: "10px" }}>
            2. Sign up directly using your Google account. This will automatically create an account for you on Eromantic.AI.
          </li>
          <li style={{ marginTop: "10px" }}>
            3. Register with email & password. Use the same to log in and access all features of Eromantic.AI.
          </li>
        </ol>
      ),
    },
    {
      step: "Step 2",
      text: "Design Personality and Looks",
      disc: (
        <ol>
          <li style={{ marginTop: "10px" }}>
            You can personalize your AI girlfriend or boyfriend by adjusting her/his voice, hobbies, demeanor, and physical traits.
          </li>
        </ol>
      ),
    },
    {
      step: "Step 3",
      text: "Build Profile of Your GF/BF",
      disc: (
        <ol>
          <li style={{ marginTop: "10px" }}>
            Build your ideal GF/BF profile by selecting unique features like voice, personality, hobbies, and appearance.
            Personalize every detail to create the perfect virtual companion.
          </li>
        </ol>
      ),
    },
    {
      step: "Step 4",
      text: "Chat with Your GF/BF",
      disc: (
        <ol>
          <li style={{ marginTop: "10px" }}>
            Eromantic.AI offers interactive chats with your AI girlfriend, including photo requests, making conversations more engaging and immersive through its unique AI chat system.
          </li>
        </ol>
      ),
    },
    {
      step: "Step 5",
      text: "Upgrade for More Features",
      disc: (
        <ol>
          <li style={{ marginTop: "10px" }}>
            Upgrade to unlock enhanced image generation, advanced customization options, exclusive features, and more, offering a richer, personalized virtual companion experience with Eromantic.AI.
          </li>
        </ol>
      ),
    },
  ];

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const getMaxHeight = (index) => {
    if (contentRefs.current[index]) {
      return openIndex === index
        ? `${contentRefs.current[index].scrollHeight}px`
        : "0px";
    }
    return "0px";
  };

  return (
    <div className="text-white pb-14 mo:pb-24 px-5 md:px-10 lg:px-20">
      <div className="flex flex-col lg:flex-row">
        {/* Accordion Section */}
        <div className="w-full lg:w-[40%] rounded-lg mt-5 lg:mt-0">
          <div id="accordion-collapse" data-accordion="collapse">
            {questions.map((question, index) => (
              <div key={index}>
                <h2 id={`accordion-collapse-heading-${index}`}>
                  <button
                    type="button"
                    className={`flex items-center justify-between w-full py-2 px-5 font-medium text-gray-500 hover:text-red-500 transition-colors duration-300 ease-in-out gap-3 ${
                      openIndex === index ? "text-red-500" : ""
                    }`}
                    onClick={() => handleToggle(index)}
                    aria-expanded={openIndex === index}
                    aria-controls={`accordion-collapse-body-${index}`}
                  >
                    <div className="flex flex-col items-baseline hover:scale-105 animate-fadeInUp">
                      <span className="text-[#990013] text-[20px] sm:text-[22px]">
                        {question.step}:{" "}
                      </span>
                      <span className="text-black text-[18px] sm:text-[22px] hover:text-red-500 text-left ">
                        {question.text}
                      </span>
                    </div>
                    <svg
                      className={`w-3 h-3 transform ${
                        openIndex === index ? "" : "rotate-180"
                      }`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5 5 1 1 5"
                      />
                    </svg>
                  </button>
                </h2>
                <div
                  id={`accordion-collapse-body-${index}`}
                  className="overflow-hidden transition-all duration-500 ease-in-out"
                  style={{ maxHeight: getMaxHeight(index) }}
                  ref={(el) => (contentRefs.current[index] = el)}
                  aria-labelledby={`accordion-collapse-heading-${index}`}
                >
                  <div className="px-5 text-[16px] sm:text-[18px] text-[#363636]">
                    {question.disc}
                  </div>
                  {/* Show image under accordion on small screens */}
                  <div className="block lg:hidden mt-3">
                    <img
                      loading="lazy"
                      className="w-full h-auto object-contain rounded-lg"
                      src={contentImages[index]}
                      alt={`step-${index + 1}`}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Image Section for Larger Screens */}
        <div className="hidden lg:block w-full lg:w-[60%] rounded-lg mt-5 lg:mt-0">
          <img
            loading="lazy"
            key={openIndex}
            className="w-full h-[15rem] lg:h-[30rem] object-contain rounded-lg transition-opacity duration-500 ease-in-out transform animate-appear"
            src={contentImages[openIndex ?? 0]}
            alt="steps"
          />
        </div>
      </div>
    </div>
  );
};

export default Characters;
