import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PrivateRouteAdmin = ({ children, ...rest }) => {
    const user = useSelector(state => state.user)

    if (user.isAuthenticated && user.user.is_staff === true) {
        return children;
    } else {
        // Redirect to login or show an error
        alert('No authentication found. Please log in.');
        localStorage.removeItem('authToken');
        localStorage.removeItem('token');
        return <Navigate to="/admin" replace />;
    }
};

const PrivateRouteUser = ({ children }) => {
    const user = useSelector(state => state.user)

    if (user.isAuthenticated && user.user.is_staff === false) {
        return children;
    } else {
        // Redirect to login or show an error
        alert('No authentication found. Please log in.');
        localStorage.removeItem('authToken');
        localStorage.removeItem('token');
        return <Navigate to="/login" replace />;
    }
};

// const UserRouteOnly = ({ children }) => {
//     const user = useSelector(state => state.user)

//     if (user.isAuthenticated && user.user.is_staff === false) {
//         return children;
//     } else {
//         // Redirect to login or show an error
//         alert('No authentication found. Please log in.');
//         localStorage.removeItem('authToken');
//         localStorage.removeItem('token');
//         return <Navigate to="/login" replace />;
//     }
// };

export { PrivateRouteAdmin, PrivateRouteUser };