import React from "react";

const Togal = ({ handleChange, value, read_only }) => {
  const [checked, setChecked] = React.useState(value);

  return (
    <div>
      <label className="inline-flex items-center cursor-pointer ">
        <input
          type="checkbox"
          className="absolute opacity-0 h-0 w-0 peer"
          onChange={handleChange}
          defaultChecked={checked}
          disabled={read_only || false}
        />
        <div className="relative w-11 h-6 bg-red-500 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-500" />
      </label>
    </div>
  );
};

export default Togal;
