import React, { useEffect, useState } from "react";
import {  Rectangle49 } from "../../assets";
import { NavLink, useNavigate } from "react-router-dom";  // Use useNavigate instead of useHistory
import GoogleSignInBtn from "./GoogleLoginBtn";
import UserApiClient from "../../utilities/apiClients";
import Swal from "sweetalert2";

const SignUp = () => {
    const [refPage, setRefPage] = useState(false);
    const [refCode, setRefCode] = useState('');
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(null);  // To handle errors

    const navigate = useNavigate();  // Initialize useNavigate

    useEffect(() => {
        if (window.location.href.includes("join/referral")) {
            const queryParams = new URLSearchParams(window.location.search);
            const affiliateId = queryParams.get('affiliate_id');
            if (affiliateId) {
                setRefCode(affiliateId);
            }
            setRefPage(true);
        }
        const orignalBackground = document.body.style.background
        document.body.style.background = 'none'
        return () => {
            document.body.style.background = orignalBackground
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Regular expression to validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Regular expression to check for a strong password
        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        // Email validation
        if (!emailRegex.test(email)) {
            setError("Please enter a valid email address.");
            return;
        }

        // Password validation
        if (!strongPasswordRegex.test(password)) {
            setError("Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character.");
            return;
        }

        // Confirm password check
        if (password !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }
        const data = {
            email,
            password,
            password_confirm: confirmPassword,
        }
        if (refCode && refPage) {
            data.affiliate_id = refCode
        }
        await UserApiClient.post('api/v1/signup/', data, false).then(async (res) => {
            if (res.success) {
                await Swal.fire({
                    title: 'Signup Success!',
                    icon: 'success',
                    text: 'Signup successfull, Please login to continue.',
                    timer: 2000,
                    showConfirmButton: false
                })
                navigate("/login");
            }
        })
    };

    return (
        <div>
            <div className="bg-white text-black h-full w-full flex justify-between font-josefin-sans">
                <div className="md:w-[45%] hidden md:block">
                    <img loading ='lazy'
                        src={Rectangle49}
                        alt="Rectangle47"
                        className='object-cover w-full h-[100vh]'
                    />
                </div>
                <div className='relative w-full md:w-[55%] flex justify-center items-center h-[100vh]'>
                    <button
                        type="button"
                        className="btn absolute top-3 left-3 text-lg text-[#ff0022] cursor-pointer flex items-center"
                        onClick={() => navigate('/')}
                    >
                        <i className="fa-solid fa-circle-arrow-left mr-2"></i>Back
                    </button>
                    <div className=" text-white flex flex-col items-center">
                        <img loading ='lazy' src="/assets/img/logowh.png" alt="Icon" className="w-[20rem] rounded-xl mb-5" />

                        <h4 className="font-sans text-black text-[20px] md:text-[22px] lg:text-[26px] xl:text-[33px] font-semibold leading-snug tracking-normal text-blue-gray-900">
                            JOIN EROMANTIC<span className="text-[#FF0000]">.AI</span>
                        </h4>

                        <h6 className="text-[17px] text-black lg:text-[20px] w-[273px] lg:w-[373px] text-center leading-none mt-5">
                            Create your free account or
                            <NavLink to={"/login"}>
                                <span className="text-[#FF0013] cursor-pointer"> Sign In</span>
                            </NavLink>{" "}
                            if you already have one
                        </h6>
                        <form className="mt-5 lg:mt-8 mb-2 w-[278px] md:w-[330px] lg:w-[400px] xl:w-[500px]" onSubmit={handleSubmit}>
                            <div className="flex flex-col gap-6 mb-1">
                                <div className="relative h-12 lg:h-14 w-full min-w-[250px]">
                                    <input
                                        placeholder="Enter your email address"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="peer text-black  h-full w-full rounded-md border-2 border-blue-gray-200 bg-transparent px-5 py-3 font-sans text-sm font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-2 focus:border-gray-500 focus:outline-0"
                                        // required
                                    />
                                </div>
                                <div className="relative h-12 lg:h-14 w-full min-w-[250px]">
                                    <input
                                        type="password"
                                        placeholder="Enter your password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="peer text-black  h-full w-full rounded-md border-2 border-blue-gray-200 bg-transparent px-5 py-3 font-sans text-sm font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-2 focus:border-gray-500 focus:outline-0"
                                        // required
                                    />
                                </div>
                                <div className="relative h-12 lg:h-14 w-full min-w-[250px]">
                                    <input
                                        type="password"
                                        placeholder="Confirm your password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        className="peer text-black  h-full w-full rounded-md border-2 border-blue-gray-200 bg-transparent px-5 py-3 font-sans text-sm font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-2 focus:border-gray-500 focus:outline-0"
                                        // required
                                    />
                                </div>
                            </div>
                            {error && <p className="text-red-500 text-sm">{error}</p>}
                            <p className="text-base text-black">
                                Password must be at least 8 characters long.
                            </p>
                            <button
                                className="mt-6 block w-full select-none rounded-lg bg-[#FF0013] py-2.5 lg:py-3 px-6 text-center align-middle font-sans text-[20px] lg:text-[25px] font-semibold shadow-md transition-all hover:shadow-lg focus:opacity-85 active:opacity-85"
                                type="submit"
                            >
                                Create Account
                            </button>


                            {/* <NavLink to='/dashboard/girls'>
                                <button
                                    className="w-full select-none text-black rounded-lg bg-transparent py-3 text-center align-middle font-sans text-[19px] lg:text-[22px] font-normal  shadow-md transition-all hover:shadow-lg focus:opacity-85 active:opacity-85 flex items-center justify-center border-2 border-[#6b6b6b] gap-4"
                                    type="button"
                                >
                                    <img loading ='lazy' src={Google} alt="Google" />
                                    Continue with Google
                                </button>
                            </NavLink> */}
                            {!window.location.href.includes("join/referral") ? (
                                <>
                                    <div className="flex items-center justify-center text-center my-4 gap-3">
                                        <hr className="border-1 w-60 " />
                                        <p className=" text-[20px] lg:text-[22px] font-light text-black">OR</p>
                                        <hr className="border-1 w-60" />
                                    </div>
                                    <GoogleSignInBtn />
                                </>
                            ) : ''}

                            <div className="flex justify-center text-center">
                                <div className="text-[17px] lg:text-[19px] text-black  text-center mt-5 w-[376px] ">
                                    By signing up, you agree to{" "}
                                    <span className="text-[#FF0013] cursor-pointer">
                                        <NavLink to='/terms-of-service'>Terms of Use</NavLink>
                                    </span>{" "}
                                    and{" "}
                                    <span className="text-[#FF0013] cursor-pointer">
                                        <NavLink to='/privacy-policy'>Privacy policy</NavLink>
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    );
};

export default SignUp;


