import React, { useState } from "react";
import {
  aiModal5,
  Anime_bondage,
  Anime_boobjob,
  Anime_breast_grab,
  Anime_cum_bath,
  Anime_gigantic_boobs,
  Anime_imminent_penetration,
  Anime_Male_bondage,
  Anime_Male_muscular_body,
  Anime_Male_penis,
  Anime_masturbation,
  Anime_missionary_pov,
  Anime_pants_down,
  Anime_pussy_from_behind,
  Anime_reverse_suspended_anal,
  Anime_sideways_ass,
  Ass_show,
  bigtits,
  BJ,
  bj_amature,
  blind_amature,
  Bondage,
  boobjob,
  boy,
  breast_massage,
  clothing1_amature,
  cum,
  CumOnFace_amature,
  dildo,
  doggy_amature,
  footjob,
  From_behind,
  front_pussy,
  frontpussy_amature,
  futanari_V2,
  GiantTits,
  girls,
  goldE,
  Granny_amature,
  info,
  Inmouth_amature,
  legs_up_pussy,
  lickings_amature,
  Male_bondage,
  Male_muscular_body,
  Male_penis,
  mascular_amature,
  mediumtits,
  missionary,
  missionary_anal,
  pants_down,
  pregnant,
  pregnant_front_pussy,
  Reverse_cowgirl,
  smalltits,
  ThickAss_amature,
  titbondage_amature,
  titfuck_amature,
  transparent_amature,
  videoTemp,
} from "../../../assets";
import { toast, ToastContainer } from "react-toastify";
import { IoSearchOutline } from "react-icons/io5";
import { Select } from "@mui/material";

const VideoPage = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [value, setValue] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredone, setIsHoveredone] = useState(false);
  const [isHoverquality, setIsHoverquality] = useState(false);
  const [otherInput, setOtherInput] = useState(null); // Add state for input value
  const [isCheckedPrivatensf, setIsCheckedPrivatensf] = useState(false);
  const [isRadioQuality, setIsRadioQuality] = useState("Normal");
  const [isCheckedPrivate, setIsCheckedPrivate] = useState(false);
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [error, setError] = useState("");
  const [svgselected, setsvgSelected] = useState("fourth");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedIndexAi, setSelectedIndexAi] = useState(0);
  const [text, setText] = useState("");
  const [selectedSuggestions, setSelectedSuggestions] = useState([]); // To store selected suggestions
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCharacter, setSelectedCharacter] = useState("1 Girl");
  const [selectedFeature, setSelectedFeature] = useState("Hair Color");
  const [selectedIndex, setSelectedIndex] = useState(null);

  const [hover, setHover] = useState({
    model: "",
  });

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleOtherInputChange = (e) => {
    setOtherInput(e.target.value); // Update state when input changes
  };
  const handleCheckboxChangePrivate = () => {
    setIsCheckedPrivate(!isCheckedPrivate);
  };
  const handleCheckboxChangePrivateNSF = () => {
    setIsCheckedPrivatensf(!isCheckedPrivatensf);
  };

  const handleRadioChangeQuality = (e) => {
    setIsRadioQuality(e.target.id);
  };

  const handleSelect = (num) => {
    if ((num === 6 || num === 8 || num === 12 || num === 16) && isChecked) {
      setIsChecked(false);
      const isMobile = window.innerWidth <= 768;
      toast.error(
        "Upscaling all variations at once may take some time. To speed things up, you can individually upscale videos in the 'My Gallery' section.",
        {
          position: isMobile ? "top-right" : "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    } else if (value === num) {
      setValue("");
    } else {
      setValue(num);
    }

    setError(""); // Clear any existing error when a valid number is selected
  };

  const handlesvgClick = (value) => {
    if (svgselected === value) {
      setsvgSelected("");
    } else {
      setsvgSelected(value);
    }
  };
  const data = [
    {
      img: aiModal5,
    //   img: videoTemp,
      title: "Realistic",
      type: "real",
      model: "influncerv2_p1.safetensors",
      description:
        "This model generates hyper-realistic human images, capturing subtle facial features, natural lighting, and detailed textures that mirror real-world photography. Ideal for lifelike portraits and high-detail renderings",
    },
  ];
  const handleChangeadvance = (e) => {
    setText(e.target.value);
  };
  const suggestions = [
    {
      id: 1,
      line: "1girl, brown hair, (large breasts), straight hair, wearing bra, in a desert, Bokeh",
    },
    {
      id: 2,
      line: "1girl, brown hair, (large breasts), straight hair, wearing bikini, in a casino, motion blur",
    },
    {
      id: 3,
      line: "1 girl, 30 years old, brown hair, (medium breasts), pixie cut, wearing pink dress, in a strip club, bokeh",
    },
    {
      id: 4,
      line: "1 girl, 22 years old, Blonde hair, (large breasts), curly hair, wearing dress, in a cruise ship, bokeh",
    },
  ];
  const handleSuggestionClick = (line) => {
    // If the suggestion is already selected, remove it
    if (selectedSuggestions.includes(line)) {
      setSelectedSuggestions(
        selectedSuggestions.filter((item) => item !== line)
      );
      setText(text.replace(line, "")); // Remove from textarea
    } else {
      // Add the new suggestion to the list and textarea
      setSelectedSuggestions([...selectedSuggestions, line]);
      setText(text + " " + line); // Append to textarea
    }
  };
  const options = {
    Characters: [
      { name: "1 Girl", src: girls },
      { name: "1 Boy", src: boy },
    ],
    "1 Girl": {
      "Hair Color": [
        { name: "Blonde" },
        { name: "Black" },
        { name: "Brown" },
        { name: "Redhead" },
        { name: "Pink" },
        { name: "White" },
        { name: "Ginger" },
        { name: "Golden" },
        { name: "blue" },
        { name: "green" },
      ],
      "Hair Cut": [
        { name: "Pixie Cut" },
        { name: "Straight Hair" },
        { name: "Bob Cut" },
        { name: "Curly Hair" },
        { name: "Braids" },
        { name: "Bangs" },
        // { 'name': 'Bald' },
        { name: "Dreadlocks Hair" },
        { name: "Short Curly Hair" },
        { name: "Long Straight Hair" },
        { name: "Afro Hair" },
      ],
      Wearing: [
        { name: "Lingerie" },
        { name: "Fishnets" },
        { name: "Swimwear" },
        { name: "Bodysuits" },
        { name: "Corset" },
        { name: "Bikini" },
        { name: "Chemise" },
        { name: "French Maid" },
        { name: "Thong" },
        { name: "Yoga Pants" },
        { name: "Nightgown" },
        { name: "Transparent Clothing" },
        { name: "Latex Clothing" },
      ],
      "Body Type": [
        { name: "Slim" },
        { name: "Athletic" },
        { name: "Curvy" },
        // { 'name': 'Petite' },
        { name: "Hourglass" },
        // { 'name': 'Pear Shaped' },
        // { 'name': 'Tall' },
        // { 'name': 'Plus Size' },
      ],
      Nationality: [
        { name: "American" },
        { name: "Russian" },
        { name: "Polish" },
        { name: "German" },
        { name: "Italian" },
        { name: "French" },
        { name: "Indian" },
        { name: "Asian" },
        { name: "Brazilian" },
        // { 'name': 'Korean' },
        { name: "Japanese" },
        { name: "Mexican" },
        { name: "African" },
      ],
      Environment: [
        { name: "Street" },
        { name: "Swimming Pool" },
        { name: "Bathroom" },
        { name: "Apartment" },
        { name: "Beach" },
        { name: "Shopping Mall" },
        { name: "Luxurious Castle" },
        { name: "Cafe" },
        { name: "University" },
        { name: "Strip Club" },
        { name: "Restaurant" },
        { name: "Park" },
        // { 'name': 'Spaceship' },
        { name: "Mars" },
        { name: "Forest" },
        { name: "Mountain" },
        { name: "Lake" },
        { name: "Library" },
        // { 'name': 'Concert Venue' },
        { name: "Sports Stadium" },
        { name: "Cruise Ship" },
        { name: "Desert" },
        { name: "Farm" },
        { name: "Art Gallery" },
        { name: "Amusement Park" },
        { name: "Ski Resort" },
        { name: "Rainforest" },
        // { 'name': 'Space Station' },
        { name: "Aquarium" },
        { name: "Vineyard" },
        // { 'name': 'Movie Theater' },
        { name: "Fitness Center" },
        { name: "Historical Monument" },
        { name: "Airport" },
        // { 'name': 'Submarine' },
        // { 'name': 'Circus Tent' },
        { name: "Casino" },
        { name: "Underground Cave" },
        // { 'name': 'Space Observatory' },
        { name: "Abandoned Mansion" },
        { name: "Ski Lodge" },
        { name: "Botanical Garden" },
        { name: "Wildlife Sanctuary" },
        { name: "Secret Garden" },
        { name: "Floating Island" },
        { name: "Whimsical Wonderland" },
        // { 'name': 'Mystical Labyrinth' },
        // { 'name': 'Neon Utopia' }
      ],
      // 'Style': [
      //   { 'name': 'Realistic' },
      //   { 'name': 'Fantasy' },
      //   { 'name': 'Anime' },
      //   { 'name': 'Digital Art' },
      //   { 'name': 'Photorealism' },
      //   { 'name': 'Pop Art' },
      //   { 'name': 'Vintage' },
      //   { 'name': 'Surreal' },
      //   { 'name': 'Minimalist' },
      //   { 'name': 'Expressionist' },
      //   { 'name': 'Cubist' },
      //   { 'name': 'Gothic' },
      //   { 'name': 'Renaissance' },
      // ],
      Effects: [
        { name: "Bokeh" },
        // { 'name': 'Hardcore' },
        { name: "Sepia" },
        { name: "Vintage Film" },
        { name: "Lens Flare" },
        { name: "Reflection" },
        { name: "Bloom" },
        { name: "Depth Of Field" },
        { name: "Dream Blur" },
        // { 'name': 'Monochrome' }
      ],
    },
    "1 Boy": {
      "Hair Color": [
        { name: "Blonde" },
        { name: "Black" },
        { name: "Brown" },
        { name: "Redhead" },
        { name: "Pink" },
        { name: "White" },
        { name: "Ginger" },
        { name: "Golden" },
        { name: "blue" },
        { name: "green" },
      ],
      "Hair Cut": [
        { name: "Buzz Cut" },
        { name: "Crew Cut" },
        { name: "Man Bun Hair" },
        { name: "Curly Hair" },
        { name: "Quiff Hair Cut" },
        { name: "Side Part" },
        { name: "Dread locks Hair" },
        { name: "Loose Wave Hair" },
        { name: "Afro Hair" },
        { name: "Spiky" },
        { name: "Mullet" },
      ],
      Wearing: [
        { name: "T-Shirt" },
        { name: "Jeans" },
        { name: "Hoodie" },
        { name: "Jacket" },
        { name: "Shorts" },
        { name: "Suit" },
        { name: "Sweater" },
        { name: "Blazer" },
        { name: "Shirt" },
        { name: "Tank Top" },
        { name: "Polo Shirt" },
        { name: "Cargo Pants" },
        { name: "Tracksuit" },
        { name: "Leather Jacket" },
        { name: "V-Neck" },
      ],
      "Body Type": [
        { name: "Slim" },
        { name: "Athletic" },
        { name: "Muscular" },
        { name: "Average" },
        { name: "Broad Shouldered" },
        { name: "Body Builder" },
        // { 'name': 'Tall' },
        // { 'name': 'Short' },
        // { 'name': 'Stocky' },
      ],
      Nationality: [
        { name: "American" },
        { name: "Russian" },
        { name: "Polish" },
        { name: "German" },
        { name: "Italian" },
        { name: "French" },
        { name: "Indian" },
        { name: "Asian" },
        { name: "Brazilian" },
        // { 'name': 'Korean' },
        { name: "Japanese" },
        { name: "Mexican" },
        { name: "African" },
      ],
      Environment: [
        { name: "Street" },
        { name: "Swimming Pool" },
        { name: "Bathroom" },
        { name: "Apartment" },
        { name: "Beach" },
        { name: "Shopping Mall" },
        { name: "Luxurious Castle" },
        { name: "Cafe" },
        { name: "University" },
        { name: "Strip Club" },
        { name: "Restaurant" },
        { name: "Park" },
        { name: "Mars" },
        { name: "Forest" },
        { name: "Mountain" },
        { name: "Lake" },
        { name: "Library" },
        { name: "Sports Stadium" },
        { name: "Cruise Ship" },
        { name: "Desert" },
        { name: "Farm" },
        { name: "Art Gallery" },
        { name: "Amusement Park" },
        { name: "Ski Resort" },
        { name: "Rainforest" },
        { name: "Aquarium" },
        { name: "Vineyard" },
        { name: "Fitness Center" },
        { name: "Historical Monument" },
        { name: "Airport" },
        { name: "Casino" },
        { name: "Underground Cave" },
        { name: "Abandoned Mansion" },
        { name: "Ski Lodge" },
        { name: "Botanical Garden" },
        { name: "Wildlife Sanctuary" },
        { name: "Secret Garden" },
        { name: "Floating Island" },
        { name: "Whimsical Wonderland" },
      ],
      // 'Style': [
      //   { 'name': 'Realistic' },
      //   { 'name': 'Cartoon' },
      //   { 'name': 'Abstract' },
      //   { 'name': 'Minimalist' },
      //   { 'name': 'Anime' },
      //   { 'name': 'Digital Art' },
      //   { 'name': 'Sci-Fi' },
      //   { 'name': 'Steampunk' },
      //   { 'name': 'Noir' },
      //   { 'name': 'Futuristic' },
      //   { 'name': 'Graffiti' },
      //   { 'name': '3D Rendering' },
      // ],
      Effects: [
        { name: "Bokeh" },
        { name: "Sepia" },
        { name: "Vintage Film" },
        { name: "Lens Flare" },
        { name: "Reflection" },
        { name: "Bloom" },
        { name: "Depth Of Field" },
        { name: "Dream Blur" },
      ],
    },
  };
  // Sample data for images and their titles
  const images = [
    {
      src: smalltits,
      title: "Small",
      trigerWord: "Small Tits",
      Lora: "",
    },
    {
      src: mediumtits,
      title: "Medium",
      trigerWord: "Medium Saggy Tits",
      Lora: "",
    },
    {
      src: bigtits,
      title: "Big",
      trigerWord: "Big Tits",
      Lora: "",
    },
    {
      src: GiantTits,
      title: "Huge",
      trigerWord: "Huge Tits",
      Lora: "",
    },
    {
      src: breast_massage,
      title: "Breast Massage",
      trigerWord: "Breast massage",
      type: "real",
      Lora: "",
    },
    { src: boobjob, title: "Boobjob", trigerWord: "Boobjob", Lora: "" },
    {
      src: Anime_breast_grab,
      title: "Anime Breast Grab",
      trigerWord: "Anime breast grab",
      Lora: "",
    },
    {
      src: Anime_gigantic_boobs,
      title: "Anime Gigantic Boobs",
      trigerWord: "Anime gigantic boobs",
      Lora: "",
    },
    {
      src: Anime_boobjob,
      title: "Anime Boobjob",
      trigerWord: "Anime boobjob",
      Lora: "",
      ad_prompt:
        "((blowjob face:0.8)), ((hyper realistic face:0.5)), blowjobTopofheadPovquiron, penis",
    },
    {
      src: titfuck_amature,
      title: "TitFuck",
      trigerWord: "Tit Fuck",
      Lora: "",
      ad_prompt:
        "((blowjob face:0.8)), ((hyper realistic face:0.5)), blowjobTopofheadPovquiron, penis",
    },
  ];
  const imagestwo = [
    {
      src: Ass_show,
      title: "Ass Show",
      trigerWord: "Ass show",
      Lora: "",
    },
    {
      src: pants_down,
      title: "Pants Down",
      trigerWord: "Pants down",
      Lora: "",
    },
    {
      src: Anime_pants_down,
      title: "Anime Pants Down",
      trigerWord: "Anime pants down",
      Lora: "",
    },
    {
      src: Anime_sideways_ass,
      title: "Anime Sideways Ass",
      trigerWord: "Anime sideways ass",
      Lora: "",
    },
    {
      src: ThickAss_amature,
      title: "Thick Ass",
      trigerWord: "ThickAss_amature",
      Lora: "",
    },
    // { src: Futanari, title: 'Futanari', trigerWord: 'Futanari', Lora: '' },
  ];
  const imagesthree = [
    { src: cum, title: "Cum", trigerWord: "Cum", Lora: "" },
    {
      src: Anime_cum_bath,
      title: "Anime Cum Bath",
      trigerWord: "Anime cum bath",
      Lora: "",
    },
    {
      src: CumOnFace_amature,
      title: "Cum On Face",
      trigerWord: "Cum On Face Amature",
      Lora: "",
    },
    {
      src: Inmouth_amature,
      title: "Cum In Mouth",
      trigerWord: "Inmouth_amature",
      Lora: "",
    },
  ];
  const imagesfour = [
    {
      src: front_pussy,
      title: "Front Pussy",
      trigerWord: "Front pussy",
      Lora: "",
    },
    {
      src: legs_up_pussy,
      title: "Legs Up Pussy",
      trigerWord: "Legs up pussy",
      Lora: "",
    },
    {
      src: Anime_masturbation,
      title: "Anime Masturbation",
      trigerWord: "Anime masturbation",
      Lora: "",
    },
    {
      src: Anime_pussy_from_behind,
      title: "Anime Pussy From Behind",
      trigerWord: "Anime pussy from behind",
      Lora: "",
    },
    {
      src: frontpussy_amature,
      title: "Front Pussy",
      trigerWord: "Front Pussy Amature",
      Lora: "",
    },
  ];
  const imagesfive = [
    {
      src: footjob,
      title: "Footjob",
      trigerWord: "Footjob",
      Lora: "",
    },
  ];
  const imagessix = [
    {
      src: pregnant,
      title: "Pregnant",
      trigerWord: "Pregnant",
      Lora: "",
      type: "real",
    },
    {
      src: pregnant_front_pussy,
      title: "Pregnant Front Pussy",
      trigerWord: "Pregnant front pussy",
      Lora: "",
    },
  ];
  const imagesseven = [
    {
      src: Bondage,
      title: "Bondage",
      trigerWord: "Bondage",
      Lora: "",
      type: "real",
    },
    {
      src: Anime_bondage,
      title: "Anime Bondage",
      trigerWord: "Anime bondage",
      Lora: "",
      type: "Anime",
    },
    {
      src: titbondage_amature,
      title: "Tit Bondage",
      trigerWord: "titbondage_amature",
      Lora: "",
    },
  ];
  const imageseight = [
    { src: dildo, title: "Dildo", trigerWord: "Dildo", Lora: "" },
    {
      src: missionary,
      title: "Missionary",
      trigerWord: "Missionary",
      Lora: "",
    },
    {
      src: missionary_anal,
      title: "Missionary Anal",
      trigerWord: "Missionary anal",
      Lora: "",
    },
    {
      src: Reverse_cowgirl,
      title: "Reverse Cowgirl",
      trigerWord: "Reverse cowgirl",
      Lora: "",
    },
    {
      src: From_behind,
      title: "From Behind",
      trigerWord: "From behind",
      Lora: "",
    },
    {
      src: Anime_imminent_penetration,
      title: "Anime Imminent Penetration",
      trigerWord: "Anime imminent penetration",
      Lora: "",
    },
    {
      src: Anime_missionary_pov,
      title: "Anime Missionary Pov",
      trigerWord: "Anime missionary pov",
      Lora: "",
    },
    {
      src: Anime_reverse_suspended_anal,
      title: "Anime Reverse Suspended Anal",
      trigerWord: "Anime reverse suspended anal",
      Lora: "",
    },
    {
      src: doggy_amature,
      title: "Doggy Style",
      trigerWord: "doggy_amature",
      Lora: "",
    },
  ];
  const imagesnine = [
    {
      src: futanari_V2,
      title: "Futanari",
      trigerWord: "Futanari",
      Lora: "",
    },
  ];
  const imagesten = [
    {
      src: BJ,
      title: "BJ",
      trigerWord: "Blowjob",
      Lora: "",
      ad_prompt:
        "((blowjob face:0.8)), ((hyper realistic face:0.5)), blowjobTopofheadPovquiron, penis",
    },
    {
      src: lickings_amature,
      title: "Licking",
      trigerWord: "Licking amature",
      Lora: "",
      ad_prompt:
        "((blowjob face:0.8)), ((hyper realistic face:0.5)), blowjobTopofheadPovquiron, penis",
    },
    {
      src: bj_amature,
      title: "Pov BJ",
      trigerWord: "BJ amature",
      Lora: "",
      ad_prompt:
        "((blowjob face:0.8)), ((hyper realistic face:0.5)), blowjobTopofheadPovquiron, penis",
    },
  ];
  const imageseleven = [
    {
      src: blind_amature,
      title: "Blind",
      trigerWord: "Blind",
      Lora: "",
    },
    {
      src: transparent_amature,
      title: "Transparent",
      trigerWord: "Transparent",
      Lora: "",
    },
    {
      src: clothing1_amature,
      title: "Saree",
      trigerWord: "Saree",
      Lora: "",
    },
  ];
  const imagestwelev = [
    {
      src: mascular_amature,
      title: "Muscular",
      trigerWord: "Muscular_amature",
      Lora: "",
    },
    {
      src: Granny_amature,
      title: "Old",
      trigerWord: "Granny_amature",
      Lora: "",
    },
  ];

  const imagesboyone = [
    {
      src: Male_muscular_body,
      title: "Muscular Body",
      trigerWord: "Male muscular body",
    },
    {
      src: Anime_Male_muscular_body,
      title: "Anime Muscular Body",
      type: "Anime",
      trigerWord: "Anime Male muscular body",
    },
  ];
  const imagesboytwo = [
    { src: Male_penis, title: "Penis", trigerWord: "Male penis" },
    {
      src: Anime_Male_penis,
      title: "Anime Penis",
      trigerWord: "Anime Male penis",
    },
  ];
  const imagesboythree = [
    {
      src: Male_bondage,
      title: "Bondage",
      trigerWord: "Male bondage",
    },
    {
      src: Anime_Male_bondage,
      title: "Bondage",
      trigerWord: "Anime Male bondage",
    },
  ];

  const filteredBoyone = imagesboyone.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredBoyTwo = imagesboytwo.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredBoyThree = imagesboythree.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredImages = images.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredImagesTwo = imagestwo.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredImagesThree = imagesthree.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesFour = imagesfour.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesFive = imagesfive.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesSix = imagessix.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesSeven = imagesseven.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesEight = imageseight.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesNine = imagesnine.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesTen = imagesten.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImageseleven = imageseleven.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagestwelev = imagestwelev.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <ToastContainer />

      <div className="container">
        <div className="flex justify-center items-center gap-5 my-10">
          <p className="text-center  font-semibold text-2xl xl:text-3xl">
            Select AI Video Model
          </p>
          <div className="relative">
            {hover.model && (
              <span className="absolute w-72 -left-32 mo:left-44  mo:bottom-4 -translate-x-1/2  bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                Unlock the power of diverse models, from hyper-realistic human
                portraits to vibrant Hentai and enchanting dreamscapes. Whether
                you're seeking detailed anime HD, photorealism, or surreal
                creativity, each model offers unique features to bring your
                visions to life with stunning detail and style.
              </span>
            )}
            <img
              loading="lazy"
              src={info}
              alt="infoBlack"
              className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
              onMouseEnter={() => setHover({ ...hover, model: true })}
              onMouseLeave={() => setHover({ ...hover, model: false })}
            />
          </div>
        </div>
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-2/4 ">
            <div className="flex justify-center">
              {data.map((item, index) => (
                <div
                  key={index}
                  className="relative overflow-hidden rounded-xl"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <img
                    loading="lazy"
                    src={item.img}
                    alt={item.title}
                    className={`rounded-xl`}
                  />
                  <div className="absolute bg-[#d3b7b79c] border-1 rounded-full p-3 sm:text-sm md:text-base lg:text-lg bottom-1 left-4">
                    {item.title}
                  </div>
                  <div
                    className={`absolute text-white border-1 rounded-2xl p-3 sm:text-sm md:text-lg lg:text-lg bottom-1 right-4 ${
                      selectedIndexAi === index
                        ? "bg-[#0e7a36bf]"
                        : "bg-[#9d2d2de0]"
                    }`}
                  >
                    {selectedIndexAi === index ? "Selected" : "Select Model"}
                  </div>
                  {hoveredIndex === index && (
                    <div className="absolute bottom-[15%] left-1/4 text-center  w-60 bg-black bg-opacity-70 p-2 text-white text-sm rounded-lg">
                      {item.description}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="w-full md:w-2/4  mt-10 md:mt-0">
            <div className="bg-[#83808052] shadow-2xl rounded-lg p-2 mo:p-4">
              <p className="font-semibold text-3xl pb-5 text-gray-800">
                Write Your Custom Prompt Here
              </p>
              {/* Textarea */}
              <textarea
                value={text}
                onChange={handleChangeadvance}
                rows="4"
                className="w-full bg-[#b9b9b97a] text-black p-4 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 placeholder-[#5e5e5e]"
                placeholder="Write something..."
              ></textarea>
              <div className="flex justify-between items-center my-6">
                <p className="font-semibold text-3xl pb-5 text-gray-800">
                  Extensions{" "}
                  <span className="mt-0 text-base text-gray-600">
                    (Addon 0.5 Token)
                  </span>
                </p>
                <div className="relative">
                  {hover.extension && (
                    <span className="absolute w-60 -left-32 -translate-x-1/2 bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                      (Realistic Effects Only work with Realistic & Anime
                      Effects Only work with Anime) Enhance your video creations
                      with an array of dynamic features, from smooth transitions
                      to captivating animations. Bring your scenes to life with
                      stunning effects, explore imaginative themes, and create
                      visually immersive storytelling with exceptional quality
                      and creativity.
                    </span>
                  )}
                  <img
                    loading="lazy"
                    src={info}
                    alt="infoBlack"
                    className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                    onMouseEnter={() => setHover({ ...hover, extension: true })}
                    onMouseLeave={() =>
                      setHover({ ...hover, extension: false })
                    }
                  />
                </div>
              </div>
              <div className="px-5 flex w-[85%] mo:w-[60%] items-center border rounded-full ml-6 bg-gray-100">
                <IoSearchOutline color="black" size={23} />
                <input
                  type="text"
                  placeholder="Search Extension"
                  id="searchInput"
                  className="focus:border-0 focus:outline-none active:border-0 active:outline-none !ring-0 w-full bg-transparent text-lg text-black p-2 border-0 rounded-full ml-2"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
                />
              </div>

              {isAdvanced ? (
                <>
                  <div className="w-full flex justify-center mt-2">
                    {options["Characters"].map((item, index) => (
                      <div
                        key={index}
                        className={`px-4 py-2 items-center flex flex-col justify-center rounded-lg mx-1 ${
                          selectedCharacter === item.name
                            ? "bg-black"
                            : "bg-[#d1d1d1]"
                        }`}
                        onClick={() => {
                          setSelectedCharacter(item.name);
                          setSelectedFeature("Hair Color");
                        }}
                      >
                        {/* Character Name */}
                        <p
                          className={`hover:text-red-600 capitalize hover:cursor-pointer text-lg  ${
                            selectedCharacter === item.name
                              ? "text-white"
                              : "text-black"
                          }`}
                        >
                          {item.name === "1 Girl" ? "Girls" : "Boys"}
                        </p>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <></>
              )}

              <div
                style={{
                  height: "30.3rem",
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                  scrollbarColor: "#99000b #f5b0b069",
                  borderRadius: "0.75rem",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
                className="scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 mt-6 px-4"
              >
                {selectedCharacter === "1 Girl" ? (
                  <>
                    {/* Boots section */}
                    <div className="text-center mb-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Boobs
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImages.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          } `} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                          />

                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Misc */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Misc
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagestwelev.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          } `} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                          />

                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Clothing */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Clothing
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImageseleven.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          } `} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                          />

                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Assa section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Ass
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagesTwo.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          } `} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                          />

                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Cum section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Cum
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagesThree.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          } `} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                          />

                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Pussy section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Pussy
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagesFour.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          } `} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                          />

                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Feet section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Feet
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagesFive.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          } `} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                          />

                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Pregnant section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Pregnant
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagesSix.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          } `} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                          />

                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* BDSM section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        BDSM
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagesSeven.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          } `} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                          />

                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Penetration section */}
                    <div className="text-center my-6">
                      <div className="bg-gray-200 rounded-full w-32 my-4 p-2 font-semibold text-lg text-gray-600">
                        Penetration
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagesEight.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          } `} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                          />

                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Futanari section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Futanari
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagesNine.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          } `} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                          />

                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Blow section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Blowjob
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagesTen.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          } `} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                          />

                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    {/* BodyBuilder section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Body Builder
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredBoyone.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          } `} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                          />

                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Penis section */}
                    <div className="text-center mb-6">
                      <div className="bg-gray-200 rounded-full w-32 my-4 p-2 font-semibold text-lg text-gray-600">
                        Penis
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredBoyTwo.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          } `} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                          />

                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Bondage section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Bondage
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredBoyThree.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                            selectedIndex === item.trigerWord
                              ? "border-4 border-red-600"
                              : ""
                          } `} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                          />

                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                              selectedIndex === item.trigerWord
                                ? "bg-red-600"
                                : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {/*  */}
        </div>
      </div>

      <div>
        <div className="flex flex-wrap justify-between items-start my-20 gap-4 md:gap-6 lg:gap-8">
          <div className="flex flex-col gap-5 lg:gap-12 text-black font-josefin-sans">
            <div className="flex items-center gap-5">
              <p className="text-base md:text-[15px] lg:text-[20px] xl:text-[25px] font-semibold text-gray-800">
                Aspect Ratio{" "}
                <span className="text-md text-gray-600">
                  (square, horizontal, vertical)
                </span>
              </p>
            </div>
            <div className="bg-[#d4d4d8] w-full sm:w-[75%] justify-center rounded-[20px] flex flex-wrap sm:flex-nowrap items-center gap-3 text-center p-5">
              {/* First SVG */}
              <div className="group flex flex-col items-center justify-center gap-4">
                <svg
                  onClick={() => handlesvgClick("first")}
                  className={`cursor-pointer fill-none stroke-[#ef0808c9] transition-opacity duration-300 ${
                    svgselected === "first" ? "opacity-[1]" : "opacity-[0.4]"
                  } hover:scale-[1.1] transition-transform duration-300`}
                  width="29"
                  height="40"
                  viewBox="0 0 29 42"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="1.5"
                    y="1.5"
                    width="26"
                    height="39"
                    rx="6.5"
                    strokeWidth="2"
                  ></rect>
                </svg>
                {/* Text for First */}
                <span
                  className={`${
                    svgselected === "first" ? "opacity-100" : "opacity-0"
                  } text-[#ef0808c9] text-xs group-hover:opacity-100 transition-opacity duration-300`}
                >
                  480 X 680
                </span>
              </div>

              {/* Second SVG */}
              <div className="group flex flex-col items-center justify-center gap-4">
                <svg
                  onClick={() => handlesvgClick("second")}
                  className={`cursor-pointer fill-none stroke-[#ef0808c9] transition-opacity duration-300 ${
                    svgselected === "second" ? "opacity-[1]" : "opacity-[0.4]"
                  } hover:scale-[1.1] transition-transform duration-300`}
                  width="60"
                  height="40"
                  viewBox="0 0 62 42"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="1.5"
                    y="1.5"
                    width="59"
                    height="39"
                    rx="6.5"
                    strokeWidth="2"
                  ></rect>
                </svg>
                {/* Text for Second */}
                <span
                  className={`${
                    svgselected === "second" ? "opacity-100" : "opacity-0"
                  } text-[#ef0808c9] text-xs group-hover:opacity-100 transition-opacity duration-300`}
                >
                  512 x 1024
                </span>
              </div>

              {/* Third SVG */}
              <div className="group flex flex-col items-center justify-center gap-4">
                <svg
                  onClick={() => handlesvgClick("third")}
                  className={`cursor-pointer fill-none stroke-[#ef0808c9] transition-opacity duration-300 ${
                    svgselected === "third" ? "opacity-[1]" : "opacity-[0.4]"
                  } hover:scale-[1.1] transition-transform duration-300`}
                  width="40"
                  height="40"
                  viewBox="0 0 42 42"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="1.5"
                    y="1.5"
                    width="39"
                    height="39"
                    rx="6.5"
                    strokeWidth="2"
                  ></rect>
                </svg>
                {/* Text for Third */}
                <span
                  className={`${
                    svgselected === "third" ? "opacity-100" : "opacity-0"
                  } text-[#ef0808c9] text-xs group-hover:opacity-100 transition-opacity duration-300`}
                >
                  512 x 512
                </span>
              </div>

              {/* Fourth SVG */}
              <div className="group flex flex-col items-center justify-center gap-4">
                <svg
                  onClick={() => handlesvgClick("fourth")}
                  className={`cursor-pointer fill-none stroke-[#ef0808c9] transition-opacity duration-300 ${
                    svgselected === "fourth" ? "opacity-[1]" : "opacity-[0.4]"
                  } hover:scale-[1.1] transition-transform duration-300`}
                  width="29"
                  height="40"
                  viewBox="0 0 29 42"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="1.5"
                    y="1.5"
                    width="26"
                    height="39"
                    rx="6.5"
                    strokeWidth="2"
                  ></rect>
                </svg>
                {/* Text for Fourth */}
                <span
                  className={`${
                    svgselected === "fourth" ? "opacity-100" : "opacity-0"
                  } text-[#ef0808c9] text-xs group-hover:opacity-100 transition-opacity duration-300`}
                >
                  512 X 768
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-5 lg:gap-12 text-black font-josefin-sans">
            <div className="flex items-center gap-5 md:mb-3 lg:mb-0">
              <p className="text-base md:text-xl lg:text-2xl xl:text-3xl font-semibold text-gray-800">
                Make Private
              </p>
              <button
                onClick={handleCheckboxChangePrivate}
                className={`relative inline-flex items-center h-[2rem] w-[4rem] rounded-full transition-all duration-300 ${
                  isCheckedPrivate ? "bg-[#ab1e1eb5]" : "bg-gray-300"
                }`}
              >
                {/* OFF text */}
                <span
                  className={`absolute z-10 left-2 text-black transition-all duration-300 ${
                    isCheckedPrivate ? "opacity-0" : "opacity-100"
                  }`}
                  style={{ top: "50%", transform: "translateY(-50%)" }}
                ></span>

                {/* ON text */}
                <span
                  className={`absolute z-10 right-1 text-black font-bold transition-all duration-300 ${
                    isCheckedPrivate ? "opacity-100" : "opacity-0"
                  }`}
                  style={{ top: "50%", transform: "translateY(-50%)" }}
                ></span>

                <span
                  className={`absolute left-1 top-1 transition-all duration-300 transform ${
                    isCheckedPrivate
                      ? "translate-x-8 bg-white"
                      : "translate-x-0 bg-white"
                  }`}
                  style={{ height: "25px", width: "25px", borderRadius: "50%" }}
                ></span>
              </button>
            </div>
            {!isAdvanced ? (
              <div className="flex items-center gap-5 md:mb-3 lg:mb-0">
                <input
                  type="checkbox"
                  className="w-7 h-7 rounded-full peer appearance-none border-2 border-red-500 checked:bg-red-500"
                  onClick={handleCheckboxChangePrivateNSF}
                  id="nsfw"
                />
                <label
                  className="text-base md:text-md lg:text-lg xl:text-lg"
                  htmlFor="nsfw"
                >
                  Allow NSFW
                </label>
              </div>
            ) : null}
          </div>
          <div className="flex flex-col gap-5 lg:gap-12 text-black font-josefin-sans relative">
            <div className="flex items-start gap-1">
              <p className="text-base md:text-lg lg:text-xl xl:text-2xl font-semibold text-gray-800 flex gap-1 items-start">
                Video Quality
              </p>

              <div
                // onMouseEnter={() => setIsHoverquality(true)}
                // onTouchStart={() => setIsHoverquality(true)}
                // onMouseLeave={() => setIsHoverquality(false)}
              >
                <img
                  loading="lazy"
                  src={info}
                  alt="info"
                  className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                />

                {isHoverquality && (
                  <span className="absolute text-white bg-[#8a8a8a] text-sm bottom-8 md:right-0 p-2 shadow-lg rounded max-w-xs w-auto">
                    Higher quality increases video detail but leads to larger
                    file sizes.
                  </span>
                )}
              </div>
            </div>

            <div className="flex items-center gap-4 ">
              <input
                type="radio"
                className="w-7 h-7 rounded-full peer appearance-none border-2 border-red-500 checked:bg-red-500"
                onClick={handleRadioChangeQuality}
                id="Normal"
                name="video-quality"
                checked={isRadioQuality === "Normal"}
              />
              <label
                className="text-base md:text-md lg:text-lg xl:text-lg"
                htmlFor="Normal"
              >
                Normal
              </label>

              <input
                type="radio"
                className="w-7 h-7 rounded-full peer appearance-none border-2 border-red-500 checked:bg-red-500"
                onClick={handleRadioChangeQuality}
                id="High"
                name="video-quality"
                checked={isRadioQuality === "High"}
              />
              <label
                className="text-base md:text-md lg:text-lg xl:text-lg"
                htmlFor="High"
              >
                High
              </label>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center">
          <button className=" w-full mo:w-2/3 h-[80px] mt-5 rounded-xl text-xl lg:text-3xl bg-gradient-to-r from-[#99111f] via-[#d43e44] to-[#ff5c6c] py-4 text-center flex items-center gap-5 justify-center relative overflow-hidden group shadow-xl hover:shadow-2xl transition-all duration-500 ease-out">
            <span className="relative z-10 text-white font-bold">
              Generate for 2.00
            </span>
            {/* Glowing Background Effect */}
            <span className="absolute inset-0 bg-gradient-to-r from-[#e63d3d] to-[#ff5c6c] opacity-0 group-hover:opacity-80 animate-glow-background"></span>

            {/* Glowing Icon Animation */}
            <img
              loading="lazy"
              className="w-12 transition-transform duration-300 group-hover:scale-[1.3] group-hover:rotate-0 group-hover:glow-icon"
              src={goldE}
              alt="goldE"
            />

            {/* Glowing Border and Pulse Effect */}
            <span className="absolute inset-0 rounded-xl border-4 border-transparent group-hover:border-[#99111f] opacity-0 group-hover:opacity-100 animate-glow-border"></span>
          </button>
        </div>
      </div>
    </>
  );
};

export default VideoPage;
