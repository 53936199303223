import Swal from "sweetalert2";
import axios from 'axios';
import { BaseApiUrl } from "./enviroment";
import loaders from "../frontend/components/loaders";

const handleError = (statusText, response, hideError, status) => {

    if (!hideError && status !== 404 && status !== 405) {
        console.log(statusText, response, hideError, status)
        let text = response?.detail || response?.message || response?.data || response?.msg
        if (!text && status !== 500 && status !== 502) {
            // Iterate through the object
            for (const key in response) {
                if (response.hasOwnProperty(key)) {
                    if (key === 'error') {
                        text = response[key]
                    } else {
                        text = `${key}: ${response[key]}`
                    }
                    break;
                }
            }
        }
        if (status === 401) {
            localStorage.removeItem('token');
            // text = "Wrong crediantials. Please try again."
        }
        Swal.fire({
            title: statusText === 'Bad Request' ? 'Error!!!' : statusText === 'Forbidden' ? 'Warning!!!' : statusText,
            text: text?.replaceAll('_', ' '),
            icon: "warning",
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            confirmButtonColor: "#990013",
            showCancelButton: false,
            backdrop: "#b1b1b1eb",
        });
    }
}


class UserApiClient {

    static post(endpoint, params, addToken = false, hideError = false, useLoader = true) {
        let url = BaseApiUrl + endpoint;
        if (useLoader) { loaders(true, "heart") }
        return new Promise(function (fulfill, reject) {

            if (addToken) { axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token') || localStorage.getItem('authToken')}` }
            // Check if params is FormData, and if so, add the Content-Type header
            const headers = params instanceof FormData ? {} : { 'Content-Type': 'multipart/form-data' };
            axios.post(url, params, { headers }).then(function (response) {
                if (useLoader) { loaders(false, "heart") }
                fulfill({ ...response, success: true });
            }).catch(function (error) {
                if (useLoader) { loaders(false, "heart") }
                if (error && error.response) {
                    handleError(error.response.statusText, error.response?.data, hideError, error.response?.status)
                    fulfill({ ...error.response, success: false });
                } else {
                    console.log('Network error occurred:', error.message);
                    fulfill({ data: null, success: false, message: 'Network Error' });
                }
            });
        });
    }

    static put(endpoint, params, addToken = false, hideError = false, useLoader = true) {
        let url = BaseApiUrl + endpoint;
        if (useLoader) { loaders(true, "heart") }
        return new Promise(function (fulfill, reject) {

            if (addToken) { axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token') || localStorage.getItem('authToken')}` }

            axios.put(url, params).then(function (response) {
                if (useLoader) { loaders(false, "heart") }
                fulfill({ ...response, success: true });
            }).catch(function (error) {
                if (useLoader) { loaders(false, "heart") }
                if (error && error.response) {
                    handleError(error.response.statusText, error.response?.data, hideError, error.response?.status)
                    fulfill({ ...error.response, success: false });
                } else {
                    console.log('Network error occurred:', error.message);
                    fulfill({ data: null, success: false, message: 'Network Error' });
                }
            });
        });
    }

    static patch(endpoint, params, addToken = false, hideError = false, useLoader = true) {
        let url = BaseApiUrl + endpoint;
        if (useLoader) { loaders(true, "heart") }
        return new Promise(function (fulfill, reject) {

            if (addToken) { axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token') || localStorage.getItem('authToken')}` }

            axios.patch(url, params).then(function (response) {
                if (useLoader) { loaders(false, "heart") }
                fulfill({ ...response, success: true });
            }).catch(function (error) {
                if (useLoader) { loaders(false, "heart") }
                if (error && error.response) {
                    handleError(error.response.statusText, error.response?.data, hideError, error.response?.status)
                    fulfill({ ...error.response, success: false });
                } else {
                    console.log('Network error occurred:', error.message);
                    fulfill({ data: null, success: false, message: 'Network Error' });
                }
            });
        });
    }

    static get(endpoint, params, addToken = false, hideError = false, useLoader = true) {
        let url = BaseApiUrl + endpoint;
        if (useLoader) { loaders(true, "heart") }
        return new Promise(function (fulfill, reject) {

            if (addToken) { axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token') || localStorage.getItem('authToken')}` }

            axios.get(url, params).then(function (response) {
                if (useLoader) { loaders(false, "heart") }
                fulfill({ ...response, success: true });
            }).catch(function (error) {
                if (useLoader) { loaders(false, "heart") }
                if (error && error.response) {
                    handleError(error.response.statusText, error.response?.data, hideError, error.response?.status)
                    fulfill({ ...error.response, success: false });
                } else {
                    console.log('Network error occurred:', error.message);
                    fulfill({ data: null, success: false, message: 'Network Error' });
                }
            });
        });
    }

    static delete(endpoint, params, addToken = false, hideError = false, useLoader = true) {
        let url = BaseApiUrl + endpoint;
        if (useLoader) { loaders(true, "heart") }
        return new Promise(function (fulfill, reject) {

            if (addToken) { axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token') || localStorage.getItem('authToken')}` }

            axios.delete(url, { data: params }).then(function (response) {
                if (useLoader) { loaders(false, "heart") }
                fulfill({ ...response, success: true });
            }).catch(function (error) {
                if (useLoader) { loaders(false, "heart") }
                if (error && error.response) {
                    handleError(error.response.statusText, error.response?.data, hideError, error.response?.status)
                    fulfill({ ...error.response, success: false });
                } else {
                    console.log('Network error occurred:', error.message);
                    fulfill({ data: null, success: false, message: 'Network Error' });
                }
            });
        });
    }

}

export default UserApiClient;