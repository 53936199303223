import React, { useEffect, useState } from 'react';
import UserApiClient from '../../../utilities/apiClients';

const Notification = () => {
    const [notification, setNotifications] = useState([]);

    useEffect(() => {
        UserApiClient.get("api/v1/notification/", {}, false, true, false).then(
            async (res) => {
                if (res.success) {
                    setNotifications(res.data);
                }
            }
        );
    }, []);

    const removeNotification = (id) => {
        setNotifications(notification.filter(item => item.id !== id));
    };

    const timeAgo = (timestamp) => {
        const now = new Date();
        const past = new Date(timestamp);
        const seconds = Math.floor((now - past) / 1000);

        const intervals = {
            year: 31536000,
            month: 2592000,
            week: 604800,
            day: 86400,
            hour: 3600,
            minute: 60,
            second: 1,
        };

        for (const [unit, value] of Object.entries(intervals)) {
            const count = Math.floor(seconds / value);
            if (count >= 1) {
                return `${count} ${unit}${count > 1 ? "s" : ""} ago`;
            }
        }

        return "Just now";
    };

    return (
        <div className='pt-5 md:pt-20'>

            <h2 className='text-2xl md:text-3xl lg:text-4xl xl:text-4xl text-center font-semibold text-gray-700 p-4 '>Notifications</h2>
            {(notification && notification.length > 0) ?
                <>
                    {notification.map((item) => (
                        <div key={item.id} style={{ background: 'rgba(255,255,255,0.85)' }} className='p-4 md:p-8 rounded-xl w-[90%] mx-auto my-5 shadow-lg hover:shadow-2xl transition duration-300 ease-in-out transform hover:-translate-y-1'>
                            <div className='flex justify-between gap-2 items-center'>
                                <div>
                                    <p className='text-sm md:text-base text-gray-500'>{timeAgo(item.created_at || "Just Now")}</p>
                                    <h2 className='text-base md:text-lg xl:text-xl text-gray-800 font-bold'>{item.text}</h2>
                                </div>
                                <button
                                    className="bg-red-600 hover:bg-red-700 p-2 rounded-full text-white transition duration-300 ease-in-out focus:outline-none focus:ring-4 focus:ring-red-500 focus:ring-opacity-50"
                                    onClick={() => removeNotification(item.id)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        className="w-6 h-6 hover:stroke-white"  // Tailwind class to change stroke color on hover
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L4.999 7m3-4h10a2 2 0 012 2H6a2 2 0 012-2zm3 0V3a2 2 0 00-2-2h-4a2 2 0 00-2 2v1m10 0H7" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M10 11v6m4-6v6" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    ))}</> : <>
                    <h2 className='text-lg md:text-xl text-center font-semibold text-red-700 p-4 '>No Notifications</h2>

                </>}
        </div>
    )
}

export default Notification;
