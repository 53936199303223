import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginImg, Logo } from "../../../assets";
import UserApiClient from "../../../utilities/apiClients";
import { useDispatch } from "react-redux";
import { loginSuccess, logout } from "../../../redux/actions";

const LoginAdmin = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validate, setValidate] = useState({
    emailError: "",
    passwordError: "",
  });
  const validateEmail = (email) => {
    if (!email) {
      setValidate((prev) => ({
        ...prev,
        emailError: "Email address is required.",
      }));
      return false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setValidate((prev) => ({
        ...prev,
        emailError: "Please enter a valid email address.",
      }));
      return false;
    } else {
      setValidate((prev) => ({ ...prev, emailError: "" }));
      return true;
    }
  };

  const validatePassword = (password) => {
    if (!password) {
      setValidate((prev) => ({
        ...prev,
        passwordError: "Password is required.",
      }));
      return false;
    } else if (password.length < 8) {
      setValidate((prev) => ({
        ...prev,
        passwordError: "Password must be at least 8 characters.",
      }));
      return false;
    } else {
      setValidate((prev) => ({ ...prev, passwordError: "" }));
      return true;
    }
  };

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault()
    const data = {
      username: email,
      password: password,
    };
    
    var navigarted = false;
    if (validateEmail(email) && validatePassword(password)) {
      await UserApiClient.post(`api/login/`, data, false).then(async (res) => {
        if (res.success) {
          dispatch(loginSuccess(res.data.user));
          localStorage.setItem("authToken", res.data.access);
          if (res.data.user?.is_superuser === false) {
            await UserApiClient.get(
              `api/v1/admin/staff/${res.data.user.id}/`,
              {},
              true
            ).then(async (resp) => {
              if (resp.success) {
                if (resp.data.permissions.dashboard === false) {
                  Object.entries(resp.data.permissions).map(([key, value]) => {
                    if (value === true && !navigarted) {
                      console.log("navigated to", key);
                      navigarted = true;
                      navigate(
                        `/admin/${
                          key === "user"
                            ? "allUser"
                            : key === "blog"
                            ? "blogList"
                            : key === "affiliate_member"
                            ? "affiliate"
                            : key === "home_banner"
                            ? "banners"
                            : key === "withdrawal"
                            ? "withdrawals"
                            : key === "add_template"
                            ? "template"
                            : key === "sitemap"
                            ? "sitemaps"
                            : key === "faq"
                            ? "faqs"
                            : key
                        }`
                      );
                    }
                  });
                } else {
                  navigate("/admin/dashboard");
                }
              }
            });
          } else {
            navigate("/admin/dashboard");
          }
        } else {
          dispatch(logout());
        }
      });
    }
  };

  return (
    <>
      <div className="w-full bg-white flex justify-between font-josefin-sans h-full">
        <div className="w-[40%] hidden md:block">
          <img
            loading="lazy"
            src={loginImg}
            alt="login"
            className="w-full object-cover h-[100vh]"
          />
        </div>
        <div className="px-5 relative pt-32 md:p-0 w-full md:w-[60%] h-[100vh] flex justify-center items-center">
          <button
            type="button"
            className="btn absolute top-3 left-3 text-lg text-[#ff0022] cursor-pointer flex items-center"
            onClick={() => navigate("/")}
          >
            <i className="fa-solid fa-circle-arrow-left mr-2"></i>Back
          </button>
          <form className="w-[400px] lg:w-[560px]" onSubmit={handleLogin}>
            <img loading="lazy" src={Logo} alt="Logo_Nav" className="m-auto" />
            <p className="text-3xl font-bold text-center mt-5 mb-10">
              ADMIN LOGIN
            </p>
            <div className="bg-white px-2 py-3 border-[3px] border-zinc-300 rounded-lg flex justify-between items-center mb-2">
              <input
                type="email"
                className="ps-5 focus:outline-none w-full text-lg placeholder:text-zinc-300"
                placeholder="Enter your Username/email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {validate.emailError && (
              <p className="text-red-500 text-sm mt-1">{validate.emailError}</p>
            )}
            <div className="bg-white px-2 py-3 mt-4 border-[3px] border-zinc-300 rounded-lg flex justify-between items-center ">
              <input
                type="password"
                className="text-lg ps-5 focus:outline-none w-full placeholder:text-zinc-300"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {validate.passwordError && (
              <p className="text-red-500 text-sm mt-1">
                {validate.passwordError}
              </p>
            )}
            {/* <p className='font-semibold mt-2'>Forgot your password? Reset it</p> */}
            <button
              type="submit"
              className="py-[10px] font-semibold text-lg w-full rounded-md bg-red-500 hover:bg-red-600 text-white mt-8"
              onClick={handleLogin}
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginAdmin;
