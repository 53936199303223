import React, { useState, useEffect } from "react";
import { IoSearchOutline } from "react-icons/io5";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { aiModal3, aiModal_futanari, aiModal4, aiModal11, aiModalAmatue, aiModal12, aiModal13, alert, aiModal5, aiModal6, bigtits, mediumtits, GiantTits, 
  BJ, smalltits, goldE, info, boy, girls, Ass_show, Bondage, From_behind, Reverse_cowgirl, breast_massage, boobjob, cum, dildo, footjob, front_pussy, 
  futanari_V2, legs_up_pussy, missionary, missionary_anal, pants_down, pregnant, pregnant_front_pussy, Anime_Male_bondage, Anime_Male_muscular_body, 
  Anime_Male_penis, Anime_bondage, Anime_boobjob, Anime_breast_grab, Anime_cum_bath, Anime_gigantic_boobs, Anime_imminent_penetration, Anime_masturbation, 
  Anime_missionary_pov, Anime_pants_down, Anime_pussy_from_behind, Anime_reverse_suspended_anal, Anime_sideways_ass, Male_penis, Male_bondage, 
  Male_muscular_body, CumOnFace_amature, Granny_amature, Inmouth_amature, ThickAss_amature, bj_amature, blind_amature, clothing1_amature, doggy_amature, 
  frontpussy_amature, lickings_amature, mascular_amature, titbondage_amature, titfuck_amature, transparent_amature,
} from "../../../assets";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import "../../../../src/swipper.css";
import loaders from "../../../frontend/components/loaders";
import Swal from "sweetalert2";
import { BaseApiUrl } from "../../../utilities/enviroment";

const Simplified = () => {
  const [selectedFeature, setSelectedFeature] = useState("Hair Color");
  const [selectedCharacter, setSelectedCharacter] = useState("1 Girl");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedIndexAi, setSelectedIndexAi] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedPrivate, setIsCheckedPrivate] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredone, setIsHoveredone] = useState(false);
  const [errorisOpen, setErrorIsOpen] = useState(false);
  const [modaltype, setModalType] = useState("real");
  const [uuidrequired, setUuidrequired] = useState("");

  const errorCloseModal = () => setErrorIsOpen(false);
  const errorOpenModal = () => setErrorIsOpen(true);

  const [hover, setHover] = useState({
    feature: false,
    extension: false,
    character: false,
    model: false,
  });

  const [isAdvanced, setIsAdvanced] = useState(false);
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [text, setText] = useState("");
  const [selectedSuggestions, setSelectedSuggestions] = useState([]); // To store selected suggestions

  // Suggestions data
  const suggestions = [
    {
      id: 1,
      line: "1girl, brown hair, (large breasts), straight hair, wearing bra, in a desert, Bokeh",
    },
    {
      id: 2,
      line: "1girl, brown hair, (large breasts), straight hair, wearing bikini, in a casino, motion blur",
    },
    {
      id: 3,
      line: "1 girl, 30 years old, brown hair, (medium breasts), pixie cut, wearing pink dress, in a strip club, bokeh",
    },
    {
      id: 4,
      line: "1 girl, 22 years old, Blonde hair, (large breasts), curly hair, wearing dress, in a cruise ship, bokeh",
    },
  ];

  const handleChangeadvance = (e) => {
    setText(e.target.value);
  };
  const handleSuggestionClick = (line) => {
    // If the suggestion is already selected, remove it
    if (selectedSuggestions.includes(line)) {
      setSelectedSuggestions(
        selectedSuggestions.filter((item) => item !== line)
      );
      setText(text.replace(line, "")); // Remove from textarea
    } else {
      // Add the new suggestion to the list and textarea
      setSelectedSuggestions([...selectedSuggestions, line]);
      setText(text + " " + line); // Append to textarea
    }
  };

  const handleSelect = (num) => {
    if ((num === 6 || num === 8 || num === 12 || num === 16) && isChecked) {
      setIsChecked(false);
      const isMobile = window.innerWidth <= 768; 
      toast.error("Upscaling all variations at once may take some time. To speed things up, you can individually upscale images in the 'My Gallery' section.", {
        position: isMobile ? "top-right" : "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    else if (value === num) {
      setValue("");
    } else {
      setValue(num);
    }

    setError(""); // Clear any existing error when a valid number is selected
  };

  // const handleChange = (e) => {
  //   const inputValue = e.target.value;
  //   if (inputValue === "9") {
  //     setError("You cannot enter 9");
  //     setValue("");
  //   } else if (inputValue <= 8 && inputValue >= 0) {
  //     setError("");
  //     setValue(inputValue);
  //   } else {
  //     setError("Please enter a valid number between 0 and 8");
  //   }
  // };

  const [selectedTitleAi, setSelectedTitleAi] = useState("Realistic");

  // const responsive = {
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1280 },
  //     items: 4,
  //     partialVisibilityGutter: 40,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 2,
  //     partialVisibilityGutter: 30,
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //     partialVisibilityGutter: 30,
  //   },
  // };

  const data = [
    // { img: aiModal8, title: 'Super Realistic Model', model: 'realisticinstav6.safetensors [ecb478bd5e]' },
    {
      img: aiModal5,
      title: "Realistic",
      type: "real",
      model: "influncerv2_p1.safetensors",
      description:
        "This model generates hyper-realistic human images, capturing subtle facial features, natural lighting, and detailed textures that mirror real-world photography. Ideal for lifelike portraits and high-detail renderings",
    },
    {
      img: aiModal6,
      title: "Hentai",
      type: "Anime",
      model: "metrixhentaip.safetensors",
      description:
        "Specialized for creating anime-inspired content with traditional Hentai aesthetics. It maintains bold outlines, vivid colors, and highly stylized character designs suitable for fans of classic and contemporary Hentai art styles.",
    },
    {
      img: aiModal4,
      title: "Enchant",
      type: "real",
      model: "hyperrealisticv6.safetensors",
      description:
        "This model produces images with a hyper-realistic yet magical quality, blending realism with a touch of fantasy. The resulting artwork captures mystical atmospheres, perfect for enchanting portraits or dreamlike scenes with high attention to detail.",
    },
    {
      img: aiModal11,
      title: "Anime HD",
      type: "Anime",
      model: "animeCARTOON_v10.ckpt",
      description:
        "A high-definition anime model optimized for crisp, vibrant, and detailed animation-style images. This model is suited for both fan art and original characters, capturing the essence of Japanese animation with clear lines and expressive colors.",
    },
    {
      img: aiModal12,
      title: "Surreal",
      type: "real",
      model: "realisticinstav6.safetensors",
      description:
        "Focusing on surrealism, this model generates lifelike images with an imaginative twist, merging real-world elements with abstract concepts. Perfect for creative compositions that challenge reality while maintaining a high level of detail.",
    },
    {
      img: aiModal_futanari,
      title: "Futanari",
      type: "Anime",
      model: "metrixhentaip.safetensors",
      description:
        "A anime model optimized to generate futanari images that consists hermaphrodite characters, generally with female bodies plus a male phallus. Extensions is not available on this modal.",
    },
    {
      img: aiModal13,
      title: "Anime 3D",
      type: "Anime",
      model: "bestanimface.safetensors",
      description:
        "This model generates anime-style 3D renderings, providing depth and a unique stylized realism. Ideal for creating anime characters with a modern 3D aesthetic, bringing additional dimension and detail into traditional anime styles.",
    },
    {
      img: aiModal3,
      title: "Photoreal",
      type: "real",
      model: "realisticinstav6.safetensors",
      description:
        "Aiming for photorealism, this model generates highly detailed, lifelike images that closely resemble actual photography. With a focus on capturing fine textures, natural lighting, and realistic expressions, it's ideal for users looking for a true-to-life rendering style.",
    },
    {
      img: aiModalAmatue,
      title: "Amateur",
      type: "amature",
      model: "lazymixRealAmateur_v40.safetensors",
      description:
        "Aiming for photorealism, this model generates highly detailed, lifelike images that closely resemble actual photography. With a focus on capturing fine textures, natural lighting, and realistic expressions, it's ideal for users looking for a true-to-life rendering style.",
    },
    // { img: aiModal1, title: 'Surreal Anime Model', model: 'bestanimface.safetensors' },
    // { img: aiModal2, title: 'Realistic Anime Model', model: 'anniepornbestwithhuman.safetensors' },
    // { img: aiModal7, title: 'Dark Anime Model', model: 'dksAnimeMerge_dksAnimeV10.safetensors' },
  ];

  const BASE_URL = BaseApiUrl;

  // Function to set the selected index based on the default model from the API
  const setDefaultModel = async () => {
    try {
      loaders(true, "heart");
      const uuid = uuidv4();
      setUuidrequired(uuid);

      const response = await axios.get(`${BASE_URL}api/v1/GetDefaultModel/`, {
        params: {
          uuid: uuid, // Sending UUID as a query parameter
        }
      });
      const defaultModel = response.data.sd_model_checkpoint;

      // Find the index of the model in the data array
      const defaultIndex = data.findIndex(
        (item) => item.model === defaultModel

      );
      loaders(false, "heart");

      // If the model is found, set it as the selected index
      if (defaultIndex !== -1) {
        setSelectedIndexAi(defaultIndex);
      }
    } catch (error) {
      console.log("Error fetching default model:", error);
      Swal.fire(
        error.response.data.msg ||
        'Our Servers are busy!',
        'Please wait a moment and try again.',
        'warning',
        3000
      );
    }
  };

  useEffect(() => {
    // Call the function to fetch the default model when the component is mounted
    setDefaultModel();
  }, []);

  const selectedmodal = (index) => {
    const selectedModel = data[index].model;


    let requestData = {
      sd_model_checkpoint: selectedModel,
      uuid: uuidrequired,
    };


    // axios
    //   .post(`${BASE_URL}api/v1/sd_model_setup/`, requestData)
    //   .then((response) => {
    //     console.log(JSON.stringify(response.data));
    //   })
    //   .catch((error) => {
    //     Swal.fire(
    //       error.response.data.msg || 
    //         "Oops! Something Went Wrong. Please Try Again.",
    //       "",
    //       "warning",
    //       3000
    //     );
    //   });
  };

  const handleModelSelection = (index, type) => {
    setModalType(type);
    setSelectedIndex(null)
    setSelectedIndexAi(index);


    const selectedtitle = data[index].title;
    setSelectedTitleAi(selectedtitle);
    selectedmodal(index);
  };

  // Sample data for images and their titles
  const images = [
    {
      src: smalltits,
      title: "Small",
      trigerWord: "Small Tits",
      Lora: "",
      type: modaltype === 'real' ? "real" : "amature",
    },
    {
      src: mediumtits,
      title: "Medium",
      trigerWord: "Medium Saggy Tits",
      Lora: "",
      type: modaltype === 'real' ? "real" : "amature",
    },
    {
      src: bigtits,
      title: "Big",
      trigerWord: "Big Tits",
      Lora: "",
      type: modaltype === 'real' ? "real" : "amature",
    },
    {
      src: GiantTits,
      title: "Huge",
      trigerWord: "Huge Tits",
      Lora: "",
      type: modaltype === 'real' ? "real" : "amature",
    },
    {
      src: breast_massage,
      title: "Breast Massage",
      trigerWord: "Breast massage",
      type: "real",
      Lora: "",
    },
    { src: boobjob, title: "Boobjob", trigerWord: "Boobjob", Lora: "", type: "real" },
    {
      src: Anime_breast_grab,
      title: "Anime Breast Grab",
      trigerWord: "Anime breast grab",
      Lora: "",
      type: "Anime",
    },
    {
      src: Anime_gigantic_boobs,
      title: "Anime Gigantic Boobs",
      trigerWord: "Anime gigantic boobs",
      Lora: "",
      type: "Anime",
    },
    {
      src: Anime_boobjob,
      title: "Anime Boobjob",
      trigerWord: "Anime boobjob",
      Lora: "",
      type: "Anime",
      ad_prompt:
        "((blowjob face:0.8)), ((hyper realistic face:0.5)), blowjobTopofheadPovquiron, penis",
    },
    {
      src: titfuck_amature,
      title: "TitFuck",
      trigerWord: "Tit Fuck",
      Lora: "",
      type: "amature",
      ad_prompt:
        "((blowjob face:0.8)), ((hyper realistic face:0.5)), blowjobTopofheadPovquiron, penis",
    },
  ];
  const imagestwo = [
    {
      src: Ass_show,
      title: "Ass Show",
      trigerWord: "Ass show",
      Lora: "",
      type: "real",
    },
    {
      src: pants_down,
      title: "Pants Down",
      trigerWord: "Pants down",
      Lora: "",
      type: "real",
    },
    {
      src: Anime_pants_down,
      title: "Anime Pants Down",
      trigerWord: "Anime pants down",
      Lora: "",
      type: "Anime",
    },
    {
      src: Anime_sideways_ass,
      title: "Anime Sideways Ass",
      trigerWord: "Anime sideways ass",
      Lora: "",
      type: "Anime",
    },
    {
      src: ThickAss_amature,
      title: "Thick Ass",
      trigerWord: "ThickAss_amature",
      Lora: "",
      type: "amature",
    },
    // { src: Futanari, title: 'Futanari', trigerWord: 'Futanari', Lora: '' },
  ];
  const imagesthree = [
    { src: cum, title: "Cum", trigerWord: "Cum", Lora: "", type: "real" },
    {
      src: Anime_cum_bath,
      title: "Anime Cum Bath",
      trigerWord: "Anime cum bath",
      Lora: "",
      type: "Anime",
    },
    { src: CumOnFace_amature, title: "Cum On Face", trigerWord: "Cum On Face Amature", Lora: "", type: "amature" },
    { src: Inmouth_amature, title: "Cum In Mouth", trigerWord: "Inmouth_amature", Lora: "", type: "amature" },
  ];
  const imagesfour = [
    {
      src: front_pussy,
      title: "Front Pussy",
      trigerWord: "Front pussy",
      Lora: "",
      type: "real",
    },
    {
      src: legs_up_pussy,
      title: "Legs Up Pussy",
      trigerWord: "Legs up pussy",
      Lora: "",
      type: "real",
    },
    {
      src: Anime_masturbation,
      title: "Anime Masturbation",
      trigerWord: "Anime masturbation",
      Lora: "",
      type: "Anime",
    },
    {
      src: Anime_pussy_from_behind,
      title: "Anime Pussy From Behind",
      trigerWord: "Anime pussy from behind",
      Lora: "",
      type: "Anime",
    },
    {
      src: frontpussy_amature,
      title: "Front Pussy",
      trigerWord: "Front Pussy Amature",
      Lora: "",
      type: "amature",
    },
  ];
  const imagesfive = [
    {
      src: footjob,
      title: "Footjob",
      trigerWord: "Footjob",
      Lora: "",
      type: "real",
    },
  ];
  const imagessix = [
    {
      src: pregnant,
      title: "Pregnant",
      trigerWord: "Pregnant",
      Lora: "",
      type: "real",
    },
    {
      src: pregnant_front_pussy,
      title: "Pregnant Front Pussy",
      trigerWord: "Pregnant front pussy",
      Lora: "",
      type: "real",
    },
  ];
  const imagesseven = [
    {
      src: Bondage,
      title: "Bondage",
      trigerWord: "Bondage",
      Lora: "",
      type: "real",
    },
    {
      src: Anime_bondage,
      title: "Anime Bondage",
      trigerWord: "Anime bondage",
      Lora: "",
      type: "Anime",
    },
    {
      src: titbondage_amature,
      title: "Tit Bondage",
      trigerWord: "titbondage_amature",
      Lora: "",
      type: "amature",
    },
  ];
  const imageseight = [
    { src: dildo, title: "Dildo", trigerWord: "Dildo", Lora: "", type: "real" },
    {
      src: missionary,
      title: "Missionary",
      trigerWord: "Missionary",
      Lora: "",
      type: "real",
    },
    {
      src: missionary_anal,
      title: "Missionary Anal",
      trigerWord: "Missionary anal",
      Lora: "",
      type: "real",
    },
    {
      src: Reverse_cowgirl,
      title: "Reverse Cowgirl",
      trigerWord: "Reverse cowgirl",
      Lora: "",
      type: "real",
    },
    {
      src: From_behind,
      title: "From Behind",
      trigerWord: "From behind",
      Lora: "",
      type: "real",
    },
    {
      src: Anime_imminent_penetration,
      title: "Anime Imminent Penetration",
      trigerWord: "Anime imminent penetration",
      Lora: "",
      type: "Anime",
    },
    {
      src: Anime_missionary_pov,
      title: "Anime Missionary Pov",
      trigerWord: "Anime missionary pov",
      Lora: "",
      type: "Anime",
    },
    {
      src: Anime_reverse_suspended_anal,
      title: "Anime Reverse Suspended Anal",
      trigerWord: "Anime reverse suspended anal",
      Lora: "",
      type: "Anime",
    },
    {
      src: doggy_amature,
      title: "Doggy Style",
      trigerWord: "doggy_amature",
      Lora: "",
      type: "amature",
    },
  ];
  const imagesnine = [
    {
      src: futanari_V2,
      title: "Futanari",
      trigerWord: "Futanari",
      Lora: "",
      type: "real",
    },
  ];
  const imagesten = [
    {
      src: BJ,
      title: "BJ",
      trigerWord: "Blowjob",
      Lora: "",
      type: "real",
      ad_prompt:
        "((blowjob face:0.8)), ((hyper realistic face:0.5)), blowjobTopofheadPovquiron, penis",
    },
    {
      src: lickings_amature,
      title: "Licking",
      trigerWord: "Licking amature",
      Lora: "",
      type: "amature",
      ad_prompt:
        "((blowjob face:0.8)), ((hyper realistic face:0.5)), blowjobTopofheadPovquiron, penis",
    },
    {
      src: bj_amature,
      title: "Pov BJ",
      trigerWord: "BJ amature",
      Lora: "",
      type: "amature",
      ad_prompt:
        "((blowjob face:0.8)), ((hyper realistic face:0.5)), blowjobTopofheadPovquiron, penis",
    },
  ];
  const imageseleven = [
    {
      src: blind_amature,
      title: "Blind",
      trigerWord: "Blind",
      Lora: "",
      type: "amature",
    },
    {
      src: transparent_amature,
      title: "Transparent",
      trigerWord: "Transparent",
      Lora: "",
      type: "amature",
    },
    {
      src: clothing1_amature,
      title: "Saree",
      trigerWord: "Saree",
      Lora: "",
      type: "amature",
    },
  ]
  const imagestwelev = [
    {
      src: mascular_amature,
      title: "Muscular",
      trigerWord: "Muscular_amature",
      Lora: "",
      type: "amature",
    },
    {
      src: Granny_amature,
      title: "Old",
      trigerWord: "Granny_amature",
      Lora: "",
      type: "amature",
    },
  ]

  const imagesboyone = [
    {
      src: Male_muscular_body,
      title: "Muscular Body",
      type: "real",
      trigerWord: "Male muscular body",
    },
    {
      src: Anime_Male_muscular_body,
      title: "Anime Muscular Body",
      type: "Anime",
      trigerWord: "Anime Male muscular body",
    },
  ];
  const imagesboytwo = [
    { src: Male_penis, title: "Penis", trigerWord: "Male penis", type: "real", },
    {
      src: Anime_Male_penis,
      title: "Anime Penis",
      type: "Anime",
      trigerWord: "Anime Male penis",
    },
  ];
  const imagesboythree = [
    {
      src: Male_bondage, title: "Bondage", trigerWord: "Male bondage",
      type: "real",
    },
    {
      src: Anime_Male_bondage,
      title: "Bondage",
      type: "Anime",
      trigerWord: "Anime Male bondage",
    },
  ];

  const filteredBoyone = imagesboyone.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredBoyTwo = imagesboytwo.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredBoyThree = imagesboythree.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredImages = images.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredImagesTwo = imagestwo.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredImagesThree = imagesthree.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesFour = imagesfour.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesFive = imagesfive.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesSix = imagessix.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesSeven = imagesseven.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesEight = imageseight.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesNine = imagesnine.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesTen = imagesten.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImageseleven = imageseleven.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagestwelev = imagestwelev.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const features = [
    { name: "Hair Color" },
    { name: "Hair Cut" },
    { name: "Wearing" },
    { name: "Body Type" },
    { name: "Nationality" },
    { name: "Environment" },
    // { name: 'Style' },
    { name: "Effects" },
  ];

  const options = {
    Characters: [
      { name: "1 Girl", src: girls },
      { name: "1 Boy", src: boy },
    ],
    "1 Girl": {
      "Hair Color": [
        { name: "Blonde" },
        { name: "Black" },
        { name: "Brown" },
        { name: "Redhead" },
        { name: "Pink" },
        { name: "White" },
        { name: "Ginger" },
        { name: "Golden" },
        { name: "blue" },
        { name: "green" },
      ],
      "Hair Cut": [
        { name: "Pixie Cut" },
        { name: "Straight Hair" },
        { name: "Bob Cut" },
        { name: "Curly Hair" },
        { name: "Braids" },
        { name: "Bangs" },
        // { 'name': 'Bald' },
        { name: "Dreadlocks Hair" },
        { name: "Short Curly Hair" },
        { name: "Long Straight Hair" },
        { name: "Afro Hair" },
      ],
      Wearing: [
        { name: "Lingerie" },
        { name: "Fishnets" },
        { name: "Swimwear" },
        { name: "Bodysuits" },
        { name: "Corset" },
        { name: "Bikini" },
        { name: "Chemise" },
        { name: "French Maid" },
        { name: "Thong" },
        { name: "Yoga Pants" },
        { name: "Nightgown" },
        { name: "Transparent Clothing" },
        { name: "Latex Clothing" },
      ],
      "Body Type": [
        { name: "Slim" },
        { name: "Athletic" },
        { name: "Curvy" },
        // { 'name': 'Petite' },
        { name: "Hourglass" },
        // { 'name': 'Pear Shaped' },
        // { 'name': 'Tall' },
        // { 'name': 'Plus Size' },
      ],
      Nationality: [
        { name: "American" },
        { name: "Russian" },
        { name: "Polish" },
        { name: "German" },
        { name: "Italian" },
        { name: "French" },
        { name: "Indian" },
        { name: "Asian" },
        { name: "Brazilian" },
        // { 'name': 'Korean' },
        { name: "Japanese" },
        { name: "Mexican" },
        { name: "African" },
      ],
      Environment: [
        { name: "Street" },
        { name: "Swimming Pool" },
        { name: "Bathroom" },
        { name: "Apartment" },
        { name: "Beach" },
        { name: "Shopping Mall" },
        { name: "Luxurious Castle" },
        { name: "Cafe" },
        { name: "University" },
        { name: "Strip Club" },
        { name: "Restaurant" },
        { name: "Park" },
        // { 'name': 'Spaceship' },
        { name: "Mars" },
        { name: "Forest" },
        { name: "Mountain" },
        { name: "Lake" },
        { name: "Library" },
        // { 'name': 'Concert Venue' },
        { name: "Sports Stadium" },
        { name: "Cruise Ship" },
        { name: "Desert" },
        { name: "Farm" },
        { name: "Art Gallery" },
        { name: "Amusement Park" },
        { name: "Ski Resort" },
        { name: "Rainforest" },
        // { 'name': 'Space Station' },
        { name: "Aquarium" },
        { name: "Vineyard" },
        // { 'name': 'Movie Theater' },
        { name: "Fitness Center" },
        { name: "Historical Monument" },
        { name: "Airport" },
        // { 'name': 'Submarine' },
        // { 'name': 'Circus Tent' },
        { name: "Casino" },
        { name: "Underground Cave" },
        // { 'name': 'Space Observatory' },
        { name: "Abandoned Mansion" },
        { name: "Ski Lodge" },
        { name: "Botanical Garden" },
        { name: "Wildlife Sanctuary" },
        { name: "Secret Garden" },
        { name: "Floating Island" },
        { name: "Whimsical Wonderland" },
        // { 'name': 'Mystical Labyrinth' },
        // { 'name': 'Neon Utopia' }
      ],
      // 'Style': [
      //   { 'name': 'Realistic' },
      //   { 'name': 'Fantasy' },
      //   { 'name': 'Anime' },
      //   { 'name': 'Digital Art' },
      //   { 'name': 'Photorealism' },
      //   { 'name': 'Pop Art' },
      //   { 'name': 'Vintage' },
      //   { 'name': 'Surreal' },
      //   { 'name': 'Minimalist' },
      //   { 'name': 'Expressionist' },
      //   { 'name': 'Cubist' },
      //   { 'name': 'Gothic' },
      //   { 'name': 'Renaissance' },
      // ],
      Effects: [
        { name: "Bokeh" },
        // { 'name': 'Hardcore' },
        { name: "Sepia" },
        { name: "Vintage Film" },
        { name: "Lens Flare" },
        { name: "Reflection" },
        { name: "Bloom" },
        { name: "Depth Of Field" },
        { name: "Dream Blur" },
        // { 'name': 'Monochrome' }
      ],
    },
    "1 Boy": {
      "Hair Color": [
        { name: "Blonde" },
        { name: "Black" },
        { name: "Brown" },
        { name: "Redhead" },
        { name: "Pink" },
        { name: "White" },
        { name: "Ginger" },
        { name: "Golden" },
        { name: "blue" },
        { name: "green" },
      ],
      "Hair Cut": [
        { name: "Buzz Cut" },
        { name: "Crew Cut" },
        { name: "Man Bun Hair" },
        { name: "Curly Hair" },
        { name: "Quiff Hair Cut" },
        { name: "Side Part" },
        { name: "Dread locks Hair" },
        { name: "Loose Wave Hair" },
        { name: "Afro Hair" },
        { name: "Spiky" },
        { name: "Mullet" },
      ],
      Wearing: [
        { name: "T-Shirt" },
        { name: "Jeans" },
        { name: "Hoodie" },
        { name: "Jacket" },
        { name: "Shorts" },
        { name: "Suit" },
        { name: "Sweater" },
        { name: "Blazer" },
        { name: "Shirt" },
        { name: "Tank Top" },
        { name: "Polo Shirt" },
        { name: "Cargo Pants" },
        { name: "Tracksuit" },
        { name: "Leather Jacket" },
        { name: "V-Neck" },
      ],
      "Body Type": [
        { name: "Slim" },
        { name: "Athletic" },
        { name: "Muscular" },
        { name: "Average" },
        { name: "Broad Shouldered" },
        { name: "Body Builder" },
        // { 'name': 'Tall' },
        // { 'name': 'Short' },
        // { 'name': 'Stocky' },
      ],
      Nationality: [
        { name: "American" },
        { name: "Russian" },
        { name: "Polish" },
        { name: "German" },
        { name: "Italian" },
        { name: "French" },
        { name: "Indian" },
        { name: "Asian" },
        { name: "Brazilian" },
        // { 'name': 'Korean' },
        { name: "Japanese" },
        { name: "Mexican" },
        { name: "African" },
      ],
      Environment: [
        { name: "Street" },
        { name: "Swimming Pool" },
        { name: "Bathroom" },
        { name: "Apartment" },
        { name: "Beach" },
        { name: "Shopping Mall" },
        { name: "Luxurious Castle" },
        { name: "Cafe" },
        { name: "University" },
        { name: "Strip Club" },
        { name: "Restaurant" },
        { name: "Park" },
        { name: "Mars" },
        { name: "Forest" },
        { name: "Mountain" },
        { name: "Lake" },
        { name: "Library" },
        { name: "Sports Stadium" },
        { name: "Cruise Ship" },
        { name: "Desert" },
        { name: "Farm" },
        { name: "Art Gallery" },
        { name: "Amusement Park" },
        { name: "Ski Resort" },
        { name: "Rainforest" },
        { name: "Aquarium" },
        { name: "Vineyard" },
        { name: "Fitness Center" },
        { name: "Historical Monument" },
        { name: "Airport" },
        { name: "Casino" },
        { name: "Underground Cave" },
        { name: "Abandoned Mansion" },
        { name: "Ski Lodge" },
        { name: "Botanical Garden" },
        { name: "Wildlife Sanctuary" },
        { name: "Secret Garden" },
        { name: "Floating Island" },
        { name: "Whimsical Wonderland" },
      ],
      // 'Style': [
      //   { 'name': 'Realistic' },
      //   { 'name': 'Cartoon' },
      //   { 'name': 'Abstract' },
      //   { 'name': 'Minimalist' },
      //   { 'name': 'Anime' },
      //   { 'name': 'Digital Art' },
      //   { 'name': 'Sci-Fi' },
      //   { 'name': 'Steampunk' },
      //   { 'name': 'Noir' },
      //   { 'name': 'Futuristic' },
      //   { 'name': 'Graffiti' },
      //   { 'name': '3D Rendering' },
      // ],
      Effects: [
        { name: "Bokeh" },
        { name: "Sepia" },
        { name: "Vintage Film" },
        { name: "Lens Flare" },
        { name: "Reflection" },
        { name: "Bloom" },
        { name: "Depth Of Field" },
        { name: "Dream Blur" },
      ],
    },
  };
  const [selectedFeatures, setSelectedFeatures] = useState(options);

  const [selected, setSelected] = useState("medium");
  const [aiselected, setaiSelected] = useState("creative");
  const [MagicSelected, setMagicSelected] = useState("none");
  const [svgselected, setsvgSelected] = useState("fourth");
  const [otherInput, setOtherInput] = useState(null); // Add state for input value
  const [FaceoptionalInput, setFaceoptionalInput] = useState(null); // Add state for input value
  const [isCheckedPrivatensf, setIsCheckedPrivatensf] = useState(false);

  // const MagicClick = (value) => {
  //   setMagicSelected(value);
  // };
  // const handleClick = (value) => {
  //   setSelected(value);
  // };
  // const handleaiClick = (value) => {
  //   setaiSelected(value);
  // };

  const handlesvgClick = (value) => {
    if (svgselected === value) {
      setsvgSelected("");
    } else {
      setsvgSelected(value);
    }
  };

  const handleOtherInputChange = (e) => {
    setOtherInput(e.target.value); // Update state when input changes
  };
  // const handleCheckboxChange = (event) => {
  //   setIsChecked(event.target.checked);
  // };
  // const handleFaceoptionalInputChange = (e) => {
  //   setFaceoptionalInput(e.target.value); // Update state when input changes
  // };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleCheckboxChangePrivate = () => {
    setIsCheckedPrivate(!isCheckedPrivate);
  };
  const handleCheckboxChangePrivateNSF = () => {
    setIsCheckedPrivatensf(!isCheckedPrivatensf);
  };

  const navigate = useNavigate();
  //  const generate = () => {
  //   navigate('/dashboard/ImageView')
  //  }

  const generate = (modelindex) => {
    // const uuid = uuidv4(); 
    const subscription = localStorage.getItem("subscription_type");
    if (subscription === "free") {
      errorOpenModal();
      return;
    }
    loaders(true, "heart");

    // selectedmodal(selectedIndexAi,uuid);

    // const selectedModel = data[selectedIndexAi].model;
    // console.log(selectedModel,"SAdsadjksdjh")

    // let requestData = {
    //   sd_model_checkpoint: selectedModel,
    //   uuid: uuid,
    // };


    // axios
    //   .post(`${BASE_URL}api/v1/sd_model_setup/`, requestData)
    //   .then((response) => {
    //     console.log(JSON.stringify(response.data),"model setups ");
    //   }).catch((error) => {
    //       Swal.fire(
    //         error.response.data.msg ||
    //           "Oops! Something Went Wrong. Please Try Again.",
    //         "",
    //         "warning",
    //         3000
    //       );
    //     });


    const selectedHairColor =
      typeof selectedFeatures[selectedCharacter]["Hair Color"] === "string"
        ? selectedFeatures[selectedCharacter]["Hair Color"]
        : null;
    const selectedHaircut =
      typeof selectedFeatures[selectedCharacter]["Hair Cut"] === "string"
        ? selectedFeatures[selectedCharacter]["Hair Cut"]
        : null;
    const selectedWearing =
      typeof selectedFeatures[selectedCharacter]["Wearing"] === "string"
        ? selectedFeatures[selectedCharacter]["Wearing"]
        : null;
    const selectedBodyType =
      typeof selectedFeatures[selectedCharacter]["Body Type"] === "string"
        ? selectedFeatures[selectedCharacter]["Body Type"]
        : null;
    const selectedNationality =
      typeof selectedFeatures[selectedCharacter]["Nationality"] === "string"
        ? selectedFeatures[selectedCharacter]["Nationality"]
        : null;
    const selectedEnvironment =
      typeof selectedFeatures[selectedCharacter]["Environment"] === "string"
        ? selectedFeatures[selectedCharacter]["Environment"]
        : null;
    const selectedStyle =
      typeof selectedFeatures[selectedCharacter]["Style"] === "string"
        ? selectedFeatures[selectedCharacter]["Style"]
        : null;
    const selectedEffects =
      typeof selectedFeatures[selectedCharacter]["Effects"] === "string"
        ? selectedFeatures[selectedCharacter]["Effects"]
        : null;
    const nationality = selectedNationality;
    const Upscale = isChecked;
    const promptString = isAdvanced
      ? text
      : Object.values({
        character: selectedCharacter || null,
        hair_color: selectedHairColor ? selectedHairColor + " hair" : null,
        haircut: selectedHaircut ? selectedHaircut + " hair" : null,
        wearing: selectedWearing ? "Wearing " + selectedWearing : null,
        body_type: selectedBodyType ? selectedBodyType + " body" : null,
        nationality: selectedNationality || null,
        environment: selectedEnvironment
          ? "in a " + selectedEnvironment
          : null,
        style: selectedStyle ? selectedStyle + " style" : null,
        effects: selectedEffects ? selectedEffects + " effect" : null,
        extensions: selectedIndex,
        // extensions: selectedIndex ? "(" + selectedIndex + ")" : null,
        other: otherInput || null,
        face_prompt: FaceoptionalInput || null,
      })
        .filter((value) => value !== null)
        .join(", ");
    setSelectedItem(promptString);
    setSelectedItem(promptString);

    // Other arguments
    var newDate = new Date();

    var datetime =
      newDate.getDate() +
      "_" +
      newDate.getMonth() +
      "_" +
      newDate.getFullYear() +
      "_" +
      newDate.getTime();
    const selectedModel = data[selectedIndexAi].model;
    const requestPayload = {
      ...(isAdvanced ? {
        generation_prompt: promptString,
        AdvancePrompt: true,
      } : {
        character: selectedCharacter || null,
        hair_color: selectedHairColor ? selectedHairColor : null,
        haircut: selectedHaircut ? selectedHaircut : null,
        wearing: selectedWearing ? selectedWearing : null,
        body_type: selectedBodyType ? selectedBodyType : null,
        nationality: selectedNationality || null,
        environment: selectedEnvironment ? selectedEnvironment : null,
        style: selectedStyle ? selectedStyle : null,
        effects: selectedEffects ? selectedEffects : null,
        extensions: selectedIndex,
        generation_prompt: promptString,
        AdvancePrompt: false,
      }),

      steps: selected,
      nationality: nationality,
      make_ai_listen: aiselected,
      magic: MagicSelected,
      aspect_ratio: svgselected,
      upscale: Upscale,
      make_private: isCheckedPrivate,
      make_Nesf: isCheckedPrivatensf,
      user_name: localStorage.getItem("login"),
      generation_id: "ER_GenImg_" + datetime,
      generation_slug:
        "ER_" +
        selectedIndexAi +
        "_" +
        promptString.replaceAll(", ", "_").replaceAll(" ", "_"),
      Aimodel: selectedIndexAi,
      Aititle: selectedTitleAi,
      extensions: selectedIndex,
      face_prompt: FaceoptionalInput,
      other: otherInput,
      variations: value === "" ? "1" : value, // Corrected the ternary here
      uuid: selectedModel,
    };

    axios
      .post(`${BASE_URL}api/v1/generate_image/`, requestPayload)
      .then((response) => {
        // window.location.href = "/dashboard/ImageView/ER_GenImg_" + datetime;
        navigate('/dashboard/ImageView/ER_GenImg_' + datetime);
        loaders(false, "heart");
      })
      .catch((error) => {
        console.log("Error generating image:", error);
        loaders(false, "heart");
        Swal.fire(
          error.response.data.msg ||
          'Our Servers are busy!',
          'Please wait a moment and try again.',
          'warning',
          3000
        );
      });


  };
  const [nextButton, setNextButton] = useState(false)
  const choosefeature = (featureName, featureType) => {
    setSelectedFeatures((prev) => {
      const currentFeatures = prev[selectedCharacter]?.[featureType] || {};

      // Check if the feature is already selected
      if (currentFeatures === featureName) {
        const updatedCharacter = { ...prev[selectedCharacter] };
        setNextButton(false)
        delete updatedCharacter[featureType]; // Remove the feature type
        return {
          ...prev,
          [selectedCharacter]: updatedCharacter,
        };
      }

      // Otherwise, select the new feature
      return {
        ...prev,
        [selectedCharacter]: {
          ...prev[selectedCharacter],
          [featureType]: featureName,
        },
      };
    }
    );
    if (currentFeatureIndex + 1 < features.length) {
      setNextButton(true)
    }
    else {
      setNextButton(false)
    }


    // Move to the next feature
    // const nextIndex = currentFeatureIndex + 1;
    // if (nextIndex < features.length) {
    //   setSelectedFeature(features[nextIndex].name); // Set the next feature as selected
    //   setCurrentFeatureIndex(nextIndex); // Update current feature index
    // } else {
    //   // Optionally, handle when all features have been selected
    //   console.log("All features have been selected");
    // }
  };

  const next = () => {
    const nextIndex = currentFeatureIndex + 1;
    if (nextIndex < features.length) {
      setSelectedFeature(features[nextIndex].name); // Set the next feature as selected
      setCurrentFeatureIndex(nextIndex); // Update current feature index
      setNextButton(false)
    } else {
      // Optionally, handle when all features have been selected
      console.log("All features have been selected");
    }
  }

  return (
    <>
      <Helmet>
        <title>The Best AI Gf/Bf Chatbot Online - Eromantic.ai</title>
        <meta
          name="description"
          content="Customize your AI character step by step on Eromantic.AI. Choose their appearance, personality, hobbies, and relationship type to create a truly unique companion. Start building your perfect AI character today!"
        />
        <meta
          name="keywords"
          content="AI Girlfriend, Virtual Girlfriend, Anime Companions, Interactive AI, NSFW-friendly"
        />
        {/* You can add more meta tags if needed */}
      </Helmet>
      <ToastContainer />
      <div className="">
        <div className="container">
          <div className="flex justify-center items-center gap-5 pt-5 md:pt-5">
            <p className="text-center  font-semibold text-2xl xl:text-3xl">
              Select AI Model
            </p>
            <div className="relative">
              {hover.model && (
                <span className="absolute w-72 -left-32 mo:left-44  mo:bottom-4 -translate-x-1/2  bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                  Unlock the power of diverse models, from hyper-realistic human
                  portraits to vibrant Hentai and enchanting dreamscapes.
                  Whether you're seeking detailed anime HD, photorealism, or
                  surreal creativity, each model offers unique features to bring
                  your visions to life with stunning detail and style.
                </span>
              )}
              <img
                loading="lazy"
                src={info}
                alt="infoBlack"

                className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                onMouseEnter={() => setHover({ ...hover, model: true })}
                onMouseLeave={() => setHover({ ...hover, model: false })}
              />
            </div>
          </div>
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            pagination={{ el: ".swiper-pagination", clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="swiper_container"
          >
            {/* {data.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  className="relative"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  onClick={() => handleModelSelection(index, item.type)}
                >
                  <img loading="lazy" src={item.img} alt={item.title} />
                  <div className="absolute bg-[#d3b7b79c] border-1 rounded-full p-3 sm:text-sm md:text-base lg:text-lg bottom-1 left-1">
                    {item.title}
                  </div>
                  <div
                    className={`absolute text-white border-1 rounded-2xl p-3 sm:text-sm md:text-lg lg:text-lg bottom-1 right-1 ${
                      selectedIndexAi === index
                        ? "bg-[#0e7a36bf]"
                        : "bg-[#9d2d2de0]"
                    }`}
                  >
                    {selectedIndexAi === index ? "Selected" : "Select Model"}
                  </div>
                  {hoveredIndex === index && (
                    <div className="absolute mb-[6rem] ml-[6rem] text-center bottom-0 left-0 w-60 bg-black bg-opacity-70 p-2 text-white text-sm rounded-lg">
                      {item.description}
                    </div>
                  )}
                </div>
              </SwiperSlide>
            ))} */}

            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  className="relative"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  onClick={() => handleModelSelection(index, item.type)}
                >
                  <img loading="lazy" src={item.img} alt={item.title} />
                  <div className="absolute bg-[#d3b7b79c] border-1 rounded-full p-3 sm:text-sm md:text-base lg:text-lg bottom-1 left-4">
                    {item.title}
                  </div>
                  <div
                    className={`absolute text-white border-1 rounded-2xl p-3 sm:text-sm md:text-lg lg:text-lg bottom-1 right-4 ${selectedIndexAi === index
                      ? "bg-[#0e7a36bf]"
                      : "bg-[#9d2d2de0]"
                      }`}
                  >
                    {selectedIndexAi === index ? "Selected" : "Select Model"}
                  </div>
                  {hoveredIndex === index && (
                    <div className="absolute mb-[6rem] ml-[6rem] text-center bottom-0 left-0 w-60 bg-black bg-opacity-70 p-2 text-white text-sm rounded-lg">
                      {item.description}
                    </div>
                  )}
                </div>
              </SwiperSlide>
            ))}


            <div className="slider-controler">
              <div className="swiper-button-prev slider-arrow xss:-ml-5 lg:-ml-3">
                <span className="hover-text prev-text">Prev</span>
              </div>
              <div className="swiper-button-next slider-arrow xss:ml-5 lg:ml-3 ">
                <span className="hover-text next-text">Next</span>
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </Swiper>

          {selectedIndexAi !== null && (
            <div className="mt-4 p-4 bg-[#75787e6e] border rounded-lg text-lg text-gray-800">
              <h3 className="font-semibold mb-2">
                Selected {data[selectedIndexAi].title} Model
              </h3>
              <p>{data[selectedIndexAi].description}</p>
            </div>
          )}
        </div>
      </div>

      <div className="bg-[#afa4a4] rounded-lg">
        <div className="p-6">
          {/* Buttons to toggle content */}
          {isAdvanced ? (
            <button
              onClick={() => setIsAdvanced(false)}
              className={`px-6 py-2 rounded-lg focus:outline-none transition duration-300 ${isAdvanced ? "bg-white text-black" : "bg-black text-[#ffb5b5]"
                }`}
            >
              Back to Normal Mode
            </button>
          ) : (
            <>
              <span className="text-md text-gray-700">
                Advanced mode is designed for users skilled in crafting
                effective prompts. To make the most of this feature, we
                recommend browsing through the showcased section to find
                examples that resonate with you and replicating those prompts.
                Ensure you retain all other settings, including extensions, to
                achieve your desired outcomes seamlessly.
              </span>
              <div className="flex mt-2 items-center space-x-4">
                <button
                  onClick={() => setIsAdvanced(true)}
                  className={`px-6 py-2 rounded-lg focus:outline-none transition duration-300 ${isAdvanced
                    ? "bg-black text-white"
                    : "bg-[#a90d0d73] text-black"
                    }`}
                >
                  Try Advanced Prompting{" "}
                  <i className="ml-2 fa-solid fa-arrow-right"></i>
                </button>
              </div>
            </>
          )}
        </div>

        {!isAdvanced ? (
          <>
            <div className="flex justify-center items-center gap-5 pt-5 md:pt-5 align-center">
              <p className="font-semibold text-2xl xl:text-3xl">
                Select Character
              </p>
              <div className="relative">
                {hover.character && (
                  <span className="absolute w-60 -left-32 -translate-x-1/2  bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                    Choose your character and bring their story to life. Whether
                    it's a bold boy or a graceful girl, the possibilities are
                    endless!
                  </span>
                )}
                <img
                  loading="lazy"
                  src={info}
                  alt="infoBlack"
                  className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                  onMouseEnter={() => setHover({ ...hover, character: true })}
                  onMouseLeave={() => setHover({ ...hover, character: false })}
                />
              </div>
            </div>
            <div className="flex justify-center">
              {options["Characters"].map((item, index) => (
                <div
                  key={index}
                  className={`p-2 rounded-xl items-center flex flex-col justify-center space-y-4 m-4 ${selectedCharacter === item.name
                    ? "bg-black"
                    : "bg-[#d1d1d1]"
                    }`}
                  onClick={() => {
                    setSelectedCharacter(item.name);
                    setSelectedFeature("Hair Color");
                  }}
                >
                  {/* Image of the character */}
                  <img
                    loading="lazy"
                    src={item.src}
                    alt={item.name}
                    className="w-60 h-60 object-cover rounded-lg"
                  />

                  {/* Character Name */}
                  <p
                    className={`font-semibold hover:text-red-600 capitalize hover:cursor-pointer text-2xl ${selectedCharacter === item.name
                      ? "text-white"
                      : "text-black"
                      }`}
                  >
                    {item.name}
                  </p>
                </div>
              ))}
            </div>
          </>
        ) : (
          ""
        )}

        <div className="flex flex-wrap p-6">
          {/* Left Section: Select Features */}
          {!isAdvanced ? (
            <div className="w-full md:w-2/4 md:pr-4">
              {selectedCharacter && (
                <div className="bg-[#83808052] shadow-xl rounded-lg p-2 mo:px-4">
                  <div className="flex justify-between items-center mb-6">
                    <p className="font-semibold text-2xl p-5 text-gray-800">
                      Select Features
                    </p>
                    <div className="relative">
                      {hover.feature && (
                        <span className="absolute w-60 -left-32 -translate-x-1/2  bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                          Unleash your creativity! Customize your character with
                          features like Hair Color, Body Type, and Effects to
                          bring your vision to life.
                        </span>
                      )}
                      <img
                        loading="lazy"
                        src={info}
                        alt="infoBlack"
                        className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                        onMouseEnter={() =>
                          setHover({ ...hover, feature: true })
                        }
                        onMouseLeave={() =>
                          setHover({ ...hover, feature: false })
                        }
                      />
                    </div>
                  </div>

                  <div className="bg-[#bbaeaf] p-4 rounded-lg shadow-sm flex flex-wrap gap-1 sm:gap-4">
                    {features.map((item, index) => (
                      <p
                        key={index}
                        className={`font-semibold text-md py-2 px-3 sm:px-6 rounded-lg cursor-pointer transition-transform duration-200 transform ${selectedFeature === item.name
                          ? "bg-gray-800 text-white"
                          : "hover:bg-gray-300"
                          }`}
                        onClick={() => {
                          setSelectedFeature(item.name);
                          setCurrentFeatureIndex(index); // Set the current feature index on click
                        }}
                      >
                        {item.name}
                      </p>
                    ))}
                  </div>

                  {selectedFeature && (
                    <div className="mt-6 bg-[#b9b9b97a] p-4 md:p-6 rounded-lg shadow-sm ">
                      <div
                        className="p-4 md:p-6 h-[15rem] mo:h-full rounded-lg  flex justify-center flex-wrap gap-2 overflow-scroll"
                        style={{ scrollbarWidth: "none" }}
                      >
                        {options[selectedCharacter][selectedFeature].map((item, index) => (
                          <div
                            key={index}
                            className={`flex justify-center items-center h-10 border-2 border-gray-400 hover:border-gray-600 hover:scale-105 rounded-full p-2 cursor-pointer transition-all duration-300 ease-in-out transform ${selectedFeatures[selectedCharacter]?.[selectedFeature] === item.name
                              ? "bg-[#202936] text-white"
                              : "bg-white"
                              }`}
                            onClick={() => { choosefeature(item.name, selectedFeature) }}
                          >
                            <p
                              className="font-semibold text-[14px] mo:text-[16px] text-center px-4"
                              style={{ textTransform: "capitalize" }}
                            >
                              {item.name}
                            </p>
                          </div>
                        ))}

                        {["Hair Color", "Wearing", "Environment"].includes(selectedFeature) && (
                          <div className="flex items-center justify-between ">
                            <div className="">
                              {/* <label
                                htmlFor="custom"
                                className="text-sm font-medium text-gray-700 capitalize me-1"
                              >
                                Enter Custom {selectedFeature} Here
                              </label> */}
                              <input
                                id="custom"
                                type="text"
                                placeholder={`Enter custom ${selectedFeature.toLowerCase()} here`}
                                value={
                                  typeof selectedFeatures[selectedCharacter]?.[selectedFeature] ===
                                    "string"
                                    ? selectedFeatures[selectedCharacter][selectedFeature]
                                    : ""
                                }
                                onChange={(e) => {
                                  setNextButton(true)
                                  setSelectedFeatures((prev) => ({
                                    ...prev,
                                    [selectedCharacter]: {
                                      ...prev[selectedCharacter],
                                      [selectedFeature]: e.target.value,
                                    },
                                  }));
                                }}
                                className="border border-gray-300 rounded-md p-2 text-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 transition-all shadow-sm capitalize"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      {/* Next button */}
                      {nextButton === true && (
                        <div className="flex justify-end">
                          <button
                            className="bg-red-500 hover:bg-red-600 text-white px-4 py-1 rounded-lg shadow-md transition-all duration-200 focus:outline-none focus:ring-4 focus:ring-red-300"
                            onClick={next}
                          >
                            <i className="fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      )}
                    </div>
                  )}



                  {/* {selectedCharacter &&
                    Object.keys(selectedFeatures[selectedCharacter] || {})
                      .length > 0 && (
                      <div className="bg-[#b9b9b97a] p-1 mo:p-4 rounded-lg shadow-sm mt-6">
                        <h3 className="font-semibold text-xl text-center text-gray-800">
                          Selected Features
                        </h3>
                        <div className="flex flex-wrap justify-center gap-2 mo:gap-4">
                          {Object.entries(
                            selectedFeatures[selectedCharacter] || {}
                          ).map(([feature, value], index) => (
                            <div
                              key={index}
                              className="flex flex-col w-[100px] sm:w-[150px] bg-white rounded-lg shadow-md p-1 mo:p-3 border-l-4 border-gray-400 transition-transform duration-300 ease-in-out transform hover:scale-105"
                            >
                              <span className="font-semibold text-md text-gray-700">
                                {feature}
                              </span>
                              <span className="text-sm text-gray-500">
                                {typeof value === "object" ? value.name : value}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )} */}

                  {selectedCharacter && (
                    <div className="bg-[#b9b9b97a] p-1 mo:p-4 rounded-lg shadow-sm mt-6">
                      <h3 className="font-semibold text-xl text-center text-gray-800">
                        Selected Features
                      </h3>
                      <div className="flex flex-wrap justify-center gap-2 mo:gap-4">
                        {Object.keys(options[selectedCharacter])
                          .map((feature, index) => (
                            <div
                              key={index}
                              className="flex flex-col w-auto sm:w-[150px] bg-white rounded-lg shadow-md p-2 mo:p-4 border-l-4 border-gray-400 transition-transform duration-300 ease-in-out transform hover:scale-105"
                            >
                              <span className="font-semibold text-[14px] mo:text-[16px] text-gray-700">
                                {feature}
                              </span>
                              <span className="text-sm text-gray-500 capitalize">
                                {selectedFeatures[selectedCharacter]?.[feature]
                                  ? typeof selectedFeatures[selectedCharacter][feature] === "object"
                                    ? selectedFeatures[selectedCharacter][feature].name
                                    : selectedFeatures[selectedCharacter][feature]
                                  : "Not Selected"} {/* Placeholder for deselected features */}
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}


                </div>
              )}
            </div>
          ) : (
            <div className="w-full md:w-2/4 md:pr-4">
              <div className="bg-[#83808052] shadow-xl rounded-lg p-6">
                <p className="font-semibold text-3xl pb-5 text-gray-800">
                  Write Your Custom Prompt Here
                </p>
                {/* Textarea */}
                <textarea
                  value={text}
                  onChange={handleChangeadvance}
                  rows="4"
                  className="w-full bg-[#b9b9b97a] text-black p-4 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 placeholder-[#5e5e5e]"
                  placeholder="Write something..."
                ></textarea>

                {/* Suggestions Box */}
                <div className="mt-4 p-4 bg-[#b9b9b97a] rounded-lg shadow-md">
                  <h3 className="font-semibold text-gray-700">Suggestions:</h3>
                  <ul className="list-disc pl-5 space-y-2">
                    {suggestions.map((suggestion) => (
                      <li
                        key={suggestion.id}
                        className={`cursor-pointer p-2 rounded-lg transition-colors ${selectedSuggestions.includes(suggestion.line)
                          ? "bg-red-200 text-red-800"
                          : "hover:bg-gray-200"
                          }`}
                        onClick={() => handleSuggestionClick(suggestion.line)}
                      >
                        {suggestion.line}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}

          {/* Right Section: Extensions */}
          <div className="w-full md:w-2/4 md:pl-4 mt-8 md:mt-0">
            <div className="bg-[#83808052] shadow-xl rounded-lg p-2 mo:px-4">
              <div className="flex justify-between items-center mb-6">
                <p className="font-semibold text-2xl p-5 text-gray-800">
                  Extensions{" "}
                  <span className="mt-0 text-base text-gray-600">
                    (Addon 0.5 Token)
                  </span>
                </p>
                <div className="relative">
                  {hover.extension && (
                    <span className="absolute w-60 -left-32 -translate-x-1/2  bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                      (Realistic Extensions Only work with Realistic & Anime
                      Extensions Only work with Anime) Transform your creations
                      with a diverse range of features, from elegant poses to
                      dynamic expressions. Highlight captivating details,
                      explore imaginative themes, and bring your vision to life
                      with stunning realism and creativity.
                    </span>
                  )}
                  <img
                    loading="lazy"
                    src={info}
                    alt="infoBlack"
                    className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                    onMouseEnter={() => setHover({ ...hover, extension: true })}
                    onMouseLeave={() =>
                      setHover({ ...hover, extension: false })
                    }
                  />
                </div>
              </div>
              <div className="px-5 flex w-[85%] mo:w-[60%] items-center border rounded-full ml-6 bg-gray-100">
                <IoSearchOutline color="black" size={23} />
                <input
                  type="text"
                  placeholder="Search Extension"
                  id="searchInput"
                  className="focus:border-0 focus:outline-none active:border-0 active:outline-none !ring-0 w-full bg-transparent text-lg text-black p-2 border-0 rounded-full ml-2"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
                />
              </div>

              {isAdvanced ? (
                <>
                  <div className="w-full flex justify-center mt-2">
                    {options["Characters"].map((item, index) => (
                      <div
                        key={index}
                        className={`px-4 py-2 items-center flex flex-col justify-center rounded-lg mx-1 ${selectedCharacter === item.name
                          ? "bg-black"
                          : "bg-[#d1d1d1]"
                          }`}
                        onClick={() => {
                          setSelectedCharacter(item.name);
                          setSelectedFeature("Hair Color");
                        }}
                      >
                        {/* Character Name */}
                        <p
                          className={`hover:text-red-600 capitalize hover:cursor-pointer text-lg  ${selectedCharacter === item.name
                            ? "text-white"
                            : "text-black"
                            }`}
                        >
                          {item.name === "1 Girl" ? "Girls" : "Boys"}
                        </p>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <></>
              )}

              <div
                style={{
                  height: "30.3rem",
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                  scrollbarColor: "#99000b #f5b0b069",
                  borderRadius: "0.75rem",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",

                }}
                className="scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 mt-6 px-4"
              >
                {selectedCharacter === "1 Girl" ? (
                  <>
                    {/* Boots section */}
                    <div className="text-center mb-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Boobs
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImages.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${selectedIndex === item.trigerWord
                            ? "border-4 border-red-600"
                            : ""
                            } ${item.type !== modaltype ? "pointer-events-none" : ""
                            }`} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg ${item.type !== modaltype ? "opacity-50" : ""
                              }`} // Make image semi-transparent if type doesn't match modaltype
                          />
                          {item.type !== modaltype && (
                            <div className="absolute inset-0 bg-black opacity-30 rounded-lg"></div> // Black overlay for non-matching types
                          )}
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${selectedIndex === item.trigerWord
                              ? "bg-red-600"
                              : ""
                              }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Misc */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Misc
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagestwelev.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${selectedIndex === item.trigerWord
                            ? "border-4 border-red-600"
                            : ""
                            } ${item.type !== modaltype ? "pointer-events-none" : ""
                            }`} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg ${item.type !== modaltype ? "opacity-50" : ""
                              }`} // Make image semi-transparent if type doesn't match modaltype
                          />
                          {item.type !== modaltype && (
                            <div className="absolute inset-0 bg-black opacity-30 rounded-lg"></div> // Black overlay for non-matching types
                          )}
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${selectedIndex === item.trigerWord
                              ? "bg-red-600"
                              : ""
                              }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Clothing */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Clothing
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImageseleven.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${selectedIndex === item.trigerWord
                            ? "border-4 border-red-600"
                            : ""
                            } ${item.type !== modaltype ? "pointer-events-none" : ""
                            }`} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg ${item.type !== modaltype ? "opacity-50" : ""
                              }`} // Make image semi-transparent if type doesn't match modaltype
                          />
                          {item.type !== modaltype && (
                            <div className="absolute inset-0 bg-black opacity-30 rounded-lg"></div> // Black overlay for non-matching types
                          )}
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${selectedIndex === item.trigerWord
                              ? "bg-red-600"
                              : ""
                              }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Assa section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Ass
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagesTwo.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${selectedIndex === item.trigerWord
                            ? "border-4 border-red-600"
                            : ""
                            } ${item.type !== modaltype ? "pointer-events-none" : ""
                            }`} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg ${item.type !== modaltype ? "opacity-50" : ""
                              }`} // Make image semi-transparent if type doesn't match modaltype
                          />
                          {item.type !== modaltype && (
                            <div className="absolute inset-0 bg-black opacity-30 rounded-lg"></div> // Black overlay for non-matching types
                          )}
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${selectedIndex === item.trigerWord
                              ? "bg-red-600"
                              : ""
                              }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Cum section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Cum
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagesThree.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${selectedIndex === item.trigerWord
                            ? "border-4 border-red-600"
                            : ""
                            } ${item.type !== modaltype ? "pointer-events-none" : ""
                            }`} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg ${item.type !== modaltype ? "opacity-50" : ""
                              }`} // Make image semi-transparent if type doesn't match modaltype
                          />
                          {item.type !== modaltype && (
                            <div className="absolute inset-0 bg-black opacity-30 rounded-lg"></div> // Black overlay for non-matching types
                          )}
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${selectedIndex === item.trigerWord
                              ? "bg-red-600"
                              : ""
                              }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Pussy section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Pussy
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagesFour.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${selectedIndex === item.trigerWord
                            ? "border-4 border-red-600"
                            : ""
                            } ${item.type !== modaltype ? "pointer-events-none" : ""
                            }`} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg ${item.type !== modaltype ? "opacity-50" : ""
                              }`} // Make image semi-transparent if type doesn't match modaltype
                          />
                          {item.type !== modaltype && (
                            <div className="absolute inset-0 bg-black opacity-30 rounded-lg"></div> // Black overlay for non-matching types
                          )}
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${selectedIndex === item.trigerWord
                              ? "bg-red-600"
                              : ""
                              }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Feet section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Feet
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagesFive.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${selectedIndex === item.trigerWord
                            ? "border-4 border-red-600"
                            : ""
                            } ${item.type !== modaltype ? "pointer-events-none" : ""
                            }`} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg ${item.type !== modaltype ? "opacity-50" : ""
                              }`} // Make image semi-transparent if type doesn't match modaltype
                          />
                          {item.type !== modaltype && (
                            <div className="absolute inset-0 bg-black opacity-30 rounded-lg"></div> // Black overlay for non-matching types
                          )}
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${selectedIndex === item.trigerWord
                              ? "bg-red-600"
                              : ""
                              }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Pregnant section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Pregnant
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagesSix.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${selectedIndex === item.trigerWord
                            ? "border-4 border-red-600"
                            : ""
                            } ${item.type !== modaltype ? "pointer-events-none" : ""
                            }`} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg ${item.type !== modaltype ? "opacity-50" : ""
                              }`} // Make image semi-transparent if type doesn't match modaltype
                          />
                          {item.type !== modaltype && (
                            <div className="absolute inset-0 bg-black opacity-30 rounded-lg"></div> // Black overlay for non-matching types
                          )}
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${selectedIndex === item.trigerWord
                              ? "bg-red-600"
                              : ""
                              }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* BDSM section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        BDSM
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagesSeven.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${selectedIndex === item.trigerWord
                            ? "border-4 border-red-600"
                            : ""
                            } ${item.type !== modaltype ? "pointer-events-none" : ""
                            }`} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg ${item.type !== modaltype ? "opacity-50" : ""
                              }`} // Make image semi-transparent if type doesn't match modaltype
                          />
                          {item.type !== modaltype && (
                            <div className="absolute inset-0 bg-black opacity-30 rounded-lg"></div> // Black overlay for non-matching types
                          )}
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${selectedIndex === item.trigerWord
                              ? "bg-red-600"
                              : ""
                              }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Penetration section */}
                    <div className="text-center my-6">
                      <div className="bg-gray-200 rounded-full w-32 my-4 p-2 font-semibold text-lg text-gray-600">
                        Penetration
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagesEight.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${selectedIndex === item.trigerWord
                            ? "border-4 border-red-600"
                            : ""
                            } ${item.type !== modaltype ? "pointer-events-none" : ""
                            }`} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg ${item.type !== modaltype ? "opacity-50" : ""
                              }`} // Make image semi-transparent if type doesn't match modaltype
                          />
                          {item.type !== modaltype && (
                            <div className="absolute inset-0 bg-black opacity-30 rounded-lg"></div> // Black overlay for non-matching types
                          )}
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${selectedIndex === item.trigerWord
                              ? "bg-red-600"
                              : ""
                              }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Futanari section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Futanari
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagesNine.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${selectedIndex === item.trigerWord
                            ? "border-4 border-red-600"
                            : ""
                            } ${item.type !== modaltype ? "pointer-events-none" : ""
                            }`} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg ${item.type !== modaltype ? "opacity-50" : ""
                              }`} // Make image semi-transparent if type doesn't match modaltype
                          />
                          {item.type !== modaltype && (
                            <div className="absolute inset-0 bg-black opacity-30 rounded-lg"></div> // Black overlay for non-matching types
                          )}
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${selectedIndex === item.trigerWord
                              ? "bg-red-600"
                              : ""
                              }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Blow section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Blowjob
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredImagesTen.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${selectedIndex === item.trigerWord
                            ? "border-4 border-red-600"
                            : ""
                            } ${item.type !== modaltype ? "pointer-events-none" : ""
                            }`} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg ${item.type !== modaltype ? "opacity-50" : ""
                              }`} // Make image semi-transparent if type doesn't match modaltype
                          />
                          {item.type !== modaltype && (
                            <div className="absolute inset-0 bg-black opacity-30 rounded-lg"></div> // Black overlay for non-matching types
                          )}
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${selectedIndex === item.trigerWord
                              ? "bg-red-600"
                              : ""
                              }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                  </>
                ) : (
                  <>
                    {/* BodyBuilder section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Body Builder
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredBoyone.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${selectedIndex === item.trigerWord
                            ? "border-4 border-red-600"
                            : ""
                            } ${item.type !== modaltype ? "pointer-events-none" : ""
                            }`} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg ${item.type !== modaltype ? "opacity-50" : ""
                              }`} // Make image semi-transparent if type doesn't match modaltype
                          />
                          {item.type !== modaltype && (
                            <div className="absolute inset-0 bg-black opacity-30 rounded-lg"></div> // Black overlay for non-matching types
                          )}
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${selectedIndex === item.trigerWord
                              ? "bg-red-600"
                              : ""
                              }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Penis section */}
                    <div className="text-center mb-6">
                      <div className="bg-gray-200 rounded-full w-32 my-4 p-2 font-semibold text-lg text-gray-600">
                        Penis
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredBoyTwo.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${selectedIndex === item.trigerWord
                            ? "border-4 border-red-600"
                            : ""
                            } ${item.type !== modaltype ? "pointer-events-none" : ""
                            }`} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg ${item.type !== modaltype ? "opacity-50" : ""
                              }`} // Make image semi-transparent if type doesn't match modaltype
                          />
                          {item.type !== modaltype && (
                            <div className="absolute inset-0 bg-black opacity-30 rounded-lg"></div> // Black overlay for non-matching types
                          )}
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${selectedIndex === item.trigerWord
                              ? "bg-red-600"
                              : ""
                              }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Bondage section */}
                    <div className="text-center my-6 px-2">
                      <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                        Bondage
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-6 py-4">
                      {filteredBoyThree.map((item, index) => (
                        <div
                          key={index}
                          className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${selectedIndex === item.trigerWord
                            ? "border-4 border-red-600"
                            : ""
                            } ${item.type !== modaltype ? "pointer-events-none" : ""
                            }`} // Make the div unclickable if type doesn't match modaltype
                          onClick={() => {
                            if (selectedIndex === item.trigerWord) {
                              // Deselect the item if it's already selected
                              setSelectedIndex(null);
                            } else {
                              // Select the item
                              setSelectedIndex(item.trigerWord);
                            }
                          }}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className={`w-full h-full object-cover cursor-pointer rounded-lg ${item.type !== modaltype ? "opacity-50" : ""
                              }`} // Make image semi-transparent if type doesn't match modaltype
                          />
                          {item.type !== modaltype && (
                            <div className="absolute inset-0 bg-black opacity-30 rounded-lg"></div> // Black overlay for non-matching types
                          )}
                          <div
                            className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${selectedIndex === item.trigerWord
                              ? "bg-red-600"
                              : ""
                              }`}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="flex flex-wrap justify-between pt-20 gap-2 md:gap-0">
          <div className="flex flex-col gap-5 lg:gap-12 text-black font-josefin-sans">
            <div className="flex relative  gap-5 md:mb-3 lg:mb-0">
              <p className="text-base capitalize md:text-[15px] lg:text-[20px] xl:text-[25px] font-semibold text-gray-800">
                Do you want to Upscale Image{" "}
                <p className="flex md:text-[8px] lg:text-[12px] xl:text-[16px] mt-2 items-center">
                  (Addon 1-Token
                  {"  "}
                  <img
                    loading="lazy"
                    className="w-5 h-5 ms-1"
                    src={goldE}
                    alt="goldE"
                  />
                  )
                </p>
              </p>

              {/* Stylish toggle button */}
              <button
                onClick={handleCheckboxChange}
                className={`relative inline-flex items-center h-[2rem] w-[4rem] rounded-full transition-all duration-300 ${isChecked ? "bg-[#ab1e1eb5]" : "bg-gray-300"
                  }`}
                disabled={value === 6 || value === 8 || value === 12 || value === 16}
              >
                <span
                  className={`absolute left-1 top-1 transition-all duration-300 transform ${isChecked
                    ? "translate-x-8 bg-white"
                    : "translate-x-0 bg-white"
                    }`}
                  style={{ height: "25px", width: "25px", borderRadius: "50%" }}
                ></span>
              </button>

              {/* Info Tooltip */}
              <div
                className=""
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <img
                  loading="lazy"
                  src={info}
                  alt="info"
                  className="h-4 md:h-6 mt-1 cursor-pointer transition-transform duration-200 hover:scale-110"
                />
                {isHovered && (
                  <span className="absolute bg-[#8a8a8a] text-white text-sm top-7 right-0 p-2 shadow-lg rounded max-w-xs w-auto z-10">
                    Elevate your images with AI-powered upscaling. Increase
                    resolution for sharper, more detailed visuals, perfect for
                    larger displays.
                  </span>
                )}
              </div>
            </div>
            <div>
              <div className="flex relative items-center gap-5">
                <p className="text-base capitalize md:text-[15px] lg:text-[20px] xl:text-[25px] font-semibold text-gray-800">
                  Other{" "}
                  <span className="text-md text-gray-600">
                    (use prompt words with commas)
                  </span>
                </p>
                <div
                  className=""
                  onMouseEnter={() => setIsHoveredone(true)}
                  onMouseLeave={() => setIsHoveredone(false)}
                >
                  <img
                    loading="lazy"
                    src={info}
                    alt="info"
                    className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                  />
                  {isHoveredone && (
                    <span className="absolute text-white bg-[#8a8a8a] text-sm bottom-100 right-0 p-2 shadow-lg rounded max-w-xs w-auto">
                      Specify art styles, features, colors, mood, or setting to
                      unlock the full creative potential of the model,
                      generating images that match your unique vision and
                      aesthetic.
                    </span>
                  )}
                </div>
              </div>
              <div className="bg-zinc-300 rounded-xl mt-2 px-5 py-2 text-black text-2xl font-light w-full h-38 shadow-lg hover:shadow-xl transition-all">
                <textarea
                  type="text"
                  rows={3}
                  className="focus:outline-none bg-zinc-300 w-full text-black p-2 placeholder-gray-600 rounded-lg"
                  placeholder="e.g. smiling, sunny weather"
                  value={otherInput}
                  onChange={handleOtherInputChange}
                />
              </div>
            </div>
            <div className="flex items-center gap-5 md:mb-3 lg:mb-0">
              {/* <input
                type="radio"
                className='w-7 h-7'
              /> */}
              <p className="text-base md:text-xl lg:text-2xl xl:text-3xl  font-semibold text-gray-800">
                Make Private
              </p>
              <button
                onClick={handleCheckboxChangePrivate}
                className={`relative inline-flex items-center h-[2rem] w-[4rem] rounded-full transition-all duration-300 ${isCheckedPrivate ? "bg-[#ab1e1eb5]" : "bg-gray-300"
                  }`}
              >
                {/* OFF text */}
                <span
                  className={`absolute z-10 left-2 text-black transition-all duration-300 ${isCheckedPrivate ? "opacity-0" : "opacity-100"
                    }`}
                  style={{ top: "50%", transform: "translateY(-50%)" }}
                ></span>

                {/* ON text */}
                <span
                  className={`absolute z-10 right-1 text-black font-bold transition-all duration-300 ${isCheckedPrivate ? "opacity-100" : "opacity-0"
                    }`}
                  style={{ top: "50%", transform: "translateY(-50%)" }}
                >

                </span>

                <span
                  className={`absolute left-1 top-1 transition-all duration-300 transform ${isCheckedPrivate
                    ? "translate-x-8 bg-white"
                    : "translate-x-0 bg-white"
                    }`}
                  style={{ height: "25px", width: "25px", borderRadius: "50%" }}
                ></span>
              </button>
            </div>
            {!isAdvanced ? (
              <div className="flex items-center gap-5 md:mb-3 lg:mb-0">
                <input
                  type="checkbox"
                  className="w-7 h-7 rounded-full peer appearance-none border-2 border-red-500 checked:bg-red-500"
                  onClick={handleCheckboxChangePrivateNSF}
                  id="nsfw"
                />
                <label className="text-base md:text-md lg:text-lg xl:text-lg" htmlFor="nsfw">
                  Allow NSFW
                </label>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="flex flex-col gap-5 lg:gap-12 text-black font-josefin-sans">
            <div>
              <div className="flex flex-col items-start">
                <label
                  htmlFor="variations"
                  className="text-base capitalize md:text-[15px] lg:text-[20px] xl:text-[25px] font-semibold text-gray-800"
                >
                  Number of variations you want (Max 16)
                </label>
                <div className="my-4 ">
                  <button
                    className={`${value === 1 ? `border-red-500` : `border-gray-300`
                      }  bg-[#d4d4d8] border-2  rounded-lg p-2 mr-2 mt-2 w-14 sm:w-18 md:w-22 text-center text-sm`}
                    onClick={() => handleSelect(1)}
                  >
                    1
                  </button>
                  <button
                    className={`${value === 2 ? `border-red-500` : `border-gray-300`
                      }  bg-[#d4d4d8] border-2  rounded-lg p-2 mr-2 mt-2 w-14 sm:w-18 md:w-22 text-center text-sm`}
                    onClick={() => handleSelect(2)}
                  >
                    2
                  </button>
                  <button
                    className={`${value === 3 ? `border-red-500` : `border-gray-300`
                      }  bg-[#d4d4d8] border-2  rounded-lg p-2 mr-2 mt-2 w-14 sm:w-18 md:w-22 text-center text-sm`}
                    onClick={() => handleSelect(3)}
                  >
                    3
                  </button>
                  <button
                    className={`${value === 4 ? `border-red-500` : `border-gray-300`
                      }  bg-[#d4d4d8] border-2  rounded-lg p-2 mr-2 mt-2 w-14 sm:w-18 md:w-22 text-center text-sm`}
                    onClick={() => handleSelect(4)}
                  >
                    4
                  </button>
                  {/* <button
                    className={`${
                      value === 5 ? `border-red-500` : `border-gray-300`
                    }  bg-[#d4d4d8] border-2  rounded-lg mt-2 p-2 w-14 sm:w-18 md:w-22 text-center text-sm`}
                    onClick={() => handleSelect(5)}
                  >
                    5
                  </button> */}
                  <button
                    className={`${value === 6 ? `border-red-500` : `border-gray-300`
                      }  bg-[#d4d4d8] border-2  rounded-lg p-2 mr-2 mt-2 w-14 sm:w-18 md:w-22 text-center text-sm`}
                    onClick={() => handleSelect(6)}
                  >
                    6
                  </button>
                  {/* <button
                    className={`${
                      value === 7 ? `border-red-500` : `border-gray-300`
                    }  bg-[#d4d4d8] border-2  rounded-lg p-2 mt-2 w-14 sm:w-18 md:w-22 text-center text-sm`}
                    onClick={() => handleSelect(7)}
                  >
                    7
                  </button> */}
                  <button
                    className={`${value === 8 ? `border-red-500` : `border-gray-300`
                      }  bg-[#d4d4d8] border-2  rounded-lg p-2 w-14 mr-2 mt-2 sm:w-18 md:w-22 text-center text-sm`}
                    onClick={() => handleSelect(8)}
                  >
                    8
                  </button>
                  <button
                    className={`${value === 12 ? `border-red-500` : `border-gray-300`
                      }  bg-[#d4d4d8] border-2  rounded-lg p-2 w-14 mr-2 mt-2 sm:w-18 md:w-22 text-center text-sm`}
                    onClick={() => handleSelect(12)}
                  >
                    12
                  </button>
                  <button
                    className={`${value === 16 ? `border-red-500` : `border-gray-300`
                      }  bg-[#d4d4d8] border-2  rounded-lg p-2 w-14 mr-2 mt-2 sm:w-18 md:w-22 text-center text-sm`}
                    onClick={() => handleSelect(16)}
                  >
                    16
                  </button>
                </div>
                {/* <input
                  type="text"
                  id="variations"
                  name="variations"
                  value={value}
                  onChange={handleChange}
                  maxLength="1"
                  className="my-4 bg-[#d4d4d8] border-2 border-gray-300 rounded-lg p-2 text-center w-48 focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter number"
                /> */}
                {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
              </div>
              <div className="flex items-center gap-5 mt-4">
                <p className="text-base md:text-[15px] lg:text-[20px] xl:text-[25px] font-semibold text-gray-800">
                  Aspect Ratio{" "}
                  <span className="text-md text-gray-600">
                    (square, horizontal, vertical)
                  </span>
                </p>
              </div>
              <div className="bg-[#d4d4d8] w-full sm:w-[75%] justify-center rounded-[20px] flex flex-wrap sm:flex-nowrap items-center gap-3 text-center mt-2 p-5">
                {/* First SVG */}
                <div className=" group flex flex-col items-center justify-center gap-4 ">
                  <svg
                    onClick={() => handlesvgClick("first")}
                    className={`cursor-pointer fill-none stroke-[#ef0808c9] transition-opacity duration-300 ${svgselected === "first" ? "opacity-[1]" : "opacity-[0.4]"
                      } hover:scale-[1.1] transition-transform duration-300`}
                    width="29"
                    height="40"
                    viewBox="0 0 29 42"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="1.5"
                      y="1.5"
                      width="26"
                      height="39"
                      rx="6.5"
                      strokeWidth="2"
                    ></rect>
                  </svg>
                  {/* Text for Third */}
                  <span
                    className={`${svgselected === "first" ? "opacity-100" : "opacity-0"
                      } text-[#ef0808c9] text-xs  group-hover:opacity-100 transition-opacity duration-300`}
                  >
                    480 X 680
                  </span>
                </div>

                {/* Second SVG */}
                <div className=" group flex flex-col items-center justify-center gap-4 ">
                  <svg
                    onClick={() => handlesvgClick("second")}
                    className={`cursor-pointer fill-none stroke-[#ef0808c9] transition-opacity duration-300 ${svgselected === "second" ? "opacity-[1]" : "opacity-[0.4]"
                      } hover:scale-[1.1] transition-transform duration-300`}
                    width="60"
                    height="40"
                    viewBox="0 0 62 42"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="1.5"
                      y="1.5"
                      width="59"
                      height="39"
                      rx="6.5"
                      strokeWidth="2"
                    ></rect>
                  </svg>
                  {/* Text for Second */}
                  <span
                    className={`${svgselected === "second" ? "opacity-100" : "opacity-0"
                      } text-[#ef0808c9] text-xs  group-hover:opacity-100 transition-opacity duration-300`}
                  >
                    512 x 1024
                  </span>
                </div>

                {/* Third SVG */}
                <div className=" group flex flex-col items-center justify-center gap-4 ">
                  <svg
                    onClick={() => handlesvgClick("third")}
                    className={`cursor-pointer fill-none stroke-[#ef0808c9]  transition-opacity duration-300 ${svgselected === "third" ? "opacity-[1]" : "opacity-[0.4]"
                      } hover:scale-[1.1] transition-transform duration-300`}
                    width="40"
                    height="40"
                    viewBox="0 0 42 42"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="1.5"
                      y="1.5"
                      width="39"
                      height="39"
                      rx="6.5"
                      strokeWidth="2"
                    ></rect>
                  </svg>
                  {/* Text for First */}
                  <span
                    className={`${svgselected === "third" ? "opacity-100" : "opacity-0"
                      } text-[#ef0808c9] text-xs  group-hover:opacity-100 transition-opacity duration-300 `}
                  >
                    512 x 512
                  </span>
                </div>

                {/* Fourth SVG */}
                <div className=" group flex flex-col items-center justify-center gap-4 ">
                  <svg
                    onClick={() => handlesvgClick("fourth")}
                    className={`cursor-pointer fill-none stroke-[#ef0808c9] transition-opacity duration-300 ${svgselected === "fourth" ? "opacity-[1]" : "opacity-[0.4]"
                      } hover:scale-[1.1] transition-transform duration-300`}
                    width="29"
                    height="40"
                    viewBox="0 0 29 42"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="1.5"
                      y="1.5"
                      width="26"
                      height="39"
                      rx="6.5"
                      strokeWidth="2"
                    ></rect>
                    <path
                      d="M5.399 22.194V21.459L9.158 16.65H10.292L6.5645 21.459L6.029 21.291H11.8985V22.194H5.399ZM9.4835 24V22.194L9.515 21.291V19.695H10.502V24H9.4835ZM14.7627 19.821C14.5667 19.821 14.3987 19.7545 14.2587 19.6215C14.1187 19.4815 14.0487 19.3065 14.0487 19.0965C14.0487 18.8865 14.1187 18.715 14.2587 18.582C14.3987 18.449 14.5667 18.3825 14.7627 18.3825C14.9517 18.3825 15.1127 18.449 15.2457 18.582C15.3857 18.715 15.4557 18.8865 15.4557 19.0965C15.4557 19.3065 15.3857 19.4815 15.2457 19.6215C15.1127 19.7545 14.9517 19.821 14.7627 19.821ZM14.7627 24.063C14.5667 24.063 14.3987 23.9965 14.2587 23.8635C14.1187 23.7235 14.0487 23.5485 14.0487 23.3385C14.0487 23.1285 14.1187 22.957 14.2587 22.824C14.3987 22.691 14.5667 22.6245 14.7627 22.6245C14.9517 22.6245 15.1127 22.691 15.2457 22.824C15.3857 22.957 15.4557 23.1285 15.4557 23.3385C15.4557 23.5485 15.3857 23.7235 15.2457 23.8635C15.1127 23.9965 14.9517 24.063 14.7627 24.063ZM20.2916 24.084C19.7736 24.084 19.2696 24.0035 18.7796 23.8425C18.2966 23.6815 17.9011 23.4645 17.5931 23.1915L18.0761 22.362C18.3211 22.593 18.6396 22.782 19.0316 22.929C19.4236 23.076 19.8401 23.1495 20.2811 23.1495C20.8411 23.1495 21.2716 23.0305 21.5726 22.7925C21.8736 22.5545 22.0241 22.236 22.0241 21.837C22.0241 21.564 21.9576 21.326 21.8246 21.123C21.6916 20.92 21.4606 20.766 21.1316 20.661C20.8096 20.549 20.3651 20.493 19.7981 20.493H18.1286L18.5171 16.65H22.6646V17.5635H18.9056L19.4516 17.049L19.1471 20.0835L18.6011 19.5795H20.0186C20.7536 19.5795 21.3451 19.674 21.7931 19.863C22.2411 20.052 22.5666 20.3145 22.7696 20.6505C22.9726 20.9795 23.0741 21.361 23.0741 21.795C23.0741 22.215 22.9726 22.6 22.7696 22.95C22.5666 23.293 22.2586 23.5695 21.8456 23.7795C21.4396 23.9825 20.9216 24.084 20.2916 24.084Z"
                      fill="white"
                    ></path>
                  </svg>
                  {/* Text for Fourth */}
                  <span
                    className={`${svgselected === "fourth" ? "opacity-100" : "opacity-0"
                      } text-[#ef0808c9] text-xs  group-hover:opacity-100 transition-opacity duration-300 `}
                  >
                    512 X 768
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <button onClick={generate} className='w-full mt-5 rounded-xl text-xl lg:text-3xl bg-red-600 hover:bg-red-800 py-4 text-center flex items-center gap-5 justify-center'>
          Generate for 1.00
          <img loading ='lazy' className='w-10' src={goldE} alt="goldE" />
        </button> */}

        <div className="flex justify-center items-center">
          <button
            onClick={() => generate(selectedIndexAi)}
            className=" w-full mo:w-2/3 h-[80px] mt-5 rounded-xl text-xl lg:text-3xl bg-gradient-to-r from-[#99111f] via-[#d43e44] to-[#ff5c6c] py-4 text-center flex items-center gap-5 justify-center relative overflow-hidden group shadow-xl hover:shadow-2xl transition-all duration-500 ease-out"
          >
            <span className="relative z-10 text-white font-bold">
              Generate for{" "}
              {(2 + (isChecked ? 1 : 0)) * (value ? parseInt(value, 10) : 1) +
                (selectedIndex ? 0.5 : 0) *
                (value ? parseInt(value, 10) : 1)}
              {selectedIndex ? "" : ".00"}
            </span>
            {/* Glowing Background Effect */}
            <span className="absolute inset-0 bg-gradient-to-r from-[#e63d3d] to-[#ff5c6c] opacity-0 group-hover:opacity-80 animate-glow-background"></span>

            {/* Glowing Icon Animation */}
            <img
              loading="lazy"
              className="w-12 transition-transform duration-300 group-hover:scale-[1.3] group-hover:rotate-0 group-hover:glow-icon"
              src={goldE}
              alt="goldE"
            />

            {/* Glowing Border and Pulse Effect */}
            <span className="absolute inset-0 rounded-xl border-4 border-transparent group-hover:border-[#99111f] opacity-0 group-hover:opacity-100 animate-glow-border"></span>
          </button>
        </div>
      </div>

      {errorisOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-lg">
          <div className="modal-dialog modal-lg bg-white rounded-lg overflow-hidden shadow-xl max-w-[16rem] mo:max-w-xl w-full">
            {/* Modal Content */}
            <div className="modal-wrap flex-wrap mo:flex relative">
              {/* Image Container - 50% Width */}
              <div className="w-full mo:w-1/2">
                {/* Desktop Image */}
                <img
                  src={alert}
                  alt="Modal Img"
                  className="login-img hidden md:block w-full h-full object-cover"
                />
                {/* Mobile Image */}
                <img
                  src={alert}
                  alt="Modal Img Mobile"
                  className="login-img-mob sm:hidden w-full"
                />
              </div>

              {/* Content Container - 50% Width */}
              <div className="modal-content p-2 w-full mo:w-1/2 grid justify-center">
                {/* Modal Header */}
                <div className="modal-header flex justify-between items-start">
                  <h4 className="modal-title text-uppercase text-xl"></h4>
                  <button
                    type="button"
                    className="btn-close text-xl text-[#990013]"
                    onClick={errorCloseModal}
                  >
                    <i className="fa-regular fa-circle-xmark"></i>
                  </button>
                </div>

                {/* Modal Body */}
                <div className="modal-body justify-center">
                  <div className="container-fluid">
                    <div className="row mb-4">
                      <div className="col-12 ps-4">
                        <h2 className="text-[#990013] font-bold text-uppercase text-2xl">
                          PLEASE UPGRADE
                        </h2>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 ps-4">
                        <span className="text-muted text-gray-900 text-md">
                          Upgrade to a premium membership to unlock access to
                          all features!
                        </span>
                      </div>
                    </div>
                    <div className="row justify-center p-3 gap-1">
                      <div className="col-12">
                        <button
                          onClick={() => navigate("/priceplan")}
                          className="btn btn-primary w-full py-3 text-white bg-[#990012ab] hover:bg-[#990013] rounded-md"
                        >
                          Upgrade
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Modal Overlay */}
            {/* <div
              className="fixed inset-0"
              onClick={errorCloseModal}
            ></div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Simplified;
