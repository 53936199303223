import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Togal from "../../components/Togal";
import { IoClose } from "react-icons/io5";
import { DeleteImg, eye } from "../../../assets";
import PagenameSearchBox from "../../components/PagenameSearchBox";
import UserApiClient from "../../../utilities/apiClients";
import AdminPagination from "../AdminPagination";
import Swal from "sweetalert2";

const Faqs = () => {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [faqsList, setfaqsList] = useState([]);
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const [popupData, setpopupData] = useState({});
  const [searchQuery, setSearchQuery] = useState(null);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [pageLinks, setPageLinks] = useState({ "next": null, "prev": null });

  const togglePopup = (data) => {
    setIsPopupOpen(!isPopupOpen);
    setpopupData(data);
  };

  const handleClose = () => {
    setIsPopupOpen(false);
    setpopupData({});
  };

  const handleDelete = async (faqsId) => {
    await Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert this!`,
      icon: "question",
      showCancelButton: true,
      cancelButtonColor: "grey",
      confirmButtonColor: "#d33",
      confirmButtonText: `Yes!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await UserApiClient.delete(`api/v1/admin/faq/${faqsId}/`, {}, true).then(
          async (res) => {
            if (res.success) {
              await fetchfaqsList();
            }
          }
        );
      }
    });
  };

  const toggleStatus = async (faqsId, currentStatus) => {
    await UserApiClient.patch(`api/v1/admin/faq/${faqsId}/`, { status: !currentStatus }, true).then(async (res) => {
      if (res.success) {
        await fetchfaqsList();
      }
    });
  };

  // Fetch the FAQ list when the component is mounted
  const fetchfaqsList = async (pageLink) => {
    await UserApiClient.get(pageLink && pageLink.includes("?page") ?
      `${pageLink}${"&search=" + (searchQuery != null ? searchQuery : '')}${pageSize != null ? '&page_size=' + pageSize : ''}` :
      `api/v1/admin/faq/${"?search=" + (searchQuery != null ? searchQuery : '')}${pageSize != null ? '&page_size=' + pageSize : ''}`,
      {}, true).then(async (res) => {
        if (res.success) {
          setPageLinks({ 'next': res.data.next, 'prev': res.data.previous });
          setfaqsList(res.data.results);
        }
      });
  };

  useEffect(() => {
    fetchfaqsList();
  }, []);

  const handlePrevNextPage = async (btn) => {
    if (btn === 'next') {
      fetchfaqsList(pageLinks.next);
    } else if (btn === 'prev') {
      fetchfaqsList(pageLinks.prev);
    }
  };

  useEffect(() => {
    if (searchQuery != null) {
      const timer = setTimeout(() => {
        setDebouncedSearchQuery(searchQuery);
      }, 700);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [searchQuery]);

  useEffect(() => {
    if (debouncedSearchQuery != null) {
      fetchfaqsList()
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (pageSize != null) {
      fetchfaqsList()
    }
  }, [pageSize]);


  return (
    <>
      <div className="h-full bg-white px-7 pb-20 font-josefin-sans">
        <PagenameSearchBox
          title="FAQs List"
          placename="Search FAQ"
          btnname="Add FAQ"
          btnfunction={() => navigate("/admin/addFaqs")}
          inputValue={searchQuery}
          onSearchChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
        />


        <div className="flex justify-end w-full">
          <div className="space-x-4 mr-4">
            <select className="py-1.5 px-3 focus:outline-none text-lg rounded" onChange={(e) => setPageSize(e.target.value)}>
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
              <option value='500'>500</option>
              <option value='1000'>1000</option>
            </select>
          </div>
        </div>
        <div className="relative overflow-x-auto sm:rounded-lg mt-5">
          <table className="w-full text-left text-gray-500 border border-stone-300 table-auto">
            <thead className="text-xl text-black bg-stone-300">
              <tr>
                <th scope="col" className="px-2 2xl:px-6 py-3">
                  Sr.
                </th>
                <th scope="col" className="px-2 2xl:px-6 py-3">
                  Questions
                </th>
                <th scope="col" className="px-2 2xl:px-6 py-3">
                  Answers
                </th>
                {/* <th scope="col" className="px-2 2xl:px-6 py-3">
                  Pages
                </th> */}
                <th scope="col" className="px-2 2xl:px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-2 2xl:px-6 py-3 text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {faqsList.map((faqs, index) => (
                <tr
                  key={faqs.id}
                  className="bg-white text-xl font-normal text-black border-b border-stone-300"
                >
                  <td className="px-2 2xl:px-6 py-4 mo:whitespace-normal break-words">
                    {index + 1}.
                  </td>
                  <td className="px-2 2xl:px-6 py-4 whitespace-nowrap md:whitespace-normal break-words">
                    {faqs.question}
                  </td>
                  <td className="px-2 2xl:px-6 py-4 whitespace-nowrap md:whitespace-normal break-words">
                    {faqs.answer}
                  </td>
                  {/* <td className="px-2 2xl:px-6 py-4 mo:whitespace-nowrap">
                    {faqs.page}
                  </td> */}
                  <td className="px-2 2xl:px-6 py-4">
                    <Togal
                      isChecked={faqs.status}
                      value={faqs.status}
                      handleChange={() => toggleStatus(faqs.id, faqs.status)}
                    />
                  </td>
                  <td className="px-2 2xl:px-6 py-4 flex justify-center items-center gap-5">
                    <button>
                      <img
                        loading="lazy"
                        src={eye}
                        alt="eye"
                        className="hover:cursor-pointer"
                        onClick={() => togglePopup(faqs)}
                      />
                    </button>
                    <button>
                      <img
                        loading="lazy"
                        src={DeleteImg}
                        alt="delete"
                        className="hover:cursor-pointer"
                        onClick={() => handleDelete(faqs.id)}
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <AdminPagination pageLinks={pageLinks} handlePrevNextPage={handlePrevNextPage} />
      </div>

      {isPopupOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60"
          style={{ zIndex: "999999999" }}
        >
          <div className="bg-white mx-8 pt-5 pb-10 px-10 rounded-lg text-left shadow-lg w-[1100px] landscape:h-[280px] overflow-auto" style={{scrollbarWidth:"none",scrollBehavior:"smooth"}}>
            <div className="relative left-[100%]">
              <IoClose
                size={29}
                onClick={handleClose}
                className="hover:cursor-pointer"
              />
            </div>
            <p className="font-semibold text-2xl">Question</p>
            <p className="mb-4 font-font-light text-md mx-2">
              {popupData.question}
            </p>
            <p className="font-semibold text-2xl">Answer</p>
            <p className="mb-4 font-font-light text-md mx-2">
              {popupData.answer}
            </p>
            {/* <p className="font-semibold text-2xl">Page</p>
            <p className="mb-4 font-font-light text-md mx-2">
              {popupData.page}
            </p> */}
            <p className="font-semibold text-2xl">Status</p>
            <p className="mb-4 font-font-light text-md mx-2">
              {popupData.status ? "Active" : "Inactive"}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Faqs;
