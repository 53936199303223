import { useParams } from "react-router-dom";
import TemplateOne from "./TemplateOne";
import TemplateTwo from "./TemplateTwo";
import TemplateThree from "./TemplateThree";
import TemplateFour from "./TemplateFour";
import TemplateFive from "./TemplateFive";

const TemplatePages = () => {
    const { template } = useParams();
    return (
        template === 'one' ? <TemplateOne />
            : template === 'two' ? <TemplateTwo />
                : template === 'three' ? <TemplateThree />
                    // : template === 'four' ? <TemplateFour />
                    //     : template === 'five' ? <TemplateFive />
                            : <div>No such template</div>
    )
}


export default TemplatePages;