import React from "react";
import { Instagram, Logo, Twitter, VisaImg1, VisaImg2, VisaImg3, VisaImg4, VisaImg5, VisaImg6, VisaImg7 } from "../../assets";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const visaImages = [VisaImg1, VisaImg2, VisaImg3, VisaImg4, VisaImg5, VisaImg6, VisaImg7];

  const renderVisaImages = () => visaImages.map((imgSrc, index) => (
    <img key={index} loading='lazy' src={imgSrc} alt="visa" className="w-9 md:w-8 lg:w-7 xl:w-8" />
  ));

  return (
    <>
      <hr className="mt-16" style={{ backgroundColor: '#6d6d6d', height: '1px', border: 'none' }} />
      <div className="px-2 md:px-16 py-5 text-black mb-20 md:mb-0">
        <img loading='lazy' src={Logo} alt="logo" className="m-auto pb-5 w-25 md:w-50" />
        <div className="flex flex-wrap gap-2 md:gap-5 justify-center pb-5 text-base md:text-lg font-medium cursor-pointer">
          <div>
            <p className="text-xl md:text-xl font-semibold">Popular :</p>
          </div>
          <div className="flex items-center gap-2">
            <p>AI Image Generation</p>
            <p>AI Character Generation</p>
            <p>AI Video Generation</p>
            <p>AI Chat</p>
          </div>
        </div>
        <p className="text-center m-auto md:w-[70%] font-medium pb-5">
          The content on this website is generated by AI for entertainment purposes only and does not portray real individuals or events. Visitors are advised to use their own discretion, and neither the website nor its operators are liable for any consequences resulting from the use of this content.
        </p>
        <div className="flex justify-center items-center gap-10 pb-5 md:pb-10">
          <NavLink to="https://x.com/eromanticai" target="_blank">
            <img loading='lazy' src={Twitter} alt="twitter" className="w-9 md:w-11 lg:w-auto" />
          </NavLink>
          <NavLink to="https://www.instagram.com/eromantic.ai/" target="_blank">
            <img loading='lazy' src={Instagram} alt="insta" className="w-9 md:w-11 lg:w-auto" />
          </NavLink>
        </div>
        <div className="border border-t-black border-b-gray-500 border-l-0 border-r-0 flex flex-wrap justify-center md:justify-between md:items-center py-1">
          <div className="flex justify-center items-center gap-4 xl:gap-5 pb-1 md:pb-0 text-[12px] md:text-[14px] lg:text-base xl:text-lg font-medium">
            <NavLink to="/blogs">Blogs</NavLink>
            <NavLink to="/terms-of-service">Terms Of Service</NavLink>
            <NavLink to="/privacy-policy">Our Policy</NavLink>
            <NavLink to="/cookie-policy">Cookie Policy</NavLink>
          </div>
          <div className="flex flex-wrap justify-center items-center gap-4 md:gap-2 xl:gap-5">
            <p className="text-[12px] md:text-[14px] lg:text-base xl:text-lg font-medium">WE ACCEPT</p>
            {renderVisaImages()}
          </div>
        </div>
        <p className="text-[12px] md:text-[14px] lg:text-base xl:text-lg text-center pt-2 md:pt-5">
          © 2025 EROMANTIC.AI. All Rights Reserved - Sitemap
        </p>
      </div>
    </>
  );
};

export default Footer;
