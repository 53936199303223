import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import {
  firstImage, img42, secondThird, alert, secondFifth, greenEye, blonde, secondSecond, img41, age4, age5, age2, secondSix, img44, 
  age3, age1, img45, blackEye, grayEye, brownEye, blueEye, img43, secondFirstImage, redhair, blackhair, brownhair, pinkhair, greyhair, girl_african, 
  girl_arabic, girl_asian, girl_caucasian, girl_latina, eighteenAnimeFemale, realBobcut, curlyAnimeFemale, realBraids, realCurly, twentyAnimeFemale, 
  realCurvy, curvyAnimeFemale, realDreadlocks, dreadlockAnimeFemale, realHourglass, thirtyAnimeFemale, realsmallAss, realLargeAss, realLargeB, 
  hourglassAnimeFemale, realMediumAss, largeAnimeFemale, realMediumB, largeAssAnimeFemale, realPixie, realShag, afroAnimeFemale, realSlim, realSmallB, 
  shagAnimeFemale, mediumAnimeFemale, athleticAnimeFemale, mediumAssAnimeFemale, bangsAnimeFemale, bobcutAnimeFemale, slimAnimeFemale, playbutton, 
  pixieCutAnimeFemale, braidsAnimeFemale, realAfro, realAthletic, smallAnimeFemale, realBangs, smallAssAnimeFemale, Bodyicon, Bodyback, Relationshipicon, 
  Location, age, brest, eyes, hair, occupation, manbody, personality,
} from "../../../assets";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loaders from "../../../frontend/components/loaders";
import Tooltip from "@mui/material/Tooltip";
import { BaseApiUrl } from "../../../utilities/enviroment";
import Footer from "../../../frontend/components/Footer";

const BASE_URL = BaseApiUrl + "assistant_api/";

const AIGirlfriend = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [gfData, setGFdata] = useState("");
  const [style, setStyle] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [finalInputValue, setFinalInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionsData, setOptionsData] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [pageSequence, setPageSequence] = useState([]);
  const [firstmsg, setFirstmsg] = useState();
  const [completedata, setCompletedata] = useState("");
  const [generatedimg, setGeneratedimg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const audioRefs = useRef([]);
  const [activeIndex, setActiveIndex] = useState(null); // Track which audio is being played
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [errorisOpen, setErrorIsOpen] = useState(false);
  const errorCloseModal = () => setErrorIsOpen(false);
  const errorOpenModal = () => setErrorIsOpen(true);

  // A list of names to choose from
  const namesList = [
    "Alice",
    "Diana",
    "Eve",
    "Grace",
    "Hannah",
    "Kathy",
    "Mia",
    "Nina",
    "Rachel",
    "Tina",
    "Ursula",
    "Wendy",
    "Yara",
    "Lily",
    "Sophie",
    "Emma",
    "Olivia",
    "Ava",
    "Chloe",
    "Isabella",
  ];

  const getRandomNames = () => {
    // Shuffle the namesList and pick the first 8
    const shuffled = [...namesList].sort(() => Math.random() - 0.5);
    return shuffled.slice(0, 8);
  };

  useEffect(() => {
    // Get random suggestions when the component is mounted
    setSuggestions(getRandomNames());
  }, []);

  const handleImageClickview = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === "popup-overlay") {
      setIsPopupOpen(false);
    }
  };

  // const user = localStorage.getItem("login");

  const handleColorClick = (color) => {
    setSelectedColor(color);
  };

  const navigate = useNavigate();

  const images0 = [
    { src: firstImage, title: "Realistic", tab: "Style" },
    { src: twentyAnimeFemale, title: "Anime", tab: "Style" },
  ];
  const images1 = [
    { src: secondFirstImage, title: "Caucasian", tab: "Ethnicity" },
    { src: secondSecond, title: "Asian", tab: "Ethnicity" },
    { src: secondThird, title: "Arabic", tab: "Ethnicity" },
    { src: secondFifth, title: "Latina", tab: "Ethnicity" },
    { src: secondSix, title: "African", tab: "Ethnicity" },
  ];
  const images2 = [
    { src: age1, titleshow: "Teen (18+)", title: "Teen (18+)", tab: "Age" },
    { src: age2, titleshow: "Twenties", title: "Twenties", tab: "Age" },
    { src: age3, titleshow: "Thirties", title: "Thirties", tab: "Age" },
    { src: age4, titleshow: "Forties", title: "Forties", tab: "Age" },
    { src: age5, titleshow: "Fifties", title: "Fifties", tab: "Age" },
  ];
  const images3 = [
    { src: brownEye, title: "Brown", tab: "Eyes" },
    { src: blueEye, title: "Blue", tab: "Eyes" },
    { src: greenEye, title: "Green", tab: "Eyes" },
    { src: blackEye, title: "Black", tab: "Eyes" },
    { src: grayEye, title: "Grey", tab: "Eyes" },
  ];
  const images4 = [
    { src: realBraids, title: "Braids", tab: "Hair Style & Color" },
    { src: realCurly, title: "Curly", tab: "Hair Style & Color" },
    { src: realBobcut, title: "Bob Cut", tab: "Hair Style & Color" },
    { src: realBangs, title: "Bangs", tab: "Hair Style & Color" },
    { src: realShag, title: "Straight", tab: "Hair Style & Color" },
    { src: realPixie, title: "Pixie", tab: "Hair Style & Color" },
    { src: realAfro, title: "Afro", tab: "Hair Style & Color" },
    { src: realDreadlocks, title: "Dreadlocks", tab: "Hair Style & Color" },
  ];
  const images5 = [
    { src: realSlim, title: "Slim", tab: "Body Type" },
    { src: realHourglass, title: "Hourglass", tab: "Body Type" },
    { src: realAthletic, title: "Athletic", tab: "Body Type" },
    { src: realCurvy, title: "Curvy", tab: "Body Type" },
  ];
  const images6 = [
    { src: realSmallB, title: "Small", tab: "Breast Size" },
    { src: realMediumB, title: "Medium", tab: "Breast Size" },
    { src: realLargeB, title: "Large", tab: "Breast Size" },
  ];
  const images7 = [
    { src: realsmallAss, title: "Small", tab: "Butt Size" },
    { src: realMediumAss, title: "Medium", tab: "Butt Size" },
    { src: realLargeAss, title: "Large", tab: "Butt Size" },
  ];

  // understanding, , writer, , hammer, handInHand, , , couple1, fireman, , hugs, couple, ,
  // fuel, voiceMessage, kiss, winner, hug, hearts, hugCopy,

  const images8 = [
    { src: "", title: "Trailblazer", tab: "Personality" },
    { src: "", title: "Nurturer", tab: "Personality" },
    { src: "", title: "Free Spirit", tab: "Personality" },
    { src: "", title: "Intellectual", tab: "Personality" },
    { src: "", title: "Social Butterfly", tab: "Personality" },
    { src: "", title: "Creative Soul", tab: "Personality" },
    { src: "", title: "Leader", tab: "Personality" },
    { src: "", title: "Peacemaker", tab: "Personality" },
    { src: "", title: "Analyst", tab: "Personality" },
    { src: "", title: "Optimist", tab: "Personality" },
    { src: "", title: "Realist", tab: "Personality" },
    { src: "", title: "Advocate", tab: "Personality" },
    { src: "", title: "Homebody", tab: "Personality" },
    { src: "", title: "Wit", tab: "Personality" },
    { src: "", title: "Life of the Party", tab: "Personality" },
    { src: "", title: "Wife", tab: "Personality" },
    { src: "", title: "Sexual Deviant", tab: "Personality" },
    { src: "", title: "Mistress", tab: "Personality" },
    { src: "", title: "Milf", tab: "Personality" },
    { src: "", title: "Drama Queen", tab: "Personality" },
    { src: "", title: "Party Girl", tab: "Personality" },
    { src: "", title: "Submissive", tab: "Personality" },
    { src: "", title: "Caregiver", tab: "Personality" },
    { src: "", title: "Rebel", tab: "Personality" },
    { src: "", title: "Thrill-Seeker", tab: "Personality" },
    { src: "", title: "Minimalist", tab: "Personality" },
    { src: "", title: "Fashionista", tab: "Personality" },
    { src: "", title: "Gamer", tab: "Personality" },
    { src: "", title: "Foodie", tab: "Personality" },
    { src: "", title: "Nature Lover", tab: "Personality" },
    { src: "", title: "Bookworm", tab: "Personality" },
    { src: "", title: "Manipulator", tab: "Personality" },
  ];
  const images9 = [
    {
      src: playbutton,
      title: "Natural",
      tab: "Voice",
      id: "LcfcDJNUP1GQjkzn1xUU",
    },
    {
      src: playbutton,
      title: "Formal",
      tab: "Voice",
      id: "mBoEKrpgGV9T9hW3aApV",
    },
    {
      src: playbutton,
      title: "Casual",
      tab: "Voice",
      id: "z9fAnlkpzviPz146aGWa",
    },
    {
      src: playbutton,
      title: "Emotional",
      tab: "Voice",
      id: "oWAxZDx7w5VEj9dCyTzz",
    },
    {
      src: playbutton,
      title: "Romantic",
      tab: "Voice",
      id: "pM7QPMisGYZVwLR9oQj6",
    },
    {
      src: playbutton,
      title: "Seductive",
      tab: "Voice",
      id: "HnuJZzPKTgcHaCYl1KRF",
    },
    {
      src: playbutton,
      title: "Warm",
      tab: "Voice",
      id: "thfYL0Elyru2qqTtNQsE",
    },
    {
      src: playbutton,
      title: "Loved",
      tab: "Voice",
      id: "pFZP5JQG7iQjIQuC4Bku",
    },
    {
      src: playbutton,
      title: "Charming",
      tab: "Voice",
      id: "EaBs7G1VibMrNAuz2Na7",
    },
    {
      src: playbutton,
      title: "Gentle",
      tab: "Voice",
      id: "eVItLK1UvXctxuaRV2Oq",
    },
  ];
  const images11 = [
    { src: "", title: "Nurse", tab: "Occupation" },
    { src: "", title: "Teacher", tab: "Occupation" },
    { src: "", title: "Lawyer", tab: "Occupation" },
    { src: "", title: "Doctor", tab: "Occupation" },
    { src: "", title: "Dentist", tab: "Occupation" },
    { src: "", title: "Pharmacist", tab: "Occupation" },
    { src: "", title: "Veterinarian", tab: "Occupation" },
    { src: "", title: "Physical Therapist", tab: "Occupation" },
    { src: "", title: "Mental Health Counselor", tab: "Occupation" },
    { src: "", title: "Librarian", tab: "Occupation" },
    { src: "", title: "School Counselor", tab: "Occupation" },
    { src: "", title: "Accountant", tab: "Occupation" },
    { src: "", title: "Personal Trainer", tab: "Occupation" },
    { src: "", title: "Human Resources", tab: "Occupation" },
    { src: "", title: "Project Manager", tab: "Occupation" },
    { src: "", title: "Software Engineer", tab: "Occupation" },
    { src: "", title: "Musician", tab: "Occupation" },
    { src: "", title: "Artist", tab: "Occupation" },
    { src: "", title: "Data Scientist", tab: "Occupation" },
    { src: "", title: "Interior Designer", tab: "Occupation" },
    { src: "", title: "Writer", tab: "Occupation" },
    { src: "", title: "Architect", tab: "Occupation" },
    { src: "", title: "Environmental Scientist", tab: "Occupation" },
    { src: "", title: "Entrepreneur", tab: "Occupation" },
    { src: "", title: "Construction Worker", tab: "Occupation" },
    { src: "", title: "Auto Mechanic", tab: "Occupation" },
    { src: "", title: "Electrician", tab: "Occupation" },
    { src: "", title: "Ballerina", tab: "Occupation" },
    { src: "", title: "Social Worker", tab: "Occupation" },
    { src: "", title: "Pilot", tab: "Occupation" },
    { src: "", title: "Real Estate Agent", tab: "Occupation" },
    { src: "", title: "Chef", tab: "Occupation" },
  ];

  const images12 = [
    { src: "", title: "Casual", tab: "Relationship" },
    { src: "", title: "Romantic", tab: "Relationship" },
    { src: "", title: "Friendship", tab: "Relationship" },
    { src: "", title: "Wife", tab: "Relationship" },
    { src: "", title: "Girlfriend", tab: "Relationship" },
    { src: "", title: "Partner", tab: "Relationship" },
    { src: "", title: "Fiancée", tab: "Relationship" },
    { src: "", title: "Lover", tab: "Relationship" },
    { src: "", title: "Friend with Benefits", tab: "Relationship" },
    { src: "", title: "Ex-Wife", tab: "Relationship" },
    { src: "", title: "Childhood Sweetheart", tab: "Relationship" },
    { src: "", title: "First Love", tab: "Relationship" },
    { src: "", title: "Soulmate", tab: "Relationship" },
  ];

  const images10 = [
    { src: girl_caucasian, title: "Caucasian", tab: "Ethnicity" },
    { src: girl_asian, title: "Asian", tab: "Ethnicity" },
    { src: girl_latina, title: "Latina", tab: "Ethnicity" },
    { src: girl_arabic, title: "Arabic", tab: "Ethnicity" },
    { src: girl_african, title: "African", tab: "Ethnicity" },
  ];
  const images20 = [
    {
      src: eighteenAnimeFemale,
      titleshow: "Twenties",
      title: "Teen (18+)",
      tab: "Age",
    },
    {
      src: twentyAnimeFemale,
      titleshow: "Thirties",
      title: "Twenties",
      tab: "Age",
    },
    {
      src: thirtyAnimeFemale,
      titleshow: "Forties",
      title: "Thirties",
      tab: "Age",
    },
  ];
  const images30 = [
    { src: img42, title: "Brown", tab: "Eyes" },
    { src: img44, title: "Blue", tab: "Eyes" },
    { src: img45, title: "Green", tab: "Eyes" },
    { src: img41, title: "Black", tab: "Eyes" },
    { src: img43, title: "Grey", tab: "Eyes" },
  ];
  const images40 = [
    { src: bangsAnimeFemale, title: "Bangs", tab: "Hair Style & Color" },
    { src: curlyAnimeFemale, title: "Curly", tab: "Hair Style & Color" },
    { src: braidsAnimeFemale, title: "Braids", tab: "Hair Style & Color" },
    { src: bobcutAnimeFemale, title: "Bob Cut", tab: "Hair Style & Color" },
    { src: pixieCutAnimeFemale, title: "PixieCut", tab: "Hair Style & Color" },
    { src: shagAnimeFemale, title: "Straight", tab: "Hair Style & Color" },
    { src: afroAnimeFemale, title: "Afro", tab: "Hair Style & Color" },
    {
      src: dreadlockAnimeFemale,
      title: "Dreadlock",
      tab: "Hair Style & Color",
    },
  ];
  const images50 = [
    { src: slimAnimeFemale, title: "Skinny", tab: "Body Type" },
    { src: hourglassAnimeFemale, title: "Slim", tab: "Body Type" },
    { src: athleticAnimeFemale, title: "Athleic", tab: "Body Type" },
    { src: curvyAnimeFemale, title: "Curvy", tab: "Body Type" },
  ];
  const images60 = [
    { src: smallAnimeFemale, title: "Small", tab: "Breast Size" },
    { src: mediumAnimeFemale, title: "Medium", tab: "Breast Size" },
    { src: largeAnimeFemale, title: "Large", tab: "Breast Size" },
  ];
  const images70 = [
    { src: smallAssAnimeFemale, title: "Small", tab: "Butt Size" },
    { src: mediumAssAnimeFemale, title: "Medium", tab: "Butt Size" },
    { src: largeAssAnimeFemale, title: "Large", tab: "Butt Size" },
  ];

  const images80 = [
    { src: "", title: "Trailblazer", tab: "Personality" },
    { src: "", title: "Nurturer", tab: "Personality" },
    { src: "", title: "Free Spirit", tab: "Personality" },
    { src: "", title: "Intellectual", tab: "Personality" },
    { src: "", title: "Social Butterfly", tab: "Personality" },
    { src: "", title: "Creative Soul", tab: "Personality" },
    { src: "", title: "Leader", tab: "Personality" },
    { src: "", title: "Peacemaker", tab: "Personality" },
    { src: "", title: "Analyst", tab: "Personality" },
    { src: "", title: "Optimist", tab: "Personality" },
    { src: "", title: "Realist", tab: "Personality" },
    { src: "", title: "Advocate", tab: "Personality" },
    { src: "", title: "Homebody", tab: "Personality" },
    { src: "", title: "Wit", tab: "Personality" },
    { src: "", title: "Life of the Party", tab: "Personality" },
    { src: "", title: "Wife", tab: "Personality" },
    { src: "", title: "Sexual Deviant", tab: "Personality" },
    { src: "", title: "Mistress", tab: "Personality" },
    { src: "", title: "Milf", tab: "Personality" },
    { src: "", title: "Drama Queen", tab: "Personality" },
    { src: "", title: "Party Girl", tab: "Personality" },
    { src: "", title: "Submissive", tab: "Personality" },
    { src: "", title: "Caregiver", tab: "Personality" },
    { src: "", title: "Rebel", tab: "Personality" },
    { src: "", title: "Thrill-Seeker", tab: "Personality" },
    { src: "", title: "Minimalist", tab: "Personality" },
    { src: "", title: "Fashionista", tab: "Personality" },
    { src: "", title: "Gamer", tab: "Personality" },
    { src: "", title: "Foodie", tab: "Personality" },
    { src: "", title: "Nature Lover", tab: "Personality" },
    { src: "", title: "Bookworm", tab: "Personality" },
    { src: "", title: "Manipulator", tab: "Personality" },
  ];
  const images90 = [
    {
      src: playbutton,
      title: "Natural",
      tab: "Voice",
      id: "LcfcDJNUP1GQjkzn1xUU",
    },
    {
      src: playbutton,
      title: "Formal",
      tab: "Voice",
      id: "mBoEKrpgGV9T9hW3aApV",
    },
    {
      src: playbutton,
      title: "Casual",
      tab: "Voice",
      id: "z9fAnlkpzviPz146aGWa",
    },
    {
      src: playbutton,
      title: "Emotional",
      tab: "Voice",
      id: "oWAxZDx7w5VEj9dCyTzz",
    },
    {
      src: playbutton,
      title: "Romantic",
      tab: "Voice",
      id: "pM7QPMisGYZVwLR9oQj6",
    },
    {
      src: playbutton,
      title: "Seductive",
      tab: "Voice",
      id: "HnuJZzPKTgcHaCYl1KRF",
    },
    {
      src: playbutton,
      title: "Warm",
      tab: "Voice",
      id: "thfYL0Elyru2qqTtNQsE",
    },
    {
      src: playbutton,
      title: "Loved",
      tab: "Voice",
      id: "pFZP5JQG7iQjIQuC4Bku",
    },
    {
      src: playbutton,
      title: "Charming",
      tab: "Voice",
      id: "EaBs7G1VibMrNAuz2Na7",
    },
    {
      src: playbutton,
      title: "Gentle",
      tab: "Voice",
      id: "eVItLK1UvXctxuaRV2Oq",
    },
  ];
  const images110 = [
    { src: "", title: "Nurse", tab: "Occupation" },
    { src: "", title: "Teacher", tab: "Occupation" },
    { src: "", title: "Lawyer", tab: "Occupation" },
    { src: "", title: "Doctor", tab: "Occupation" },
    { src: "", title: "Dentist", tab: "Occupation" },
    { src: "", title: "Pharmacist", tab: "Occupation" },
    { src: "", title: "Veterinarian", tab: "Occupation" },
    { src: "", title: "Physical Therapist", tab: "Occupation" },
    { src: "", title: "Mental Health Counselor", tab: "Occupation" },
    { src: "", title: "Librarian", tab: "Occupation" },
    { src: "", title: "School Counselor", tab: "Occupation" },
    { src: "", title: "Accountant", tab: "Occupation" },
    { src: "", title: "Personal Trainer", tab: "Occupation" },
    { src: "", title: "Human Resources", tab: "Occupation" },
    { src: "", title: "Project Manager", tab: "Occupation" },
    { src: "", title: "Software Engineer", tab: "Occupation" },
    { src: "", title: "Musician", tab: "Occupation" },
    { src: "", title: "Artist", tab: "Occupation" },
    { src: "", title: "Data Scientist", tab: "Occupation" },
    { src: "", title: "Interior Designer", tab: "Occupation" },
    { src: "", title: "Writer", tab: "Occupation" },
    { src: "", title: "Architect", tab: "Occupation" },
    { src: "", title: "Environmental Scientist", tab: "Occupation" },
    { src: "", title: "Entrepreneur", tab: "Occupation" },
    { src: "", title: "Construction Worker", tab: "Occupation" },
    { src: "", title: "Auto Mechanic", tab: "Occupation" },
    { src: "", title: "Electrician", tab: "Occupation" },
    { src: "", title: "Ballerina", tab: "Occupation" },
    { src: "", title: "Social Worker", tab: "Occupation" },
    { src: "", title: "Pilot", tab: "Occupation" },
    { src: "", title: "Real Estate Agent", tab: "Occupation" },
    { src: "", title: "Chef", tab: "Occupation" },
  ];
  const images120 = [
    { src: "", title: "Casual", tab: "Relationship" },
    { src: "", title: "Romantic", tab: "Relationship" },
    { src: "", title: "Friendship", tab: "Relationship" },
    { src: "", title: "Wife", tab: "Relationship" },
    { src: "", title: "Girlfriend", tab: "Relationship" },
    { src: "", title: "Partner", tab: "Relationship" },
    { src: "", title: "Fiancée", tab: "Relationship" },
    { src: "", title: "Lover", tab: "Relationship" },
    { src: "", title: "Friend with Benefits", tab: "Relationship" },
    { src: "", title: "Ex-Wife", tab: "Relationship" },
    { src: "", title: "Childhood Sweetheart", tab: "Relationship" },
    { src: "", title: "First Love", tab: "Relationship" },
    { src: "", title: "Soulmate", tab: "Relationship" },
  ];


  useEffect(() => {
    setSelectedOptions([]);
  }, [currentPage]);

  useEffect(() => {
    if (selectedOptions.length === 5) {
      handleNextoptions();
    }
  }, [selectedOptions]);

  const handleOptionSelect = (value) => {
    setSelectedOptions((prevState) => {
      // If the value is already selected, remove it; otherwise, add it
      if (prevState.includes(value)) {
        return prevState.filter((option) => option !== value);
      } else if (prevState.length < 5) {
        return [...prevState, value];
      }
      return prevState; // Don't add more than 3
    });
  };

  const handleBack = () => {
    if (selectedIndex === null) {
      if (currentPage === 1) {
        navigate(-1);
      } else {
        setCurrentPage(currentPage - 1);
      }
    } else {
      setSelectedIndex(null);
    }
  };

  const colors = [
    { name: redhair, color: "red" },
    { name: blonde, color: "Blonde" },
    // { name: greenhair, color: 'green' },
    { name: pinkhair, color: "pink" },
    { name: blackhair, color: "black" },
    { name: brownhair, color: "brown" },
    { name: greyhair, color: "grey" },
  ];

  const handleNext = (item, selectedColor) => {
    const { title, tab } = item;

    if (currentPage === 1) {
      setStyle(title);
    }
    let newData = { ...gfData, [`gfData${currentPage}`]: { title, tab } };

    if (currentPage >= 1 && currentPage <= 4) {
      setGFdata(newData);
      if (currentPage < 15) {
        setCurrentPage(currentPage + 1);
      } else {
        navigate("/final");
      }
    } else if (currentPage === 5) {
      if (selectedColor === "") {
        toast.error("Select Hair Color First", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        newData = {
          ...newData,
          [`gfData${currentPage}`]: { title, tab, color: selectedColor },
        };
        setGFdata(newData);
        setCurrentPage(currentPage + 1);
      }
    } else if (currentPage >= 6 && currentPage <= 12) {
      // Update state for pages 6 through 8
      let newData = { ...gfData, [`gfData${currentPage}`]: { title, tab } };
      setGFdata(newData);
      if (currentPage < 15) {
        setCurrentPage(currentPage + 1);
      } else {
        navigate("/final");
      }
    }
  };

  const pageNames = {
    13: "Hobbies",
  };

  const getPageName = (pageNumber) =>
    pageNames[pageNumber] || `Page ${pageNumber}`;
  const handleNextoptions = () => {
    let nextPage = currentPage;
    if (currentPage >= 13 && currentPage <= 13) {
      if (Object.values(selectedOptions).every((option) => option !== null)) {
        if (currentPage <= 13) {
          nextPage = currentPage + 1;
        } else {
          navigate("/final");
          return;
        }
      }
    }
    setPageSequence((prevSequence) => {
      const updatedSequence = [...prevSequence, currentPage, selectedOptions];
      const groupByPage = (sequence) => {
        const grouped = {};
        for (let i = 0; i < sequence.length; i += 2) {
          const page = sequence[i];
          const options = sequence[i + 1];
          if (!grouped[page]) {
            grouped[page] = [];
          }
          grouped[page].push(options);
        }
        return grouped;
      };
      const formatGroupedData = (groupedData) => {
        return Object.keys(groupedData)
          .sort((a, b) => parseInt(a) - parseInt(b))
          .map((page) => ({
            page: getPageName(parseInt(page)),
            options: groupedData[page],
          }));
      };
      const groupedData = groupByPage(updatedSequence);
      const formattedData = formatGroupedData(groupedData);

      setOptionsData(formattedData);
      return updatedSequence;
    });

    setCurrentPage(nextPage);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Handler for button click
  // const handleData = () => {
  //   if (inputValue.trim() === "") {
  //     alert("Set GirlFriend Name");
  //   } else {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };
  const u_id = localStorage.getItem("u_id");

  const Generategf = async () => {
    const subscription = localStorage.getItem('subscription_type');
    if (subscription === "free") {
      errorOpenModal();
      return;
    }
    if (!inputValue || inputValue.trim() === "") {
      toast.error("Please provide a Name", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    const payload = {
      gfData: gfData,
      optionsData: optionsData,
      GFNmae: inputValue,
      Summary: finalInputValue,
    };

    try {
      loaders(true, "heart_time_req");
      const authToken = localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}bot_create/`, payload, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 201) {
        loaders(false, "heart_time_req");
        setFirstmsg(response.data.response_message);
        setCurrentPage(currentPage + 1);
        setFinalInputValue(response.data);
        setGeneratedimg(response.data.imgname);
      } else if (response.status === 200) {
        loaders(false, "heart_time_req");
        Swal.fire({
          title: "Token Executed",
          text: response.data.msg,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Add Tokens",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/priceplan");
            //   Swal.fire({
            //     title: "okkkk!",
            //     text: "Your file has been deleted.",
            //     icon: "success"
            //   });
          }
        });
      }
    } catch (error) {
      loaders(false, "heart_time_req");
      const errorMessage =
        error.response?.data?.error || 'Our Servers are busy! Please Try Again';
      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#df132c",
        cancelButtonColor: "#d33",
        confirmButtonText: "ok",
      })
      // toast.error(errorMessage, {
      //   position: "top-left",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
      console.error("Error logging in:", error);
    }
  };

  useEffect(() => {
    NewGenerate();
  }, []);

  const NewGenerate = async () => {
    // setLoading(true);
    // setError(null);
    try {
      const response = await axios.get(`${BASE_URL}bot_fetch/?user_id=${u_id}`);
      if (response.status === 200) {
        setCompletedata(response.data);
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };
  const handleAudioEnded = (index) => {
    // Reset activeIndex when the audio has completely played
    if (activeIndex === index) {
      setActiveIndex(null); // Or setActiveIndex([]) if it's an array
    }
  };

  // const voiceId = "pM7QPMisGYZVwLR9oQj6"; // Replace with your voice ID
  const fetchAudioStream = async (voiceId, index) => {
    const text =
      "Here's a playful peek just for you, baby . I hope you enjoy it as much as I enjoy teasing you. ![Here I am] Let me know if there's more you want to see! "; // Replace with your text
    const apiKey = "sk_fd230ee022b2e4b901f2c72cb37ac59964573c9325d74248"; // Replace with your API key
    const voiceSettings = {
      stability: 0.5,
      similarity_boost: 0.75,
    };
    try {
      setIsLoading(true);
      setActiveIndex(index); // Track active audio being fetched

      const response = await fetch(
        `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}/stream`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "xi-api-key": apiKey,
          },
          body: JSON.stringify({
            text,
            voice_settings: voiceSettings,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch audio stream: ${response.statusText}`);
      }

      // Convert the response to a Blob and create an audio URL
      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);

      // Set the audio source and play
      if (audioRefs.current[index]) {
        audioRefs.current[index].src = audioUrl;
        audioRefs.current[index].play();
      }
    } catch (error) {
      console.error("Error fetching or playing audio:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const itemsToDisplay = finalInputValue
    ? (finalInputValue.character_info.Butt_Size === "" ||
      finalInputValue.character_info.Butt_Size === "NA") &&
      (finalInputValue.character_info.Breast_Size === "" ||
        finalInputValue.character_info.Breast_Size === "NA")
      ? [
        {
          label: "Relationship:",
          value: finalInputValue.character_info.Relationship || "",
          src: Relationshipicon,
        },
        {
          label: "Age:",
          value: finalInputValue.character_info.age || "",
          src: age,
        },
        {
          label: "Personality:",
          value: finalInputValue.character_info.Personality || "",
          src: personality,
        },
        {
          label: "Occupation:",
          value: finalInputValue.character_info.Occupation || "",
          src: occupation,
        },
        {
          label: "Hair Style and Color:",
          value: `${finalInputValue.character_info.hair_Style || ""} ${finalInputValue.character_info.hair_color || ""
            }`,
          src: hair,
        },
        {
          label: "Eyes:",
          value: finalInputValue.character_info.Eyes || "",
          src: eyes,
        },
        {
          label: "Ethnicity:",
          value: finalInputValue.character_info.Ethnicity || "",
          src: Location,
        },
        // {
        //   label: "Region:",
        //   value: finalInputValue.character_info.region || "",
        //   src: country,
        // },
        {
          label: "Body Type:",
          value: finalInputValue.character_info.Body_Type || "",
          src: manbody,
        },
      ]
      : [
        {
          label: "Relationship:",
          value: finalInputValue.character_info.Relationship || "",
          src: Relationshipicon,
        },
        {
          label: "Age:",
          value: finalInputValue.character_info.age || "",
          src: age,
        },
        {
          label: "Personality:",
          value: finalInputValue.character_info.Personality || "",
          src: personality,
        },
        {
          label: "Occupation:",
          value: finalInputValue.character_info.Occupation || "",
          src: occupation,
        },
        {
          label: "Hair Style and Color:",
          value: `${finalInputValue.character_info.hair_Style || ""} ${finalInputValue.character_info.hair_color || ""
            }`,
          src: hair,
        },
        {
          label: "Eyes:",
          value: finalInputValue.character_info.Eyes || "",
          src: eyes,
        },
        {
          label: "Ethnicity:",
          value: finalInputValue.character_info.Ethnicity || "",
          src: Location,
        },
        // {
        //   label: "Region:",
        //   value: finalInputValue.character_info.region || "",
        //   src: country,
        // },
        {
          label: "Body Type:",
          value: finalInputValue.character_info.Body_Type || "",
          src: Bodyicon,
        },
        {
          label: "Breast Size:",
          value: finalInputValue.character_info.Breast_Size || "",
          src: brest,
        },
        {
          label: "Butt Size:",
          value: finalInputValue.character_info.Butt_Size || "",
          src: Bodyback,
        },
      ]
    : [];

  return (
    <>
      <Helmet>
        <title>The Best AI Girlfriend Chatbot Online - Eromantic.ai</title>
        <meta
          name="description"
          content="Create your AI Girlfriend step by step on Eromantic.AI. Choose their appearance, personality, hobbies, and relationship type to create a truly unique companion. Start building your perfect AI character today!"
        />
        <meta
          name="keywords"
          content="AI Girlfriend, Virtual Girlfriend, Anime Companions, Interactive AI, NSFW-friendly"
        />
        {/* You can add more meta tags if needed */}
      </Helmet>
      <ToastContainer />
      {currentPage === 1 && (
        <div className="flex justify-center">
          <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
            <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
              <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                <span>1 /</span>
                <span className="text-red-600">
                  15
                  <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                </span>
                <span> Select Style</span>
              </div>
              <button
                className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                onClick={handleBack}
              >
                Back
              </button>
            </div>

            <div className="flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-2 mt-5">
              {images0.map((item, index) => (
                <div
                  key={index}
                  className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg hover:rounded-2xl overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] ${selectedIndex === index
                      ? "bg-red-600 border-4 dreamclr"
                      : "border border-transparent"
                    }`}
                  onClick={() => handleNext(item)}
                >
                  <img
                    loading="lazy"
                    src={item.src}
                    alt={item.title}
                    className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover  duration-300"
                  />
                  <div className="flex justify-center mt-5">
                    <button
                      className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute py-[1px] px-[6px] mo:px-5  bottom-4 "
                      onClick={() => handleNext(item)}
                    >
                      {item.title}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {currentPage === 2 && (
        <>
          <div className="flex justify-center">
            <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
              <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                  <span>2 /</span>
                  <span className="text-red-600">
                    15
                    <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                  </span>
                  <span> Select Ethnicity</span>
                </div>
                <button
                  className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                  onClick={handleBack}
                >
                  Back
                </button>
              </div>

              <div className="flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-2 mt-5">
                {style === "Realistic"
                  ? images1.map((item, index) => (
                    <div
                      key={index}
                      className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg hover:rounded-2xl overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a]  ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                        }`}
                      onClick={() => handleNext(item)}
                    >
                      <img
                        loading="lazy"
                        src={item.src}
                        alt={item.title}
                        className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                      />
                      <div className="flex justify-center mt-5">
                        <button
                          className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute py-[1px] px-[6px] mo:px-5 bottom-4 "
                          onClick={() => handleNext(item)}
                        >
                          {item.title}
                        </button>
                      </div>
                    </div>
                  ))
                  : images10.map((item, index) => (
                    <div
                      key={index}
                      className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] hover:rounded-2xl ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                        }`}
                      onClick={() => handleNext(item)}
                    >
                      <img
                        loading="lazy"
                        src={item.src}
                        alt={item.title}
                        className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                      />
                      <div className="flex justify-center mt-5">
                        <button
                          className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute py-[1px] px-[6px] mo:px-5 bottom-4 "
                          onClick={() => handleNext(item)}
                        >
                          {item.title}
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}

      {currentPage === 3 && (
        <>
          <div className="flex justify-center">
            <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
              <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                  <span>3 /</span>
                  <span className="text-red-600">
                    15
                    <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                  </span>
                  <span> Select Age</span>
                </div>
                <button
                  className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                  onClick={handleBack}
                >
                  Back
                </button>
              </div>

              <div className="flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-2 mt-5">
                {style === "Realistic"
                  ? images2.map((item, index) => (
                    <div
                      key={index}
                      className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] hover:rounded-2xl ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                        }`}
                      onClick={() => handleNext(item)}
                    >
                      <img
                        loading="lazy"
                        src={item.src}
                        alt={item.title}
                        className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                      />
                      <div className="flex justify-center mt-5">
                        <button
                          className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute py-[1px] px-[6px] mo:px-5 bottom-4 "
                          onClick={() => handleNext(item)}
                        >
                          {item.titleshow}
                        </button>
                      </div>
                    </div>
                  ))
                  : images20.map((item, index) => (
                    <div
                      key={index}
                      className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] hover:rounded-2xl ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                        }`}
                      onClick={() => handleNext(item)}
                    >
                      <img
                        loading="lazy"
                        src={item.src}
                        alt={item.title}
                        className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                      />
                      <div className="flex justify-center mt-5">
                        <button
                          className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute py-[1px] px-[6px] mo:px-5 bottom-4 "
                          onClick={() => handleNext(item)}
                        >
                          {item.title}
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}

      {currentPage === 4 && (
        <>
          <div className="flex justify-center">
            <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
              <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                  <span>4 /</span>
                  <span className="text-red-600">
                    15
                    <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                  </span>
                  <span> Select Eyes</span>
                </div>
                <button
                  className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                  onClick={handleBack}
                >
                  Back
                </button>
              </div>

              <div className="flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-2 mt-5">
                {style === "Realistic"
                  ? images3.map((item, index) => (
                    <div
                      key={index}
                      className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] hover:rounded-2xl ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                        }`}
                      onClick={() => handleNext(item)}
                    >
                      <img
                        loading="lazy"
                        src={item.src}
                        alt={item.title}
                        className="md:w-72 md:h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                      />
                      <div className="flex justify-center mt-5">
                        <button
                          className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute py-[1px] px-[6px] mo:px-5 bottom-4 "
                          onClick={() => handleNext(item)}
                        >
                          {item.title}
                        </button>
                      </div>
                    </div>
                  ))
                  : images30.map((item, index) => (
                    <div
                      key={index}
                      className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] hover:rounded-2xl ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                        }`}
                      onClick={() => handleNext(item)}
                    >
                      <img
                        loading="lazy"
                        src={item.src}
                        alt={item.title}
                        className="md:w-72 md:h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                      />
                      <div className="flex justify-center mt-5">
                        <button
                          className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute py-[1px] px-[6px] mo:px-5 bottom-4 "
                          onClick={() => handleNext(item)}
                        >
                          {item.title}
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}

      {currentPage === 5 && (
        <>
          <div className="flex justify-center">
            <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
              <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                  <span>5 /</span>
                  <span className="text-red-600">
                    15
                    <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                  </span>
                  <span> Select hair Style & Color*</span>
                </div>
                <button
                  className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                  onClick={handleBack}
                >
                  Back
                </button>
              </div>

              <div className="text-center">
                {/* <p className='text-[35px] py-2'> Choose hair Color*</p> */}
                <div className="flex flex-wrap justify-center items-center gap-4">
                  {colors.map(({ name, color }) => (
                    <div className="flex flex-col items-center justify-center space-y-2" key={name}>
                      <div
                        className={`relative w-[6rem] h-[6rem] rounded-full inline-flex items-center justify-center text-xl font-bold border-2 transition-all duration-300 ease-in-out transform hover:scale-105 
                    ${selectedColor === color
                            ? "border-red-600 shadow-lg shadow-red-500/50"
                            : "border-gray-300 hover:border-gray-500"
                          }
                `}
                        onClick={() => handleColorClick(color)}
                      >
                        <img
                          loading="lazy"
                          src={name}
                          alt={name}
                          className="w-[6rem] h-[6rem] rounded-full object-cover shadow-md transition-all duration-300 ease-in-out"
                        />
                      </div>
                      <span className="text-center capitalize text-sm font-medium text-gray-700 hover:text-gray-900 transition-all duration-200">
                        {color}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 my-5">
                {style === "Realistic"
                  ? images4.map((item, index) => (
                    <div
                      key={index}
                      className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] hover:rounded-2xl ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                        }`}
                      onClick={() => handleNext(item, selectedColor)}
                    >
                      <img
                        loading="lazy"
                        src={item.src}
                        alt={item.title}
                        className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                      />
                      <div className="flex justify-center mt-5">
                        <button
                          className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute py-[1px] px-[6px] mo:px-5  bottom-4 "
                          onClick={() => handleNext(item, selectedColor)}
                        >
                          {item.title}
                        </button>
                      </div>
                    </div>
                  ))
                  : images40.map((item, index) => (
                    <div
                      key={index}
                      className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] hover:rounded-2xl ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                        }`}
                      onClick={() => handleNext(item, selectedColor)}
                    >
                      <img
                        loading="lazy"
                        src={item.src}
                        alt={item.title}
                        className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                      />
                      <div className="flex justify-center mt-5">
                        <button
                          className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute py-[1px] px-[6px] mo:px-5 bottom-4 "
                          onClick={() => handleNext(item, selectedColor)}
                        >
                          {item.title}
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}

      {currentPage === 6 && (
        <>
          <div className="flex justify-center">
            <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
              <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                  <span>6 /</span>
                  <span className="text-red-600">
                    15
                    <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                  </span>
                  <span>Choose Body Type</span>
                </div>
                <button
                  className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                  onClick={handleBack}
                >
                  Back
                </button>
              </div>

              <div className="flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-2 mt-5">
                {style === "Realistic"
                  ? images5.map((item, index) => (
                    <div
                      key={index}
                      className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] hover:rounded-2xl ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                        }`}
                      onClick={() => handleNext(item)}
                    >
                      <img
                        loading="lazy"
                        src={item.src}
                        alt={item.title}
                        className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                      />
                      <div className="flex justify-center mt-5">
                        <button
                          className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute py-[1px] px-[6px] mo:px-5 bottom-4 "
                          onClick={() => handleNext(item)}
                        >
                          {item.title}
                        </button>
                      </div>
                    </div>
                  ))
                  : images50.map((item, index) => (
                    <div
                      key={index}
                      className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] hover:rounded-2xl ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                        }`}
                      onClick={() => handleNext(item)}
                    >
                      <img
                        loading="lazy"
                        src={item.src}
                        alt={item.title}
                        className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                      />
                      <div className="flex justify-center mt-5">
                        <button
                          className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute py-[1px] px-[6px] mo:px-5 bottom-4 "
                          onClick={() => handleNext(item)}
                        >
                          {item.title}
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}

      {currentPage === 7 && (
        <>
          <div className="flex justify-center">
            <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
              <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                  <span>7 /</span>
                  <span className="text-red-600">
                    15
                    <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                  </span>
                  <span> Choose Breast Size</span>
                </div>
                <button
                  className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                  onClick={handleBack}
                >
                  Back
                </button>
              </div>

              <div className="flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-2 mt-5">
                {style === "Realistic"
                  ? images6.map((item, index) => (
                    <div
                      key={index}
                      className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] hover:rounded-2xl ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                        }`}
                      onClick={() => handleNext(item)}
                    >
                      <img
                        loading="lazy"
                        src={item.src}
                        alt={item.title}
                        className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                      />
                      <div className="flex justify-center mt-5">
                        <button
                          className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute py-[1px] px-[6px] mo:px-5 bottom-4 "
                          onClick={() => handleNext(item)}
                        >
                          {item.title}
                        </button>
                      </div>
                    </div>
                  ))
                  : images60.map((item, index) => (
                    <div
                      key={index}
                      className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] hover:rounded-2xl ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                        }`}
                      onClick={() => handleNext(item)}
                    >
                      <img
                        loading="lazy"
                        src={item.src}
                        alt={item.title}
                        className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                      />
                      <div className="flex justify-center mt-5">
                        <button
                          className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute py-[1px] px-[6px] mo:px-5 bottom-4 "
                          onClick={() => handleNext(item)}
                        >
                          {item.title}
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}

      {currentPage === 8 && (
        <>
          <div className="flex justify-center">
            <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
              <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                  <span>8 /</span>
                  <span className="text-red-600">
                    15
                    <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                  </span>
                  <span> Choose The Butt Size</span>
                </div>
                <button
                  className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                  onClick={handleBack}
                >
                  Back
                </button>
              </div>

              <div className="flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-2 mt-5">
                {style === "Realistic"
                  ? images7.map((item, index) => (
                    <div
                      key={index}
                      className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] hover:rounded-2xl ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                        }`}
                      onClick={() => handleNext(item)}
                    >
                      <img
                        loading="lazy"
                        src={item.src}
                        alt={item.title}
                        className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                      />
                      <div className="flex justify-center mt-5">
                        <button
                          className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute py-[1px] px-[6px] mo:px-5 bottom-4 "
                          onClick={() => handleNext(item)}
                        >
                          {item.title}
                        </button>
                      </div>
                    </div>
                  ))
                  : images70.map((item, index) => (
                    <div
                      key={index}
                      className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] hover:rounded-2xl ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                        }`}
                      onClick={() => handleNext(item)}
                    >
                      <img
                        loading="lazy"
                        src={item.src}
                        alt={item.title}
                        className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                      />
                      <div className="flex justify-center mt-5">
                        <button
                          className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute py-[1px] px-[6px] mo:px-5 bottom-4 "
                          onClick={() => handleNext(item)}
                        >
                          {item.title}
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}

      {currentPage === 9 && (
        <>
          <div className="flex justify-center">
            <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
              <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                  <span>9 /</span>
                  <span className="text-red-600">
                    15
                    <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                  </span>
                  <span> Choose Personality</span>
                </div>
                <button
                  className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                  onClick={handleBack}
                >
                  Back
                </button>
              </div>

              <div>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-center gap-2 lg:gap-5 mt-5">
                  {style === "Realistic"
                    ? images8.map((item, index) => (
                      <div
                        key={index}
                        className={`flex justify-center items-center relative text-center cursor-pointer rounded-full overflow-hidden transform transition-transform duration-300 border-0 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] ${selectedIndex === index
                            ? "bg-red-600 border-4 dreamclr"
                            : "border "
                          }`}
                        onClick={() => handleNext(item)}>
                        <Tooltip title={item.title} placement="top">
                          <button
                            className="dreamclr text-white font-medium w-72 lg:w-full rounded-full px-4 py-2 h-full"
                            onClick={() => handleNext(item)}
                          >
                            {item.title.slice(0, 13)}...
                          </button>
                        </Tooltip>
                      </div>
                    ))
                    : images80.map((item, index) => (
                      <div
                        key={index}
                        className={`flex justify-center items-center relative text-center cursor-pointer rounded-full overflow-hidden transform transition-transform duration-300 border-0 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] ${selectedIndex === index
                            ? "bg-red-600 border-4 dreamclr"
                            : "border "
                          }`}
                        onClick={() => handleNext(item)}
                      >
                        {/* <img loading ='lazy'
                                          src={item.src}
                                          alt={item.title}
                                          className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                      /> */}
                        <Tooltip title={item.title} placement="top">
                          <button
                            className="dreamclr text-white font-medium w-72 lg:w-full rounded-full px-4 py-2 h-full"
                            onClick={() => handleNext(item)}
                          >
                            {item.title.slice(0, 13)}...
                          </button>
                        </Tooltip>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {currentPage === 10 && (
        <>
          <div className="flex justify-center">
            <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
              <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                  <span>10 /</span>
                  <span className="text-red-600">
                    15
                    <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                  </span>
                  <span> Choose Voice</span>
                </div>
                <button
                  className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                  onClick={handleBack}
                >
                  Back
                </button>
              </div>

              <div className="flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-2 mt-5">
                {style === "Realistic"
                  ? images9.map((item, index) => (
                    <div
                      key={index}
                      className="audio-card flex flex-col justify-center items-center p-4 border rounded-lg hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300"
                    >
                      {isLoading && activeIndex === index ? (
                        // Show loader when fetching audio
                        <div className="loaderaud-container">
                          <div className="loaderaud"></div>
                        </div>
                      ) : activeIndex === index && !isLoading ? (
                        // Show audio wave UI when audio is playing
                        <div className="audio-wave-ui">
                          {/* Audio wave animation */}
                          <div className="wave-container">
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                          </div>
                        </div>
                      ) : (
                        // Show play button if not loading
                        <button
                          className="play-button flex items-center bg-gray-700 text-white px-4 py-2 rounded-full"
                          onClick={() => fetchAudioStream(item.id, index)}
                          disabled={isLoading && activeIndex === index}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className="w-5 h-5 mr-1 rounded-xl object-cover"
                          />
                          Play
                        </button>
                      )}

                      {/* Show audio progress bar once the audio is loaded */}
                      <audio
                        ref={(el) => (audioRefs.current[index] = el)}
                        className={`audio-player mt-4 w-full ${activeIndex === index && !isLoading
                            ? "hidden"
                            : "hidden"
                          }`}
                        controls
                        onEnded={() => handleAudioEnded(index)}
                      />
                      <button
                        onClick={() => handleNext(item)}
                        className="dreamclr mt-4 ml-2 text-white flex items-center justify-center w-60 font-medium rounded-full px-5 py-2"
                      >
                        Voice-{index + 1} ({item.title})
                      </button>
                    </div>
                  ))
                  : images90.map((item, index) => (
                    <div
                      key={index}
                      className="audio-card flex flex-col justify-center items-center p-4 border rounded-lg hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300"
                    >
                      {isLoading && activeIndex === index ? (
                        // Show loader when fetching audio
                        <div className="loaderaud-container">
                          <div className="loaderaud"></div>
                        </div>
                      ) : activeIndex === index && !isLoading ? (
                        // Show audio wave UI when audio is playing
                        <div className="audio-wave-ui">
                          {/* Audio wave animation */}
                          <div className="wave-container">
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                          </div>
                        </div>
                      ) : (
                        // Show play button if not loading
                        <button
                          className="play-button flex items-center bg-gray-700 text-white px-4 py-2 rounded-full"
                          onClick={() => fetchAudioStream(item.id, index)}
                          disabled={isLoading && activeIndex === index}
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.title}
                            className="w-8 h-8 mr-1 rounded-xl object-cover"
                          />
                          Play
                        </button>
                      )}

                      {/* Show audio progress bar once the audio is loaded */}
                      <audio
                        ref={(el) => (audioRefs.current[index] = el)}
                        className={`audio-player mt-4 w-full ${activeIndex === index && !isLoading
                            ? "hidden"
                            : "hidden"
                          }`}
                        controls
                        onEnded={() => handleAudioEnded(index)}
                      />
                      <button
                        onClick={() => handleNext(item)}
                        className="dreamclr mt-4 ml-2 text-white  flex justify-center items-center w-60 font-medium rounded-full px-5 py-2"
                      >
                        Voice-{index + 1} ({item.title})
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}

      {currentPage === 11 && (
        <>
          <div className="flex justify-center">
            <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
              <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                  <span>11 /</span>
                  <span className="text-red-600">
                    15
                    <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                  </span>
                  <span> Choose Occupation</span>
                </div>
                <button
                  className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                  onClick={handleBack}
                >
                  Back
                </button>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-center gap-2 lg:gap-5 mt-5">
                {style === "Realistic"
                  ? images11.map((item, index) => (
                    <div
                      key={index}
                      className={`flex justify-center items-center relative  text-center cursor-pointer rounded-full overflow-hidden transform transition-transform duration-300 border-0 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border"
                        }`}
                      onClick={() => handleNext(item)}
                    >
                      {/* <img loading ='lazy'
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    /> */}
                      <Tooltip title={item.title} placement="top">
                        <button
                          className="dreamclr text-white font-medium w-72 lg:w-full rounded-full px-4 py-2 h-full"
                          onClick={() => handleNext(item)}
                        >
                          {item.title.slice(0, 13)}...
                        </button>
                      </Tooltip>
                    </div>
                  ))
                  : images110.map((item, index) => (
                    <div
                      key={index}
                      className={`flex justify-center items-center relative  text-center cursor-pointer rounded-full overflow-hidden transform transition-transform duration-300 border-0 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border"
                        }`}
                      onClick={() => handleNext(item)}
                    >
                      {/* <img loading ='lazy'
                                      src={item.src}
                                      alt={item.title}
                                      className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                  /> */}
                      <Tooltip title={item.title} placement="top">
                        <button
                          className="dreamclr text-white font-medium w-72 lg:w-full rounded-full px-4 py-2 h-full"
                          onClick={() => handleNext(item)}
                        >
                          {item.title.slice(0, 13)}...
                        </button>
                      </Tooltip>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}

      {currentPage === 12 && (
        <>
          <div className="flex justify-center">
            <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
              <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                  <span>12 /</span>
                  <span className="text-red-600">
                    15
                    <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                  </span>
                  <span>Choose Relationship</span>
                </div>
                <button
                  className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                  onClick={handleBack}
                >
                  Back
                </button>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-center gap-2 lg:gap-5 mt-5">
                {style === "Realistic"
                  ? images12.map((item, index) => (
                    <div
                      key={index}
                      className={`flex justify-center items-center relative  text-center cursor-pointer rounded-full overflow-hidden transform transition-transform duration-300 border-0 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border "
                        }`}
                      onClick={() => handleNext(item)}
                    >
                      {/* <img loading ='lazy'
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    /> */}
                      <Tooltip title={item.title} placement="top">
                        <button
                          className="dreamclr text-white font-medium w-72 lg:w-full rounded-full px-4 py-2 h-full"
                          onClick={() => handleNext(item)}
                        >
                          {item.title.slice(0, 13)}...
                        </button>
                      </Tooltip>
                    </div>
                  ))
                  : images120.map((item, index) => (
                    <div
                      key={index}
                      className={`flex justify-center items-center relative  text-center cursor-pointer rounded-full overflow-hidden transform transition-transform duration-300 border-0 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border "
                        }`}
                      onClick={() => handleNext(item)}
                    >
                      {/* <img loading ='lazy'
                                      src={item.src}
                                      alt={item.title}
                                      className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                  /> */}
                      <Tooltip title={item.title} placement="top">
                        <button
                          className="dreamclr text-white font-medium w-72 lg:w-full rounded-full px-4 py-2 h-full"
                          onClick={() => handleNext(item)}
                        >
                          {item.title.slice(0, 13)}...
                        </button>
                      </Tooltip>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}

      {currentPage === 13 && (
        <>
          <div className="flex justify-center">
            <div className="bg-[#54545496]  mo:w-[75%] p-[1rem] mb-16 mo:mb-0 mo:p-8 mt-2 rounded-2xl">
              <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                  <span>{selectedOptions.length} /</span>
                  <span className="text-red-600">
                    5
                    <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                  </span>
                  <span>Choose Hobbies (Choose 5 options)</span>
                </div>
                <button
                  className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                  onClick={handleBack}
                >
                  Back
                </button>
              </div>

              <div>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 px-4">
                  {[
                    "Painting/Drawing",
                    "Photography",
                    "Writing (poetry, fiction, journaling)",
                    "Knitting / Crocheting",
                    "Sewing",
                    "Jewelry Making",
                    "Pottery/Ceramics",
                    "Calligraphy",
                    "Scrapbooking",
                    "Baking/Cooking",
                    "Yoga",
                    "Dancing (ballet, hip-hop, salsa, etc.)",
                    "Hiking/Camping",
                    "Running/Jogging",
                    "Cycling",
                    "Swimming",
                    "Gardening",
                    "Rock Climbing",
                    "Reading",
                    "Learning a new language",
                    "Playing a musical instrument",
                    "Astronomy",
                    "History",
                    "Stamps/Coins",
                    "Antiques",
                    "Books",
                    "Vinyl Records",
                    "Traveling",
                    "Volunteering",
                    "Blogging",
                    "Birdwatching",
                    "Learning a new skill (coding, woodworking, etc.)",
                    "Meditation",
                    "Playing board games/card games",
                    "Stargazing",
                    "DIY projects",
                    "Learning about different cultures",
                    "Genealogy",
                    "Interior design",
                    "Fashion",
                    "Watching movies",
                    "Going to festivals",
                  ].map((option) => (
                    <div
                      key={option}
                      className={`justify-center flex items-center gap-2 py-4 px-8 border-2 bg-[#6161614A] 
                        ${selectedOptions.includes(option)
                          ? "bg-[#FF00133D] border-red-700 text-red-500"
                          : ""
                        } border-zinc-600 text-white text-lg rounded-[10px] hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300`}
                      onClick={() => handleOptionSelect(option)}
                    >
                      <p className="text-center text-[12px] mo:text-[14px] md:text-[15px] text-wrap">{option}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {currentPage === 14 && (
        <>
          <div className="flex justify-center">
            <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
              <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                  <span>14 /</span>
                  <span className="text-red-600">
                    15
                    <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                  </span>
                  <span> Name Your Girlfriend</span>
                </div>
                <button
                  className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                  onClick={handleBack}
                >
                  Back
                </button>
              </div>

              <div className="">
                <div className="bg-gray-700 rounded-xl mt-2 px-5 py-3 text-black text-2xl font-light w-auto h-38">
                  <input
                    type="text"
                    className="focus:outline-none border-0 text-white bg-gray-700 w-full"
                    name="gfname"
                    placeholder="Beautiful"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <h2 className="my-5 ml-4">Name Suggestions:</h2>
                  <ul className="flex flex-wrap ml-10">
                    {suggestions.map((name, index) => (
                      <li
                        key={index}
                        onClick={() => setInputValue(name)}
                        className="border text-center rounded-lg w-[7rem] p-2 m-1 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300 cursor-pointer"
                      >
                        {name}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="flex justify-center">
                  <button
                    className="w-80 bg-red-700 text-white px-5 py-2 rounded mt-[100px]"
                    onClick={Generategf}
                  >
                    Bring My Girlfriend To Life
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {currentPage === 15 && (
        <>
          <div className="flex justify-center">
            <div className="bg-[#54545496]  mo:w-[85%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
              <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                  <span>15 /</span>
                  <span className="text-red-600">
                    15
                    <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                  </span>
                  <span> Summary</span>
                </div>
                {/* <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button> */}
              </div>

              <div className="p-4">
                <div className="w-full flex justify-center">
                  <h1 className="text-3xl text-white font-semibold mb-6 text-center xl:text-left px-4 py-2 bg-gradient-to-r from-[#99000b] to-[#ff6666] rounded-lg shadow-lg">
                    Character Profile
                  </h1>
                </div>

                <div className="rounded flex flex-col items-center justify-center">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 overflow-hidden">
                    <div className="flex justify-center">
                      <img
                        loading="lazy"
                        src={generatedimg}
                        alt="Generated Img"
                        className="w-full h-auto max-w-[90%] rounded-xl cursor-pointer"
                        onClick={handleImageClickview}
                      />
                    </div>

                    <div className="flex flex-col gap-12">
                      <div className="flex justify-between items-center">
                        <h2 className="text-[#b30016d9] text-lg sm:text-xl font-bold md:text-2xl lg:text-4xl whitespace-nowrap">
                          {finalInputValue.character_info.name}
                        </h2>
                      </div>

                      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 mb-4">
                        {itemsToDisplay.map(({ label, value, src }, index) => (
                          <div key={index} className="flex items-start gap-3">
                            <img
                              loading="lazy"
                              src={src}
                              className="icon w-6 sm:w-8 mt-1 text-black fill-current "
                              alt="icon"
                            />
                            <div className="flex flex-col">
                              <span className="text-muted font-bold !text-[12px] md:text-base">
                                {label}
                              </span>
                              <span
                                className="text-xs md:text-sm"
                                style={{ textTransform: "capitalize" }}
                              >
                                {value}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="flex items-start gap-3">
                        <span className="icon mt-1 mr-2 icon-summary"></span>
                        <div className="flex flex-col">
                          <span className="font-semibold text-muted text-base sm:text-md md:text-lg lg:text-xl">
                            About:
                          </span>
                          <span className="text-[12px] sm:text-base md:text-md lg:text-lg">
                            {finalInputValue.Summary}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full flex justify-center mt-6">
                    <button
                      className="bg-red-800 text-white font-medium rounded-2xl p-2 sm:p-4 lg:p-4 w-[50%] md:w-[35%] lg:w-[25%] xl:w-[20%]"
                      onClick={() => navigate(`/aichat/${finalInputValue.assistant}`)}
                    >
                      Chat Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {isPopupOpen && (
              <div
                id="popup-overlay"
                className="fixed  inset-0 bg-black mt-15 bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto"
                onClick={handleOutsideClick}
              >
                <div className="relative bg-gray-300 w-full mo:w-[28%] rounded-lg border-2 border-[#7d2424b3]">
                  <button
                    className="absolute w-10 top-1 right-2 bg-red-600 text-white rounded-full"
                    onClick={handleClosePopup}
                  >
                    X
                  </button>

                  <img
                    src={generatedimg ? generatedimg : ""}
                    alt="Full View"
                    className="w-full h-auto rounded-lg"
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
      <Footer />

      {errorisOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-lg">
          <div className="modal-dialog modal-lg bg-white rounded-lg overflow-hidden shadow-xl max-w-[16rem] mo:max-w-xl w-full">
            {/* Modal Content */}
            <div className="modal-wrap flex-wrap mo:flex relative">
              {/* Image Container - 50% Width */}
              <div className="w-full mo:w-1/2">
                {/* Desktop Image */}
                <img
                  src={alert}
                  alt="Modal Img"
                  className="login-img hidden md:block w-full h-full object-cover"
                />
                {/* Mobile Image */}
                <img
                  src={alert}
                  alt="Modal Img Mobile"
                  className="login-img-mob sm:hidden w-full"
                />
              </div>

              {/* Content Container - 50% Width */}
              <div className="modal-content p-2 w-full mo:w-1/2 grid justify-center">
                {/* Modal Header */}
                <div className="modal-header flex justify-between items-start">
                  <h4 className="modal-title text-uppercase text-xl"></h4>
                  <button
                    type="button"
                    className="btn-close text-xl text-[#990013]"
                    onClick={errorCloseModal}
                  >
                    <i className="fa-regular fa-circle-xmark"></i>
                  </button>
                </div>

                {/* Modal Body */}
                <div className="modal-body justify-center">
                  <div className="container-fluid">
                    <div className="row mb-4">
                      <div className="col-12 ps-4">
                        <h2 className="text-[#990013] font-bold text-uppercase text-2xl">
                          PLEASE UPGRADE
                        </h2>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 ps-4">
                        <span className="text-muted text-gray-900 text-md">
                          Upgrade to a premium membership to unlock access to all features!
                        </span>
                      </div>
                    </div>
                    <div className="row justify-center p-3 gap-1">
                      <div className="col-12">
                        <button onClick={() => navigate('/priceplan')} className="btn btn-primary w-full py-3 text-white bg-[#990012ab] hover:bg-[#990013] rounded-md">
                          Upgrade
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Modal Overlay */}
            {/* <div
              className="fixed inset-0"
              onClick={errorCloseModal}
            ></div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default AIGirlfriend;
