import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom"; // Import useParams from react-router
import axios from "axios";
import "../../../App.css";
import UserApiClient from "../../../utilities/apiClients";
import Swal from "sweetalert2";
import { BaseApiUrl } from "../../../utilities/enviroment";
import Footer from "../../../frontend/components/Footer";
import CircularWithValueLabel from "../CreateDreamGF/CircularWithValueLabel";

const promptDataWithoutdis = [
  { name: "PROMPT" },
  //   { name: "FACE PROMPT" },
  //   { name: "EXCLUDE" },
  //   { name: "EXTENSIONS" },
  //   { name: "OTHER DETAILS" },
];

const ImageView = () => {
  const [selectedName, setSelectedName] = useState("PROMPT");
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false); // Separate loading for image generation
  const ref = useRef(null);
  const [error, setError] = useState(null);
  const [drive, setDrive] = useState(null);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [GeneratedImage, setGeneratedImage] = useState(null);
  const [putResponsedata, setPutResponsedata] = useState(null);
  const [generateduser, setGeneratedUser] = useState("UNKNOWN");
  const [promptData, setPromptData] = useState(promptDataWithoutdis); // Initialize with default data
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [likesstatus, setLikesStatus] = useState(false);
  const [showMoreBtn, setShowMoreBtn] = useState(true);
  const [pageLinks, setPageLinks] = useState({ "next": null, "prev": null });

  const [imageData, setImageData] = useState([]); // Ensure this is initialized as an empty array
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);


  const BASE_URL = BaseApiUrl;


  const fetchImageData = async (pageLink) => {  
    const pageUrl = typeof pageLink === 'string' ? pageLink : `api/v1/get_allPublic_image/?page_size=5`;

    await UserApiClient.get(pageUrl, {}, false).then(async (res) => {
      if (res.success) {
        const newImages = res.data.results;

        if (!newImages || newImages.length === 0) {
          Swal.fire({
            title: "No more images to load!",
            icon: "info",
            confirmButtonColor: "#990013",
            timer: 3000,
          });
          setShowMoreBtn(false);
          return;
        }

        setImageData((prevData) => {
          const newData = [...prevData];
          newImages.forEach((newImage) => {
            const duplicate = newData.some(
              (image) => image.generation_id === newImage.generation_id
            );
            if (!duplicate) {
              newData.push(newImage);
            }
          });
          return newData;
        });

        setPageLinks({ next: res.data.next, prev: res.data.previous });
      } else {
        Swal.fire({
          title: "Our Servers are busy!",
          text: "Please wait a moment and try again.",
          icon: "warning",
          timer: 3000,
        });
      }
    });
  };
  useEffect(() => {
    fetchImageData();
  }, []);
  
  // const handleImageView = (imgId) => {
  //   navigate(`/dashboard/ImageView/${imgId}`);
  // };

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>{error}</p>;

  // Ensure imageData is an array before calling .reverse()
  const imagesToShow = Array.isArray(imageData)
    ? imageData.reverse().slice(0, 15)
    : []; // Get the latest 15 images
  const { id } = useParams();
  const selectedDescription = promptData.find(
    (item) => item.name === selectedName
  )?.description;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dataString = queryParams.get("data");

  const dataArray = dataString ? dataString.split(",") : [];

  // let url = id.trim();

  const fetchMoreImageData = async (itemId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}api/v1/get_generated_image/${itemId}/`
      );
      const updatedPromptData = promptDataWithoutdis.map((item) => {
        setDrive(response.data.generation_prompt);
        if (response.data.user_name !== null) {
          setGeneratedUser(response.data);
        }
        setPutResponsedata(response.data);
        if (item.name === "PROMPT") {
          return { ...item, description: response.data.generation_prompt };
          //   } else if (item.name === "EXCLUDE") {
          //     return { ...item, description: response.data.variations };
          //   } else if (item.name === "FACE PROMPT") {
          //     return { ...item, description: response.data.face_prompt };
        } else if (item.name === "EXTENSIONS") {
          return { ...item, description: response.data.extensions };
        } else if (item.name === "OTHER DETAILS") {
          return { ...item, description: response.data.other };
        }
        return item;
      });
      setPromptData(updatedPromptData);
      setLoading(false);
      if (
        response.data.image_file_name &&
        response.data.image_file_name.length > 0
      ) {
        setGeneratedImage(response.data.image_file_name);
        setLoading2(false);
      } else {
        generateImage(response.data);
      }
    } catch (error) {
      Swal.fire(
        error.response.data.msg ||
        'Our Servers are busy!',
        'Please wait a moment and try again.',
        'warning',
        3000
      );
      console.log(error, "error");
      setLoading(false);
    }
  };

  useEffect(() => {
    setGeneratedImage(dataArray);

    const fetchImageData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}api/v1/get_generated_image/${id}/`
        );
        const updatedPromptData = promptDataWithoutdis.map((item) => {
          setDrive(response.data.generation_prompt);
          if (response.data.user_name !== null) {
            setGeneratedUser(response.data);
          }
          setPutResponsedata(response.data);
          if (item.name === "PROMPT") {
            return { ...item, description: response.data.generation_prompt };
            //   } else if (item.name === "EXCLUDE") {
            //     return { ...item, description: response.data.variations };
            //   } else if (item.name === "FACE PROMPT") {
            //     return { ...item, description: response.data.face_prompt };
          } else if (item.name === "EXTENSIONS") {
            return { ...item, description: response.data.extensions };
          } else if (item.name === "OTHER DETAILS") {
            return { ...item, description: response.data.other };
          }
          return item;
        });
        setPromptData(updatedPromptData);
        setLoading(false);
        if (
          response.data.image_file_name &&
          response.data.image_file_name.length > 0
        ) {
          setGeneratedImage(response.data.image_file_name);
          setLoading2(false);
        } else {
          generateImage(response.data);
        }
      } catch (error) {
        Swal.fire(
          error.response.data.msg ||
          'Our Servers are busy!',
          'Please wait a moment and try again.',
          'warning',
          3000
        );
        console.log(error, "error");
        setLoading(false);
      }
    };

    // Only call fetchImageData once when the component mounts
    if (id) {
      fetchImageData();
    }

    // Scroll to top when the page loads
    window.scrollTo(0, 0);
  }, [id]);

  const generateImage = async (data) => {
    setLoading2(true); // Show loader for generating image
    try {
      const payload = {
        Aimodel: data.Aimodel ?? "1", // Default value if Aimodel is null
        Aititle: data.Aititle,
        generation_prompt: data.generation_prompt,
        // image_file_name: data.image_file_name,
        steps: data.steps || "medium",
        make_ai_listen: data.make_ai_listen || "creative",
        magic: data.magic || "none",
        aspect_ratio: data.aspect_ratio || "fourth",
        variations: data.variations || "",
        upscale: data.upscale || false,
        make_private: data.make_private || false,
        user_name: data.user_name || "Anonymous",
        generation_id: data.generation_id || `ER_GenImg_${Date.now()}`,
        generation_slug: data.generation_slug || `ER_${Date.now()}_slug`,
        extensions: data.extensions || "",
        face_prompt: data.face_prompt || "",
        other: data.other || "",
        nationality: data.nationality,
        uuid: data.uuid,
      };
      const authToken = localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}api/v1/LoadImage/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      // Handle the response
      setGeneratedImage(response.data.images);
      setLoading2(false);
    } catch (error) {
      console.error("Error generating image:", error.response.data);
      setLoading2(false);
      Swal.fire(
        error.response.data.msg ||
        'Our Servers are busy!',
        'Please wait a moment and try again.',
        'warning',
        3000
      );
    }
  };

  const downloadImage = async (GeneratedImageCurrentIndex) => {
    if (GeneratedImage || Array.isArray(GeneratedImage)) {
      setLoading(true); // Start loading
      setError(null); // Reset any previous error
      try {
        const response = await fetch(GeneratedImageCurrentIndex, {
          cache: "no-cache",
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Show a preview of the image in an <img loading ='lazy'> element (optional)
        const imagePreview = document.createElement("img");
        imagePreview.src = url;
        imagePreview.alt = "Generated Image Preview";
        document.body.appendChild(imagePreview);

        // Automatically download the image
        const link = document.createElement("a");
        link.href = url;
        link.download = `generated-image_${Date.now()}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading image:", error);
        Swal.fire(
          'Our Servers are busy!',
          'Please wait a moment and try again.',
          'warning',
          3000
        );
      } finally {
        setLoading(false); // Stop loading
      }
    } else if (GeneratedImage) {
      setLoading(true); // Start loading
      setError(null); // Reset any previous error
      try {
        const response = await fetch(GeneratedImageCurrentIndex);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Show a preview of the image in an <img loading ='lazy'> element (optional)
        const imagePreview = document.createElement("img");
        imagePreview.src = url;
        imagePreview.alt = "Generated Image Preview";
        document.body.appendChild(imagePreview);

        // Automatically download the image
        const link = document.createElement("a");
        link.href = url;
        link.download = `generated-image_${Date.now()}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading image:", error);
        Swal.fire(
          'Our Servers are busy!',
          'Please wait a moment and try again.',
          'warning',
          3000
        );
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      setError("No image available for download");
    }
  };

  const toggleModal = () => {
    setOpen(!open);
  };
  const handleImageClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === "popup-overlay") {
      setIsPopupOpen(false);
    }
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % GeneratedImage.length);
  };

  const prevImage = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + GeneratedImage.length) % GeneratedImage.length
    );
  };

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index); // Update the currentIndex to the clicked thumbnail's index
  };

  const [copy, setCopy] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const copyToClipboard = () => {
    if (ref.current) {
      navigator.clipboard
        .writeText(ref.current.innerText)
        .then(() => {
          setCopy(true);
          setTimeout(() => {
            setCopy(false);
          }, 3000);
        })
        .catch((err) => {
          console.error("Error copying text: ", err);
          setCopy(false);
        });
    }
  };
  // const handleCopyUrl = () => {
  //   const urlToCopy = window.location.href; // Current URL
  //   navigator.clipboard
  //     .writeText(urlToCopy)
  //     .then(() => {
  //       setCopySuccess("URL copied to clipboard!");
  //       setTimeout(() => {
  //         setCopySuccess(false);
  //       }, 3000);
  //     })
  //     .catch(() => {
  //       setCopySuccess("Failed to copy URL.");
  //     });
  // };
  const myRef = useRef(null);

  useLayoutEffect(() => {
    if (myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth" }); // Smooth scrolling
    }
  }, [drive, fetchMoreImageData]);


  const toggleLike = async (generation_id) => {
    const u_id = localStorage.getItem("u_id");
    const payload = { generation_id: generation_id, u_id: u_id };

    try {
      const authToken = localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}api/v1/public/image-like/`, payload, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.status === 200) {
        setLikesStatus(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <div
        className="2xl:px-11 lg:px-10 px-2 mt-5 pb-20 pt-10 text-white font-josefin-sans"
        ref={myRef}
      >
        <div className="flex flex-wrap justify-center gap-5">
          <div className="flex flex-col gap-3">
            {loading2 ? (
              <>
                <div className="rounded-lg bg-[#22222272] px-6 py-4 sm:px-12 sm:py-8 md:px-24 md:py-12 shadow-md ">
                  <div className=" flex items-center justify-center w-[350px] h-[400px] lg:w-[400px] xl:w-[350px] lg:h-[500px] xl:h-[550px] 2xl:w-[400px] 2xl:h-[600px] rounded-xl">
                    <CircularWithValueLabel isLoadingAIResponse={loading2} loadingText="loading" percentageShow={true} />
                  </div>
                </div>
                  <p className=" font-light text-center text-slate-800">
                    Please hold on, as this might take a few moments to process.
                  </p>
              </>
            ) : (
              <div className="rounded-lg bg-[#22222272] px-6 py-4 sm:px-12 sm:py-8 md:px-24 md:py-12 shadow-md ">
                <div className="image-container">
                  {Array.isArray(GeneratedImage) &&
                    GeneratedImage.length === 1 ? (
                    // If only 1 image is present, render a single image
                    <img
                      loading="lazy"
                      src={GeneratedImage[0]}
                      style={{ objectFit: "cover" }}
                      onClick={handleImageClick}
                      alt="Generated Img"
                      className="w-[350px] h-[400px] lg:w-[400px] xl:w-[350px] lg:h-[500px] xl:h-[550px] 2xl:w-[400px] 2xl:h-[600px] rounded-xl"
                    />
                  ) : Array.isArray(GeneratedImage) &&
                    GeneratedImage.length > 1 ? (
                    // If multiple images are present, render them in a slider
                    <div className="flex gap-2">
                      {/* Thumbnails Gallery */}

                      <div className="overflow-y-auto h-[335px] lg:h-[500px] xl:h-[590px] 2xl:h-[620px]" style={{ scrollBehavior: "smooth", scrollbarWidth: "none" }}>
                        {Array.isArray(GeneratedImage) &&
                          GeneratedImage.length > 1 &&
                          GeneratedImage.map((image, index) => (
                            <div
                              key={index}
                              onClick={() => handleThumbnailClick(index)}
                              className={`cursor-pointer rounded-lg w-[70px] h-[103px] 2xl:h-[115px] 3xl:h-[103px] m-2 ${currentIndex === index
                                ? "border-2 border-red-600"
                                : ""
                                }`}
                            >
                              <img
                                loading="lazy"
                                src={image}
                                alt={`Thumbnail ${index + 1}`}
                                className="w-auto h-auto object-cover rounded-lg"
                              />
                            </div>
                          ))}
                      </div>
                      <img
                        loading="lazy"
                        src={GeneratedImage[currentIndex]}
                        alt={`Generated Img ${currentIndex + 1}`}
                        className="w-[200px] h-[330px] lg:w-[400px] xl:w-[500px] lg:h-[500px] xl:h-[600px] 2xl:w-[570px] 2xl:h-[621px] rounded-xl"
                        style={{ objectFit: "cover" }}
                        onClick={handleImageClick}
                      />
                    </div>
                  ) : (
                    // Fallback for when no images are available
                    <div>No images available</div>
                  )}
                </div>
                {/* Options for images */}
                {Array.isArray(GeneratedImage) && GeneratedImage.length > 0 ? (
                  <div className="flex gap-2 items-center justify-end my-2">
                    {/* Download option */}

                    <div
                      className="flex items-center justify-center"
                      onClick={() =>
                        downloadImage(GeneratedImage[currentIndex])
                      }
                    >
                      {loading && <div className="loader"></div>}
                      <svg
                        className="h-8 w-8 text-gray-900 hover:text-red-600 duration-300"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        {"{"}" "{"}"}
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                        {"{"}" "{"}"}
                        <polyline points="7 10 12 15 17 10" />
                        {"{"}" "{"}"}
                        <line x1={12} y1={15} x2={12} y2={3} />
                      </svg>
                    </div>
                    <div
                      className="flex items-center justify-center"
                      onClick={() => toggleLike(putResponsedata.generation_id)}
                    // onClick={() => setLike(like === 0 ? 1 : 0)}
                    >
                      {likesstatus == "Like added" ? (
                        <i className="fa-solid fa-2xl fa-heart text-[#b30016d9]"></i>
                      ) : (
                        <i className="fa-regular fa-2xl fa-heart text-[#b30016d9]"></i>
                      )}

                    </div>
                    {/* <div
                      className="flex items-center justify-center"
                      onClick={handleCopyUrl}
                    >
                      {copySuccess && (
                        <p className="text-sm text-red-600">{copySuccess}</p>
                      )}
                      <svg
                        className="h-8 w-8 text-gray-900 hover:text-red-600 duration-300"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        {" "}
                        <circle cx={18} cy={5} r={3} />{" "}
                        <circle cx={6} cy={12} r={3} />{" "}
                        <circle cx={18} cy={19} r={3} />{" "}
                        <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" />{" "}
                        <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" />
                      </svg>
                    </div> */}
                  </div>
                ) : null}
                {error && <div className="error-message">{error}</div>}{" "}
              </div>
            )}
            {error && <div className="error-message">{error}</div>}{" "}
            {/* Display error */}
            {isOpen && (
              <div
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 pointer-events-auto"
                style={{ zIndex: "9999" }}
              >
                <div className="bg-white p-4 rounded-lg">
                  <img
                    loading="lazy"
                    src={GeneratedImage[currentIndex]}
                    alt="Enlarged"
                    className="w-full h-auto max-w-2xl max-h-full rounded-lg"
                  />
                  <button
                    className="mt-4 p-2 bg-blue-500 text-white rounded"
                    onClick={toggleModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-3">
            {/* <div className="flex justify-between">
              <div className="flex items-center gap-3">
                <VscHeartFilled color="#696969" className="lg:text-2xl" />
                <p className="w-7 lg:w-14 lg:text-2xl font-medium text-stone-500 text-left">
                  8
                </p>
                <div
                  className="flex"
                  style={{ cursor: "pointer" }}
                  onClick={downloadImage}
                >
                  {loading && <div className="loader"></div>}
                  <p className="lg:text-2xl font-medium hover:text-red-600">
                    <i className="fa-solid fa-download"></i> DOWNLOAD
                  </p>
                  {error && <div className="error-message">{error}</div>}{" "}
      
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <button
                  className="bg-red-800 [w-75%] text-white font-medium rounded-2xl py-2 px-4"
                  onClick={() => navigate("/dashboard")}
                >
                  Generate New
                </button>
              </div>
            </div> */}
            {/* <div className="md:w-[700px] lg:w-[900px] lg:h-[500px] xl:w-[800px] xl:h-[600px] 2xl:w-[966px] 2xl:h-[621px] border-2 border-zinc-600 bg-[#36432e4a] rounded-xl py-3 px-5"> */}
            {/* <div className="flex flex-wrap justify-start gap-2 md:gap-4 lg:gap-7 w-full text-[#42372e]">
                {promptData.map((item) => (
                  <p
                    key={item.name}
                    className={`cursor-pointer ${
                      selectedName === item.name ? "text-red-600 overline" : ""
                    } md:text-xl font-semibold`}
                    onClick={() => setSelectedName(item.name)}
                  >
                    {item.name}
                  </p>
                ))}
              </div>
              <div className="mt-5 text-[#352039] md:text-[23px] font-normal">
                {selectedDescription
                  ? selectedDescription
                  : "No description available"}
              </div> */}
            <div className="shadow-md flex items-center justify-between rounded-lg bg-[#22222272] p-2 ">
              <div className="flex items-center gap-3">
                <p className="lg:text-3xl font-medium text-black">
                  Created by{" "}
                </p>
                <p className="lg:text-2xl font-light text-yellow-500">
                  @{generateduser.user_name}
                </p>
              </div>
            </div>
            <div className="md:w-[565px] lg:w-[900px] xl:w-[800px] 2xl:w-[760px]  rounded-xl py-6 px-8 shadow-lg hover:shadow-2xl transition-shadow duration-300">
              <button
                className="bg-red-800 [w-75%] text-white font-medium rounded-2xl py-2 px-4 my-4"
                onClick={() => navigate("/dashboard")}
              >
                Generate New
              </button>

              <div className="p-4 space-y-4 shadow rounded-lg">
                {/* Title Section */}
                <div className="flex items-center justify-between">
                  <p className="text-2xl font-semibold text-gray-600">Prompt</p>
                  {/* Uncomment the button if needed */}
                  {/* <button
      onClick={copyToClipboard}
      className="flex items-center gap-x-2 rounded-md border border-gray-700 px-4 py-2 text-sm text-gray-300 hover:bg-gray-800"
    >
      <svg
        className="h-5 w-5 text-gray-300"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect x={9} y={9} width={13} height={13} rx={2} ry={2} />
        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
      </svg>
      <span>{copy ? "Copied" : "Copy Prompt"}</span>
    </button> */}
                </div>

                {/* Description Section */}
                <div className="text-md text-black" ref={ref}>
                  {selectedDescription
                    ? selectedDescription
                    : "No description available"}
                </div>

                {/* Details Section */}
                <div className="flex flex-wrap gap-4 rounded-lg p-4 ">
                  {/* Created With */}
                  <div className="w-full sm:w-1/3">
                    <p className="text-gray-600 font-semibold">Created With</p>
                    <p className="text-lg font-medium capitalize text-black">
                      {generateduser.Aititle ? generateduser.Aititle : "N/A"}
                    </p>
                  </div>

                  {/* Created At */}
                  <div className="w-full sm:w-1/3">
                    <p className="text-gray-600 font-semibold">Created At</p>
                    <p className="text-lg font-medium capitalize text-black">
                      {generateduser.created_at
                        ? generateduser.created_at.split("T")[0]
                        : "Date not available"}
                    </p>
                  </div>

                  {/* AI Type */}
                  <div className="w-full sm:w-1/3">
                    <p className="text-gray-600 font-semibold">AI Type</p>
                    <p className="text-lg font-medium capitalize text-black">
                      {generateduser.make_ai_listen
                        ? generateduser.make_ai_listen
                        : "N/A"}
                    </p>
                  </div>

                  {/* Nationality */}
                  <div className="w-full sm:w-1/3">
                    <p className="text-gray-600 font-semibold">Nationality</p>
                    <p className="text-lg font-medium capitalize text-black">
                      {generateduser.nationality
                        ? generateduser.nationality
                        : "N/A"}
                    </p>
                  </div>

                  {/* Upscale */}
                  <div className="w-full sm:w-1/3">
                    <p className="text-gray-600 font-semibold">Upscale</p>
                    <p className={`text-lg font-medium text-black`}>
                      {generateduser !== "UNKNOWN" &&
                        (generateduser?.upscale[currentIndex] == true
                          ? "Upscaled"
                          : "Not Upscaled")}
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="bg-gradient-to-r from-[#b59797] via-[#b59797] to-[#6d6d6d] p-6 rounded-xl shadow-md mb-6 transform transition-all hover:scale-105 hover:bg-gradient-to-r hover:from-[#6d6d6d] hover:via-[#b59797] hover:to-[#b59797] duration-300 fade-in"></div> */}

              {/* <div className="bg-gradient-to-r from-[#b59797] via-[#b59797] to-[#6d6d6d] p-6 rounded-xl shadow-md mb-6 transform transition-all hover:scale-105 hover:bg-gradient-to-r hover:from-[#6d6d6d] hover:via-[#b59797] hover:to-[#b59797] duration-300 fade-in">
                <div className="text-2xl ul font-semibold text-white mb-4">
                  Upscale Image
                </div>
                <div className="text-md text-zinc-300">
                  Section 2 description goes here. You can add dynamic content
                  based on your needs.
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="border-t border-zinc-600 my-9"></div>

        {/* <div className="flex items-center gap-6">
          <div className="bg-red-700 w-5 h-5 rounded-full"></div>
          <p className="text-lg lg:text-2xl font-light text-yellow-500">
            @{generateduser}
          </p>
          <p className="text-xl text-stone-600 font-medium">
            {generateduser.created_at
              ? generateduser.created_at.split("T")[0]
              : "Date not available"}
          </p>
        </div> */}

        {/* <p className='text-lg lg:text-2xl font-medium mt-1 lg:mt-6'>{drive}</p>
                <p className='text-3xl lg:text-4xl font-medium mt-4 lg:mt-[75px]'>Comment</p>
                <div className='bg-zinc-300 rounded-xl mt-1 lg:mt-2 px-5 py-3 text-black text-lg lg:text-2xl font-light w-full h-48'>
                    <input type="text" className='focus:outline-none bg-zinc-300 w-full' placeholder='Leave a comment' />
                </div>
                <button className='bg-red-800 w-40 lg:w-80 text-center py-2 rounded-full text-lg lg:text-2xl mt-3 lg:mt-4'>SUBMIT</button>

                <div className='border-t border-zinc-600 my-4 lg:my-9'></div> */}

        {/* <DiscoverImagesBox /> */}

        <p className="text-2xl lg:text-4xl font-medium mt-1 lg:mt-10 text-center">
          You Might Also Enjoy
        </p>
        <div className="p-5 md:p-10">
          <div className="columns-1 gap-1 lg:gap-1 sm:columns-2 lg:columns-3 xl:columns-4 [&>img:not(:first-child)]:mt-5 lg:[&>img:not(:first-child)]:mt-2">
            {imagesToShow.map((item, index) => {
              // Check if image_file_name exists and has at least one item
              if (!item.image_file_name || item.image_file_name.length === 0) {
                return null; // Skip rendering this item if no images are present
              }

              return item.image_file_name.map((imageUrl, imgIndex) => {
                const baseUrl = `${imageUrl}?auto=format&fit=crop`;
                return (
                  <div
                    className="relative group"
                    key={`${index}-${imgIndex}`}
                    onClick={() => {
                      if (loading2) {
                        Swal.fire({
                          title: "Warning",
                          icon: "warning",
                          text: "Your image generation is in process. Are you sure you want to skip generation and move to another image?",
                          showCancelButton: true,
                          confirmButtonText: "Yes",
                          cancelButtonText: "Stay on this page",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            fetchMoreImageData(item.generation_id);
                          }
                        });
                      } else {
                        fetchMoreImageData(item.generation_id);
                      }
                    }}
                  >
                    <img
                      loading="lazy"
                      className="h-auto w-full rounded-2xl object-cover object-center p-2 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg cursor-pointer"
                      src={`${baseUrl}`}
                      alt={`gallery-photo-${imgIndex}`}
                    />
                    <div className="absolute bottom-2 left-2 right-2 bg-black bg-opacity-70 opacity-100 transition-opacity duration-300 p-4 text-white text-center border-1 border-gray-400 rounded-[10px]">
                      <p className="text-sm sm:text-sm md:text-md">
                        {item.generation_prompt.slice(0, 50)}...
                      </p>
                      <h2 className="text-red-800 text-xl text-wrap">
                        @{item.user_name}
                      </h2>
                    </div>
                  </div>
                );
              });
            })}
          </div>
        </div>
        {showMoreBtn && pageLinks.next && (
              <div className="flex items-center w-full justify-center mt-10">
                <button
                  className="bg-red-800 [w-75%] text-white font-medium rounded-2xl py-2 px-4"
                  onClick={() => fetchImageData(pageLinks.next)}
                >
                  <i className="fa-solid fa-angles-down mr-1"></i> Load More
                </button>
              </div>
            )}
      </div>

      {isPopupOpen && (
        <div
          id="popup-overlay"
          className="fixed  inset-0 bg-black mt-15 bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto"
          onClick={handleOutsideClick}
        >
          <div className="relative bg-gray-300 max-w-[95%] md:max-w-[65%] lg:max-w-[45%] rounded-lg border-2 border-[#7d2424b3]">
            {/* {/ Close button /} */}
            <button
              className="absolute w-10 top-1 right-2 bg-red-600 text-white rounded-full"
              onClick={handleClosePopup}
            >
              X
            </button>

            {/* {/ Full image inside the popup /} */}
            <img
              // src={girlData && girlData.Image ? girlData.Image : ""}
              src={GeneratedImage[currentIndex]}
              alt="Full View"
              className="w-full h-auto rounded-lg"
            />
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default ImageView;
