import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../frontend/components/Footer";
import UserApiClient from "../../utilities/apiClients";
import Swal from "sweetalert2";

const DiscoverImagesBox = () => {
  const [imageData, setImageData] = useState([]); // Ensure this is initialized as an empty array
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [showMoreBtn, setShowMoreBtn] = useState(true);
  const [pageLinks, setPageLinks] = useState({ "next": null, "prev": null });



  const fetchImageData = async (pageLink) => {
    const pageUrl = typeof pageLink === 'string' ? pageLink : `api/v1/get_allPublic_image/?page_size=5`;

    await UserApiClient.get(pageUrl, {}, false).then(async (res) => {
      if (res.success) {
        const newImages = res.data.results;

        if (!newImages || newImages.length === 0) {
          Swal.fire({
            title: "No more images to load!",
            icon: "info",
            confirmButtonColor: "#990013",
            timer: 3000,
          });
          setShowMoreBtn(false);
          return;
        }

        setImageData((prevData) => {
          const newData = [...prevData];
          newImages.forEach((newImage) => {
            const duplicate = newData.some(
              (image) => image.generation_id === newImage.generation_id
            );
            if (!duplicate) {
              newData.push(newImage);
            }
          });
          return newData;
        });

        setPageLinks({ next: res.data.next, prev: res.data.previous });
      } else {
        Swal.fire({
          title: "Our Servers are busy!",
          text: "Please wait a moment and try again.",
          icon: "warning",
          timer: 3000,
        });
      }
    });
  };
  useEffect(() => {
    fetchImageData();
  }, []);

  const handleImageView = (imgId) => {
    navigate(`/dashboard/ImageView/${imgId}`);
  };
  const myRef = useRef(null);

  useLayoutEffect(() => {
    if (myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth" }); // Smooth scrolling
    }
  }, [imageData]);


  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  // Ensure imageData is an array before calling .reverse()
  const imagesToShow = Array.isArray(imageData)
    ? imageData
    : []; // Get the latest 15 images

  return (
    <>

      <p className="text-2xl lg:text-4xl font-medium mt-1 lg:mt-10 text-center">
        You Might Also Enjoy
      </p>
      <div className="p-5 md:p-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {imagesToShow.map((item, index) => {
            // Check if image_file_name exists and has at least one item
            if (!item.image_file_name || item.image_file_name.length === 0) {
              return null; // Skip rendering this item if no images are present
            }

            return item.image_file_name.map((imageUrl, imgIndex) => {
              const baseUrl = `${imageUrl}?auto=format&fit=crop`;
              return (
                <div
                  className="relative group"
                  key={`${index}-${imgIndex}`}
                  onClick={() => handleImageView(item.generation_id)}
                >
                  <img loading='lazy'
                    className="h-auto w-full rounded-2xl object-cover object-center p-2 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg cursor-pointer"
                    src={`${baseUrl}`}
                    alt={`gallery-photo-${imgIndex}`}
                  />
                  <div className="absolute bottom-2 left-2 right-2 bg-black bg-opacity-70 opacity-100 transition-opacity duration-300 p-4 text-white text-center border-1 border-gray-400 rounded-[10px]">
                    <p className="text-sm sm:text-sm md:text-md">
                      {item.generation_prompt.slice(0, 50)}...
                    </p>
                    <h2 className="text-red-800 text-xl text-wrap">
                      @{item.user_name}
                    </h2>
                  </div>
                </div>
              );
            });
          })}
        </div>
      </div>
      {showMoreBtn && pageLinks.next && (
        <div className="flex items-center w-full justify-center mt-10">
          <button
            className="bg-red-800 [w-75%] text-white font-medium rounded-2xl py-2 px-4"
            onClick={() => fetchImageData(pageLinks.next)}
          >
            <i className="fa-solid fa-angles-down mr-1"></i> Load More
          </button>
        </div>
      )}
      {/* Optional: Footer component can be added here if required */}
      <Footer />
    </>
  );
};

export default DiscoverImagesBox;
