import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserApiClient from '../../utilities/apiClients';
import PagenameSearchBox from '../components/PagenameSearchBox';

const Affiliated = () => {
    const navigate = useNavigate();
    const [affiliates, setAffiliates] = useState([]);
    const [activeTab, setActiveTab] = useState(1);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [referralDetails, setReferralDetails] = useState({});
    const [searchQuery, setSearchQuery] = useState(null);
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(null);
    const [pageSize, setPageSize] = useState(null);

    const handleTabClick = async (tabNumber, change = false) => {
        setSearchQuery(null);
        if (activeTab !== tabNumber || change) {
            setActiveTab(tabNumber);
        }
    };

    const fetchAffiliates = async () => {
        await UserApiClient.get(
            `api/v1/affiliate/request/${"?search=" + (searchQuery !== null ? searchQuery : '')}${activeTab === "3" ? "&reject_affiliate=1" : activeTab === "2" ? "&req_affiliate=0" : '&req_affiliate=1'}${pageSize !== null ? '&page_size=' + pageSize : ''}`,
            {}, true).then(async res => {
                if (res.success) {
                    setAffiliates(res.data);
                }
            })
    };

    useEffect(() => {
        fetchAffiliates();
    }, [activeTab]);

    const handleApprovedReject = async (e, status) => {
        if (referralDetails.id) {
            if (status === true) {
                const data = {
                    "ref_commission": referralDetails.ref_commission,
                    "ref_commission_raise": referralDetails.ref_commission_raise,
                    "token_commission": referralDetails.token_commission,
                    "token_commission_raise": referralDetails.token_commission_raise
                }
                await UserApiClient.put(`api/v1/affiliate/request/${referralDetails.id}/`, data, true).then(async res => {
                    if (res.success) {
                        await handleTabClick(1, true);
                    }
                })
            } else {
                await UserApiClient.delete(`api/v1/affiliate/request/${referralDetails.id}/`, {}, true).then(async res => {
                    if (res.success) {
                        await handleTabClick(1, true);
                    }
                })
            }
            closePopup();
        }
    };

    const openPopup = (data) => {
        setReferralDetails(data); // Set referral details
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setReferralDetails({});
    };

    useEffect(() => {
        if (searchQuery !== null) {
            const timer = setTimeout(() => {
                setDebouncedSearchQuery(searchQuery);
            }, 700);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [searchQuery]);

    useEffect(() => {
        if (debouncedSearchQuery !== null) {
            fetchAffiliates()
        }
    }, [debouncedSearchQuery]);

    useEffect(() => {
        if (pageSize !== null) {
            fetchAffiliates()
        }
    }, [pageSize]);

    return (
        <>
            <div className='h-full bg-white px-7 pb-20 font-josefin-sans'>
                {/* Temporary removal of PagenameSearchBox */}
                <PagenameSearchBox
                    title="User List"
                    placename="Search by email"
                    inputValue={searchQuery}
                    onSearchChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                />

                <div className="flex justify-between w-full">
                    <div className="flex space-x-4">
                        <button
                            onClick={() => handleTabClick(1)}
                            className={`px-4 py-2 m-2  font-semibold text-lg rounded-lg transition-colors duration-300 ${activeTab === 1 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'
                                }`}
                        >
                            Requested
                        </button>
                        <button
                            onClick={() => handleTabClick(2)}
                            className={`px-4 py-2 m-2  font-semibold text-lg rounded-lg transition-colors duration-300 ${activeTab === 2 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'
                                }`}
                        >
                            Approved
                        </button>
                        <button
                            onClick={() => handleTabClick(3)}
                            className={`px-4 py-2 m-2  font-semibold text-lg rounded-lg transition-colors duration-300 ${activeTab === 3 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'
                                }`}
                        >
                            Rejected
                        </button>
                    </div>

                    <div className="space-x-4 mr-4">
                        <select className="py-1.5 px-3 focus:outline-none text-lg rounded" onChange={(e) => setPageSize(e.target.value)}>
                            <option value='20'>20</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                            <option value='500'>500</option>
                            <option value='1000'>1000</option>
                        </select>
                    </div>
                </div>
                <div className="rounded-lg overflow-x-auto sm:rounded-lg">
                    <table className="w-full text-left text-gray-500 border border-stone-300">
                        <thead className="text-xl text-black bg-stone-300">
                            <tr>
                                <th scope="col" className="px-6 py-3">ID</th>
                                <th scope="col" className="px-6 py-3">Email</th>
                                <th scope="col" className="px-6 py-3">referred by</th>
                                <th scope="col" className="px-6 py-3">Affiliate ID</th>
                                <th scope="col" className="px-6 py-3 text-center">Joined At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {affiliates.map((item) => (
                                <tr key={item.id} className="bg-white text-black text-xl font-normal border-b border-stone-300">
                                    <td className="px-6 py-4 whitespace-nowrap">{item.id}</td>
                                    <td className="px-6 py-4 whitespace-nowrap cursor-pointer" onClick={() => navigate(`/admin/userdetail/${item.id}`)}>{item.email}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{item.ref_by || 'Not referred'}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{item.is_affiliated ? item.affiliate_id :
                                        activeTab === 1 ? (
                                            <button className="rounded-lg text-md bg-red-800 hover:bg-red-700 text-white py-2 px-4" onClick={(e) => openPopup(item)}>Approve</button>
                                        ) : 'Rejected'}</td>
                                    <td className="px-6 py-4 flex gap-5 justify-center items-center">{item.date_joined}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {/* Temporary removal of ConfirmationPopup */}
            </div>
            {/* Modal */}
            {isPopupOpen && referralDetails && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-lg w-2/3 md:w-1/3">
                        <div className='flex justify-between relative'>
                            <h3 className="text-xl font-semibold mb-4">Referral Details</h3>
                            <button className="bg-red-500 text-white h-7 w-7 rounded-full absolute right-[-20px] bottom-[35px] " onClick={closePopup}> x </button>
                        </div>

                        {/* Referral Details */}
                        <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                            <strong className='mb-1'>Website/Social Profile:</strong> {referralDetails.ref_website}
                        </p>
                        <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                            <strong className='mb-1'>Promotion Plan:</strong> {referralDetails.promotion_plan}
                        </p>
                        {/* <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                            <strong className='mb-1'>Audience Details:</strong> {referralDetails.audience_details}
                        </p>
                        <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                            <strong className='mb-1'>How Did You Hear About Us:</strong> {referralDetails.referral_source}
                        </p> */}

                        {/* Commission Section */}
                        <div className="mt-6 border rounded-lg p-2">
                            <div className='flex justify-between'>
                                <h4 className="text-lg font-semibold mb-4 border-b-2">Commission</h4>
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                {/* Commission Inputs */}
                                <div className="flex flex-col">
                                    <label className="mb-2 font-medium" htmlFor="commission-rate">Subscription Commission (%)</label>
                                    <input
                                        id="commission-rate"
                                        type="text"
                                        defaultValue={referralDetails.ref_commission}
                                        onChange={(e) => { referralDetails.ref_commission = e.target.value; }}
                                        className="p-2 border rounded-lg"
                                        placeholder="Enter commission rate"
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label className="mb-2 font-medium" htmlFor="commission-type">Token Commission (%)</label>
                                    <input
                                        id="payment-method"
                                        type="text"
                                        defaultValue={referralDetails.token_commission}
                                        onChange={(e) => { referralDetails.token_commission = e.target.value; }}
                                        className="p-2 border rounded-lg"
                                        placeholder="Enter payment method"
                                    />
                                </div>
                                <div className="flex flex-col">
                                    {/* <label className="mb-2 font-medium" htmlFor="payment-method">Payment Method</label> */}
                                    <input
                                        id="commission-type"
                                        type="text"
                                        defaultValue={referralDetails.ref_commission_raise}
                                        onChange={(e) => { referralDetails.ref_commission_raise = e.target.value; }}
                                        className="p-2 border rounded-lg"
                                        placeholder="Enter commission type"
                                    />
                                </div>
                                <div className="flex flex-col">
                                    {/* <label className="mb-2 font-medium" htmlFor="commission-duration">Commission Duration</label> */}
                                    <input
                                        id="commission-duration"
                                        type="text"
                                        defaultValue={referralDetails.token_commission_raise}
                                        onChange={(e) => { referralDetails.token_commission_raise = e.target.value; }}
                                        className="p-2 border rounded-lg"
                                        placeholder="Enter commission duration"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Action Buttons */}
                        <div className="mt-4 flex flex-col md:flex-row flex-wrap gap-1 justify-between items-center">

                            <button
                                className="bg-yellow-600 text-white  w-[48%] py-2 px-4 rounded"
                                onClick={(e) => handleApprovedReject(e, false)}
                            >
                                Reject
                            </button>
                            <button
                                className="bg-green-600 text-white  w-[48%] py-2 px-4 rounded"
                                onClick={(e) => handleApprovedReject(e, true)}
                            >
                                Approve
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Affiliated;
