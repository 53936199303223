const AdminPagination = ({ pageLinks, handlePrevNextPage }) => {
    return (
        <div className="mt-4 flex justify-end gap-2">
            <button disabled={!pageLinks.prev} onClick={() => handlePrevNextPage('prev')} className={`px-4 py-1 m-2 font-semibold text-lg rounded-lg transition-colors duration-300 ${pageLinks.prev ? "bg-[#000] text-white" : 'text-gray-600 cursor-not-allowed'}`} >
                {"< Prev"}
            </button>
            <button disabled={!pageLinks.next} onClick={() => handlePrevNextPage('next')} className={`px-4 py-1 m-2 font-semibold text-lg rounded-lg transition-colors duration-300 ${pageLinks.next ? "bg-[#000] text-white" : 'text-gray-600 cursor-not-allowed'}`} >
                {"Next >"}
            </button>
        </div>
    )
}

export default AdminPagination;