import React, { useEffect, useState } from 'react';

import { Rectangle47 } from '../../assets';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import GoogleSignInBtn from './GoogleLoginBtn';

import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../redux/actions';
import UserApiClient from '../../utilities/apiClients';

const Login = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [validate, setValidate] = useState({
        emailError: '',
        passwordError: ''
    })
    const validateEmail = (email) => {
        if (!email) {
            setValidate(prev => ({ ...prev, emailError: 'Email address is required.' }));
            return false
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setValidate(prev => ({ ...prev, emailError: 'Please enter a valid email address.' }));
            return false
        } else {
            setValidate(prev => ({ ...prev, emailError: '' }));
            return true
        }
    };

    const validatePassword = (password) => {
        if (!password) {
            setValidate(prev => ({ ...prev, passwordError: 'Password is required.' }));
            return false
        } else if (password.length < 8) {
            setValidate(prev => ({ ...prev, passwordError: 'Password must be at least 8 characters.' }));
            return false
        } else {
            setValidate(prev => ({ ...prev, passwordError: '' }));
            return true
        }
    };




    useEffect(()=>{
        const orignalBackground = document.body.style.background
        document.body.style.background='none'
        return ()=>{
            document.body.style.background=orignalBackground
        }
    },[])

    useEffect(() => {
        const data = localStorage.getItem("login");
        if (data === 'loginKey') {
            setIsLoggedIn(true);
            navigate('/dashboard/girls'); // Automatically redirect to dashboard
        }
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        if (validateEmail(email)
            && validatePassword(password)) {
        await UserApiClient.post('api/v1/login/', {
            'email': email,
            "password": password,
        }, false).then(async (res) => {
            if (res.success) {
                await Swal.fire({
                    title: "Welcome, Logged In Successfully.",
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(loginSuccess(res.data.user));
                localStorage.setItem("token", res.data.access); // Store JWT token
                localStorage.setItem("refreshToken", res.data.refresh); // Store refresh token if provided
                localStorage.setItem("login", res.data.Logger);
                localStorage.setItem("u_id", res.data.u_id);
                navigate('/');
            }
        })
        }
    };

    // const handleLogout = () => {
    //     localStorage.removeItem("token");
    //     localStorage.removeItem("refreshToken");
    //     localStorage.removeItem("login");
    //     setIsLoggedIn(false);
    // };

    return (
        <div>
            <ToastContainer />
            <div>
                <div className='bg-black w-full flex justify-between font-josefin-sans h-full'>
                    <div className='relative hidden md:block md:w-[45%]'>
                        <img loading ='lazy'
                            src={Rectangle47}
                            alt='Rectangle47'
                            className='w-full object-cover h-[100vh]'
                        />
                    </div>
                    <div className='relative w-full bg-white md:w-[55%] flex justify-center items-center h-[100vh]'>
                        <button
                            type="button"
                            className="btn absolute top-3 left-3 text-lg text-[#ff0022] cursor-pointer flex items-center"
                            onClick={() => navigate('/')}
                        >
                            <i className="fa-solid fa-circle-arrow-left mr-2"></i>Back
                        </button>
                        <div className="text-white flex flex-col items-center">
                            <img loading ='lazy' src="/assets/img/logowh.png" alt="Icon" className="w-[20rem] rounded-xl mb-5" />
                            <h4 className="font-sans text-black text-[20px] md:text-[22px] lg:text-[26px] xl:text-[33px] font-semibold leading-snug tracking-normal text-blue-gray-900">
                                LOGIN TO EROMANTIC<span className='text-red-700'>.AI</span>
                            </h4>

                            <form className="mt-5 lg:mt-8 mb-2 w-[278px] md:w-[330px] lg:w-[400px] xl:w-[500px]" onSubmit={handleLogin}>
                                <div className="flex flex-col gap-6 mb-1">
                                    <div className="relative h-11 md:h-12 lg:h-14 w-full min-w-[278px] md:min-w-[300px]">
                                        <input
                                            placeholder="Enter your email address"
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="peer text-black h-full w-full rounded-md border-2 border-blue-gray-200 bg-transparent px-5 py-3 font-sans text-lg font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-2 focus:border-gray-500 focus:outline-0"
                                   
                                        />
                                           {validate.emailError && <p className="text-red-500 text-sm mt-1">{validate.emailError}</p>}

                                    </div>
                                    <div className="relative h-11 md:h-12 lg:h-14 w-full min-w-[278px] md:min-w-[300px] mb-4">
                                        <input
                                            type="password"
                                            placeholder="Enter your password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="peer text-black h-full w-full rounded-md border-2 border-blue-gray-200 bg-transparent px-5 py-3 font-sans text-lg font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-2 focus:border-gray-500 focus:outline-0"
                                      
                                        />
                                         {validate.passwordError && <p className="text-red-500 text-sm my-1">{validate.passwordError}</p>}
                                    </div>
                                </div>
                                {error && <p className="text-red-500 text-sm">{error}</p>}
                                <NavLink to="/ResetPassword" className="text-base text-black border-b-2 border-gray-500 w-56 cursor-pointer" >Forgot your password? Reset it</NavLink>
                                <button
                                    className="mt-6 block w-full select-none rounded-lg bg-[#FF0013] py-2.5 lg:py-3 px-6 text-center align-middle font-sans text-[20px] lg:text-[23px] xl:text-[25px] font-semibold text-white shadow-md transition-all hover:shadow-lg focus:opacity-85 active:opacity-85"
                                    type="submit">
                                    Log In
                                </button>
                                <div className='flex items-center justify-center text-center my-4 gap-3'>
                                    <hr className='border-1 w-60 ' />
                                    <p className=" text-[20px] lg:text-[22px] font-light text-black">OR</p>
                                    <hr className='border-1 w-60' />
                                </div>
                                {/* <NavLink to='/dashboard/girls'>
                                    <button
                                        className="w-full text-black select-none rounded-lg bg-transparent py-2.5 lg:py-3 text-center align-middle font-sans text-[18px] lg:text-[20px] xl:text-[22px] font-normal shadow-md transition-all hover:shadow-lg focus:opacity-85 active:opacity-85 flex items-center justify-center border-2 border-[#6b6b6b] gap-4"
                                        type="button">
                                        <img loading ='lazy' src={Google} alt='Google' />
                                        Continue with Google
                                    </button>
                                </NavLink> */}
                                <GoogleSignInBtn />
                                <div>
                                    <div className='text-[17px] text-black lg:text-[19px] text-center mt-5'>Don't have an account yet? <br /> <Link to={'/join'}><span className='text-[#FF0013] cursor-pointer'>Sign Up</span></Link></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Login;
