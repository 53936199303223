import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PagenameSearchBox from '../components/PagenameSearchBox';

const Template = () => {
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [templates, setTemplates] = useState([
        { id: 1, title: 'Template 1', imageUrl: 'https://via.placeholder.com/200', content: 'Content for template 1' },
        { id: 2, title: 'Template 2', imageUrl: 'https://via.placeholder.com/200', content: 'Content for template 2' },
        { id: 3, title: 'Template 3', imageUrl: 'https://via.placeholder.com/200', content: 'Content for template 3' },
        // { id: 4, title: 'Template 4', imageUrl: 'https://via.placeholder.com/200', content: 'Content for template 4' },
        // { id: 5, title: 'Template 5', imageUrl: 'https://via.placeholder.com/200', content: 'Content for template 5' },

    ]);

    const navigate = useNavigate();  // Hook for navigation

    const handleAddTemplate = () => {
        // Placeholder for Add Template functionality
        alert('Add Template functionality');
    };

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filtered = templates.filter(template =>
            template.title.toLowerCase().includes(searchTerm)
        );
        setFilteredRequests(filtered);
    };

    const handleUseTemplate = (templateId) => {
        // Redirect to the corresponding template page
        if (templateId === 1) {
            navigate('/admin/template/one');
        } else if (templateId === 2) {
            navigate('/admin/template/two');
        } else if (templateId === 3) {
            navigate('/admin/template/three');
        }else if (templateId === 4) {
            navigate('/admin/template/four');
        }else if (templateId === 5) {
            navigate('/admin/template/five');
        }
    };

    return (
        <div className="h-full bg-white px-7 pb-20 font-josefin-sans">
            <PagenameSearchBox
                title="Template Requests"
                placename="Search Templates"
                // btnname="Add Template"
                btnfunction={handleAddTemplate}
                onSearchChange={handleSearchChange}
            />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                {templates.map((template) => (
                    <div key={template.id} className="border p-4 rounded-lg">
                        <h3 className="text-xl font-bold mb-4">{template.title}</h3>
                        <img loading ='lazy' src={template.imageUrl} alt={template.title} className="w-full h-auto mb-4" />
                        <button
                            onClick={() => handleUseTemplate(template.id)} // Redirect to the corresponding template page
                            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                        >
                            Use Template
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Template;
