import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useCountries } from 'use-react-countries';
import PhoneInput from "react-phone-input-2";
import { Logo  } from '../../assets';
import 'react-phone-input-2/lib/style.css'; // Phone input library CSS

function Payment() {
    const { countries } = useCountries();

    const [email, setEmail] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCvv] = useState('');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [country, setCountry] = useState('');
    const [address, setAddress] = useState('');
    const [errors, setErrors] = useState({});
    const [planConfig] = useState({
        plan: 'Premium AI Plan',
        amount: '99.99',
        no_of_days: 30,
    });
    const navigate = useNavigate();

    const validateCardNumber = (number) => /^\d{16}$/.test(number.replaceAll(' ', ''));

    const validateExpiryDate = (date) => {
        const regex = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
        if (!regex.test(date)) return false;
        const [month, year] = date.split('/').map(Number);
        const currentYear = new Date().getFullYear() % 100;
        const currentMonth = new Date().getMonth() + 1;
        return !(year < currentYear || (year === currentYear && month < currentMonth));
    };

    const validateCvv = (cvv) => /^\d{3,4}$/.test(cvv);

    const handleSubmit = (event) => {
        event.preventDefault();
        let validationErrors = {};

        if (!validateCardNumber(cardNumber)) validationErrors.cardNumber = 'Invalid card number';
        if (!validateExpiryDate(expiryDate)) validationErrors.expiryDate = 'Invalid expiry date';
        if (!validateCvv(cvv)) validationErrors.cvv = 'Invalid CVV';
        if (!email) validationErrors.email = 'Email is required';
        if (!name) validationErrors.name = 'Name is required';
        if (!country) validationErrors.country = 'Country is required';
        if (!address) validationErrors.address = 'Address is required';
        if (!phoneNumber) validationErrors.phoneNumber = 'Invalid phone number';

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        // Handle successful submission
        Swal.fire({
            icon: 'success',
            title: 'Payment successful!',
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false,
            timer: 3000,
        });

        setTimeout(() => navigate('/'), 2000);
    };

    return (
        <div className="min-h-screen flex items-center justify-center py-10">
            <div className="container mx-auto max-w-5xl p-5">
                <div className="grid grid-cols-1 md:grid-cols-2 bg-white/5 shadow-lg rounded-3xl overflow-hidden border border-gray-700 backdrop-blur-md">
                    {/* {/ Plan Details /} */}
                    <div className="bg-gradient-to-b from-[#d71313a6] to-[#563b3b] text-white p-10 flex flex-col justify-between">
                        <div>
                            <div className="flex items-center gap-3 mb-6">
                                <i
                                    className="fa-solid fa-arrow-left cursor-pointer text-xl text-gray-300 hover:text-white"
                                    onClick={() => navigate('/')}
                                ></i>
                                <img loading ='lazy'
                                    src={Logo}
                                    className="logo w-[30%] cursor-pointer"
                                    onClick={() => navigate('/')}
                                    alt="logo"
                                />
                            </div>
                            <h4 className="text-2xl font-extrabold tracking-wide">Subscribe to <span className="text-yellow-300">{planConfig.plan}</span></h4>
                            <div className="flex justify-between items-center mt-6">
                                <div className="text-5xl font-black">${planConfig.amount}</div>
                                <p className="text-lg text-gray-300 uppercase">{planConfig.no_of_days === 30 ? 'Monthly' : 'Yearly'}</p>
                            </div>
                            {/* <hr className="my-6 border-purple-700" /> */}
                        </div>
                        <div>
                            <div className="flex justify-between">
                                <p className="text-gray-400">Subtotal:</p>
                                <p>${planConfig.amount}</p>
                            </div>
                            <div className="flex justify-between mt-2">
                                <p className="text-gray-400">Tax:</p>
                                <p>$0.00</p>
                            </div>
                            <div className="flex justify-between mt-2">
                                <p className="text-gray-400">Total due today:</p>
                                <p className="font-bold text-white">${planConfig.amount}</p>
                            </div>
                        </div>
                    </div>

                    {/* {/ Payment Form /} */}
                    <div className="bg-gray-800 p-10 rounded-r-3xl sm:rounded-tr-none">
                        <h4 className="text-2xl font-bold text-white mb-6">Complete Your Payment</h4>
                        <form onSubmit={handleSubmit}>
                            {/* {/ Email /} */}
                            <div className="mb-6">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-400">Email Address</label>
                                <input
                                    type="email"
                                    id="email"
                                    className="w-full p-3 mt-2 bg-gray-900 text-white rounded-lg border border-gray-700 focus:ring-2 focus:ring-yellow-300 focus:outline-none"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {errors.email && <div className="text-yellow-400 text-sm mt-1">{errors.email}</div>}
                            </div>

                            {/* {/ Card Information /} */}
                            <div className="mb-6">
                                <label htmlFor="cardNumber" className="block text-sm font-medium text-gray-400">Card Number</label>
                                <input
                                    type="text"
                                    id="cardNumber"
                                    className="w-full p-3 mt-2 bg-gray-900 text-white rounded-lg border border-gray-700 focus:ring-2 focus:ring-yellow-300 focus:outline-none"
                                    placeholder="1234 1234 1234 1234"
                                    maxLength={19}
                                    value={cardNumber}
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/[^0-9]/g, '');
                                        const formattedValue = value.replace(/(\d{4})(?=\d)/g, '$1 ');
                                        setCardNumber(formattedValue);
                                    }}
                                />
                                {errors.cardNumber && <div className="text-yellow-400 text-sm mt-1">{errors.cardNumber}</div>}
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                {/* {/ Expiry Date /} */}
                                <div>
                                    <label htmlFor="expiryDate" className="block text-sm font-medium text-gray-400">Expiration Date</label>
                                    <input
                                        type="text"
                                        id="expiryDate"
                                        className="w-full p-3 mt-2 bg-gray-900 text-white rounded-lg border border-gray-700 focus:ring-2 focus:ring-yellow-300 focus:outline-none"
                                        placeholder="MM/YY"
                                        maxLength={5}
                                        value={expiryDate}
                                        onChange={(e) => setExpiryDate(e.target.value)}
                                    />
                                    {errors.expiryDate && <div className="text-yellow-400 text-sm mt-1">{errors.expiryDate}</div>}
                                </div>

                                {/* {/ CVV /} */}
                                <div>
                                    <label htmlFor="cvv" className="block text-sm font-medium text-gray-400">CVV</label>
                                    <input
                                        type="password"
                                        id="cvv"
                                        className="w-full p-3 mt-2 bg-gray-900 text-white rounded-lg border border-gray-700 focus:ring-2 focus:ring-yellow-300 focus:outline-none"
                                        placeholder="CVV"
                                        maxLength={4}
                                        value={cvv}
                                        onChange={(e) => setCvv(e.target.value)}
                                    />
                                    {errors.cvv && <div className="text-yellow-400 text-sm mt-1">{errors.cvv}</div>}
                                </div>
                            </div>

                            {/* {/ Name /} */}
                            <div className="mb-6">
                                <label htmlFor="name" className="block text-sm font-medium text-gray-400">Full Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    className="w-full p-3 mt-2 bg-gray-900 text-white rounded-lg border border-gray-700 focus:ring-2 focus:ring-yellow-300 focus:outline-none"
                                    placeholder="Enter your full name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                {errors.name && <div className="text-yellow-400 text-sm mt-1">{errors.name}</div>}
                            </div>

                            {/* {/ Phone Number /}  */}
                            <div className="mb-6">
                                <label htmlFor="phone" className="block text-sm font-medium text-gray-400">Phone Number</label>
                                <PhoneInput
                                    country={'us'}
                                    value={phoneNumber}
                                    onChange={(phone) => setPhoneNumber(phone)}
                                    inputClass="w-full p-3 bg-gray-900 text-white rounded-lg border border-gray-700 focus:ring-2 focus:ring-yellow-300 focus:outline-none"
                                />
                                {errors.phoneNumber && <div className="text-yellow-400 text-sm mt-1">{errors.phoneNumber}</div>}
                            </div>

                            {/* {/ Address /} */}
                            <div className="mb-6">
                                <label htmlFor="address" className="block text-sm font-medium text-gray-400">Address</label>
                                <input
                                    type="text"
                                    id="address"
                                    className="w-full p-3 mt-2 bg-gray-900 text-white rounded-lg border border-gray-700 focus:ring-2 focus:ring-yellow-300 focus:outline-none"
                                    placeholder="Enter your address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                                {errors.address && <div className="text-yellow-400 text-sm mt-1">{errors.address}</div>}
                            </div>

                            {/* {/ Country /} */}
                            <div className="mb-6">
                                <label htmlFor="country" className="block text-sm font-medium text-gray-400">Country</label>
                                <select
                                    id="country"
                                    className="w-full p-3 mt-2 bg-gray-900 text-white rounded-lg border border-gray-700 focus:ring-2 focus:ring-yellow-300 focus:outline-none"
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                >
                                    <option value="">Select your country</option>
                                    {countries?.map((country) => (
                                        <option key={country.code} value={country.code}>{country.name}</option>
                                    ))}
                                </select>
                                {errors.country && <div className="text-yellow-400 text-sm mt-1">{errors.country}</div>}
                            </div>

                            <button type="submit" className="bg-yellow-500 hover:bg-yellow-600 text-gray-900 py-3 px-6 rounded-lg w-full font-semibold transition-all duration-200">
                                Subscribe Now
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Payment;
