import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  // IoThumbsDownOutline,
  // IoThumbsUpOutline,
  IoVolumeHighSharp,
} from "react-icons/io5";
import axios from "axios";
import { PiUserSoundFill } from "react-icons/pi";
// import { FiAlignJustify } from "react-icons/fi";
// import { IoCloseSharp } from "react-icons/io5";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  // img1,
  // img3,
  // img4,
  // img7,
  // pg12,
  // pg22,
  // pg23,
  // pg24,
  // pg25,
  // pg26,
  // brown1,
  // AURORA1,
  // AURORA2,
  // ELORA1,
  // ELORA2,
  // LISA1,
  // LISA2,
  // MYRA1,
  // MYRA2,
  // NOLA1,
  // NOLA2,
  // RHEA1,
  // RHEA2,
  // YESENIA1,
  // YESENIA2,
  // ZEMIRA1,
  // ZEMIRA2,
  // ZULEIKA1,
  // ZULEIKA2,
  // GIANINA1,
  // GIANINA2,
  // whitebg,
  // Bodyback,
  // brest,
  // country,
  // abrafo1,
  // abrafo2,
  // akshat1,
  // akshat2,
  // brian1,
  // brian2,
  // charles1,
  // charles2,
  // daniel1,
  // daniel2,
  // joseph1,
  // joseph2,
  // joshua1,
  // joshua2,
  // matthew1,
  // matthew2,
  // thomas1,
  // thomas2,
  // william1,
  // william2,
  // ashley1,
  // ashley2,
  // avni1,
  // avni2,
  // emma1,
  // emma2,
  // grace1,
  // grace2,
  // hannah1,
  // hannah2,
  // izumi1,
  // izumi2,
  // nailah1,
  // nailah2,
  // olivia1,
  // olivia2,
  // sarah1,
  // sarah2,
  // sophia1,
  // sophia2,
  // CONNOR1,
  // CONNOR2,
  // JACK1,
  // JACK2,
  // LUKE1,
  // LUKE2,
  // ETHAN1,
  // ETHAN2,
  // OZAN1,
  // OZAN2,
  // JUNG1,
  // JUNG2,
  // HORO1,
  // HORO2,
  // ANDREW1,
  // ANDREW2,
  // JACOB1,
  // JACOB2,
  // HENRY1,
  // HENRY2,
  // bananaGift,
  // ropesGift,
  // arrowup,
  // beachGift,
  // lockGift,
  // dinnerGift,
  mountainGift,
  eyes,
  hair,
  occupation,
  personality,
  Bodyicon,
  Relationshipicon,
  Location,
  age,
  bookGift,
  alert,
  drinksGift,
  dildGift,
  flowersGift,
  necklaceGift,
  yachtGift,
  beachTrip,
  linjriGift,
  purseGift,
  blindfoldGift,
  dressGift,
  resortGift,
  playbutton,
  handcuffs,
  dinner,
} from "../../../assets";
import loaders from "../../../frontend/components/loaders";
import CircularWithValueLabel from './CircularWithValueLabel'; // Import the loader
import { BaseApiUrl } from "../../../utilities/enviroment";

const BASE_URL = BaseApiUrl + "assistant_api/"; 


const Aidreamchat = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [assistants, setAssistants] = useState([]);
  // const [isSpeaking, setIsSpeaking] = useState(false);
  const [message, setMessage] = useState("");
  const [assistant_id, setActiveAssistantId] = useState(null);
  const [characterid, setCharacterId] = useState(null);
  const [isLoadingAIResponse, setIsLoadingAIResponse] = useState(false);
  const [isLoadingImage,setIsLoadingImage] = useState(false);
  const [activeAssistantName, setActiveAssistantName] = useState("");
  const [activeAssistantdetails, setActiveAssistantdetails] = useState("");
  const [allimages, setAllimages] = useState([]);
  const [messagesHistory, setMessagesHistory] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const audioRefs = useRef([]);
  const [activeIndex, setActiveIndex] = useState(null); // Track which audio is being played
  // const [audio, setAudio] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  // const [voicemessage, setVoiceMessage] = useState("");
  const [userimg, setUserimg] = useState("");
  // const u_id = localStorage.getItem("u_id");
  const scrollbarRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("photo");
  const [speakingIndex, setSpeakingIndex] = useState(null);
  const [refreshChats, setRefreshChats] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [ismsgVisible, setIsmsgVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedgift, setSelectedgift] = useState(""); // Selected image URL
  const [selectedgiftmessage, setSelectedgiftMessage] = useState(""); // Selected image URL
  const [imageInput, setImageInput] = useState(""); // Input text
  const [errorisOpen, setErrorIsOpen] = useState(false);
  const errorCloseModal = () => {
    setErrorIsOpen(false); // Close the modal
    navigate('/'); // Navigate to the root ("/") route
  }
  const errorOpenModal = () => setErrorIsOpen(true);
  const modalRef = useRef(null);

  // Image URLs with titles

  // Setting image names in the imageData array
  const imageData = [
    // { url: bananaGift, title: "Banana Gift", token: "" },
    { url: bookGift, title: "Book", token: "1T", giftid: "1" },
    { url: drinksGift, title: "Drinks", token: "2T", giftid: "2" },
    { url: mountainGift, title: "Mountain", token: "4T", giftid: "3" },
    // { url: ropesGift, title: "Ropes Gift", token: "", giftid: "" },
    // { url: beachGift, title: "Beach Gift", token: "", giftid: "" },
    { url: dildGift, title: "Dildo", token: "2T", giftid: "4" },
    { url: flowersGift, title: "Flowers", token: "1T", giftid: "5" },
    { url: necklaceGift, title: "Necklace", token: "2T", giftid: "6" },
    { url: yachtGift, title: "Yacht", token: "8T", giftid: "7" },
    { url: beachTrip, title: "Beach", token: "5T", giftid: "8" },
    // { url: dinnerGift, title: "Dinner Gift", token: "2T", giftid: "" },
    { url: purseGift, title: "Purse", token: "3T", giftid: "9" },
    { url: blindfoldGift, title: "Blindfold", token: "1T", giftid: "10" },
    { url: dressGift, title: "Dress", token: "4T", giftid: "11" },
    // { url: lockGift, title: "Lock Gift", token: "2T", giftid: "" },
    { url: resortGift, title: "Resort", token: "7T", giftid: "12" },
    { url: handcuffs, title: "Handcuffs", token: "2T", giftid: "13" },
    { url: dinner, title: "Dinner", token: "2T", giftid: "14" },
    { url: linjriGift, title: "Lingerie", token: "3T", giftid: "15" },
  ];

  const togglegiftPopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handlegiftClick = (imageUrl, giftid, title) => {
    setSelectedgiftMessage(giftid);
    const giftname = title;
    setMessage(giftname + " GIFT");
    setSelectedgift(imageUrl);
    setImageInput(""); // Clear input when image is selected
  };

  const NewGenerate = async () => {
    loaders(true, "heart");
    setError(null);
    try {
      const authToken = localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}bot_fetch/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      // Handle 200 status code (successful response)
      if (response.status === 200) {
        setUserimg(response.data?.data[0]?.Image);
        setAssistants(response.data.data);
        setActiveAssistantId(response.data.data[0]?.assistant_id);
        setActiveAssistantName(response.data.data[0]?.name);
        setActiveAssistantdetails(response.data.data[0]);
        setCharacterId(response.data.data[0]?.thread_id);
      } else {
        setError("Unexpected response from the server.");
      }
    }catch (error) {
      console.error("Error fetching data:", error);
  
      // Handle different types of error (like network issues or invalid response)
      Swal.fire({
        title: "Oops!",
        text: error.response?.data?.detail || "You don't have any creation",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Create New",
        confirmButtonColor: "#990013",
        reverseButtons: true,
        backdrop: "#b1b1b1eb",
        willClose: () => {
          navigate("/CreateDreamGF"); // Redirect when the Swal modal is closed (clicked outside or confirmed)
        },
      });
  
      setError("Failed to fetch data. Please try again.");
    }  finally {
      loaders(false, "heart");
    }
  };

  useEffect(() => {
    const subscription =localStorage.getItem('subscription_type');
    if (subscription === "free") {
      errorOpenModal();
      return;
    }
    NewGenerate();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messagesHistory, messages]);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollbarRef.current) {
        scrollbarRef.current.scrollTop = scrollbarRef.current.scrollHeight;
      }
    }, 100);
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const toggleVisibilitymsg = () => {
    setIsmsgVisible(!ismsgVisible);
  };

  // useEffect(() => {
  //     const fetchOldMessages = async () => {
  //         if (characterid) {
  //             try {
  //                 const authToken = localStorage.getItem('token');
  //                 const response = await axios.get(`${BASE_URL}fetch_history/?thread_id=${characterid}`, {
  //                     headers: {
  //                         'Authorization': `Bearer ${authToken}`
  //                     }
  //                 });
  //                 if (response.status === 200 || response.status === 201) {
  //                     const oldMessages = response.data || [];
  //                     const formattedMessages = [];

  //                     oldMessages.forEach(msg => {
  //                         if (msg.user_message) {
  //                             formattedMessages.push({
  //                                 sender: 'user',
  //                                 text: msg.user_message || 'No message',
  //                                 time: new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
  //                             });
  //                         }
  //                         if (msg.ai_response) {
  //                             formattedMessages.push({
  //                                 sender: 'ai',
  //                                 text: msg.ai_response || 'No response',
  //                                 time: new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
  //                             });
  //                         }
  //                     });

  //                     formattedMessages.sort((a, b) => new Date(a.time) - new Date(b.time));

  //                     setMessagesHistory(formattedMessages);
  //                 } else {
  //                     console.error("Unexpected response status:", response.status);
  //                 }
  //             } catch (error) {
  //                 console.error('Error fetching message history:', error);
  //             }
  //         }
  //     };

  //     fetchOldMessages();
  // }, [characterid]);

  const getCurrentTime = () => {
    const now = new Date();
    return now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  // const BASE_URL = BaseApiUrl; 

  const handleClick = async (
    assistantId,
    assistantName,
    thread_id,
    assistantImage,
    assistent
  ) => {
    setActiveAssistantId(assistantId);
    setActiveAssistantName(assistantName);
    setActiveAssistantdetails(assistent);
    setAllimages(assistent);
    setUserimg(assistantImage || "/static/media/img1.9322f9a528d87450122a.png");
    const effectiveId = thread_id !== undefined ? thread_id : characterid;
    setCharacterId(effectiveId);
    setMessages([]);
    if(isMobile === true){
      setIsmsgVisible(false)

    }
  };

  const SendMsg = async () => {
    if (!assistant_id || !message.trim()) {
      console.warn("Assistant ID or message is not valid");
      return;
    }
    const userMessage = message;
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        text: message,
        sender: "user",
        time: getCurrentTime(),
        giftid: selectedgiftmessage,
        giftMsg: imageInput,
      },
    ]);
    setMessage("");
    const imageRequestKeywords = [
      "image", "picture","pic","selfie","send","see", "photo", "photograph", "visual", "screenshot", "drawing", 
      "artwork", "graphic", "illustration", "show me", "send me", "provide", 
      "share", "can I see", "want to see", "a picture of", "can you show me a photo of"
    ];
    const isImageRequest = imageRequestKeywords.some(keyword =>
      message.toLowerCase().includes(keyword)
    );
  
    if (isImageRequest) {
      setIsLoadingImage(true);
    } else {
      setIsLoadingAIResponse(true); // Otherwise, use AI response loading state
    }

    const payload = {
      assistant_id,
      message: message,
      giftid: selectedgiftmessage,
    };
    try {
      const authToken = localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}bot_chat/`, payload, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        if (response.data.msg) {
          Swal.fire({
            title: "Token Executed",
            text: response.data.msg,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Add Tokens",
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: "okkkk!",
                text: "Your file has been deleted.",
                icon: "success",
              });
            }
          });
        }
        const aiResponseMessage = response.data.response_message;
        const ImgMsg = response.data.img_url;
        const ChatTime = response.data.chat_time;
        setMessages((prev) => [
          ...prev,
          {
            text: aiResponseMessage,
            chatimg: ImgMsg,
            msgtime: ChatTime,
            sender: "bot",
            time: getCurrentTime(),
          },
        ]);
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      if (isImageRequest) {
        setIsLoadingImage(false);
      } else {
        setIsLoadingAIResponse(false);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const trimmedMessage = message.trim();
      if (trimmedMessage) {
        SendMsg(trimmedMessage);
        setMessage(""); // Clear the input field after sending
      }
    }
  };

  const sendMessage = () => {
    const trimmedMessage = message.trim();
    if (trimmedMessage) {
      SendMsg(trimmedMessage);
      setMessage("");
      setSelectedgiftMessage("");
    }
  };
  const sendMessagegift = () => {
    const trimmedMessage = message.trim();
    if (trimmedMessage) {
      SendMsg(trimmedMessage);
      setSelectedgiftMessage("");
      setImageInput("");
      setSelectedgift("");
      setIsPopupOpen(false);
    }
  };

  useEffect(() => {
    const fetchOldMessages = async () => {
      if (characterid) {
        try {
          const authToken = localStorage.getItem("token");
          const response = await axios.get(
            `${BASE_URL}fetch_history/?thread_id=${characterid}`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
          if (response.status === 200 || response.status === 201) {
            setMessagesHistory(response.data);
          } else {
            console.error("Unexpected response status:", response.status);
          }
        } catch (error) {
          console.error("Error fetching message history:", error);
        }
      }
    };

    fetchOldMessages();
  }, [characterid, refreshChats]); //

  const startRecording = () => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();

    recognition.onstart = () => {
      setIsRecording(true);
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setMessage(transcript);
    };

    recognition.onerror = (event) => {
      console.error("Error occurred in recognition: " + event.error);
    };

    recognition.onend = () => {
      setIsRecording(false);
      const enterEvent = new KeyboardEvent("keydown", { key: "Enter" });
      handleKeyPress(enterEvent);
    };

    recognition.start();
  };

  const speak = (audioUrl) => {
    const audio = new Audio(audioUrl);
    audio.play();
  };

  const Voices = activeAssistantdetails.Breast_Size
    ? [
        {
          src: playbutton,
          title: "Natural",
          tab: "Voice",
          id: "LcfcDJNUP1GQjkzn1xUU",
        },
        {
          src: playbutton,
          title: "Formal",
          tab: "Voice",
          id: "mBoEKrpgGV9T9hW3aApV",
        },
        {
          src: playbutton,
          title: "Casual",
          tab: "Voice",
          id: "z9fAnlkpzviPz146aGWa",
        },
        {
          src: playbutton,
          title: "Emotional",
          tab: "Voice",
          id: "oWAxZDx7w5VEj9dCyTzz",
        },
        {
          src: playbutton,
          title: "Romantic",
          tab: "Voice",
          id: "pM7QPMisGYZVwLR9oQj6",
        },
        {
          src: playbutton,
          title: "Seductive",
          tab: "Voice",
          id: "HnuJZzPKTgcHaCYl1KRF",
        },
        {
          src: playbutton,
          title: "Warm",
          tab: "Voice",
          id: "thfYL0Elyru2qqTtNQsE",
        },
        {
          src: playbutton,
          title: "Loved",
          tab: "Voice",
          id: "pFZP5JQG7iQjIQuC4Bku",
        },
        {
          src: playbutton,
          title: "Charming",
          tab: "Voice",
          id: "EaBs7G1VibMrNAuz2Na7",
        },
        {
          src: playbutton,
          title: "Gentle",
          tab: "Voice",
          id: "eVItLK1UvXctxuaRV2Oq",
        },
      ]
    : [
        {
          src: playbutton,
          title: "Natural",
          tab: "Voice",
          id: "GBv7mTt0atIp3Br8iCZE",
        },
        {
          src: playbutton,
          title: "Formal",
          tab: "Voice",
          id: "ChTmpsMNHelv22TdNq0c",
        },
        {
          src: playbutton,
          title: "Casual",
          tab: "Voice",
          id: "tcO8jJ1XXzdQ4pzViV9c",
        },
        {
          src: playbutton,
          title: "Emotional",
          tab: "Voice",
          id: "xrNwYO0xeioXswMCcFNF",
        },
        {
          src: playbutton,
          title: "Romantic",
          tab: "Voice",
          id: "CwhRBWXzGAHq8TQ4Fs17",
        },
        {
          src: playbutton,
          title: "Seductive",
          tab: "Voice",
          id: "TX3LPaxmHKxFdv7VOQHJ",
        },
        {
          src: playbutton,
          title: "Warm",
          tab: "Voice",
          id: "mCQMfsqGDT6IDkEKR20a",
        },
        {
          src: playbutton,
          title: "Loved",
          tab: "Voice",
          id: "29vD33N1CtxCmqQRPOHJ",
        },
        {
          src: playbutton,
          title: "Charming",
          tab: "Voice",
          id: "iP95p4xoKVk53GoZ742B",
        },
        {
          src: playbutton,
          title: "Gentle",
          tab: "Voice",
          id: "fnTkWBxNUMwv63t10YIq",
        },
      ];

  const findVoiceIdByTitle = (title) => {
    const voice = Voices.find((voice) => voice.title === title);
    return voice ? voice.id : null;
  };
  const removeContentBetweenAsterisks = (text) => {
    return text.replace(/\*\*.*?\*\*/g, "");
  };

  const handlePlayAudiomsgs = async (msg, index) => {
    setSpeakingIndex((prevIndex) => (prevIndex === index ? null : index));
    const msgreplce = removeContentBetweenAsterisks(msg.ai_response || msg.text);
    const text = msgreplce.replaceAll("**", "");
    const voicetitle = activeAssistantdetails.Voice;
    const voiceId = findVoiceIdByTitle(voicetitle);
    const apiKey = "sk_fd230ee022b2e4b901f2c72cb37ac59964573c9325d74248"; // Replace with your API key
    const voiceSettings = {
      stability: 0.5,
      similarity_boost: 0.75,
    };
    try {
      setIsLoading(true);
      setActiveIndex(index); // Track active audio being fetched

      const response = await fetch(
        `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}/stream`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "xi-api-key": apiKey,
          },
          body: JSON.stringify({
            text,
            voice_settings: voiceSettings,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch audio stream: ${response.statusText}`);
      }

      // Convert the response to a Blob and create an audio URL
      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);

      // Set the audio source and play
      if (audioRefs.current[index]) {
        audioRefs.current[index].src = audioUrl;
        audioRefs.current[index].play();
        audioRefs.current[index].onended = () => {
          setSpeakingIndex(null);  // Reset speakingIndex when audio is finished
        };
      }
    } catch (error) {
      console.error("Error fetching or playing audio:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlayAudiomsg = async (msg, index) => {
      try {
        const authToken = localStorage.getItem("token");
        const response = await axios.post(`${BASE_URL}audio/token/credit/`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status === 200 || response.status === 201) {
          await handlePlayAudiomsgs(msg, index);
          toast.success("1.00 token has been deducted.", {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "You don't have enough Tokens!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ok",
        })
        console.error("Error fetching message history:", error);
      }
    }

  const toggleChat = () => {
    setIsOpen((prev) => !prev);
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const deletechat = (thread_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const authToken = localStorage.getItem("token");
          const payload = { thread_id }; // Define the payload with the thread_id
          // const response = await axios.delete(`${BASE_URL}bot_delete/`, payload);
          const response = await axios.delete(
            `${BASE_URL}chat_delete/`,
            { data: payload },
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );

          if (response.status === 200 || response.status === 201) {
            // Show success message after API call
            // setRefreshChats(prev => !prev);
            setMessagesHistory([]);
            setMessages([]);
            Swal.fire({
              title: "Deleted!",
              text: "Your chat has been deleted.",
              icon: "success",
            });
          } else {
            console.error("Unexpected response status:", response.status);
          }
        } catch (error) {
          console.error("Error sending message:", error);
        } finally {
          setIsLoadingAIResponse(false); // Reset loading state
        }
      }
    });
  };

  const deletecharacter = (assistant_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const authToken = localStorage.getItem("token");
          const payload = { assistant_id };
          const response = await axios.delete(
            `${BASE_URL}bot_delete/`,
            { data: payload },
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );

          if (response.status === 200 || response.status === 201) {
            setMessagesHistory([]);
            setMessages([]);
            Swal.fire({
              title: "Deleted!",
              text: "Your Character has been deleted.",
              icon: "success",
            });
          } else {
            console.error("Unexpected response status:", response.status);
          }
        } catch (error) {
          console.error("Error sending message:", error);
        } finally {
          setIsLoadingAIResponse(false);
        }
      }
    });
  };

  const handleImageClick = (imgUrl) => {
    setSelectedImage(imgUrl);
    setIsModalOpen(true);
  };
  const handleImagechatClick = (img_url, chatimg) => {
    setSelectedImage(img_url || chatimg);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (isPopupOpen) {
      // Ensures the popup is visible after a state change
      setTimeout(
        () =>
          modalRef.current.classList.add(
            "transition-opacity",
            "transition-transform"
          ),
        100
      );
    }
  }, [isPopupOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      setIsModalOpen(false);
      setSelectedImage(null);
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
        setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isMobile == true) {
      setIsVisible(!isVisible);
      setIsmsgVisible(!ismsgVisible);
    }
  }, [isMobile]);

  return (
    <>
      <Helmet>
        <title>The Best AI Girlfriend Chatbot Online - Eromantic.ai</title>
        <meta
          name="description"
          content="Are you looking for AI generated girl to chat with? At Eromantic.ai you can make custom AI gf/bf chatbots and enjoy their company!"
        />
        <meta
          name="keywords"
          content="AI Girlfriend, Virtual Girlfriend, Anime Companions, Interactive AI, NSFW-friendly"
        />
        {/* You can add more meta tags if needed */}
      </Helmet>
      <ToastContainer />
      <div className=" relative flex h-full flex-1 shrink-0 flex-col items-stretch overflow-hidden xl:pb-0">
        <div className="flex-1 shrink-0 overflow-auto">
          <div className="relative z-0 flex h-full w-full  3xl:max-w-full justify-center">
            <div className="absolute left-0 top-0 px-2 py-4 3xl:p-4  z-bg cursor-default opacity-0  -translate-x-1/2 space-x-2 3xl:translate-x-0   flex transform items-center  ">
              <div className="  h-10 w-10 hidden 3xl:flex">
                <img loading ='lazy'
                  src="/image/logo-white.svg"
                  className=" w-full  cursor-pointer"
                />
              </div>
              <button className="flex justify-center items-center border text-sm h-11 w-11 rounded-2xl border-none bg-black-6/50">
                <span className="iconfont-custom icon-pickupLiftIcon text-3xl font-light text-white/70" />
              </button>
            </div>
            <div className="absolute right-0  top-0 z-subtitle py-4 pr-2 lg:pr-4 z-bg z-subtitle opacity-100 xl:flex  3xl:hidden">
              <button className="flex justify-center items-center border text-sm h-11 w-11 rounded-2xl border-none bg-black-6/50">
                <span className="iconfont-custom icon-packupIcon text-5xl font-light text-white/70" />
              </button>
            </div>
            <div className=" absolute right-0  top-0 z-subtitle py-4 pr-2 lg:pr-4   hidden">
              <button className="justify-center items-center border text-sm hidden h-11 w-11 rounded-2xl border-none bg-black-6/50 2xl:flex">
                <span className="iconfont-custom icon-packupIcon text-5xl font-light text-white/70" />
              </button>
            </div>
            <div
              className={`w-full basis-2/5 transform transition-all duration-200  chatbox_mob ${
                ismsgVisible ? "3xl:flex" : "hidden"
              } min-h-screen`}
            >
              <div className="relative flex flex-col h-full w-full overflow-hidden duration-1000 animate-in fade-in">
                <div className="flex w-full items-center justify-between py-4 pl-6 pr-2 font-semibold text-white/70">
                  <div className="flex items-center">
                    <span className="text-xl text-[#484848]">Messages</span>
                  </div>
                  <button
                    onClick={toggleVisibilitymsg}
                    className="flex justify-center items-center border text-sm h-8 w-8 rounded-2xl border-none bg-[#0000009c]"
                  >
                    <span className="iconfont-custom icon-pickuprightIcon text-3xl font-light text-white/70"></span>
                  </button>
                </div>
                <div
                  className="flex-grow items-center justify-center overflow-y-auto custom-scrollbar"
                  style={{
                    overflowX: "hidden",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#81050559 transparent",
                  }}
                >
                  {assistants.length === 0 ? (
                    <>
                      {/* <img loading ='lazy'
                                            alt="chat-placeholder"
                                            loading="lazy"
                                            width={92}
                                            height={104}
                                            decoding="async"
                                            data-nimg={1}
                                            className="h-auto mt-30 w-1/2"
                                            src="/image/nochat.svg"
                                            style={{ color: "transparent" }}
                                        /> */}
                    </>
                  ) : (
                    <>
                      <ul className="w-full">
                        {assistants.map((assistant) => (
                          <li
                            key={assistant.assistant_id}
                            className="flex items-center gap-2 justify-center capitalize border-[#000] text-black rounded-xl bg-[#0e0e0e3d] min-h-[5rem] my-[10px] mx-[10px] p-2 cursor-pointer"
                            onClick={() =>{
                              handleClick(
                                assistant.assistant_id,
                                assistant.name,
                                assistant.thread_id,
                                assistant?.Image,
                                assistant
                              );
                            }}
                          >
                            <div className="w-16 h-16">
                              <img loading ='lazy'
                                src={
                                  assistant?.Image ||
                                  "/static/media/img1.9322f9a528d87450122a.png"
                                }
                                alt="userProfile"
                                className="w-16 h-16 rounded-full object-cover"
                              />
                            </div>
                            <div className="px-2">
                              <div className="flex items-center justify-between">
                                <p className="text-xl">
                                  {assistant.name || "Unnamed Assistant"}
                                  <span className="inline-block w-3 h-3 bg-green-600 rounded-full mx-3 mb-[2px] border-2 border-green-600 shadow-md shadow-green-800"></span>
                                </p>
                                {/* <p className="text-xl">{getCurrentTime()}</p> */}
                              </div>
                              <div style={{ width: "15rem" }}>
                                <span className="text-sm">
                                  {(assistant.Summary?.split(" ")
                                    .slice(0, 5)
                                    .join(" ") || "No message available.") +
                                    (assistant.Summary?.split(" ").length > 5
                                      ? "..."
                                      : "")}
                                </span>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="relative flex w-full grow justify-center ">
              <img loading ='lazy'
                alt="background"
                fetchpriority="high"
                width={1000}
                height={1200}
                decoding="async"
                data-nimg={1}
                className="absolute z-bg h-full w-full  object-cover object-center  "
                src={
                  userimg
                    ? userimg
                    : "/static/media/img1.9322f9a528d87450122a.png"
                }
                style={{ color: "transparent", filter: "blur(10px)" }}
              />
              <div className="absolute inset-0 z-overlay hidden bg-black-11/20 backdrop-blur-xl sxl:flex" />
              <div className="h-100 absolute top-0 z-overlay w-full bg-gradient-row-black-2" />
              <div className="absolute top-0 z-overlay h-full w-full  bg-black/[0.26]" />
              <div
                className={`absolute left-0 chatback2 top-0 z-subtitle py-4 pl-2 lg:pl-4 ${
                  ismsgVisible ? "hidden" : "3xl:flex"
                }`}
              >
                <button
                  onClick={toggleVisibilitymsg}
                  className="flex justify-center items-center border text-sm h-8 w-8 rounded-2xl border-none bg-[#0000009c]
"
                >
                  <span className="iconfont-custom icon-pickupLiftIcon text-3xl font-light text-white/70" />
                </button>
              </div>
              <div className=" flex w-full flex-col mx-[10%]">
                <div className="fixed w-full mo:w-[60%]">
                <div className="fixed h-16 w-[80%] md:w-[50%] items-center">
                  <div className="relative flex h-full w-full right-[50px] mo:right-0 items-center justify-between bg-transparent p-3 pb-1  lg:mx-0">
                    <div className="flex items-center gap-5">
                      <button className="xl:hidden ml-[30px] ">
                        <span className="iconfont-custom icon-hanburgerIcon text-5xl" />
                      </button>
                      <div className="flex items-center gap-2">
                        <div
                          className={`absolute left-0 top-[4px] z-subtitle py-4 pl-2 lg:pl-4 chatback mr-[30px] ${
                            ismsgVisible ? "hidden" : "3xl:flex"
                          }`}
                        >
                          <button
                            onClick={toggleVisibilitymsg}
                            className="        flex justify-center items-center border text-sm h-8 w-8 rounded-2xl border-none bg-[#0000009c]
"
                          >
                            <span className="iconfont-custom icon-pickupLiftIcon text-3xl font-light text-white/70" />
                          </button>
                        </div>
                        <div
                          className="relative shrink-0 grow-0 rounded-full h-10 w-10"
                          style={{ padding: 0 }}
                        >
                          <img loading ='lazy'
                            alt="avatarPlaceholder"
                            width={10}
                            height={10}
                            className="absolute inset-0 z-overlay h-full w-full rounded-full object-cover"
                            src={userimg ? userimg : "/static/media/img1.9322f9a528d87450122a.png"}
                            style={{ color: "transparent" }}
                          />
                        </div>
                        <div className="flex flex-col text-base">
                          <span className="line-clamp-1 font-medium text-lg capitalize text-white">
                            {activeAssistantName}
                          </span>
                          {/* <div className="flex items-center">
                                                    <span className="mr-1 text-xs text-white/[0.98]">
                                                        Lv1
                                                    </span>
                                                    <div className="relative h-2 w-14 rounded-full bg-black/30">
                                                        <div
                                                            className="absolute h-2 transform rounded-full bg-white/80 duration-200"
                                                            style={{ width: "0%" }}
                                                        />
                                                    </div>
                                                </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <div
                        className="relative left-[80px] mo:left-0 flex h-fit w-fit focus-visible:border-none"
                        data-headlessui-state=""
                      >
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <Menu.Button className="focus-visible:transparent focus:outline-none">
                              <div className="flex h-7 w-7 items-center justify-center rounded-full bg-white hover:bg-gray-200 focus-visible:shadow-none">
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                              </div>
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-[115px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={() => deletechat(characterid)}
                                      className={`${
                                        active
                                          ? "bg-red-600 text-white"
                                          : "text-gray-900"
                                      } block px-4 py-2 text-sm`}
                                    >
                                      Reset Chat
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                      <div
                        hidden=""
                        style={{
                          position: "fixed",
                          top: 1,
                          left: 1,
                          width: 1,
                          height: 0,
                          padding: 0,
                          margin: "-1px",
                          overflow: "hidden",
                          clip: "rect(0px, 0px, 0px, 0px)",
                          whiteSpace: "nowrap",
                          borderWidth: 0,
                          display: "none",
                        }}
                      />
                      <h2
                        id="radix-:r5g:"
                        className="text-lg font-semibold leading-none tracking-tight"
                      />
                      <h2
                        id="radix-:r5j:"
                        className="text-lg font-semibold leading-none tracking-tight"
                      />
                      <h2
                        id="radix-:r5m:"
                        className="text-lg font-semibold leading-none tracking-tight"
                      />
                      <h2
                        id="radix-:r5p:"
                        className="text-lg font-semibold leading-none tracking-tight"
                      />
                    </div>
                  </div>
                </div>
              <div
                className={`absolute right-10  top-[4px] z-subtitle py-4 pr-2 lg:pr-4 ${
                  isVisible ? "hidden" : "3xl:flex"
                }`}
              >
                <button
                  onClick={toggleVisibility}
                  className="        flex justify-center items-center border text-sm h-8 w-8 rounded-2xl border-none bg-[#0000009c]
  "
                >
                  <span className="iconfont-custom icon-pickuprightIcon text-3xl font-light text-white/70" />
                </button>
              </div>
              </div>
                <div className="relative z-0 flex h-full w-full mt-16 mb-[150px] mo:mb-[5px] mo:mt-[60px] flex-1 overflow-hidden">
                  <div
                    className="min-h-full w-full py-[20px] px-0 md:px-5 overflow-auto"
                    ref={scrollbarRef}
                    style={{ scrollbarWidth: "none" }}
                  >
                    {messagesHistory.length === 0 && <p>{message}</p>}
                    {messagesHistory.map((msg, index) => (
                      <>
                        <div key={index} className={`flex justify-end mx-0 my-6 md:m-6`}>
                          {msg.user_message ? (
                            <div
                              className={`userchat max-w-[60%] border-[#88ceff] flex p-2.5 pl-4`}
                            >
                              {msg.giftid ? (
                                    <div key={index} className="">
                                      { imageData.map((item, index)=>(
                                        msg.giftid == item.giftid ?
                                        <div className="flex flex-col flex-wrap justify-center">
                                        <img src={item.url} className="w-20"/>
                                        {msg.giftMsg}
                                        </div>
                                        : ""
                                      ) )}
                                  </div> 
                                  ) : msg.user_message}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className={`flex flex-col justify-start mx-0 my-6 md:m-6`}>
                          <div
                            className={`aichat justify-between max-w-[60%] border-[#88ceff] flex p-2.5 pl-4`}
                          >
                            {msg.ai_response &&
                            msg.ai_response.includes("**") ? (
                              <span>
                                {msg.ai_response
                                  .split(/\*\*(.*?)\*\*/)
                                  .map((part, idx) =>
                                    idx % 2 === 1 ? (
                                      <span
                                        key={idx}
                                        style={{
                                          fontSize: "14px",
                                          fontStyle: "italic",
                                          color: "[#5c4242]",
                                        }}
                                      >
                                        *{part}*
                                      </span>
                                    ) : (
                                      <span key={idx}>{part}</span>
                                    )
                                  )}
                              </span>
                            ) : (
                              <span>{msg.ai_response}</span>
                            )}
                            {/* {msg.ai_response} */}
                            <div className="">
                              {/* <div
                                                className='text-red-500 bg-[#ffffff] p-[5px] rounded-full shadow-lg'
                                                onClick={() => handlePlayAudiomsg(msg)}
                                            >
                                                {isSpeaking ? <PiUserSoundFill /> : <IoVolumeHighSharp />}
                                            </div> */}
                                <div
                                  className={`text-red-500 bg-[#ffffff] p-[5px] rounded-full shadow-lg ${speakingIndex === index ? "disable" : ""}`}
                                  style={speakingIndex === index ? { pointerEvents: 'none', opacity: 0.5 } : {}}
                                  onClick={() => speakingIndex !== index && handlePlayAudiomsg(msg, index)}
                                >
                                {speakingIndex === index ? (
                                  <PiUserSoundFill />
                                ) : (
                                  <IoVolumeHighSharp />
                                )}
                              </div>
                              <audio
                                ref={(el) => (audioRefs.current[index] = el)}
                                className={`audio-player mt-4 w-full ${
                                  activeIndex === index && !isLoading
                                    ? "hidden"
                                    : "hidden"
                                }`}
                                controls
                              />
                              {/* <div>{msg.timestamp}</div> */}
                            </div>
                          </div>
                          {msg.img_url ? (
                            <div className="relative">
                            <button onClick={() => alert('Button clicked!')} className="absolute top-3 left-4 px-4 rounded-full text-white 
                            font-semibold bg-gradient-to-r from-[#990013] to-red-500 shadow-xl transition-all duration-300 transform hover:scale-110">
                              Upscale<i className="fa-solid fa-arrow-up-wide-short ml-1"></i>
                            </button>
                            <img 
                              loading="lazy"
                              src={msg.img_url}
                              onClick={() => handleImagechatClick(msg.img_url)}
                              alt="img"
                              className="m-2 rounded-xl w-[75%]"
                            />
                          </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ))}
                    {messages.map((msg, index) => (
                      <>
                        {msg.sender === "user" ? (
                          <div key={index} className={`flex justify-end mx-0 my-6 md:m-6`}>
                            <div
                              className={`userchat max-w-[60%] border-[#88ceff] flex p-2.5 pl-4`}
                            >
                              {msg.giftid ? (
                                      <div key={index} className="">
                                        { imageData.map((item, index)=>(
                                          msg.giftid == item.giftid ?
                                          <div className="flex flex-col flex-wrap justify-center">
                                          <img src={item.url} className="w-20"/>
                                          {msg.giftMsg}
                                          </div>
                                          : ""
                                        ) )}
                                    </div> 
                                    ) : msg.text}
                              
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className={`flex flex-col justify-start mx-0 my-6 md:m-6`}>
                              <div
                                className={`aichat justify-between max-w-[60%] border-[#88ceff] flex p-2.5 pl-4`}
                              >
                                {msg.text && msg.text.includes("**") ? (
                                  <span>
                                    {msg.text
                                      .split(/\*\*(.*?)\*\*/)
                                      .map((part, idx) =>
                                        idx % 2 === 1 ? (
                                          <span
                                            key={idx}
                                            style={{
                                              fontSize: "14px",
                                              fontStyle: "italic",
                                              color: "[#5c4242]",
                                            }}
                                          >
                                            *{part}*
                                          </span>
                                        ) : (
                                          <span key={idx}>{part}</span>
                                        )
                                      )}
                                  </span>
                                ) : (
                                  <span>{msg.text}</span>
                                )}
                                {/* {msg.text} */}
                                <div className="">
                                <div
                                  className={`text-red-500 bg-[#ffffff] p-[5px] rounded-full shadow-lg ${speakingIndex === index ? "disable" : ""}`}
                                  style={speakingIndex === index ? { pointerEvents: 'none', opacity: 0.5 } : {}}
                                  onClick={() => speakingIndex !== index && handlePlayAudiomsg(msg, index)}
                                >
                                    {speakingIndex === index ? (
                                      <PiUserSoundFill />
                                    ) : (
                                      <IoVolumeHighSharp />
                                    )}
                                  </div>
                                  <audio
                                    ref={(el) =>
                                      (audioRefs.current[index] = el)
                                    }
                                    className={`audio-player mt-4 w-full ${
                                      activeIndex === index && !isLoading
                                        ? "hidden"
                                        : "hidden"
                                    }`}
                                    controls
                                  />
                                </div>
                                
                                {/* <div>{msg.msgtime}</div> */}
                              </div>
                              {msg.chatimg ? (
                                <div>
                                  <img loading ='lazy'
                                    src={msg.chatimg}
                                    onClick={() =>
                                      handleImagechatClick(msg.chatimg)
                                    }
                                    alt="img"
                                    className="m-2 rounded-xl"
                                    style={{ width: "75%" }}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        )}
                      </>
                    ))}

                    {/* Show loader dots when waiting for AI response */}
                    {isLoadingAIResponse && (
                      <div
                        className={`aichat justify-between max-w-[20%] m-6 border-[#88ceff] flex p-2.5 pl-6`}
                      >
                        <div className="loaderdots"></div>
                      </div>
                    )}
                    {isLoadingImage && (
                      <> <CircularWithValueLabel isLoadingAIResponse={isLoadingImage} percentageShow={true} /></>
                                            

                    )}
                  </div>
                  {/* <div className="relative flex h-[40.4rem] w-full flex-col overflow-auto pt-20">
                                    <div className="mx-auto mb-5 mt-auto flex w-9/10 items-end gap-2 break-words rounded-2xl bg-[#39373E] px-4 text-lg text-gray-1 opacity-70 backdrop-blur-[10px] md:text-base py-3">
                                        <span className="w-full line-clamp-4 overflow-hidden whitespace-normal">
                                            You are appreciating Colin playing bass for his own band at a
                                            bar tonight. He seems to enjoy your staring during the
                                            performance.{" "}
                                        </span>
                                    </div>
                                    <div className="bg-red m-3 mb-8 flex items-end" />
                                </div> */}
                </div>
                <div className="w-full px-2 pb-2 sm:mb-0 fixed bottom-[100px] left-0 right-0 lg:!static">
                  <div className=" w-full  pt-1">
                    <div className="overflow-hidden pt-2">
                      <div className="flex h-12 gap-2">
                        <div className="relative">
                          <button
                            className="rounded-full flex h-full px-4 justify-center bg-[#bdb1b1] items-center border text-lg border-none"
                            onClick={togglegiftPopup}
                          >
                            <i className="fa-solid fa-gift"></i>
                          </button>
                        </div>
                        <div className="relative mx-auto flex flex-1 items-center overflow-hidden rounded-full px-4 p-1 bg-[#bdb1b1]">
                          <input
                            className="w-full border-0 bg-transparent pr-1 text-white caret-purple-1 placeholder:text-white placeholder:opacity-40 focus:outline-none focus:ring-0 resize-none"
                            placeholder={
                              isRecording ? "Recording..." : "Start typing..."
                            }
                            id="chat"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyPress={handleKeyPress}
                          />

                          <div
                            className="flex items-center relative z-subtitle  h-10 w-10"
                            onClick={sendMessage}
                          >
                            <i
                              className="fa-solid fa-paper-plane"
                              style={{ color: "#fff", fontSize: "22px" }}
                            ></i>
                          </div>
                        </div>
                        <div
                          className=""
                          style={{ touchAction: "none", cursor: "pointer" }}
                        >
                          <div
                            style={{
                              justifySelf: "end",
                              transform: "scale(0.5)",
                            }}
                          />
                          <div style={{ transform: "none" }}>
                            <div className="relative  flex h-12 w-12 items-center justify-center rounded-full bg-[#bdb1b1] ">
                              <span
                                className="absolute left-[-140px] z-subtitle  text-gray-3 sm:left-[-120px] md:left-[-140px] lg:left-[-160px] xl:left-[-180px] "
                                style={{ display: "none" }}
                              >
                                &lt; Slide to cancel
                              </span>
                              <div
                                className="hover:animate-fadePulse"
                                onClick={startRecording}
                              >
                                {isRecording ? (
                                  <div className="recordloader"></div>
                                ) : (
                                  <i className="fa-solid fa-microphone  text-2xl text-white"></i>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="flex h-14 items-center space-x-2 justify-center"> */}

                      {/* <div className="ka-wrapper">
                                                <div className="ka-content">
                                                    <div className="relative">
                                                        <button className="rounded flex px-4 py-1 rounded-full justify-center bg-[#c588bab0] items-center border text-lg border-none p-0"> */}
                      {/* <img loading ='lazy' src="/icon/selfie-button.svg" className="h-12" /> */}
                      {/* <i className="fa-solid fa-gift mr-2 "></i>Send a Gift
                                                        </button>
                                                    </div>
                                                </div>
                                            </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <div _nk="UVSV21" style={{ display: "none" }}>
                  <div _nk="bNyU21" />
                </div>
              </div>
            </div>
            <div
              className={`max-w-[500px] h-full shrink transform transition-all duration-200 z-99 w-full basis-2/5  ${
                isMobile ? "absolute" : "a"
              } ${isVisible ? "3xl:flex" : "hidden"}`}
            >
              <div className="relative  flex h-full w-full flex-col">
                <img
                    loading="lazy"
                    alt="background"
                    className="-z-5 absolute object-cover h-[80%] xl:h-[70%] w-full"
                    src={
                      userimg
                        ? userimg
                        : "/static/media/img1.9322f9a528d87450122a.png"
                    }
                    style={{ color: "transparent" }}
                  />
                <div className="sticky left-0 top-0 w-full px-5 py-3">
                  <button
                    onClick={toggleVisibility}
                    className="        flex justify-center items-center border text-sm h-8 w-8 rounded-2xl border-none bg-[#0000009c]
"
                  >
                    <span className="iconfont-custom icon-pickupLiftIcon text-3xl font-light text-white/70" />
                  </button>
                </div>
                <div
                  className="h-full w-full overflow-auto pt-[28rem]"
                  style={{ scrollbarWidth: "none" }}
                >
                  <div className="relative flex min-h-full w-full flex-col overflow-hidden rounded-t-3xl bg-[#272e34]">
                    {/* <div className="absolute top-1 right-1">
                    <img loading ='lazy'  alt="avatar"  loading="lazy" width={35} height={35} decoding="async" data-nimg={1} 
                    className="bg-[#ffffffbd] rounded-full p-1 overflow-visible" src={arrowup} />
                  </div> */}
                    <div className="w-full bg-[#bfb4b480] px-4 pb-2 pt-4  border-0 border-b-2 border-b-white">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <div className=" h-24 w-24  rounded-full  ">
                            <div
                              className="relative shrink-0 grow-0 rounded-full h-24 w-24"
                              style={{ padding: 0 }}
                            >
                              <img loading ='lazy'
                                alt="avatar"
              
                                width={24}
                                height={24}
                                decoding="async"
                                data-nimg={1}
                                className="relative h-full w-full rounded-full"
                                src="/static/media/img1.9322f9a528d87450122a.png"
                                style={{ color: "transparent" }}
                              />
                              <img loading ='lazy'
                                alt="avatarPlaceholder"
                                width={24}
                                height={24}
                                decoding="async"
                                data-nimg={1}
                                className="absolute inset-0 z-overlay h-full w-full rounded-full object-cover"
                                src={userimg}
                                style={{ color: "transparent" }}
                              />
                            </div>
                          </div>
                          <div className=" ml-3 text-white">
                            <span className="text-3xl font-medium">
                              {activeAssistantName}
                            </span>
                            <div className="flex items-center whitespace-nowrap font-light">
                              {/* <div className="mb-1 h-5 w-5">
                                                            <img loading ='lazy'
                                                                src="/icon/messageIcon/favorabilityIcon.svg"
                                                                className=" w-full  cursor-pointer"
                                                            />
                                                        </div> */}
                              <span className="mr-2 whitespace-nowrap">
                                Age: {activeAssistantdetails.age}
                              </span>
                              <span className="iconfont-custom icon-message-fill mr-1 mt-1 text-base" />
                              {/* <span>589k</span> */}
                            </div>
                            <span className="text-xs text-white">
                              Created by Eromantic AI
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="m-2 mt-6 font-robotoLight text-sm text-white">
                        <div className=" inline-block">
                          <button className="rounded flex justify-center items-center border text-sm border-none">
                            <span className="iconfont-custom icon-voiceFree mr-1 rounded-full bg-gray-7 px-[4px] text-sm font-semibold text-white" />
                          </button>
                          <audio src="https://cdn.iher.ai/196009864237981440/830ec8653b0341a88bd2d921b21bd9ff.mp3" />
                        </div>
                        {activeAssistantdetails.Occupation}
                      </div>
                      <div className="m-2 flex flex-wrap">
                        <span className="mb-2 mr-4 rounded-full bg-gray-100/20 px-2 py-1 text-sm font-light text-white">
                          {activeAssistantdetails.Relationship}
                        </span>
                        <span className="mb-2 mr-4 rounded-full  bg-gray-100/20 px-2 py-1   text-sm font-light text-white">
                          {activeAssistantdetails.Personality}
                        </span>
                        <span className="mb-2 mr-4 rounded-full  bg-gray-100/20 px-2 py-1   text-sm font-light text-white">
                          {activeAssistantdetails.Ethnicity}
                        </span>
                      </div>
                    </div>
                    <div
                      className="grow bg-[#bfb4b480] px-4 pb-8 pt-4 h-[30rem] overflow-scroll mb-[80px] mo:mb-0"
                      style={{ scrollbarWidth: "none" }}
                    >
                      <div>
                        <div
                          className="flex gap-14"
                          role="tablist"
                          aria-orientation="horizontal"
                        >
                          {/* Photo Tab Button */}
                          <button
                            className={`mb-3 py-1 text-lg font-semibold focus:outline-none ${
                              activeTab === "photo"
                                ? "border-purple-8 text-[#000000ba] border-b-[3px] font-bold"
                                : "border-black-7 text-white"
                            }`}
                            onClick={() => handleTabClick("photo")}
                            aria-selected={activeTab === "photo"}
                            role="tab"
                            type="button"
                          >
                            Photo
                          </button>

                          {/* {/ About Tab Button /} */}
                          <button
                            className={`mb-3  py-1 text-lg font-semibold focus:outline-none ${
                              activeTab === "about"
                                ? "border-purple-8 text-[#000000ba] border-b-[3px] font-bold"
                                : "border-black-7 text-white"
                            }`}
                            onClick={() => handleTabClick("about")}
                            aria-selected={activeTab === "about"}
                            role="tab"
                            type="button"
                          >
                            About
                          </button>
                        </div>

                        <div>
                          {/* Show Photo content when activeTab is 'photo' */}
                          {activeTab === "photo" && (
                            <div>
                              <div
                                id="headlessui-tabs-panel-:r6e:"
                                role="tabpanel"
                                tabIndex={0}
                                data-headlessui-state="selected"
                                data-selected=""
                                aria-labelledby="headlessui-tabs-tab-:r6a:"
                              >
                                <div className="min-h-32 grid h-auto w-full grid-cols-3">
                                {messages && messages.length > 0 && messages.map((msg, index) => (
                                  msg.chatimg && (
                                    <div key={index} className="relative h-0 w-full pb-[100%]">
                                      <button
                                        onClick={() => handleImagechatClick(msg.chatimg)}
                                        className="rounded flex justify-center items-center border text-sm absolute left-0 top-0 h-full w-full border-none p-1"
                                      >
                                        <div className="relative flex items-center justify-center overflow-hidden rounded-md h-full w-full bg-white/10">
                                          <div className="relative h-full w-full">
                                            <img
                                              loading="lazy"
                                              src={msg.chatimg}
                                              alt="Chat image"
                                              className="absolute inset-0 z-overlay aspect-[2/3] h-full w-full object-cover object-center"
                                            />
                                          </div>
                                        </div>
                                      </button>
                                    </div>)                              
                                ))}
                                  {messagesHistory.some(
                                    (message) => message.img_url
                                  ) ||
                                  assistants.some(
                                    (assistant) =>
                                      assistant.image_file_names.length > 0
                                  ) ? (
                                    <>
                                      {messagesHistory.map((message, index) =>
                                        message.img_url ? (
                                          <div
                                            key={index}
                                            className="relative h-0 w-full pb-[100%]"
                                          >
                                            <button
                                              onClick={() =>
                                                handleImageClick(
                                                  message.img_url
                                                )
                                              }
                                              className="rounded flex justify-center items-center border text-sm absolute left-0 top-0 h-full w-full border-none p-1"
                                            >
                                              <div className="relative flex items-center justify-center overflow-hidden rounded-md h-full w-full bg-white/10">
                                                <div className="relative h-full w-full">
                                                  <img loading ='lazy'
                                                    className="absolute inset-0 z-overlay aspect-[2/3] h-full w-full object-cover object-center"
                                                    src={message.img_url}
                                                    alt="Dynamic content"
                                                  />
                                                </div>
                                              </div>
                                            </button>
                                          </div>
                                        ) : null
                                      )}

                                      {Array.isArray(allimages)
                                        ? allimages.map((assistant, index) =>
                                            assistant.image_file_names?.map(
                                              (imageFileName, imageIndex) => (
                                                <div
                                                  key={`assistant-${index}-${imageIndex}`}
                                                  className="relative h-0 w-full pb-[100%]"
                                                >
                                                  <button
                                                    onClick={() =>
                                                      handleImageClick(
                                                        imageFileName
                                                      )
                                                    }
                                                    className="rounded flex justify-center items-center border text-sm absolute left-0 top-0 h-full w-full border-none p-1"
                                                  >
                                                    <div className="relative flex items-center justify-center overflow-hidden rounded-md h-full w-full bg-white/10">
                                                      <div className="relative h-full w-full">
                                                        <img loading ='lazy'
                                                          className="absolute inset-0 z-overlay aspect-[2/3] h-full w-full object-cover object-center"
                                                          src={imageFileName}
                                                          alt={`Assistant content ${index}-${imageIndex}`}
                                                        />
                                                      </div>
                                                    </div>
                                                  </button>
                                                </div>
                                              )
                                            )
                                          )
                                        : allimages?.image_file_names?.map(
                                            (imageFileName, imageIndex) => (
                                              <div
                                                key={`assistant-${imageIndex}`}
                                                className="relative h-0 w-full pb-[100%]"
                                              >
                                                <button
                                                  onClick={() =>
                                                    handleImageClick(
                                                      imageFileName
                                                    )
                                                  }
                                                  className="rounded flex justify-center items-center border text-sm absolute left-0 top-0 h-full w-full border-none p-1"
                                                >
                                                  <div className="relative flex items-center justify-center overflow-hidden rounded-md h-full w-full bg-white/10">
                                                    <div className="relative h-full w-full">
                                                      <img loading ='lazy'
                                                        className="absolute inset-0 z-overlay aspect-[2/3] h-full w-full object-cover object-center"
                                                        src={imageFileName}
                                                        alt={`Assistant content ${imageIndex}`}
                                                      />
                                                    </div>
                                                  </div>
                                                </button>
                                              </div>
                                            )
                                          )}
                                    </>
                                  ) : (
                                    <div className="col-span-3 flex items-center justify-center h-32 text-black-500">
                                      No image found
                                    </div>
                                  )}
                                </div>
                              </div>
                              <span
                                aria-hidden="true"
                                id="headlessui-tabs-panel-:r6g:"
                                role="tabpanel"
                                tabIndex={-1}
                                aria-labelledby="headlessui-tabs-tab-:r6c:"
                                style={{
                                  position: "fixed",
                                  top: 1,
                                  left: 1,
                                  width: 1,
                                  height: 0,
                                  padding: 0,
                                  margin: "-1px",
                                  overflow: "hidden",
                                  clip: "rect(0px, 0px, 0px, 0px)",
                                  whiteSpace: "nowrap",
                                  borderWidth: 0,
                                }}
                              />
                            </div>
                          )}

                          {/* Show About content when activeTab is 'about' */}

                          {activeTab === "about" && (
                            <div
                              id="about-content"
                              className="text-black space-y-6"
                            >
                              {/* Ethnicity Section */}
                              <div
                                className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out"
                                style={{ marginTop: "2px" }}
                              >
                                <div className="flex items-center w-full">
                                  <img loading ='lazy'
                                    className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md"
                                    src={Location}
                                    alt="Location icon"
                                  />
                                  <div className="flex w-[80%] justify-between">
                                    <h3 className="text-gray-800 font-semibold text-base">
                                      Ethnicity:
                                    </h3>
                                    <p className="text-gray-700 text-sm">
                                      {activeAssistantdetails.Ethnicity}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/* Age Section */}
                              <div
                                className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out"
                                style={{ marginTop: "2px" }}
                              >
                                <div className="flex items-center w-full">
                                  <img loading ='lazy'
                                    className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md"
                                    src={age}
                                    alt="Age icon"
                                  />
                                  <div className="flex w-[80%] justify-between">
                                    <h3 className="text-gray-800 font-semibold text-base">
                                      Age:
                                    </h3>
                                    <p className="text-gray-700 text-sm">
                                      {activeAssistantdetails.age}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/* Eyes Section */}
                              <div
                                className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out"
                                style={{ marginTop: "2px" }}
                              >
                                <div className="flex items-center w-full">
                                  <img loading ='lazy'
                                    className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md"
                                    src={eyes}
                                    alt="Eyes icon"
                                  />
                                  <div className="flex w-[80%] justify-between">
                                    <h3 className="text-gray-800 font-semibold text-base">
                                      Eyes:
                                    </h3>
                                    <p className="text-gray-700 text-sm">
                                      {activeAssistantdetails.Eyes}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/* Hair Section */}
                              <div
                                className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out"
                                style={{ marginTop: "2px" }}
                              >
                                <div className="flex items-center w-full">
                                  <img loading ='lazy'
                                    className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md"
                                    src={hair}
                                    alt="Hair icon"
                                  />
                                  <div className="flex w-[80%] justify-between">
                                    <h3 className="text-gray-800 font-semibold text-base">
                                      Hair:
                                    </h3>
                                    <p
                                      className="text-gray-700 text-sm"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {activeAssistantdetails.hair_color} -{" "}
                                      {activeAssistantdetails.hair_Style}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/* Body Type Section */}
                              <div
                                className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out"
                                style={{ marginTop: "2px" }}
                              >
                                <div className="flex items-center w-full">
                                  <img loading ='lazy'
                                    className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md"
                                    src={Bodyicon}
                                    alt="Body type icon"
                                  />
                                  <div className="flex w-[80%] justify-between">
                                    <h3 className="text-gray-800 font-semibold text-base">
                                      Body Type:
                                    </h3>
                                    <p className="text-gray-700 text-sm">
                                      {activeAssistantdetails.Body_Type}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/* Personality Section */}
                              <div
                                className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out"
                                style={{ marginTop: "2px" }}
                              >
                                <div className="flex items-center w-full">
                                  <img loading ='lazy'
                                    className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md"
                                    src={personality}
                                    alt="Personality icon"
                                  />
                                  <div className="flex w-[80%] justify-between">
                                    <h3 className="text-gray-800 font-semibold text-base">
                                      Personality:
                                    </h3>
                                    <p className="text-gray-700 text-sm">
                                      {activeAssistantdetails.Personality}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/* Voice Section */}
                              <div
                                className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out"
                                style={{ marginTop: "2px" }}
                              >
                                <div className="flex items-center w-full">
                                  <img loading ='lazy'
                                    className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md"
                                    src={eyes}
                                    alt="Voice icon"
                                  />
                                  <div className="flex w-[80%] justify-between">
                                    <h3 className="text-gray-800 font-semibold text-base">
                                      Voice:
                                    </h3>
                                    <p className="text-gray-700 text-sm">
                                      {activeAssistantdetails.Voice}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/* Occupation Section */}
                              <div
                                className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out"
                                style={{ marginTop: "2px" }}
                              >
                                <div className="flex items-center w-full">
                                  <img loading ='lazy'
                                    className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md"
                                    src={occupation}
                                    alt="Occupation icon"
                                  />
                                  <div className="flex w-[80%] justify-between">
                                    <h3 className="text-gray-800 font-semibold text-base">
                                      Occupation:
                                    </h3>
                                    <p className="text-gray-700 text-sm">
                                      {activeAssistantdetails.Occupation}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/* Relationship Section */}
                              <div
                                className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out"
                                style={{ marginTop: "2px" }}
                              >
                                <div className="flex items-center w-full">
                                  <img loading ='lazy'
                                    className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md"
                                    src={Relationshipicon}
                                    alt="Relationship icon"
                                  />
                                  <div className="flex w-[80%] justify-between">
                                    <h3 className="text-gray-800 font-semibold text-base">
                                      Relationship:
                                    </h3>
                                    <p className="text-gray-700 text-sm">
                                      {activeAssistantdetails.Relationship}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/* Hobbies Section */}
                              <div
                                className="bg-gradient-to-r from-white via-gray-100 to-gray-200 p-4 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out"
                                style={{ marginTop: "2px" }}
                              >
                                <div className="flex items-center gap-5 justify-between w-full">
                                  <p className="text-gray-800 font-semibold text-base">
                                    Hobbies:
                                  </p>
                                  <div className="flex justify-between">
                                    <p className="text-gray-700 text-sm">
                                      {activeAssistantdetails.Hobbies
                                        ? Object.values(
                                            activeAssistantdetails.Hobbies
                                          ).join(", ")
                                        : "N/A"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h2
                  id="radix-:r6j:"
                  className="text-lg font-semibold leading-none tracking-tight"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Modal (fullscreen image view) */}
        {isModalOpen && selectedImage && (
          <div
            ref={modalRef}
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
          >
            <div className="relative max-w-full max-h-full p-4">
              <button
                onClick={closeModal}
                className="absolute top-8 right-8 text-white text-md w-8 h-8 bg-[#808080] p-[5px] font-bold rounded-full"
              >
                X
              </button>
              <img loading ='lazy'
                className="max-w-full max-h-screen object-contain"
                src={selectedImage}
                alt="Fullscreen View"
              />
            </div>
          </div>
        )}
        {/* Popup */}
        {isPopupOpen && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
            <div
              ref={modalRef}
              className="bg-white rounded-lg p-6 w-[85%] sm:w-[80%] md:w-[60%] lg:w-[25%]"
            >
              {/* Image Grid */}
              <div className={`grid grid-cols-2 md:grid-cols-3 gap-2 mb-4 overflow-y-auto ${selectedgift ? "max-h-[150px] mo:max-h-[300px]" : "max-h-[300px]"}`}>
                {imageData.map((image, index) => (
                  <div
                    key={index}
                    className="relative flex justify-center bg-gray-300 h-20 rounded-lg cursor-pointer"
                    onClick={() => handlegiftClick(image.url,image.giftid, image.title)}
                  >
                    <img loading ='lazy'
                      src={image.url}
                      alt={image.title}
                      className="w-full h-full p-2 object-contain rounded-lg"
                    />
                    {/* Title */}
                    <div className="absolute w-full bottom-0 flex justify-between bg-[#12121242] rounded-lg px-2 py-1">
                      <p className="text-center text-sm text-white ">
                        {image.title}
                      </p>
                      <p className="text-center text-sm text-white ">
                        {image.token}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              {/* Input Field */}
              <input
                type="text"
                value={imageInput}
                onChange={(e) => setImageInput(e.target.value)}
                placeholder="Send a message with your gift"
                className="w-full p-2 border border-gray-300 rounded-lg mb-4"
              />

              {/* Display Selected Image */}
              {selectedgift && (
                <div className="mt-4">
                  <p className="mb-2">Selected Gift:</p>
                  <img loading ='lazy'
                    src={selectedgift}
                    alt="Selected"
                    className="w-full h-32 object-contain rounded-lg"
                  />
                </div>
              )}

              {/* Close Button */}
              <div className="flex justify-evenly">
                <button
                  onClick={togglegiftPopup}
                  className="mt-4 bg-red-500 w-[40%] text-white px-4 py-2 rounded-full"
                >
                  Close
                </button>
                <button
                  onClick={sendMessagegift}
                  className="mt-4 bg-[#76b77c] w-[40%] text-white px-4 py-2 rounded-full"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        )}
      </div>


      {errorisOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-lg">
          <div className="modal-dialog modal-lg bg-white rounded-lg overflow-hidden shadow-xl max-w-[16rem] mo:max-w-xl w-full">
            {/* Modal Content */}
            <div className="modal-wrap flex-wrap mo:flex relative">
              {/* Image Container - 50% Width */}
              <div className="w-full mo:w-1/2">
                {/* Desktop Image */}
                <img
                  src={alert}
                  alt="Modal Image"
                  className="login-img hidden md:block w-full h-full object-cover"
                />
                {/* Mobile Image */}
                <img
                  src={alert}
                  alt="Modal Image Mobile"
                  className="login-img-mob sm:hidden w-full"
                />
              </div>
              
              {/* Content Container - 50% Width */}
              <div className="modal-content p-2 w-full mo:w-1/2 grid justify-center">
                {/* Modal Header */}
                <div className="modal-header flex justify-between items-start">
                  <h4 className="modal-title text-uppercase text-xl"></h4>
                  <button
                    type="button"
                    className="btn-close text-xl text-[#990013]"
                    onClick={errorCloseModal}
                  >
                    <i className="fa-regular fa-circle-xmark"></i>
                  </button>
                </div>

                {/* Modal Body */}
                <div className="modal-body justify-center">
                    <div className="container-fluid">
                      <div className="row mb-4">
                        <div className="col-12 ps-4">
                          <h2 className="text-[#990013] font-bold text-uppercase text-2xl">
                            PLEASE UPGRADE
                          </h2>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-12 ps-4">
                          <span className="text-muted text-gray-900 text-md">
                          Upgrade to a premium membership to unlock access to all features!
                          </span>
                        </div>
                      </div>
                      <div className="row justify-center p-3 gap-1">
                        <div className="col-12">
                          <button onClick={()=>navigate('/priceplan')} className="btn btn-primary w-full py-3 text-white bg-[#990012ab] hover:bg-[#990013] rounded-md">
                            Upgrade
                          </button>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>

            {/* Modal Overlay */}
            {/* <div
              className="fixed inset-0"
              onClick={errorCloseModal}
            ></div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Aidreamchat;
