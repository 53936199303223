import React, { useEffect, useState } from 'react';
import UserApiClient from '../../utilities/apiClients';
import { Tooltip } from '@mui/material';

function BannerUpload() {
    const [bannerList, setBannerList] = useState({
        boys: [],
        girls: []
    });
    const [banners, setBanners] = useState({
        girls: { file: null, url: '', previewSrc: '', message: '' },
        boys: { file: null, url: '', previewSrc: '', message: '' }
    });

    const buttonData = [
        {
            name: "Girls",
            info: "Upload Banner For Girls By Clicking Here"
        },
        {
            name: "Boys",
            info: "Upload Banner For Boys By Clicking Here"
        },
    ];
      const [activeComponent, setActiveComponent] = useState("Girls");
    

    const fetchData = () => {
        UserApiClient.get('api/banner/setting/', {}, false).then(async res => {
            if (res.success) {
                setBannerList({
                    boys: res.data.data.boys_banners || [],
                    girls: res.data.data.girls_banners || []
                });
            }
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleFileChange = (gender, event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBanners(prev => ({
                    ...prev,
                    [gender]: { ...prev[gender], file: file, previewSrc: reader.result }
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUrlChange = (gender, event) => {
        const newUrl = event.target.value;
        setBanners(prev => ({
            ...prev,
            [gender]: { ...prev[gender], url: newUrl }
        }));
    };

    const handleSubmit = async (gender, event) => {
        event.preventDefault();
        const { file, url } = banners[gender];
        if (!file || !url) {
            setBanners(prev => ({
                ...prev,
                [gender]: { ...prev[gender], message: 'Both a file and a URL are required.' }
            }));
            return;
        }

        const formData = new FormData();
        formData.append('image', file);
        formData.append('name', url);
        formData.append('type', gender);

        try {
            UserApiClient.post('api/banner/setting/', formData, true, true).then(async res => {
                if (res.success) {
                    setBanners(prev => ({
                        ...prev,
                        [gender]: { file: null, url: '', previewSrc: '', message: 'Banner uploaded successfully!' }
                    }));
                    fetchData();
                } else {
                    setBanners(prev => ({
                        ...prev,
                        [gender]: { ...prev[gender], message: 'Failed to upload banner.' }
                    }));
                }
            });
        } catch {
            setBanners(prev => ({
                ...prev,
                [gender]: { ...prev[gender], message: 'Server error or network issue.' }
            }));
        }
    };

    // Function to delete a banner
    const deleteBanner = async (gender, id) => {
        try {
            // Creating the payload that includes the bannerId
            const payload = { id };

            const res = await UserApiClient.delete('api/banner/setting/', payload, true);
            if (res.success) {
                fetchData(); // Re-fetch data after deletion
            } else {
                alert('Failed to delete banner.');
            }
        } catch {
            alert('Server error or network issue.');
        }
    };


    return (
        <>
        <div className="flex justify-center ms-10 items-center mb-5 mt-12 gap-2">
            {buttonData.map((item, index) => (
                <Tooltip title={item.info} key={index}>
                    <button
                        className={`flex justify-center items-center gap-2 rounded-lg ${activeComponent === item.name
                            ? "bg-red-700 text-white"
                            : "bg-stone-300"
                            } text-black text-lg py-2 px-5 lg:w-52`}
                        onClick={() => setActiveComponent(item.name)}
                    >
                        {item.name}
                    </button>
                </Tooltip>
            ))}
        </div>
    
        {activeComponent === "Girls" && (
            <div className="flex xl:flex-nowrap sm:flex-wrap md:flex-wrap lg:flex-wrap justify-center xl:mx-0 p-4">
                <div className='w-full md:w-1/2 m-3 mt-5 xl:m-2'>
                    <h1 className="text-2xl font-bold mb-2">Upload Banner For Girls</h1>
                    <form onSubmit={(e) => handleSubmit('girls', e)} className="flex flex-col gap-2">
                        <input type="file" onChange={(e) => handleFileChange('girls', e)} accept="image/*" className="py-2 px-4" />
                        <input type="text" value={banners.girls.url} onChange={(e) => handleUrlChange('girls', e)} placeholder="Enter banner url" className="border p-2" />
                        <button type="submit" className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            Upload Banner
                        </button>
                    </form>
                    {banners.girls.message && <p className="text-red-500">{banners.girls.message}</p>}
                    {banners.girls.previewSrc && (
                        <div className="mt-3">
                            <h3>Preview:</h3>
                            <img src={banners.girls.previewSrc} alt="Banner Preview" style={{ maxWidth: '100%' }} />
                        </div>
                    )}
                    {bannerList.girls.length > 0 && (
                        <div className="mt-3">
                            <h3>Uploaded Banners:</h3>
                            {bannerList.girls.map((item) => (
                                <div key={item.id} className="mb-4">
                                    <p><strong>Redirect URL:</strong> {item.name}</p>
                                    <img src={item.url} alt="Banner" style={{ maxWidth: '100%' }} />
                                    <button
                                        onClick={() => deleteBanner('girls', item.id)}
                                        className="mt-2 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3 rounded"
                                    >
                                        Delete
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        )}
    
        {activeComponent === "Boys" && (
            <div className="flex xl:flex-nowrap sm:flex-wrap md:flex-wrap lg:flex-wrap justify-center xl:mx-0 p-4">
                <div className='w-full md:w-1/2 m-3 mt-5 xl:m-2'>
                    <h1 className="text-2xl font-bold mb-2">Upload Banner For Boys</h1>
                    <form onSubmit={(e) => handleSubmit('boys', e)} className="flex flex-col gap-2">
                        <input type="file" onChange={(e) => handleFileChange('boys', e)} accept="image/*" className="py-2 px-4" />
                        <input type="text" value={banners.boys.url} onChange={(e) => handleUrlChange('boys', e)} placeholder="Enter banner url" className="border p-2" />
                        <button type="submit" className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            Upload Banner
                        </button>
                    </form>
                    {banners.boys.message && <p className="text-red-500">{banners.boys.message}</p>}
                    {banners.boys.previewSrc && (
                        <div className="mt-3">
                            <h3>Preview:</h3>
                            <img src={banners.boys.previewSrc} alt="Banner Preview" style={{ maxWidth: '100%' }} />
                        </div>
                    )}
                    {bannerList.boys.length > 0 && (
                        <div className="mt-3">
                            <h3>Uploaded Banners:</h3>
                            {bannerList.boys.map((item) => (
                                <div key={item.id} className="mb-4">
                                    <p><strong>Redirect URL:</strong> {item.name}</p>
                                    <img src={item.url} alt="Banner" style={{ maxWidth: '100%' }} />
                                    <button
                                        onClick={() => deleteBanner('boys', item.id)}
                                        className="mt-2 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3 rounded"
                                    >
                                        Delete
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        )}
    </>
    
    );
}

export default BannerUpload;
