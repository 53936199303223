import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
// import UserApiClient from '../../../utilities/apiClients';
import { BaseApiUrl } from '../../../utilities/enviroment';

const BASE_URL = BaseApiUrl + "api/";


function Blogs() {
    const [blogs, setBlogs] = useState([]);
    const carouselRef = useRef(null);  

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${BASE_URL}show_blog_post_public/?limit=4`);
                setBlogs(response.data);
                // console.log(response.data)
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);
    // useEffect(() => {
    //     const fetchBlogs = async () => {

    //         await UserApiClient.get('api/show_blog_post_public/?limit=4', {}, false).then(async (res) => {
    //             if (res.success) {
    //                 setBlogs(res.data);
    //             }
    //         })
    //     };

    //     fetchBlogs();
    // }, []);

    // function stripHtml(html) {
    //     const div = document.createElement('div');
    //     div.innerHTML = html;
    //     return div.textContent || div.innerText || '';
    // }

    // Slide the carousel to the left
    // const slideLeft = () => {
    //     if (carouselRef.current) {
    //         carouselRef.current.scrollBy({
    //             left: -carouselRef.current.offsetWidth / 2, // Adjust scroll amount (half the container width)
    //             behavior: 'smooth',
    //         });
    //     }
    // };

    // Slide the carousel to the right
    // const slideRight = () => {
    //     if (carouselRef.current) {
    //         carouselRef.current.scrollBy({
    //             left: carouselRef.current.offsetWidth / 2, // Adjust scroll amount (half the container width)
    //             behavior: 'smooth',
    //         });
    //     }
    // };

    return (
        <div>
            <section className="pb-4">
                <div>
                    <h2 className="font-manrope text-3xl sm:text-[2rem] font-bold text-gray-800 text-center mb-2">OUR LATEST BLOGS</h2>
                    <h4 className="font-manrope text-[20px] sm:text-2xl font-bold text-gray-900 text-center mb-2">FROM OUR AI GIRLFRIEND BLOG</h4>
                    <h4 className="font-manrope text-[14px] sm:text-lg font-bold text-gray-900 text-center mb-10 mx-2 capitalize">
                        Wondering about the concept of an AI girlfriend? Visit our blog to explore comprehensive details on the subject of AI girlfriends.
                    </h4>

                    {/* Custom Carousel */}
                    <div className="relative">
                        <div className="overflow-hidden">
                            <div
                                ref={carouselRef}  // Using ref to target the carousel
                                className="flex transition-all duration-500"
                                style={{ scrollBehavior: 'smooth' }}
                            >
                                {blogs.length > 0 ? (
                                    blogs.map(blog => (
                                        <div
                                            key={blog.blog_id}
                                            className="flex-none w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4"
                                        >
                                            <div className="group w-full border border-gray-300 rounded-2xl">
                                                <div className="flex items-center">
                                                    <img loading='lazy'
                                                        src={blog.image} // Ensure this property exists and contains the correct image URL
                                                        alt={blog.title}
                                                        className="h-72 object-cover rounded-t-2xl w-full"
                                                    />
                                                </div>
                                                <div className="px-4 py-2 lg:px-6 lg:py-2 bg-white transition-all duration-300 rounded-b-2xl group-hover:bg-gray-50">
                                                    <span className="text-[#99000b] font-medium mb-3 block">{blog.formattedDate}</span>
                                                    <div className="overflow-hidden">
                                                        <p className=' text-gray-900 '> <i className="fa-solid fa-calendar-day mr-2"></i> {new Date(blog.created_at).toLocaleDateString('en-CA')}</p>
                                                        <p className=" lg:h-[5rem] text-lg text-gray-900 font-medium leading-8 overflow-hidden">
                                                            {blog.title}
                                                        </p>
                                                    </div>

                                                    {/* <p className="text-gray-500 leading-6 mb-10">
                                                        {blog.description?.length > 100 ? stripHtml(blog.description).slice(0, 100) + '...' : stripHtml(blog.description)}
                                                    </p> */}
                                                    <a
                                                        href={`/singleBlog/${blog.slug}`}
                                                        className="cursor-pointer text-lg text-[#99000b] font-semibold"
                                                    >
                                                        Read more..
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>No blogs available at the moment.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {blogs.length > 0 ? (
                <div className='text-center py-10'>
                    <Link className="border-2 px-5 border-[#99000B] p-2 rounded-full transition-colors duration-300 bg-[#99000B] text-white" to="/blogs">Read More Blogs</Link>
                </div>
            )
                :
                ""
            }
        </div>
    );
}

export default Blogs;
