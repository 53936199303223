import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
    Relationshipicon,
    Location,
    age,
    brest,
    country,
    eyes,
    hair,
    occupation,
    manbody,
    personality,
    Bodyicon,
    Bodyback,
} from "../../assets";
import loaders from "../../frontend/components/loaders";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { BaseApiUrl } from "../../utilities/enviroment";
import Footer from "../../frontend/components/Footer";
import UserApiClient from "../../utilities/apiClients";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import _ from 'lodash'

function AllModels() {
    const navigate = useNavigate();
    const [staticData, setStaticData] = useState({
        RealisticGirl: [],
        RealisticBoy: [],
        AnimeGirl: [],
        AnimeBoy: []
    });
    const [imgdata, setImgdata] = useState([]);
    const [imgdataanime, setImgdataanime] = useState([]);
    const [imgboydata, setImgboydata] = useState([]);
    const [imgboydataani, setImgboydataani] = useState([]);
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const [girlAnimedata, setGirlAnimedata] = useState([]); // Default to empty array
    const [girlRealmedata, setGirlRealmedata] = useState([]); // Default to empty array
    const [backpage, setBackpage] = useState([]); // Default to empty array
    const [boyRealmedata, setBoyRealmedata] = useState([]); // Default to empty array
    const [loading, setLoading] = useState(false); // Loading state
    const [error, setError] = useState(null);
    const [deletedRealGItems, setDeletedRealGItems] = useState([-1]);
    const [deletedAnimeItems, setDeletedAnimeItems] = useState([-1]);
    const [deletedRealBItems, setDeletedRealBItems] = useState([-1]);
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
    const [isModalOpenStatic, setIsModalOpenStatic] = useState(false); // Modal visibility state
    const [modalData, setModalData] = useState(null); // Data for the modal
    const [modalDataStatic, setModalDataStatic] = useState(null); // Data for the modal
    const [modalImg, setModalImg] = useState(null); // Image for modal
    const [liked, setLiked] = useState(false); // Likes count for modal
    const [likesCount, setLikesCount] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState('Static_Girls');
    const [botdetail, setBotdetail] = useState(); // Likes count for modal

    const loadStaticData = async () => {
        await UserApiClient.get(`assistant_api/all_static_data/`, {}, false).then(
            async (res) => {
                if (res.success) {
                    // Fetch data and slice it to get the first 10 items
                    setImgdata(res.data?.static_data?.RealisticGirl || []);
                    setImgdataanime(res.data?.static_data?.AnimeGirl || []);
                    setImgboydata(res.data?.static_data?.RealisticBoy || []);
                    setImgboydataani(res.data?.static_data?.AnimeBoy || []);
                }
            }
        );
    };

    useEffect(() => {
        loadStaticData()
    }, [])

    const BASE_URL = BaseApiUrl + "assistant_api/";

    const NewGenerate = async () => {
        loaders(true, "heart");
        setError(null);
        try {
            const authToken = localStorage.getItem("token");
            const response = await axios.get(`${BASE_URL}bot_fetch/`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            if (response.status === 200) {
                const resdata = response.data.data
                setBackpage(resdata[0].assistant_id)
                setGirlRealmedata(resdata.filter(item => item.Butt_Size !== '' && item.style === 'Realistic'));
                setBoyRealmedata(resdata.filter(item => item.Butt_Size === '' && item.style === 'Realistic'));
                setGirlAnimedata(resdata.filter(item => item.style === 'Anime'));
            } else {
                setError("Unexpected response from the server.");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setError("No creations available.");
        } finally {
            loaders(false, "heart");
        }
    };

    useEffect(() => {
        NewGenerate();
    }, []);

    const handleAddProfileClick = () => {
        navigate("/CreateDreamGF"); // Replace '/add-profile' with your desired route
    };

    // useEffect(()=>{
    //     console.log("BOT",botdetail)
    // },[
    //     botdetail
    // ])
    const openModal = async (assistant_id, item) => {
        // console.log("assistant_id is required", assistant_id);
        // console.log(item)
        const capitalizeItem = _.mapKeys(item, (value, key) => _.startCase(key).replace(/ /g, '').replace(/([a-z])([A-Z])/g, '$1_$2'));
        console.log(capitalizeItem)

        setBotdetail(capitalizeItem);
        

        if (!assistant_id) {
            console.error("assistant_id is required");
            return;
        }
        // console.log("bot", botdetail)

        let selectedImage = null;
        const imgdataList = [imgdata]; // Add more lists here if necessary, like imgdataanime, imgboydata

        for (let list of imgdataList) {
            selectedImage = list.find((item) => item.assistant_id === assistant_id);
            if (selectedImage) break;
        }

        if (selectedImage) {
            setModalImg(selectedImage.Image); // Set the image for the modal
        }

        const payload = { assistant_id };
        try {
            const authToken = localStorage.getItem("token");
            const response = await axios.get(
                `${BASE_URL}fetch_static_data/`,
                {
                    params: payload,
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status !== 200) {
                throw new Error("Network response was not ok");
            }
            const data = response.data;
            setModalData(data);
            setLikesCount(data.likes); // Set the likes count
            setIsModalOpen(true);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    const closeModal = () => {
        setIsModalOpen(false);
        setModalData(null);
        setBotdetail(null)
    };

    const toggleLike = async (assistant_id) => {
        const u_id = localStorage.getItem("u_id");
        const payload = { assistant_id: assistant_id, u_id: u_id };

        try {
            const authToken = localStorage.getItem("token");
            const response = await axios.post(`${BASE_URL}like_static/`, payload, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                setLiked(!liked);
                console.log(response.data, "asasddasasd");

                setLikesCount(response.data.likes);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const [isLoadingsingleimage, setIsLoadingsingleimage] = useState(true);

    // Handler for when the image finishes loading
    const handleImageLoad = () => {
        setIsLoadingsingleimage(false);
    };

    const itemsToDisplay = botdetail
        ? (botdetail.Butt_Size === "" || botdetail.Butt_Size === "NA") &&
            (botdetail.Breast_Size === "" || botdetail.Breast_Size === "NA")
            ? [
                {
                    label: "Relationship:",
                    value: botdetail.Relationship?botdetail.Relationship:'N/A',
                    src: Relationshipicon,  
                },
                { label: "Age:", value: botdetail.Age?botdetail.Age:'N/A', src: age },
                {
                    label: "Occupation:",
                    value: botdetail.Occupation?botdetail.Occupation:'N/A',
                    src: occupation,
                },
                {
                    label: "Personality:",
                    value: botdetail.Personality?botdetail.Personality:'N/A',
                    src: personality,
                },
                {
                    label: "Hair Style and Color:",
                    value: `${botdetail.Hair_Style?botdetail.Hair_Style:'N/A'} ${botdetail.Hair_Color?botdetail.Hair_Color:'N/A'}`,
                    src: hair,
                },
                { label: "Eyes:", value: botdetail.Eyes?botdetail.Eyes:'N/A', src: eyes },
                { label: "Ethnicity:", value: botdetail.Ethnicity?botdetail.Ethnicity:'N/A', src: Location },
                { label: "Region:", value: botdetail.region?botdetail.region:'N/A', src: country },
                { label: "Body Type:", value: botdetail.Body_Type?botdetail.Body_Type:'N/A', src: Bodyicon },
            ]
            : [
                {
                    label: "Relationship:",
                    value: botdetail.Relationship?botdetail.Relationship:'N/A',
                    src: Relationshipicon,
                },
                { label: "Age:", value: botdetail.Age?botdetail.Age:'N/A', src: age },
                {
                    label: "Occupation:",
                    value: botdetail.Occupation?botdetail.Occupation:'N/A',
                    src: occupation,
                },
                {
                    label: "Personality:",
                    value: botdetail.Personality?botdetail.Personality:'N/A',
                    src: personality,
                },
                {
                    label: "Hair Style and Color:",
                    value: `${botdetail.Hair_Style?botdetail.Hair_Style:'N/A'} ${botdetail.Hair_Color?botdetail.Hair_Color:'N/A'}`,
                    src: hair,
                },
                { label: "Eyes:", value: botdetail.Eyes?botdetail.Eyes:'N/A', src: eyes },
                { label: "Ethnicity:", value: botdetail.Ethnicity?botdetail.Ethnicity:'N/A', src: Location },
                { label: "Region:", value: botdetail.region?botdetail.region:'N/A', src: country },
                { label: "Body Type:", value: botdetail.Body_Type?botdetail.Body_Type:'N/A', src: Bodyicon },
                { label: "Breast Size:", value: botdetail.Breast_Size?botdetail.Breast_Size:'N/A', src: brest },
                { label: "Butt Size:", value: botdetail.Butt_Size?botdetail.Butt_Size:'N/A', src: Bodyback },
            ]
        : null; //

    // console.log(botdetailStatic)
    const generatepage = (id) => {
        navigate(`/dashboard/generate/${id}`);
    };

    const deletegeneration = (assistant_id, index, character) => {
        console.log("aaaaaaaaa", character)
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#000",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                UserApiClient.delete("assistant_api/bot_delete/", { assistant_id }, true, true, false)
                    .then(async (res) => {
                        if (res.data) {
                            Swal.fire({
                                title: "Deleted!",
                                text: "Your file has been deleted.",
                                icon: "success"
                            });
                            if (character === 'GReal') {
                                setDeletedRealGItems((prevDeletedItems) => prevDeletedItems.includes(index) ? prevDeletedItems : [...prevDeletedItems, index]);
                            } else if (character === 'Anime') {
                                setDeletedAnimeItems((prevDeletedItems) => prevDeletedItems.includes(index) ? prevDeletedItems : [...prevDeletedItems, index]);
                            } else {
                                setDeletedRealBItems((prevDeletedItems) => prevDeletedItems.includes(index) ? prevDeletedItems : [...prevDeletedItems, index]);
                            }

                        } else {
                            setError("");
                            Swal.fire(
                                'Our Servers are busy!',
                                'Please wait a moment and try again.',
                                'warning',
                                3000
                            );
                        }
                    })
                    .catch((error) => {
                        setError("");
                        Swal.fire(
                            'Our Servers are busy!',
                            'Please wait a moment and try again.',
                            'error',
                            3000
                        );
                    });
            }
        });
    };


    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };
    const user = useSelector((state) => state.user);
    const Chatnow = async (id) => {
        if (user.isAuthenticated) {
            await UserApiClient.post(
                "assistant_api/static_chat/",
                { assistant_id: id, u_id: user.user.id },
                true
            ).then(async (res) => {
                if (res.success) {
                    navigate(`/aichat/${id}`);
                }
            });
        } else {
            navigate("/login");
        }
    };

    // console.log("sssssssss", selectedCategory)

    return (
        <>
            <Helmet>
                <title>Eromantic.ai- Create Your AI Gf/Bf and Start Chatting</title>
                <meta
                    name="description"
                    content="Your dream companion awaits! Create your AI Girlfriend, shape her look, personality, and bring her to life in one click."
                />
                <meta
                    name="keywords"
                    content="AI Girlfriend, Virtual Girlfriend, Anime Companions, Interactive AI, NSFW-friendly"
                />
                {/* You can add more meta tags if needed */}
            </Helmet>
                <div className="flex items-center md:justify-center justify-between mb-1 sm:mb-10 p-2">
                <div className="md:hidden flex p-1 bg-[#676464] rounded-lg ml-1">
                    <button onClick={() => navigate(`/dashboard/generate/${backpage}`)} className={`px-6 py-1 text-white rounded-lg`}>
                        <i className="fa-solid fa-arrow-left"></i>
                    </button>
                </div>
                <h1 className="block sm:hidden text-2xl font-bold text-center ">All Models</h1>
                <div className="md:hidden flex p-1 bg-[#676464] rounded-lg ml-1">
                    <button onClick={() => navigate(`/CreateDreamGF`)} className={`px-6 py-1 text-white rounded-lg`}>
                        <i class="fa-solid fa-wand-magic-sparkles"></i>
                    </button>
                </div>
                </div>
                <div className="flex items-center flex-wrap justify-center md:justify-between rounded-lg w-full px-5 py-1 mt-2 mb-1 sm:mb-10 gap-5">
                    {selectedCategory === "Girls" || selectedCategory === "Anime" || selectedCategory === "Boys" ?
                        <div className="flex p-1 bg-[#676464] rounded-lg items-center">
                            <button onClick={() => handleCategoryChange('Girls')} className={`px-6 py-1 text-white rounded-lg ${selectedCategory === 'Girls' ? "bg-[#990013]" : ''}`}><i className="fa-solid fa-venus mr-2"></i>Girls</button>
                            <button onClick={() => handleCategoryChange('Anime')} className={`px-6 py-1 text-white rounded-lg ${selectedCategory === 'Anime' ? "bg-[#990013]" : ''}`}><i className="fa-solid fa-wand-magic-sparkles mr-2"></i>Anime</button>
                            <button onClick={() => handleCategoryChange('Boys')} className={`px-6 py-1 text-white rounded-lg ${selectedCategory === 'Boys' ? "bg-[#990013]" : ''}`}><i className="fa-solid fa-mars mr-2"></i>Boys</button>
                        </div>
                        :
                        <div className="flex p-1 bg-[#676464] rounded-lg items-center">
                            <button onClick={() => handleCategoryChange('Static_Girls')} className={`px-6 py-1 text-white rounded-lg ${selectedCategory === 'Static_Girls' ? "bg-[#990013]" : ''}`}><i className="fa-solid fa-venus mr-2"></i>Girls</button>
                            <button onClick={() => handleCategoryChange('Static_Anime')} className={`px-6 py-1 text-white rounded-lg ${selectedCategory === 'Static_Anime' ? "bg-[#990013]" : ''}`}><i className="fa-solid fa-wand-magic-sparkles mr-2"></i>Anime</button>
                            <button onClick={() => handleCategoryChange('Static_Boys')} className={`px-6 py-1 text-white rounded-lg ${selectedCategory === 'Static_Boys' ? "bg-[#990013]" : ''}`}><i className="fa-solid fa-mars mr-2"></i>Boys</button>
                        </div>
                    }
                    <div className="hidden sm:block"><h1 className="text-4xl font-bold text-center ">All Models</h1></div>
                    <div className="flex items-center">
                        <div className="flex items-center justify-center p-1 bg-[#676464] rounded-lg">
                            <button onClick={() => handleCategoryChange('Static_Girls')} className={`px-6 py-1 text-white rounded-lg mr-1 ${selectedCategory === "Static_Girls" || selectedCategory === "Static_Anime" || selectedCategory === "Static_Boys" ? "bg-[#990013]" : ''}`}><i className="fa-solid fa-mars mr-2"></i>Discover</button>
                            <button onClick={() => handleCategoryChange('Girls')} className={`px-6 py-1 text-white rounded-lg ${selectedCategory === "Girls" || selectedCategory === "Anime" || selectedCategory === "Boys" ? "bg-[#990013]" : ''}`}><i className="fa-solid fa-mars mr-2"></i>My AI</button>
                        </div>
                        <div className="hidden md:flex p-1 bg-[#676464] rounded-lg ml-1">
                            <button onClick={() => navigate(`/CreateDreamGF`)} className={`px-6 py-1 text-white rounded-lg`}>
                                <i class="fa-solid fa-wand-magic-sparkles"></i>
                            </button>
                        </div>
                        <div className="hidden md:flex p-1 bg-[#676464] rounded-lg ml-1">
                            <button onClick={() => navigate(`/dashboard/generate/${backpage}`)} className={`px-6 py-1 text-white rounded-lg`}>
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                        </div>
                    </div>

                </div>
            <div className="min-h-screen p-8">


                {/* Loading and Error Handling */}
                {loading && <p className="text-center text-xl">Loading...</p>}
                {error && <p className="text-center text-red-500">{error}</p>}

                {/* Rendering Image Data */}
                <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                    {
                        selectedCategory === 'Girls' ?
                            <>
                                {girlRealmedata.length > 0
                                    ? girlRealmedata.map((item, index) => (
                                        <div key={index} className="relative group">
                                            {/* Image with hover effects */}
                                            <div className="overflow-hidden rounded-xl">
                                                {isLoadingsingleimage && (
                                                    <div className="h-80 w-full xl:h-[500px] bg-[#4b556354] rounded-2xl p-2 flex justify-center items-center">
                                                        <div className="loader"></div>
                                                    </div>
                                                )}
                                                <img loading='lazy'
                                                    src={item.Image}
                                                    alt={`Img of ${item.name}`}
                                                    className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out group-hover:scale-105 group-hover:shadow-lg  ${deletedRealGItems.includes(index) ? 'hidden' : ''}`}
                                                    onClick={() => openModal(item.assistant_id, item)} // Open modal on image click
                                                    onLoad={handleImageLoad}
                                                />
                                            </div>

                                            {/* Overlay with text */}
                                            <div
                                                onClick={(e) => { e.stopPropagation(); openModal(item.assistant_id, item) }}
                                                className={`absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-300 group-hover:opacity-100  ${deletedRealGItems.includes(index) ? 'hidden' : ''}`}
                                            >
                                                {/* Button in top-right corner */}
                                                <div className="grid justify-end">
                                                    <button
                                                        onClick={(e) => { e.stopPropagation(); Chatnow(item.assistant_id) }}
                                                        className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake"
                                                    >
                                                        <img loading='lazy'
                                                            src="/assets/img/Group-icon.png"
                                                            alt="Icon"
                                                            className="w-10 h-10"
                                                        />
                                                    </button>
                                                    <button onClick={(e) => { e.stopPropagation(); generatepage(item.assistant_id); }}
                                                        className="text-white font-medium rounded-2xl px-3 pb-2 hover:animate-shake"
                                                    >
                                                        <img loading='lazy'
                                                            src="/assets/img/Group-icon-msg.png"
                                                            alt="Icon"
                                                            className="w-10 h-10"
                                                        />
                                                    </button>
                                                    <button onClick={(e) => { e.stopPropagation(); deletegeneration(item.assistant_id, index, 'GReal'); }} className="flex items-center justify-center bg-[#ff002c] bg-opacity-90 h-10 w-10 rounded-full mx-3 border-2 duration-300 border-red-600 hover:border-red-500 hover:animate-shake">
                                                        <i className="fa-regular fa-trash-can fa-lg text-white"></i>
                                                    </button>
                                                </div>

                                                {/* Bottom text container */}
                                                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg">
                                                    <div className="flex justify-between py-1">
                                                        <span className="text-lg lg:text-lg font-medium">
                                                            {item.name}
                                                        </span>
                                                        <span className="text-lg lg:text-lg font-medium">
                                                            {item.age}
                                                        </span>
                                                    </div>
                                                    <p className="text-sm lg:text-base">
                                                        {(item.Summary || "").slice(0, 60)}...
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    : !loading && <p className="text-center text-xl"></p>
                                }
                            </>
                            :
                            selectedCategory === 'Anime' ?
                                <>
                                    {girlAnimedata.length > 0
                                        ? girlAnimedata.map((item, index) => (
                                            <div key={index} className={`relative group ${deletedAnimeItems.includes(index) ? 'hidden' : ''}`}>
                                                {/* Image with hover effects */}
                                                <div className="overflow-hidden rounded-xl">
                                                    {isLoadingsingleimage && (
                                                        <div className="h-80 w-full xl:h-[500px] bg-[#4b556354] rounded-2xl p-2 flex justify-center items-center">
                                                            <div className="loader"></div>
                                                        </div>
                                                    )}
                                                    <img loading='lazy'
                                                        src={item.Image}
                                                        alt={`Img of ${item.name}`}
                                                        className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out group-hover:scale-105 group-hover:shadow-lg`}
                                                        onClick={() => openModal(item.assistant_id, item)} // Open modal on image click
                                                        onLoad={handleImageLoad}
                                                    />
                                                </div>

                                                {/* Overlay with text */}
                                                <div
                                                    onClick={(e) => { e.stopPropagation(); openModal(item.assistant_id, item) }}
                                                    className={`absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-300 group-hover:opacity-100`}
                                                >
                                                    {/* Button in top-right corner */}
                                                    <div className="grid justify-end">
                                                        <button
                                                            onClick={(e) => { e.stopPropagation(); Chatnow(item.assistant_id) }}
                                                            className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake"
                                                        >
                                                            <img loading='lazy'
                                                                src="/assets/img/Group-icon.png"
                                                                alt="Icon"
                                                                className="w-10 h-10"
                                                            />
                                                        </button>
                                                        <button
                                                            onClick={() => generatepage(item.assistant_id)}
                                                            className="text-white font-medium rounded-2xl px-3 pb-2 hover:animate-shake"
                                                        >
                                                            <img loading='lazy'
                                                                src="/assets/img/Group-icon-msg.png"
                                                                alt="Icon"
                                                                className="w-10 h-10"
                                                            />
                                                        </button>
                                                        <button onClick={(e) => { e.stopPropagation(); deletegeneration(item.assistant_id, index, 'Anime'); }} className="flex items-center justify-center bg-[#ff002c] bg-opacity-90 h-10 w-10 rounded-full mx-3 border-2 duration-300 border-red-600 hover:border-red-500 hover:animate-shake">
                                                            <i className="fa-regular fa-trash-can fa-lg text-white"></i>
                                                        </button>
                                                    </div>

                                                    {/* Bottom text container */}
                                                    <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg">
                                                        <div className="flex justify-between py-1">
                                                            <span className="text-lg lg:text-lg font-medium">
                                                                {item.name}
                                                            </span>
                                                            <span className="text-lg lg:text-lg font-medium">
                                                                {item.age}
                                                            </span>
                                                        </div>
                                                        <p className="text-sm lg:text-base">
                                                            {(item.Summary || "").slice(0, 60)}...
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        : !loading && <p className="text-center text-xl"></p>
                                    }
                                </>
                                :
                                selectedCategory === 'Boys' ?
                                    <>
                                        {boyRealmedata.length > 0
                                            ? boyRealmedata.map((item, index) => (
                                                <div key={index} className="relative group">
                                                    {/* Image with hover effects */}
                                                    <div className="overflow-hidden rounded-xl">
                                                        {isLoadingsingleimage && (
                                                            <div className="h-80 w-full xl:h-[500px] bg-[#4b556354] rounded-2xl p-2 flex justify-center items-center">
                                                                <div className="loader"></div>
                                                            </div>
                                                        )}
                                                        <img loading='lazy'
                                                            src={item.Image}
                                                            alt={`Img of ${item.name}`}
                                                            className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out group-hover:scale-105 group-hover:shadow-lg ${deletedRealBItems.includes(index) ? 'hidden' : ''}`}
                                                            onClick={() => openModal(item.assistant_id, item)} // Open modal on image click
                                                            onLoad={handleImageLoad}
                                                        />
                                                    </div>

                                                    {/* Overlay with text */}
                                                    <div
                                                        onClick={(e) => { e.stopPropagation(); openModal(item.assistant_id, item) }}
                                                        className={`absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-300 group-hover:opacity-100 ${deletedRealBItems.includes(index) ? 'hidden' : ''}`}
                                                    >
                                                        {/* Button in top-right corner */}
                                                        <div className="grid justify-end">
                                                            <button
                                                                onClick={(e) => { e.stopPropagation(); Chatnow(item.assistant_id) }}
                                                                className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake"
                                                            >
                                                                <img loading='lazy'
                                                                    src="/assets/img/Group-icon.png"
                                                                    alt="Icon"
                                                                    className="w-10 h-10"
                                                                />
                                                            </button>
                                                            <button
                                                                onClick={() => generatepage(item.assistant_id)}
                                                                className="text-white font-medium rounded-2xl px-3 pb-2 hover:animate-shake"
                                                            >
                                                                <img loading='lazy'
                                                                    src="/assets/img/Group-icon-msg.png"
                                                                    alt="Icon"
                                                                    className="w-10 h-10"
                                                                />
                                                            </button>
                                                            <button onClick={(e) => { e.stopPropagation(); deletegeneration(item.assistant_id, index, "BReal"); }} className="flex items-center justify-center bg-[#ff002c] bg-opacity-90 h-10 w-10 rounded-full mx-3 border-2 duration-300 border-red-600 hover:border-red-500 hover:animate-shake">
                                                                <i className="fa-regular fa-trash-can fa-lg text-white"></i>
                                                            </button>
                                                        </div>

                                                        {/* Bottom text container */}
                                                        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg">
                                                            <div className="flex justify-between py-1">
                                                                <span className="text-lg lg:text-lg font-medium">
                                                                    {item.name}
                                                                </span>
                                                                <span className="text-lg lg:text-lg font-medium">
                                                                    {item.age}
                                                                </span>
                                                            </div>
                                                            <p className="text-sm lg:text-base">
                                                                {(item.Summary || "").slice(0, 60)}...
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            : !loading && <p className="text-center text-xl"></p>
                                        }
                                    </>
                                    :
                                    selectedCategory === 'Static_Anime' ?
                                        <>
                                            {imgdataanime.map((item, index) => (
                                                <div
                                                    key={index}
                                                    className="relative"
                                                    onMouseEnter={() => setHoveredIndex(index)}
                                                    onMouseLeave={() => setHoveredIndex(null)}
                                                >
                                                    <div className="overflow-hidden">
                                                        <img
                                                            loading="lazy"
                                                            src={
                                                                hoveredIndex === index
                                                                    ? item.character.img_url2
                                                                    : item.character.img_url
                                                            }
                                                            alt={`${item.character.name}`}
                                                            className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out ${hoveredIndex === index ? "scale-105" : ""
                                                                }`}
                                                        />
                                                    </div>
                                                    <div
                                                        id="char-info-GA"
                                                        className="absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700"
                                                        onClick={(e) => {
                                                            if (e.target.id === "char-info-GA")
                                                                openModal(item.assistant, item.character);
                                                        }}
                                                    >
                                                        <div className="grid justify-end pt-4">
                                                            <Tooltip title={"Chat Now"} placement="left">
                                                                <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                                    <img
                                                                        loading="lazy"
                                                                        src="/assets/img/Group-icon.png"
                                                                        onClick={() => Chatnow(item.assistant)}
                                                                        alt="Icon"
                                                                        className="w-10 h-10"
                                                                    />
                                                                </button>
                                                            </Tooltip>
                                                            <Tooltip title={"Image Create"} className="grid" placement="left">
                                                                <button
                                                                    onClick={() => navigate(`/dashboard/generate/${item.assistant}`)}
                                                                    className="text-white font-medium rounded-2xl px-3 pb-2 hover:animate-shake"
                                                                >
                                                                    <img loading='lazy'
                                                                        src="/assets/img/Group-icon-msg.png"
                                                                        alt="Icon"
                                                                        className="w-10 h-10"
                                                                    />
                                                                </button>
                                                            </Tooltip>
                                                        </div>
                                                        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg">
                                                            <div className="flex justify-between flex-wrap py-1">
                                                                <span className="text-lg lg:text-lg font-medium">
                                                                    {item.character.name}
                                                                </span>
                                                                <span className="text-lg lg:text-lg font-medium">
                                                                    {item.character.age}
                                                                </span>
                                                            </div>
                                                            <Tooltip
                                                                title={item.character.summary}
                                                                placement="top"
                                                            >
                                                                <p className="text-sm lg:text-base">
                                                                    {item.character.summary.slice(0, 60)}...
                                                                </p>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            {imgboydataani.map((item, index) => (
                                                <div
                                                    key={index}
                                                    className="relative"
                                                    onMouseEnter={() => setHoveredIndex(index)}
                                                    onMouseLeave={() => setHoveredIndex(null)}
                                                >
                                                    <div className="overflow-hidden">
                                                        <img
                                                            loading="lazy"
                                                            src={
                                                                hoveredIndex === index
                                                                    ? item.character.img_url2
                                                                    : item.character.img_url
                                                            }
                                                            alt={`${item.character.name}`}
                                                            className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out ${hoveredIndex === index ? "scale-105" : ""
                                                                }`}
                                                        />
                                                    </div>
                                                    {/* <div
                                                                    onClick={() => openModal(item.assistant, item.img_url2)}
                                                                    className="absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700"
                                                                  > */}
                                                    <div
                                                        id="char-info-BA"
                                                        className="absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700"
                                                        onClick={(e) => {
                                                            if (e.target.id === "char-info-BA")
                                                                openModal(item.assistant, item.character);
                                                        }}
                                                    >
                                                        <div className="grid justify-end pt-4">
                                                            <Tooltip title={"Chat Now"} placement="left">
                                                                <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                                    <img
                                                                        loading="lazy"
                                                                        onClick={() => Chatnow(item.assistant)}
                                                                        src="/assets/img/Group-icon.png"
                                                                        alt="Icon"
                                                                        className="w-10 h-10"
                                                                    />
                                                                </button>
                                                            </Tooltip>
                                                            <Tooltip title={"Image Create"} className="grid" placement="left">
                                                                <button
                                                                    onClick={() => navigate(`/dashboard/generate/${item.assistant}`)}
                                                                    className="text-white font-medium rounded-2xl px-3 pb-2 hover:animate-shake"
                                                                >
                                                                    <img loading='lazy'
                                                                        src="/assets/img/Group-icon-msg.png"
                                                                        alt="Icon"
                                                                        className="w-10 h-10"
                                                                    />
                                                                </button>
                                                            </Tooltip>
                                                        </div>
                                                        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg">
                                                            <div className="flex justify-between flex-wrap py-1">
                                                                <span className="text-lg lg:text-lg font-medium">
                                                                    {item.character.name
                                                                        ? item.character.name.charAt(0).toUpperCase() +
                                                                        item.character.name.slice(1).toLowerCase()
                                                                        : ""}
                                                                </span>

                                                                <span className="text-lg lg:text-lg font-medium">
                                                                    {item.character.age}
                                                                </span>
                                                            </div>
                                                            <Tooltip
                                                                title={item.character.summary}
                                                                placement="top"
                                                            >
                                                                <p className="text-sm lg:text-base">
                                                                    {item.character.summary.slice(0, 60)}...
                                                                </p>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                        :
                                        selectedCategory === 'Static_Girls' ?
                                            <>
                                                {imgdata &&
                                                    imgdata.map((item, index) => (
                                                        <div
                                                            key={index}
                                                            className={`relative ${index === 0
                                                                ? "rounded-md border-2 border-red-600 shadow-md shadow-red-800 "
                                                                : ""
                                                                }`} // Highlight first item
                                                            onMouseEnter={() => setHoveredIndex(index)}
                                                            onMouseLeave={() => setHoveredIndex(null)}
                                                        >
                                                            <div
                                                                className="overflow-hidden rounded-xl"
                                                                onClick={() =>
                                                                    openModal(item.assistant, item.character)
                                                                }
                                                            >
                                                                <img
                                                                    loading="lazy"
                                                                    src={
                                                                        hoveredIndex === index
                                                                            ? item.character.img_url2
                                                                            : item.character.img_url
                                                                    }
                                                                    alt={`${item.character.name}`}
                                                                    className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out ${hoveredIndex === index
                                                                        ? "scale-105 rounded-xl"
                                                                        : ""
                                                                        }`}
                                                                />
                                                            </div>
                                                            {/* <div
                                                                      className="absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700"
                                                                      onClick={() =>
                                                                        openModal(item.assistant, item.character.img_url)
                                                                      }
                                                                    > */}
                                                            <div
                                                                id="char-info-GR"
                                                                className="absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700"
                                                                onClick={(e) => {
                                                                    if (e.target.id === "char-info-GR")
                                                                        openModal(item.assistant, item.character);
                                                                }}
                                                            >
                                                                <div className="grid justify-end pt-4 rounded-lg">
                                                                    <Tooltip title={"Chat Now"} className="grid" placement="left">
                                                                        <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                                            <img
                                                                                loading="lazy"
                                                                                src="/assets/img/Group-icon.png"
                                                                                onClick={() => Chatnow(item.assistant)}
                                                                                alt="Icon"
                                                                                className="w-10 h-10"
                                                                            />
                                                                        </button>
                                                                    </Tooltip>
                                                                    <Tooltip title={"Image Create"} className="grid" placement="left">
                                                                        <button
                                                                            onClick={() => navigate(`/dashboard/generate/${item.assistant}`)}
                                                                            className="text-white font-medium rounded-2xl px-3 pb-2 hover:animate-shake"
                                                                        >
                                                                            <img loading='lazy'
                                                                                src="/assets/img/Group-icon-msg.png"
                                                                                alt="Icon"
                                                                                className="w-10 h-10"
                                                                            />
                                                                        </button>
                                                                    </Tooltip>
                                                                </div>
                                                                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg">
                                                                    <div className="flex justify-between flex-wrap py-1">
                                                                        <span className="text-lg lg:text-lg font-medium">
                                                                            {item.character.name}
                                                                        </span>
                                                                        <span className="text-lg lg:text-lg font-medium">
                                                                            {item.character.age}
                                                                        </span>
                                                                    </div>
                                                                    <Tooltip
                                                                        title={item.character.summary}
                                                                        placement="top"
                                                                    >
                                                                        <p className="text-[14px] lg:text-base">
                                                                            {item.character.summary.slice(0, 60)}...
                                                                        </p>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>

                                                            {/* Display "Lindi Creation" only for the first item */}
                                                            {index === 0 && (
                                                                <div
                                                                    onClick={() =>
                                                                        window.open(
                                                                            "https://www.instagram.com/lmonies/?hl=en",
                                                                            "_blank"
                                                                        )
                                                                    }
                                                                    className="absolute top-0 left-0 bg-[#ef444496] text-white font-bold p-1 m-2 rounded-lg"
                                                                >
                                                                    <i className="fa-brands fa-instagram mr-[2px]"></i>{" "}
                                                                    AI Avatar
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                            </>
                                            :
                                            selectedCategory === 'Static_Boys' ?
                                                <>
                                                    {imgboydata.map((item, index) => (
                                                        <div
                                                            key={index}
                                                            className="relative"
                                                            onMouseEnter={() => setHoveredIndex(index)}
                                                            onMouseLeave={() => setHoveredIndex(null)}
                                                        >
                                                            <div className=" overflow-hidden">
                                                                <img
                                                                    loading="lazy"
                                                                    src={
                                                                        hoveredIndex === index
                                                                            ? item.character.img_url2
                                                                            : item.character.img_url
                                                                    }
                                                                    alt={`${item.character.name}`}
                                                                    className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out ${hoveredIndex === index ? "scale-105" : ""
                                                                        }`}
                                                                />
                                                            </div>
                                                            {/* <div
                                                            onClick={() =>
                                                              openModal(item.assistant, item.character.img_url2)
                                                            }
                                                            className="absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700"
                                                          > */}
                                                            <div
                                                                id="char-info-BR"
                                                                className="absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700"
                                                                onClick={(e) => {
                                                                    if (e.target.id === "char-info-BR")
                                                                        openModal(item.assistant, item.character);
                                                                }}
                                                            >
                                                                <div className="grid justify-end pt-4">
                                                                    <Tooltip title={"Chat Now"} placement="left">
                                                                        <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                                            <img
                                                                                loading="lazy"
                                                                                onClick={() => Chatnow(item.assistant)}
                                                                                src="/assets/img/Group-icon.png"
                                                                                alt="Icon"
                                                                                className="w-10 h-10"
                                                                            />
                                                                        </button>
                                                                    </Tooltip>
                                                                    <Tooltip title={"Image Create"} className="grid" placement="left">
                                                                        <button onClick={() => navigate(`/dashboard/generate/${item.assistant}`)}
                                                                            className="text-white font-medium rounded-2xl px-3 pb-2 hover:animate-shake">
                                                                            <img loading='lazy' src="/assets/img/Group-icon-msg.png" alt="Icon" className="w-10 h-10" />
                                                                        </button>
                                                                    </Tooltip>
                                                                </div>
                                                                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg">
                                                                    <div className="flex justify-between flex-wrap py-1">
                                                                        <span className="text-lg lg:text-lg font-medium">
                                                                            {item.character.name
                                                                                ? item.character.name.charAt(0).toUpperCase() +
                                                                                item.character.name.slice(1).toLowerCase()
                                                                                : ""}
                                                                        </span>

                                                                        <span className="text-lg lg:text-lg font-medium">
                                                                            {item.character.age}
                                                                        </span>
                                                                    </div>
                                                                    <Tooltip
                                                                        title={item.character.summary}
                                                                        placement="top"
                                                                    >
                                                                        <p className="text-sm lg:text-base">
                                                                            {item.character.summary.slice(0, 60)}...
                                                                        </p>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                                : null
                    }


                </div>

                {/* Modal */}
                {isModalOpen && modalData && (
                    <div className="fixed inset-0 flex items-center justify-center">
                        {/* {console.log("MOdal DAta",modalData)} */}
                        <div
                            className="bg-white p-8 rounded w-[85%] md:w-[80%] mt-10 xxs:h-[70%] md:h-[80%] flex flex-col items-center justify-center"
                            style={{
                                // backgroundImage: `url(${modalImg})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                // filter: 'contrast(0.5)',
                            }}
                        >
                            <div className="relative -top-[3%] left-[50%]">
                                <button
                                    onClick={closeModal}
                                    className="text-black border-none  py-2 rounded"
                                >
                                    <i className="fa-regular fa-2xl fa-circle-xmark"></i>
                                </button>
                            </div>
                            <div
                                className="flex flex-col gap-5 sm:gap-16 sm:flex-row overflow-scroll"
                                style={{ scrollbarWidth: "none" }}
                            >
                                <div>
                                    <img loading='lazy'
                                        src={modalData.Image}
                                        alt="Generated Img"
                                        className="w-full h-[300px] sm:h-[330px] md:h-[400px] lg:w-[400px] xl:w-[500px] lg:h-[500px] xl:h-[600px] 2xl:w-[570px] 2xl:h-[621px] rounded-xl"
                                        style={{ objectFit: "cover" }}
                                    />
                                </div>
                                <div className="flex flex-col gap-8">
                                    <div className="flex justify-between">
                                        <h2 className="text-[#b30016d9] text-lg sm:text-xl font-bold md:text-2xl lg:text-4xl whitespace-nowrap">
                                            {modalData.name}
                                        </h2>
                                        <h2 className="text-[#b30016d9] text-lg sm:text-xl font-bold md:text-2xl lg:text-4xl whitespace-nowrap">
                                            <span
                                                onClick={() => toggleLike(modalData.assistant_id)}
                                                className="ms-5"
                                            >
                                                {liked ? (
                                                    <>
                                                        <i className="fa-solid fa-sm fa-heart"></i>{" "}
                                                        <span className="text-lg">{likesCount} Liked</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <i className="fa-regular fa-sm fa-heart"></i>{" "}
                                                        <span className="text-lg">{likesCount} Likes</span>
                                                    </>
                                                )}
                                            </span>
                                        </h2>
                                    </div>
                                    <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 mb-3">
                                        {itemsToDisplay.map(({ label, value, src }, index) => (
                                            <div key={index} className="flex  items-start gap-2">
                                                <img loading='lazy'
                                                    src={src}
                                                    className="icon w-5 md:w-10 mt-1 "
                                                    alt="icon"
                                                />

                                                <div className="flex flex-col">
                                                    <span className="text-muted text-gray-800 font-bold text-[14px] mo:text-lg md:text-xl  ">
                                                        {label}
                                                    </span>
                                                    <span className="uppercase text-[12px] mo:text-base md:text-lg">
                                                        {value}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="flex items-start">
                                        <span className="icon mt-1 mr-2 icon-summary"></span>
                                        <div className="flex flex-col">
                                            <span className="font-semibold text-muted text-base mo:text-lg md:text-xl lg:text-2xl ">
                                                About:
                                            </span>
                                            <span className="text-[12px] mo:text-base md:text-lg lg:text-xl">
                                                {modalData.Summary}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center w-full mt-7">
                                <button
                                    className="bg-red-800 text-white font-medium rounded-2xl mr-2 p-2 sm:p-4 lg:p-4 w-[35%] lg:w-[25%]"
                                    onClick={() => Chatnow(modalData.assistant_id)}
                                >
                                    Chat Now
                                </button>
                                <button
                                    className="bg-[#db3c3c] text-white font-medium rounded-2xl p-2 sm:p-4 lg:p-4 w-[35%] lg:w-[25%]"
                                    onClick={() => generatepage(modalData.assistant_id)}
                                >
                                    Generate Image
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
}

export default AllModels;
