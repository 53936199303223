import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Rectangle48 } from '../../assets';
import UserApiClient from "../../utilities/apiClients";

function ResetForgetPass(props) {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const uidb64 = query.get("uidb64");
  const token = query.get("token");
  if (!uidb64 || !token) {
    Swal.fire('Our Servers are busy!',
      'Please wait a moment and try again.',
      'warning', 3000);
  }

  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");

  const validatePassword = (password) => {
    const minLength = /.{8,}/; // Minimum 8 characters
    const hasLowercase = /[a-z]/; // At least one lowercase character
    const hasUpperCase = /[A-Z]/; // At least one uppercase character
    const hasSpecialChar = /[0-9!@#$%^&*(),.?":{}|<>]/; // At least one number, symbol, or whitespace character

    if (!minLength.test(password)) {
      return "Password must be at least 8 characters long.";
    }
    if (!hasLowercase.test(password)) {
      return "Password must contain at least one lowercase character.";
    }
    if (!hasUpperCase.test(password)) {
      return "Password must contain at least one Uppercase character.";
    }
    if (!hasSpecialChar.test(password)) {
      return "Password must contain at least one number, symbol, or whitespace character.";
    }
    return "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "password") {
      const validationError = validatePassword(value);
      setError(validationError);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (error.length > 0) return;
    await UserApiClient.post(
      `api/v1/reset-password/${uidb64}/${token}/`,
      formData
    ).then(async (res) => {
      if (res.success) {
        Swal.fire(res.data.message, "", "success", 3000);
        navigate("/login");
      }
    });
  };

  return (
    <div>
      <div>
        <div className="bg-white w-full flex justify-between font-josefin-sans h-full">
          <div className="hidden md:block md:w-[45%]">
            <img
              loading="lazy"
              src={Rectangle48}
              alt="Rectangle47"
              className=" w-full object-cover h-[100vh]"
            />
          </div>
          <div className="relative w-full md:w-[55%] flex justify-center items-center h-[100vh]">
            <button
              type="button"
              className="btn absolute top-3 left-3 text-lg text-[#ff0022] cursor-pointer flex items-center"
              onClick={() => navigate("/")}
            >
              <i className="fa-solid fa-circle-arrow-left mr-2"></i>Back
            </button>
            <div className="text-black flex flex-col items-center">
              <img
                loading="lazy"
                src="/assets/img/logowh.png"
                alt="Icon"
                className="w-[20rem] rounded-xl mb-5"
              />
              <h4 className="font-sans text-black text-[30px] md:text-[26px] xl:text-[33px] font-semibold leading-snug tracking-normal text-blue-gray-900">
                Enter New Password
              </h4>
              <small className="text-[14px] lg:text-[16px] text-center w-[300px] lg:w-[467px] my-5">
                No worries! Just enter your new password.
              </small>
              <form onSubmit={handleFormSubmit} className="space-y-6">
                <div className="form-group">
                  <input
                    type="text"
                    maxLength={100}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                    placeholder="New Password*"
                    name="password"
                    onChange={handleChange}
                    required
                  />
                  {error && <div className="text-[#ff0022] text-sm mt-1">{error}</div>}
                </div>

                <button
                  type="submit"
                  className="w-full py-2 bg-[#ff0022] text-white rounded-md hover:bg-[#ff0022] transition duration-300"
                >
                  Submit
                </button>

                <div className="mt-4 text-center">
                  <h6 className="text-sm">
                    Just Remember? <NavLink to="/login" className="text-[#ff0022] hover:text-[#ff0022]">Sign In</NavLink>
                  </h6>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetForgetPass;
