import React, { useState } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';

const UploadIDCard = ({ name }) => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedFile({
                    name: file.name,
                    type: file.type.startsWith('image') ? 'image' : 'document',
                    src: reader.result
                });
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="flex justify-center items-center w-full sm:w-[300px] md:w-[416px] h-[260px] md:h-[320px] xl:h-[593px] border-2 border-zinc-600 bg-[#6161614A] rounded-xl py-3 px-5 mt-6 md:mt-9">
            <div className="w-full">
                <label
                    htmlFor={`dropzone-file-${name}`}
                    className="flex flex-col items-center justify-center w-full rounded-lg cursor-pointer px-2 py-3"
                >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        {selectedFile ? (
                            selectedFile.type === 'image' ? (
                                <img loading ='lazy'
                                    src={selectedFile.src}
                                    alt="Selected"
                                    className="w-full object-cover mb-4"
                                />
                            ) : (
                                <p className="mb-4 text-white text-2xl text-center">{selectedFile.name}</p>
                            )
                        ) : (
                            <AiOutlinePlusCircle color='#616161' className='text-[50px] sm:text-[70px] xl:text-[100px]' />
                        )}
                        <input
                            id={`dropzone-file-${name}`}
                            type="file"
                            className="hidden"
                            accept="image/*,.pdf,.doc,.docx,.txt"  // Accept images and documents
                            onChange={handleFileChange}
                        />
                        <p className="mt-2 xl:mt-6 text-lg sm:text-xl xl:text-2xl text-zinc-600 text-center">
                            {selectedFile ? 'Change File' : name}
                        </p>
                    </div>
                </label>
            </div>
        </div>
    );
}

export default UploadIDCard;
