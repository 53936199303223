import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props) {
  return (
    <div className='flex flex-col'>
      <Box sx={{ position: 'relative', alignItems: 'center', justifyContent: 'center', display: 'inline-flex' }}>
        {/* <CircularProgress sx={{width:'62px !important', height:"62px !important",color:"#b0474f", right: 10,}} variant="determinate" {...props} /> */}
        <div className="loader-chat"></div>
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: '#ff4040', fontSize: "1rem", fontFamily: "FontAwesome", fontWeight: "bold" }}
          >
            {props.percentageShow ? `${Math.round(props.value)}%` : null}
            
          </Typography>
        </Box>
      </Box>
      <p className='text-center text-white text-sm'>{props.text ? null : <>
        Please Wait <br /> Image Creation May Take a Moment.
      </>}</p>
    </div>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */

  value: PropTypes.number.isRequired,
};

export default function CircularWithValueLabel({ isLoadingAIResponse, loadingText ,percentageShow}) {
  const [progress, setProgress] = React.useState(7);

  React.useEffect(() => {
    let timer;
    if (isLoadingAIResponse) {
      timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 98) {
            clearInterval(timer);
            return 99;
          }
          return prevProgress + 7;
        });
      }, 2500);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [isLoadingAIResponse]);

  return (
    isLoadingAIResponse && (
      <div
        className={`${loadingText ? '' : 'aichatimage'} justify-center items-center w-[60%] md:w-[33%] h-[280px] m-6 border-[#912c18ce] flex p-2.5 pl-3`}
      >
        <CircularProgressWithLabel value={progress} text={loadingText} percentageShow={percentageShow}/>
      </div>
    )
  );
}
