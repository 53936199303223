import React, { useState } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import UploadIDCard from '../../components/UploadIDCard';

const ModelverificationNextPage = () => {
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    return (
        <div className='mt-20 lg:px-20 px-2 pb-20 bg-black pt-10 text-white font-josefin-sans'>
            <p className='text-3xl lg:text-4xl text-white font-medium text-center lg:mt-10'>Model verification</p>
            <div className="flex flex-col justify-center items-center w-full mt-5 md:mt-10">
                <div className="flex justify-between mb-2 w-[90%] lg:w-[80%] xl:w-[50%] 2xl:w-[40%]">
                    <span className="text-left text-xl font-medium text-red-600">Selfie with consent</span>
                    <span className="text-right text-xl font-medium text-white">Verification</span>
                </div>
                <div>
                    <ol className="flex items-center w-[350px] md:w-[600px] m-auto ">
                        <li className="flex w-full items-center text-red-600 after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block">
                            <span className="flex items-center justify-center w-3 h-3 bg-gray-100 rounded-full lg:h-6 lg:w-6 shrink-0"></span>
                        </li>
                        <li className="flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-red-600 after:border-4 after:inline-block">
                        </li>
                        <span className="flex items-center justify-center w-3 h-3 bg-red-600 rounded-full lg:h-6 lg:w-6 shrink-0"></span>
                    </ol>
                </div>
            </div>

            <div className="w-full px-5 sm:px-8 md:px-10 xl:px-20 py-7 xl:py-10 md:h-[555px] lg:h-[560px] xl:h-[860px] border-2 border-zinc-600 bg-[#6161614A] rounded-xl mt-6 md:mt-9">
                <div className='flex flex-col md:flex-row md:justify-center items-center gap-5 xl:gap-16'>
                    <UploadIDCard name='Upload ID Card' />
                    <UploadIDCard name='Upload Selfie' />
                    <UploadIDCard name='Upload Model Release Document' />
                </div>

                <p className='text-2xl font-semibold text-center mt-10'>By submitting you agree to our model agreement</p>
                <div className="flex justify-center mt-5">
                    <button className="bg-red-800 w-96 text-white text-base sm:text-lg md:text-xl xl:text-2xl font-medium rounded-full px-6 sm:px-8 md:px-10 py-2">
                        Submit
                    </button>
                </div>
            </div>

            <p className='text-2xl lg:text-4xl text-white font-medium text-center mt-5 md:mt-10'>Images for face replication</p>

            <p className='text-lg md:text-xl lg:text-2xl text-white font-medium text-center mt-4'>To be approved, they must accurately represent the verified individual while ensuring that no other people are visible in the picture, focusing solely on the model. Be aware that our algorithms will rely on a single image as a reference. Therefore, we highly recommend providing a frontal headshot for optimal results.</p>

            <div className="flex justify-center items-center w-full md:h-[260px] lg:h-[320px] xl:h-[392px] border-2 border-zinc-600 bg-[#6161614A] rounded-xl py-3 px-5 mt-6 md:mt-9">
                <div>
                    <label
                        htmlFor="dropzone-file-face-replication"
                        className="flex flex-col items-center justify-center w-full rounded-lg cursor-pointer px-2 py-3"
                    >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            {selectedImage ? (
                                <img loading ='lazy'
                                    src={selectedImage}
                                    alt="Selected"
                                    className="w-full object-cover mb-4"
                                />
                            ) : (
                                <AiOutlinePlusCircle color='#616161' size={100} />
                            )}
                            <input
                                id="dropzone-file-face-replication"
                                type="file"
                                className="hidden"
                                onChange={handleImageChange}
                            />
                            <p className="mt-6 text-2xl text-zinc-600">
                                {selectedImage ? 'Change Featured Image' : 'Add new image'}
                            </p>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    );
}

export default ModelverificationNextPage;
