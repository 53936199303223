import React, { useEffect, useState } from "react";
import UserApiClient from "../../../utilities/apiClients";
import Chart from "react-apexcharts";

const Dashboard = () => {
  const [data, setData] = useState({});
  const [CommissionChartData, setCommissionChartData] = useState([]);
  const [UserChartData, setUserChartData] = useState([]);
  const [CommissionTableData, setCommissionTableData] = useState([]);
  const [UserTableData, setUserTableData] = useState([]);

  useEffect(() => {
    const orignalBackground = document.body.style.background;
    document.body.style.background = "none";
    return () => {
      document.body.style.background = orignalBackground;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await UserApiClient.get("api/v1/admin/dashboard/", {}, true).then(
        (res) => {
          if (res.success) {
            setData(res.data);
          }
        }
      );
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (data.chart_data) {
      setCommissionChartData(data.chart_data.commission);
      setUserChartData(data.chart_data.user);
    }
    setCommissionTableData(data.commission_data || []);
    setUserTableData(data.user_data || [])
  }, [data])

  const originalData1 = {
    series: [
      {
        name: "Users",
        data: UserChartData,
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
          allowMouseWheelZoom: true,
        },
        toolbar: {
          show: true,
          zoom: true,
          pan: true,
          reset: true,
          download: true,
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2,
        dashArray: 0,
      },

      xaxis: {
        labels: {
          show: false, // Explicitly hides the labels
        },
      },
      
      yaxis: {
        title: {
          text: "Users Count",
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value) => value.toLocaleString(), // Format numbers with commas
      },
      colors: ["#F44336", "#E91E63", "#9C27B0", "#3F51B5"], // More visually distinct colors
      title: {
        text: "Users Data",
        align: "center",
        style: {
          fontSize: "20px",
          fontWeight: "bold",
        },
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          opacity: 0.5,
        },
      },
      responsive: [
        {
          breakpoint: 1254,
          options: {
            title: {
              align: "left",
              fontSize: "15px",
            },
          },
        },
      ],
    },
  };

  // const months1 = ["January", "February", "March", "April"];
  // const [selectedMonths1, setSelectedMonths1] = useState(months1);
  // const [chartData1, setChartData1] = useState(originalData1);

  // const handleMonthChange1 = (month) => {
  //   if (selectedMonths1.includes(month)) {
  //     setSelectedMonths1(selectedMonths1.filter((e) => e !== month));
  //   } else {
  //     setSelectedMonths1([...selectedMonths1, month]);
  //   }
  //   const filteredData = originalData1.series[0].data.filter((item) =>
  //     selectedMonths1.includes(item.x)
  //   );
  //   setChartData1({
  //     ...originalData1,
  //     series: [{ ...originalData1.series[0], data: filteredData }],
  //   });
  // };

  const chartData2 = {
    series: [
      {
        name: "Amount",
        data: CommissionChartData,
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
          allowMouseWheelZoom: true,
        },
        toolbar: {
          show: true,
          zoom: true,
          pan: true,
          reset: true,
          download: true,
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2,
        dashArray: 0,
      },

      xaxis: {
        labels: {
          show: false, // Explicitly hides the labels
        },
      },
      
      yaxis: {
        title: {
          text: "Commission Amount",
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value) => value.toLocaleString(), // Format numbers with commas
      },
      colors: ["#F44336", "#E91E63", "#9C27B0", "#3F51B5"],
      title: {
        text: "Commission Data",
        align: "center",
        style: {
          fontSize: "20px",
          fontWeight: "bold",
        },
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          opacity: 0.5,
        },
      },
      responsive: [
        {
          breakpoint: 1254,
          options: {
            title: {
              align: "left",
              fontSize: "15px",
            },
          },
        },
      ],
    },
  };

  return (
    <>
      <div className="bg-white py-7 lg:p-7 h-full font-josefin-sans">
        {/* Chart Filters */}
        {/*  <div>
          <select>
            {months1.map((month) => (
              <option
                value={month}
                onChange={() => handleMonthChange1(month)}
                selected={selectedMonths1.includes(month)}
              >
                {month}
              </option>
            ))} 
          </select>
        </div>*/}

        {/* Stat Count */}
        <div className="flex justify-center gap-8 flex-wrap my-10">
          <div className="w-[250px] h-[200px] md:w-[300px]  md:h-[200px] border-2 border-zinc-300 rounded-lg py-8 px-7">
            <p className="md:text-[20px] text-lg font-semibold text-zinc-500">
              Total Images
            </p>
            <p className="md:text-[26px] text-2xl font-semibold text-black pt-3 pb-5">
              {data.img_count || 0}
            </p>
            <p className="md:text-base text-lg font-medium text-zinc-500">
              <span className="text-[20px] font-semibold text-green-600 pr-3">
                {"+32.40%"}
              </span>
              {"Increased last month"}
            </p>
          </div>
          <div className="w-[250px] h-[200px] md:w-[300px]  md:h-[200px] border-2 border-zinc-300 rounded-lg py-8 px-7">
            <p className="md:text-[20px] text-lg font-semibold text-zinc-500">
              Total Users
            </p>
            <p className="md:text-[26px] text-2xl font-semibold text-black pt-3 pb-5">
              {data.user_count || 0}
            </p>
            <p className="md:text-base text-lg font-medium text-zinc-500">
              <span className="text-[20px] font-semibold text-green-600 pr-3">
                {"+25.80%"}
              </span>
              {"Increased last month"}
            </p>
          </div>
          <div className="w-[250px] h-[200px] md:w-[300px]  md:h-[200px] border-2 border-zinc-300 rounded-lg py-8 px-7">
            <p className="md:text-[20px] text-lg font-semibold text-zinc-500">
              Total Earning
            </p>
            <p className="md:text-[26px] text-2xl font-semibold text-black pt-3 pb-5">
              {data.earning_count || 0}
            </p>
            <p className="md:text-base text-lg font-medium text-zinc-500">
              <span className="text-[20px] font-semibold text-green-600 pr-3">
                {"+33.96%"}
              </span>
              {"Increased last month"}
            </p>
          </div>
          <div className="w-[250px] h-[200px] md:w-[300px]  md:h-[200px] border-2 border-zinc-300 rounded-lg py-8 px-7">
            <p className="md:text-[20px] text-lg font-semibold text-zinc-500">
              Total Commission
            </p>
            <p className="md:text-[26px] text-2xl font-semibold text-black pt-3 pb-5">
              {data.commission_count || 0}
            </p>
            <p className="md:text-base text-lg font-medium text-zinc-500">
              <span className="md:text-base text-lg font-semibold text-green-600 pr-3">
                {"+29.56%"}
              </span>
              {"Increased last month"}
            </p>
          </div>
        </div>

        {/* Charts */}
        <div className="my-10 grid grid-cols-1 md:grid-cols-2 p-5">
          <Chart
            options={originalData1.options}
            series={originalData1.series}
            type="line"
            width="100%"
          />
          <Chart
            options={chartData2.options}
            series={chartData2.series}
            type="line"
            width="100%"
          />
        </div>

        {/* Tables */}
        <div className="flex flex-wrap flex-row my-10">
          <div className="md:w-1/2 w-full p-4">
            <div className="rounded-lg overflow-x-auto">
              <table className="w-full text-left text-gray-500 border border-stone-300">
                <thead className="text-xl text-black bg-stone-300">
                  <tr>
                    <th scope="col" className="px-6 py-3">ID</th>
                    <th scope="col" className="px-6 py-3">Email</th>
                    <th scope="col" className="px-6 py-3">Ref. By</th>
                    <th scope="col" className="px-6 py-3">Joined At</th>
                  </tr>
                </thead>
                <tbody>
                  {UserTableData.map((item, index) => (
                    <tr key={index} className="bg-white text-black text-xl font-normal border-b border-stone-300">
                      <td className="px-6 py-4 whitespace-nowrap">#{item.id}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{item.email}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{item.ref_by || 'Unreferred'}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{item.date_joined?.split("T")[0]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="md:w-1/2 w-full  p-4">
            <div className="rounded-lg overflow-x-auto">
              <table className="w-full text-left text-gray-500 border border-stone-300">
                <thead className="text-xl text-black bg-stone-300">
                  <tr>
                    <th scope="col" className="px-6 py-3">ID</th>
                    <th scope="col" className="px-6 py-3">Email</th>
                    <th scope="col" className="px-6 py-3">Affiliate</th>
                    <th scope="col" className="px-6 py-3">Type</th>
                    <th scope="col" className="px-6 py-3">Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {CommissionTableData.map((item, index)=>(
                  <tr key={index} className="bg-white text-black text-xl font-normal border-b border-stone-300">
                    <td className="px-6 py-4 whitespace-nowrap">#{item.id}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{item.user}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{item.affiliate_id}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{item.commission_type}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{item.created_at}</td>
                  </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
