import React from "react";
import Footer from "../components/Footer";

const TermsOfService = () => {
  return (
    <>
      <div className="">
        <div className="container mx-auto px-4 py-8  rounded-lg shadow-lg">
          {/* <!-- Title Section --> */}
          <div className="text-center py-12">
            <h1 className="text-3xl md:text-5xl font-semibold text-[#990013]">
              Terms of Service
            </h1>
          </div>

          {/* <!-- Content Section --> */}
          <div className="space-y-12">
            {/* <!-- Introduction --> */}
            <section>
              <h2 className="text-2xl font-bold text-[#990013]">
                Introduction
              </h2>
              <p className="text-lg text-gray-800 mt-4 leading-relaxed">
                These terms of service constitute a binding agreement between
                you and Eromantic-AI, 1000 Brickell Ave, Ste 715 Miami, FL
                33131. By accessing or using our services, you agree to be bound
                by these terms.
              </p>
            </section>

            {/* <!-- Description of Service --> */}
            <section>
              <h2 className="text-2xl font-bold text-[#990013]">
                Description of Service
              </h2>
              <p className="text-lg text-gray-800 mt-4 leading-relaxed">
                Our platform is an AI Image Generation Platform that empowers
                users to create AI-generated content, specifically images. We
                provide users with the ability to generate a wide range of
                images using artificial intelligence technology. It is important
                to note that the content generated on our platform can be of an
                explicit or sexual nature, as determined by the users
                themselves. Our platform offers access to computing
                capabilities, without directly providing nor producing any
                content.
              </p>
            </section>

            {/* <!-- Eligibility --> */}
            <section>
              <h2 className="text-2xl font-bold text-[#990013]">
                1. Eligibility
              </h2>
              <p className="text-lg text-gray-800 mt-4 leading-relaxed">
                You must be at least 18 years old or the age of majority in your
                jurisdiction to use the Platform. By accessing and using the
                Platform, you represent and warrant that you meet these age
                requirements.
              </p>
            </section>

            {/* <!-- Prohibited Content --> */}
            <section>
              <h2 className="text-2xl font-bold text-[#990013]">
                2. Prohibited Content
              </h2>
              <p className="text-lg text-gray-800 mt-4 leading-relaxed">
                Users are strictly prohibited from generating or attempting to
                generate AI-generated images that display any form of illegal or
                harmful content, including rape, murder, non-consensual
                deepfakes, and more. Violating this provision may result in the
                immediate termination of your account, content removal, and
                legal consequences.
              </p>
            </section>

            {/* <!-- Account Creation --> */}
            <section>
              <h2 className="text-2xl font-bold text-[#990013]">
                3. Account Creation
              </h2>
              <p className="text-lg text-gray-800 mt-4 leading-relaxed">
                Users can join the Platform by signing up through their Google,
                Twitter, Reddit, or Discord accounts using our convenient
                one-click signup process. By signing up, you authorize us to
                access and use your provided information, such as your email (if
                available) and username, for the purpose of account creation and
                future access to the Platform.
              </p>
            </section>

            {/* <!-- Limitation of Liability --> */}
            <section>
              <h2 className="text-2xl font-bold text-[#990013]">
                4. Limitation of Liability
              </h2>
              <p className="text-lg text-gray-800 mt-4 leading-relaxed">
                To the maximum extent permitted by law, we shall not be liable
                for any indirect, incidental, special, consequential, or
                punitive damages, or any loss of profits or revenue, whether
                incurred directly or indirectly, arising from your use of the
                Platform.
              </p>
            </section>

            {/* <!-- Content and Ownership --> */}
            <section>
              <h2 className="text-2xl font-bold text-[#990013]">
                5a. Content
              </h2>
              <p className="text-lg text-gray-800 mt-4 leading-relaxed">
                Kindly be advised that the quality of the content generated is
                predominantly contingent upon the inputs provided by the user
                (prompt), and as such, we cannot offer refunds for credits that
                have been utilized. We do not possess complete control over the
                AI output, as it is solely generated by artificial intelligence
                technology.
              </p>

              <h2 className="text-2xl font-bold text-[#990013] mt-8">
                5b. Ownership of Content
              </h2>
              <p className="text-lg text-gray-800 mt-4 leading-relaxed">
                You are granted permission to freely share and utilize any of
                your images generated by our platform for commercial purposes,
                provided that your utilization of the images is not intended to
                cause harm, defraud, or negatively impact third parties in any
                way.
              </p>
            </section>

            {/* <!-- Cancellation Policy --> */}
            <section>
              <h2 className="text-2xl font-bold text-[#990013]">
                6. Cancellation Policy
              </h2>
              <p className="text-lg text-gray-800 mt-4 leading-relaxed">
                You have the freedom to cancel your Eromantic AI subscription
                anytime. You can cancel your subscription in the plans page at
                www.eromantic.ai/plans. Should you not manage, you should
                contact CCBill or Epoch to cancel your subscription.
              </p>
            </section>

            {/* <!-- Affiliate Program --> */}
            <section>
              <h2 className="text-2xl font-bold text-[#990013]">
                7. Affiliates
              </h2>
              <p className="text-lg text-gray-800 mt-4 leading-relaxed">
                Affiliates are provided with a compelling opportunity to earn a
                substantial 25% commission on revenue generated from user
                purchases within our platform. This commission is calculated
                based on the total revenue received, after deducting payment
                gateway fees. The payment gateway fees typically vary between 8%
                and 16%, contingent upon whether users completed their purchases
                using CCBill or Epoch payment methods.
              </p>
            </section>

            {/* <!-- Image Uploads --> */}
            <section>
              <h2 className="text-2xl font-bold text-[#990013]">
                8. Images Upload
              </h2>
              <p className="text-lg text-gray-800 mt-4 leading-relaxed">
                By utilizing this platform, users are granted the capability to
                upload images for the purpose of reproducing environments,
                contexts, or poses. However, it is imperative that you, as the
                user, undertake the responsibility of exclusively uploading
                images for which you possess the necessary rights.
              </p>
            </section>

            {/* <!-- Plans --> */}
            <section>
              <h2 className="text-2xl font-bold text-[#990013]">9. Plans</h2>
              <p className="text-lg text-gray-800 mt-4 leading-relaxed">
                Plans constitute recurring subscriptions, unless payment is made
                using cryptocurrency. Credits never expire, but an active plan
                is required to use them. Our platform provides the following
                plans for purchase:
                <ul className="list-disc list-inside mt-4">
                  <li>
                    <strong>Bronze:</strong> Charged at $25/month plus up to
                    (9%), if applicable. Includes 400 credits and up to 40
                    Restores.
                  </li>
                  <li>
                    <strong>Silver:</strong> Charged at $50/month plus up to
                    (9%), if applicable. Includes 1000 credits and up to 100
                    Restores.
                  </li>
                  <li>
                    <strong>Gold:</strong> Charged at $125/month plus up to
                    (9%), if applicable. Includes 2500 credits and 2500
                    Restores.
                  </li>
                </ul>
              </p>
            </section>

            {/* <!-- Refund Policy --> */}
            <section>
              <h2 className="text-2xl font-bold text-[#990013]">
                10. Refund Policy and Liability
              </h2>
              <p className="text-lg text-gray-800 mt-4 leading-relaxed">
                We do not offer refunds for slow image generation or unmet
                expectations. Refunds will only be considered under certain
                circumstances such as double billing or if the service fails
                entirely. Please read the full details in our refund policy.
              </p>
            </section>

            {/* <!-- Intellectual Property --> */}
            <section>
              <h2 className="text-2xl font-bold text-[#990013]">
                16. Intellectual Property
              </h2>
              <p className="text-lg text-gray-800 mt-4 leading-relaxed">
                All logos, trademarks, and other distinctive brand features
                displayed on this website are the property of Eromantic-AI or
                its licensors. Unauthorized use may result in legal action.
              </p>
            </section>

            {/* <!-- Termination of Access --> */}
            <section>
              <h2 className="text-2xl font-bold text-[#990013]">
                17. Termination of Access
              </h2>
              <p className="text-lg text-gray-800 mt-4 leading-relaxed">
                We reserve the right to suspend or terminate your access to the
                platform if we determine, in our sole discretion, that you have
                violated these Terms of Service.
              </p>
            </section>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default TermsOfService;
