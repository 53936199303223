import React from 'react'
import { info } from '../../assets'

const UseShortWordsInputBox = ({ name, plachold }) => {
    return (
        <>
            <div className='flex justify-center gap-5 mt-10'>
                <p className='font-semibold text-3xl xl:text-4xl md:pb-5'>{name}</p>
                <img loading ='lazy' src={info} alt="infoBlack" className='h-6' />
            </div>
            <div className='flex justify-end'>
                <p className='text-end border-b w-fit hover:cursor-pointer hover:text-red-600 hover:boreder-b  hover:border-red-600'>Clear input</p>
            </div>
            <div className='bg-zinc-300 rounded-xl mt-2 px-5 py-3 text-black text-2xl font-light w-full h-38'>
                <input type="text" className='focus:outline-none bg-zinc-300 w-full' placeholder={plachold} />
            </div>
        </>
    )
}

export default UseShortWordsInputBox