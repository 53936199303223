import React, { useState, useEffect, useRef } from "react";
// import { FixedSizeGrid as Grid } from 'react-window';
import { AiFillLike } from "react-icons/ai";
import { FaHeart } from "react-icons/fa6";
import { GiRoundStar } from "react-icons/gi";
import { IoIosTime } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Footer from "../../../frontend/components/Footer";
import UserApiClient from "../../../utilities/apiClients";
import { Helmet } from "react-helmet";
import loaders from "../../../frontend/components/loaders";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";

const Discover = () => {
  const [imageData, setImageData] = useState([]);
  const [filteredImageData, setFilteredImageData] = useState([]); // Local state for filtered images
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [imageDataLen, setImageDataLen] = useState(0);
  const [showMoreBtn, setShowMoreBtn] = useState(true);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [errorisOpen, setErrorIsOpen] = useState(false);
  const [loadmore, setloadmore] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [hover, setHover] = useState(null)
  const [sort, setSort] = useState('all')
  const modalRef = useRef(null);
  const [pageLinks, setPageLinks] = useState({ "next": null, "prev": null });


  const errorCloseModal = () => {
    setErrorIsOpen(false); // Close the modal
    navigate("/"); // Navigate to the root ("/") route
  };
  const errorOpenModal = () => setErrorIsOpen(true);

  const Showcasedata = [
    { name: "Upscaled", icon: <GiRoundStar size={20} color="#616161" /> },
    { name: "Most Liked", icon: <FaHeart size={20} color="#616161" /> },
    { name: "Followings", icon: <AiFillLike size={20} color="#616161" /> },
    { name: "Newest", icon: <IoIosTime size={20} color="#616161" /> },
  ];
  const afterModelData = [
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
  ];

  const handleFilter = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const subscription = localStorage.getItem("subscription_type");
    if (subscription === "free") {
      setloadmore(true);
    }

    fetchImageData(); // Fetch initial images
    window.scrollTo(0, 0)
  }, []);

  const handleImagechatClick = (baseUrl) => {
    setSelectedImage(baseUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  // Fetching images from API
  const fetchImageData = async (pageLink) => {
    // setLoading(true);
    loaders(true, "heart");
    try {
      const pageUrl = typeof pageLink === 'string' ? pageLink : `api/v1/get_allPublic_image/?page_size=10`;
      const res = await UserApiClient.get(pageUrl);

      if (res.success) {
        loaders(true, "heart");
        const newImages = res.data.results;

        if (!newImages || newImages.length === 0) {
          Swal.fire({
            title: "No more images to load!",
            icon: "info",
            confirmButtonColor: "#990013",
            timer: 3000,
          });
          setShowMoreBtn(false);
          loaders(true, "heart");
          return;
        }

        setImageData((prevData) => {
          const newData = [...prevData];
          newImages.forEach((newImage) => {
            const exists = newData.some(image => image.generation_id === newImage.generation_id);
            if (!exists) {
              newData.push(newImage);
            }
          });
          return newData;
        });
        setPageLinks({ next: res.data.next, prev: res.data.previous });
        setImageDataLen(newImages.length);
        // setLoading(false);
        loaders(false, "heart");
      } else {
        setError("Failed to fetch data");
        loaders(false, "heart");
      }
    } catch (error) {
      setError("Error fetching data");
      setLoading(false);
    }
  };


  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredImageData(imageData); // Show all images if search query is empty
    } else {
      const filtered = imageData.filter(
        (item) =>
          item.generation_prompt
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) // Filter based on generation_prompt
      );
      setFilteredImageData(filtered);
    }
  }, [searchQuery, imageData]);

  // const handleImageView = (imgId) => {
  //   const subscription = localStorage.getItem("subscription_type");
  //   if (subscription === "free") {
  //     errorOpenModal();
  //     return;
  //   }
  //   navigate(`/dashboard/ImageView/${imgId}`);
  // };

  const [isLoadingsingleimage, setIsLoadingsingleimage] = useState(true);

  const handleImageLoad = () => {
    setIsLoadingsingleimage(false);
  };

  if (loading) return <p>Loading...</p>;

  // Define the number of columns for the grid (5 columns per row)
  // const columnCount = 5;
  // const columnWidth = Math.floor(window.innerWidth / columnCount); 
  // const rowCount = Math.ceil(filteredImageData.length / columnCount);

  const downloadImage = async (url) => {
    try {
      toast.success("Downloading...", {
        position: "top-left",
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        autoClose: 5000,
        pauseOnHover: false,
        draggable: false,
        theme: "colored"
      })
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `generated-image_${Date.now()}.png`;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Failed to download the image:', error);
    }
  };

  const sortImg = (value) => {
    setSort(value);

    let sortedData = [...filteredImageData];

    switch (value) {
      case 'Newest':
        sortedData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        break;

      case 'Most Liked':
        sortedData.sort((a, b) => b.likes - a.likes);
        break;

      case 'Followings':
        sortedData = sortedData.filter((item) => item.followed);
        break;

      case 'Upscaled':
        sortedData = sortedData.filter((item) => item.upscale.some((e) => e === true));
        break;

      default:
        sortedData = imageData;
        break;
    }

    setFilteredImageData(sortedData);
  };


  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>Eromantic.ai- Watch Your AI Gf/Bf </title>
        <meta
          name="description"
          content="Your dream companion awaits! Create your AI Girlfriend, shape her look, personality, and bring her to life in one click."
        />
        <meta
          name="keywords"
          content="AI Girlfriend, Virtual Girlfriend, Anime Companions, Interactive AI, NSFW-friendly"
        />
      </Helmet>
      <div className="mt-20 lg:px-10 2xl:px-20 px-2 pb-20 text-black font-josefin-sans">

        <div className="flex items-center my-[1rem] mo:my-[2rem] lg:mt-5 w-full relative justify-center">
          <img
            loading="lazy"
            src="/assets/img/Rectangledream.png"
            alt="Icon"
            className="w-full h-auto rounded-xl"
          />
          <div className="absolute text-center">
            <div className="grid justify-center p-4 mr-4 sm:mr-6 lg:mr-10">
              <div className="text-white custombuttonsize sm:text-xl lg:text-2xl xl:text-5xl font-medium text-center">
                Discover All Public Generations
              </div>
              <div className="text-center mt-1 lg:mt-10">
                <button
                  className="custombuttonsize bg-red-800 text-white font-medium rounded-2xl p-2 sm:p-4 lg:px-6 py-1"
                  onClick={() => navigate("/dashboard")}
                >
                  Create New
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-start lg:justify-center xl:justify-between flex-wrap items-center">
          <div className="m-0 mo:m-2 px-3 py-2 text-black w-full mo:w-1/2 flex items-center gap-2 bg-white rounded-full">
            <div style={{ paddingLeft: "5px" }}>
              <IoSearchOutline color="black" size={23} />
            </div>
            <input
              type="text"
              placeholder="Search anything"
              className="bg-white border-0 rounded-full w-full focus:outline-none text-xl"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)} // Update state with user input
            />
          </div>
        </div>
        {/* <div className="flex">
          {Showcasedata.map((data) => (
            <div className="m-2">
              <button onClick={() => sortImg(data.name)} className="flex items-center space-x-2 px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-red-600  duration-500 hover:shadow-xl">
                <span>{data.icon}</span>
                <span className="text-lg font-semibold">{data.name}</span>
              </button>
            </div>
          ))}
        </div> */}

        <div className="p-0 md:p-8">
          <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1">
            {filteredImageData.map((item, index) => {
              if (!item.image_file_name || item.image_file_name.length === 0) {
                return null; // Skip rendering this item if no images are present
              }

              return item.image_file_name.map((imageUrl, imgIndex) => {
                const baseUrl = `${imageUrl}?auto=format&fit=crop`;

                // Check subscription type
                const subscription = localStorage.getItem("subscription_type");
                const isFreeSubscription = subscription === "free";

                // Only show 4 images if the subscription is "free"
                // const isImageVisible = !isFreeSubscription || index < 2;

                return (
                  <div
                    className={`relative group rounded-2xl overflow-hidden ${isFreeSubscription && index >= 2
                      ? "filter blur-sm cursor-not-allowed"
                      : ""
                      }`}
                    key={`${index}-${imgIndex}`}

                    onMouseEnter={() => setHover(`${index}-${imgIndex}`)} onMouseLeave={() => setHover(null)} onTouchStart={() => setHover(`${index}-${imgIndex}`)}
                  >
                    {isLoadingsingleimage && (
                      <div className="h-80 w-full bg-[#4b556354] rounded-2xl p-2 flex justify-center items-center">
                        <div className="loader"></div>
                      </div>
                    )}
                    <img
                      loading="lazy"
                      className={`h-full w-full rounded-2xl object-cover object-center p-2 transition-transform duration-300 ease-in-out ${hover === `${index}-${imgIndex}` ? 'transform scale-105 shadow-lg' : ''} cursor-pointer ${isFreeSubscription && index >= 2
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                        }`}
                      src={baseUrl}
                      alt={`gallery-photo-${imgIndex}`}
                      onLoad={handleImageLoad} // Trigger handler when image is loaded
                      onClick={() => handleImagechatClick(baseUrl)}
                    // onClick={ isImageVisible ? () => handleImageView(item.generation_id) : null } // Prevent clicking if not visible
                    />
                    {hover === `${index}-${imgIndex}` && (
                      <>
                        <div className="absolute top-0 left-0 right-0 rounded-2xl">
                          {item.upscale[imgIndex] ?
                            <>
                              <div className="flex items-center justify-between h-12 px-2 py-1 bg-black bg-opacity-70 text-white rounded-t-2xl text-xs font-semibold  transition-all duration-300 ease-in-out shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-indigo-300">
                                <Tooltip title="Download" slotProps={{
                                  popper: {
                                    sx: {
                                      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                      {
                                        margin: '0px',
                                      },
                                    }
                                  }
                                }}>
                                  <button onClick={() => downloadImage(imageUrl)} className="flex items-center justify-center bg-red-700 bg-opacity-90 h-10 w-10 rounded-full p-2 border-2 duration-300 border-red-600 hover:border-red-500">
                                    {/* <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth={0} /><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" /><g id="SVGRepo_iconCarrier"> <path d="M12 16L12 8" stroke="#ff8a8a" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /> <path d="M9 13L11.913 15.913V15.913C11.961 15.961 12.039 15.961 12.087 15.913V15.913L15 13" stroke="#ff8a8a" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /> <path d="M3 15L3 16L3 19C3 20.1046 3.89543 21 5 21L19 21C20.1046 21 21 20.1046 21 19L21 16L21 15" stroke="#ff8a8a" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /> </g></svg> */}
                                    <i className="fa-solid text-white fa-lg fa-cloud-arrow-down"></i>
                                  </button>

                                </Tooltip>
                                <div className="flex">
                                  <i className="fa-solid text-yellow-500 fa-star"></i>
                                  <p className="ml-1">UPSCALED</p>
                                </div>
                              </div>
                            </>
                            :
                            <div className="absolute top-1 right-0 left-0 flex justify-between items-center px-4 ">
                              <Tooltip title="Download" slotProps={{
                                popper: {
                                  sx: {
                                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                    {
                                      margin: '0px',
                                    },
                                  }
                                }
                              }}>
                                <button onClick={() => downloadImage(imageUrl)} className="flex items-center justify-center bg-red-700 bg-opacity-90 h-10 w-10 rounded-full p-2 border-2 duration-300 border-red-600 hover:border-red-500">
                                  {/* <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth={0} /><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" /><g id="SVGRepo_iconCarrier"> <path d="M12 16L12 8" stroke="#ff8a8a" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /> <path d="M9 13L11.913 15.913V15.913C11.961 15.961 12.039 15.961 12.087 15.913V15.913L15 13" stroke="#ff8a8a" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /> <path d="M3 15L3 16L3 19C3 20.1046 3.89543 21 5 21L19 21C20.1046 21 21 20.1046 21 19L21 16L21 15" stroke="#ff8a8a" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /> </g></svg> */}
                                  {/* <img loading="lazy" src="/assets/img/download.png" alt="Icon" className="w-full h-auto rounded-xl"/> */}
                                  <i className="fa-solid text-white fa-lg fa-cloud-arrow-down"></i>
                                </button>

                              </Tooltip>
                            </div>
                          }
                        </div>
                        <div className="absolute bottom-1 left-1 right-1 bg-black bg-opacity-50 transition-opacity duration-300 p-2 text-white  border-1 border-gray-400 rounded-[10px]">
                          <h2 className="text-red-200 text-xs sm:text-sm md:text-md lg:text-xl text-wrap  rounded-lg  py-0 mb-2">
                            @{item.user_name}
                          </h2>
                          <div className="flex items-center justify-between mb-1 gap-3">
                            <p className="text-xs sm:text-sm md:text-md lg:text-md text-start h-auto ">
                              {item.generation_prompt
                                .split(" ")
                                .slice(0, 10)
                                .join(" ")}{" "}
                              {item.generation_prompt.split(" ").length > 10
                                ? "..."
                                : ""}
                            </p>
                            {item.Aititle ? <div className="flex flex-col items-start">
                              <h2 className="text-xs sm:text-sm  text-red-400">MODEL</h2>
                              <p className="text-white text-sm sm:text-base ">
                                {item.Aititle}
                              </p>
                            </div>
                              : null}
                            {/* {item.extensions ? <div className="flex flex-col items-start">
                              <h2 className="text-xs sm:text-sm  text-red-400">EXTENSIONS</h2>
                              <h2 className="text-white text-sm sm:text-base ">
                                {item.extensions}
                              </h2>
                            </div> : null} */}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                );
              });
            })}
          </div>
        </div>

        {imageDataLen === 0 ? (
          <div className="flex-wrap lg:flex items-center justify-between p-10 rounded-lg shadow-lg bg-[#d5d5d5]">
            <div className="flex-wrap lg:flex gap-4">
              <div className="logo flex items-center justify-center">
                <i className="fa-solid fa-heart-circle-plus text-red-600 text-4xl" />
              </div>
              <div className="flex flex-col items-center text-center lg:text-left lg:items-start gap-2">
                <h2 className="text-gray-900 text-2xl font-semibold">
                  Create Your Personalized Characters
                </h2>
                <p className="text-gray-800 text-sm">
                  Explore the power of AI to design unique images and characters
                  tailored to your preferences.
                </p>
              </div>
            </div>
            <div className="text-center lg:text-right">
              <button
                onClick={() => navigate("/dashboard")}
                className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out"
              >
                Start Creating
              </button>
            </div>
          </div>
        ) : (
          <>
            {!loadmore ? (
              showMoreBtn && pageLinks.next ? (
                <div className="flex items-center w-full justify-center">
                  <button
                    className="bg-red-800 w-[35%] sm:w-[20%] lg:w-[16%] text-white font-medium rounded-2xl py-2 px-4"
                    onClick={() => {
                      fetchImageData(pageLinks.next);
                    }}
                  >
                    <i className="fa-solid fa-angles-down mr-1"></i> Load More
                  </button>
                </div>
              ) : null
            ) : null}
          </>
        )}
      </div>
      <Footer />

      {errorisOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-lg">
          <div className="modal-dialog modal-lg bg-white rounded-lg overflow-hidden shadow-xl max-w-[16rem] mo:max-w-xl w-full">
            {/* Modal Content */}
            <div className="modal-wrap flex-wrap mo:flex relative">
              {/* Image Container - 50% Width */}
              <div className="w-full mo:w-1/2">
                {/* Desktop Image */}
                <img
                  src={alert}
                  alt="Modal Img"
                  className="login-img hidden md:block w-full h-full object-cover"
                />
                {/* Mobile Image */}
                <img
                  src={alert}
                  alt="Modal Img Mobile"
                  className="login-img-mob sm:hidden w-full"
                />
              </div>

              {/* Content Container - 50% Width */}
              <div className="modal-content p-2 w-full mo:w-1/2 grid justify-center">
                {/* Modal Header */}
                <div className="modal-header flex justify-between items-start">
                  <h4 className="modal-title text-uppercase text-xl"></h4>
                  <button
                    type="button"
                    className="btn-close text-xl text-[#990013]"
                    onClick={errorCloseModal}
                  >
                    <i className="fa-regular fa-circle-xmark"></i>
                  </button>
                </div>

                {/* Modal Body */}
                <div className="modal-body justify-center">
                  <div className="container-fluid">
                    <div className="row mb-4">
                      <div className="col-12 ps-4">
                        <h2 className="text-[#990013] font-bold text-uppercase text-2xl">
                          PLEASE UPGRADE
                        </h2>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 ps-4">
                        <span className="text-muted text-gray-900 text-md">
                          Upgrade to a premium membership to unlock access to
                          all features!
                        </span>
                      </div>
                    </div>
                    <div className="row justify-center p-3 gap-1">
                      <div className="col-12">
                        <button
                          onClick={() => navigate("/priceplan")}
                          className="btn btn-primary w-full py-3 text-white bg-[#990012ab] hover:bg-[#990013] rounded-md"
                        >
                          Upgrade
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Modal Overlay */}
            {/* <div
              className="fixed inset-0"
              onClick={errorCloseModal}
            ></div> */}
          </div>
        </div>
      )}
      {/* Modal (fullscreen image view) */}
      {isModalOpen && selectedImage && (
        <div
          ref={modalRef}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
        >
          <div className="relative w-[35rem] max-h-full p-4">
            <button
              onClick={closeModal}
              className="absolute top-8 right-8 text-white text-md w-8 h-8 bg-[#808080] p-[5px] font-bold rounded-full"
            >
              X
            </button>
            <img loading='lazy'
              className="max-w-full max-h-screen object-contain"
              src={selectedImage}
              alt="Fullscreen View"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Discover;
