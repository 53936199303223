import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Footer from '../../../frontend/components/Footer';
import { useSelector } from 'react-redux';
import { AppDomain } from '../../../utilities/enviroment';
import UserApiClient from '../../../utilities/apiClients';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const paymentReqData = {
    amount: "",
    description: "",
}
const bankDetailData = {
    bank_name: "",
    account_number: "",
    branch_name: "",
    account_name: "",
}


const Referrals = () => {
    const navigate = useNavigate();
    const userData = useSelector(state => state.user)
    const user = userData.user
    const [UserSubscription, setUserSubscription] = useState({});
    const [UserWallet, setUserWallet] = useState({});
    const [requestedAmount, setRequestedAmount] = useState(0);
    const [lastPaymentRequest, setLastPaymentRequest] = useState({});
    const [commissionList, setCommissionList] = useState([]);
    const [subCommission, setSubCommission] = useState(0);
    const [tokenCommission, setTokenCommission] = useState(0);
    const [withdrawalList, setWithdrawalList] = useState([]);
    const [copySuccess, setCopySuccess] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [showPaymentRequestModal, setShowPaymentRequestModal] = useState(false);
    const [referralLink, setReferralLink] = useState('');
    const [paymentRequest, setPaymentRequest] = useState(paymentReqData);
    const [bankDetails, setBankDetails] = useState(bankDetailData);
    const [activeTab, setActiveTab] = useState(1);
    const [customAffiliateValue, setCustomAffiliateValue] = useState('');
    const [customAffiliateCheck, setCustomAffiliateCheck] = useState(null);
    const [showCustomizeModal, setShowCustomizeModal] = useState(false);

    // Handle tab click to change the active tab
    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    // Handle change in Edit Modal
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBankDetails({
            ...bankDetails,
            [name]: value,
        });
    };

    // Handle closing of modals
    const closeModals = () => {
        setShowEditModal(false);
        setShowPaymentRequestModal(false);
        setShowCustomizeModal(false);
    };

    const handlePaymentChange = (e) => {
        const { name, value } = e.target;
        setPaymentRequest({
            ...paymentRequest,
            [name]: value,
        });
    };

    const copyToClipboard = () => {
        const textArea = document.createElement('textarea');
        textArea.value = referralLink;
        document.body.appendChild(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
            setCopySuccess('Copied!');
            setTimeout(() => {
                setCopySuccess('');
            }, 2000);
        } catch (err) {
            console.error('Failed to copy text', err);
        }

        document.body.removeChild(textArea);
    };

    const fetchData = async () => {
        setReferralLink(`${AppDomain}join/referral?affiliate_id=${user.affiliate_id}`)
        await UserApiClient.get('api/v1/user/wallet/', {}, true).then(async res => {
            if (res.success) {
                setUserWallet(res.data);
                setBankDetails({
                    bank_name: res.data.bank_name,
                    account_number: res.data.account_number,
                    branch_name: res.data.branch_name,
                    account_name: res.data.account_name
                })
            }
        });
    }

    const fetchSubData = async () => {
        await UserApiClient.get('api/v1/get_token/', {}, true).then(async res => {
            if (res.success) {
                setUserSubscription(res.data);
            }
        });
    }

    const fetchCommissionData = async () => {
        await UserApiClient.get('api/v1/affiliate/commission/', {}, true).then(async res => {
            if (res.success) {
                setCommissionList(res.data);
            }
        });
    }

    const fetchWithdrawalData = async () => {
        await UserApiClient.get('api/v1/user/withdrawals/', {}, true).then(async res => {
            if (res.success) {
                setWithdrawalList(res.data);
            }
        });
    }

    const submitWithdrawalRequest = async (e) => {
        e.preventDefault();
        if (!paymentRequest.amount || !paymentRequest.description) return;
        if (parseFloat(requestedAmount) + parseFloat(paymentRequest.amount) > parseFloat(UserWallet.balance)) {
            Swal.fire('Warning!!!', "You don't have required balance for withdrawal.", 'error');
            return;
        }
        await UserApiClient.post('api/v1/user/withdrawals/', paymentRequest, true).then(async res => {
            if (res.success) {
                closeModals();
                fetchWithdrawalData();
                setPaymentRequest(paymentReqData);
                Swal.fire({
                    icon: 'success',
                    title: 'Withdrawal request submitted.',
                    timer: 2000
                })
            }
        });
    }

    const submitBankDetails = async (e) => {
        e.preventDefault();
        console.log(bankDetails.bank_name, bankDetails.account_number, bankDetails.branch_name, bankDetails.account_name);

        if (!bankDetails.bank_name || !bankDetails.account_number || !bankDetails.branch_name || !bankDetails.account_name) return;

        await UserApiClient.put('api/v1/user/wallet/', bankDetails, true).then(async res => {
            if (res.success) {
                closeModals();
                fetchData();
                Swal.fire({
                    icon: 'success',
                    title: 'Bank details updated.',
                    timer: 2000
                })
            }
        });
    }

    const handleCheckAffiliate = async (e) => {
        if (customAffiliateValue !== '') {
            await UserApiClient.post(`api/v1/affiliate/customize/${user.id}/`, { 'affiliate_id': customAffiliateValue }, true, true).then(async res => {
                setCustomAffiliateCheck(res.success);
            })
        }
    }

    const handleSubmitCustomAffiliate = async (e) => {
        e.preventDefault();
        if (customAffiliateCheck === true) {
            await UserApiClient.patch(`api/v1/affiliate/customize/${user.id}/`, { 'affiliate_id': customAffiliateValue }, true).then(async res => {
                if (res.success) {
                    await Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Referral code updated.',
                        timer: 2000
                    });
                    window.location.reload();
                }
            })
        }
    }

    useEffect(() => {
        if (userData.isAuthenticated) {
            fetchData();
            fetchSubData();
            fetchCommissionData();
            fetchWithdrawalData();
        }
    }, [userData])

    useEffect(() => {
        var amount = 0;
        withdrawalList.map(item => {
            amount += item.status === 'pending' ? item.amount : 0;
        })
        setRequestedAmount(amount);
        if (withdrawalList.length > 0) {
            setLastPaymentRequest(withdrawalList[withdrawalList.length - 1]);
        }
    }, [withdrawalList])

    useEffect(() => {
        var sub_amount = 0;
        var token_amount = 0;
        commissionList.map(item => {
            if (item.commission_type === 'subscription') {
                sub_amount += item.amount;
            } else if (item.commission_type === 'token') {
                token_amount += item.amount;
            }
        })
        setSubCommission(sub_amount);
        setTokenCommission(token_amount);
    }, [commissionList])

    return (
        <>
            <div className='px-2 md:px-5 xl:px-20  text-black font-kanit'>
                {/* Become an Affiliate Section */}
                <div className="flex flex-col gap-12">
                    <div className="flex-1">

                        <h1 className="text-4xl font-bold text-center text-black my-8">Referrals</h1>
                        <p className="text-center text-lg text-black capitalize">
                            Earn commissions by referring users to Eromantic and receiving a share of up to 40% from their plan purchases or renewals.
                        </p>

                        <div className='border-2 border-zinc-600 rounded-xl p-5 mt-10'>
                            <p className='text-2xl text-left pb-2'>Your tracking link</p>
                            <div className="flex flex-wrap items-center border-2 border-zinc-600 rounded-xl p-2 bg-white text-black w-full sm:w-auto">
                                <input
                                    type="text"
                                    value={referralLink}
                                    readOnly
                                    className="flex-grow bg-white text-black border-none focus:outline-none focus:ring-0 p-2 text-xs sm:text-base"
                                />
                                <button
                                    onClick={copyToClipboard}
                                    className="ml-2  py-2 px-3 bg-white text-red-600 rounded-md hover:bg-gray-300 text-xs sm:text-base"
                                >
                                    {copySuccess ? 'Copied' : 'Copy Link'}
                                </button>
                            </div>
                            <div>
                {UserSubscription.subscription_type &&
                  (UserSubscription.subscription_type !== "free" ? (
                    <button
                      onClick={() => {
                        setShowCustomizeModal(true);
                      }}
                      className="px-1 mo:px-4 py-2 mt-3 font-semibold text-sm rounded-lg transition-colors duration-300 bg-[#991b1b] text-white"
                    >
                      Customize
                    </button>
                  ) : (
                    <div className="flex-wrap mo:flex items-center justify-between py-5 px-4 sm:px-6 md:px-8 lg:px-10 rounded-lg shadow-lg bg-[#d5d5d5] mt-5">
                      <div className="flex flex-col sm:flex-row items-center mo:items-start gap-4">
                        <div className="logo flex items-center justify-center">
                          <i className="fa-solid fa-user-plus text-red-600 text-3xl sm:text-4xl" />
                        </div>
                        <div className="flex flex-col  gap-2">
                          <h2 className="text-gray-900 text-xl sm:text-2xl font-semibold">
                            Create Your Custom referral code.
                          </h2>
                          <p className="text-gray-800 text-sm sm:text-md">
                            For this feature to be available, please upgrade
                            your plan.
                          </p>
                        </div>
                      </div>
                      <div className="w-full mo:w-auto flex justify-center items-center">
                        <button
                          className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out"
                          onClick={() => navigate("/priceplan")}
                        >
                          Upgrade
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
                        </div>

                        <div className="bg-[#5c5c5c] text-white p-6 rounded-lg shadow-lg my-10">
                            <div className="flex flex-col lg:flex-row justify-between gap-8">
                                {/* {/ Statistics Section /} */}
                                <div className="bg-[#424242] p-6 rounded-lg flex-grow">
                                    <h2 className="text-lg font-bold mb-4">Statistics</h2>
                                    <div className="flex flex-wrap justify-between items-start mb-4">
                                        <div className="w-full sm:w-1/2 mb-4">
                                            <div className="text-start">
                                                <div className="text-sm text-green-500 font-semibold flex items-center gap-1">
                                                    <span className="material-icons">Total Balance</span>
                                                </div>
                                                <div className="text-2xl font-bold">${UserWallet.balance || 0}</div>
                                            </div>
                                            <div className="text-start">
                                                <div className="text-sm text-green-500 font-semibold flex items-center gap-1">
                                                    <span className="material-icons">Subscription Commission</span>
                                                </div>
                                                <div className="text-2xl font-bold">${subCommission || 0}</div>
                                            </div>
                                        </div>
                                        <div className="w-full sm:w-1/2 mb-4">
                                            <div className="">
                                                <div className="text-sm text-red-500 font-semibold flex items-center gap-1">
                                                    <span className="material-icons">Requested</span>
                                                </div>
                                                <div className="text-2xl font-bold">${requestedAmount || 0}</div>
                                            </div>
                                            <div className="">
                                                <div className="text-sm text-red-500 font-semibold flex items-center gap-1">
                                                    <span className="material-icons">Token Commission</span>
                                                </div>
                                                <div className="text-2xl font-bold">${tokenCommission || 0}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="flex flex-wrap justify-between items-center mb-4">
                                        <div className="text-start">
                                            <div className="text-sm text-green-500 font-semibold flex items-center gap-1">
                                                <span className="material-icons">Subscription Commission</span>
                                            </div>
                                            <div className="text-2xl font-bold">${subCommission || 0}</div>
                                        </div>
                                        <div className="text-end">
                                            <div className="text-sm text-red-500 font-semibold flex items-center gap-1">
                                                <span className="material-icons">Token Commission</span>
                                            </div>
                                            <div className="text-2xl font-bold">${tokenCommission || 0}</div>
                                        </div>
                                    </div> */}
                                    <p className="text-sm text-white">
                                        {lastPaymentRequest.created_at ? 'Last Payment request: ' + lastPaymentRequest.created_at : 'No last payment request yet.'}
                                    </p>
                                    <button onClick={() => setShowPaymentRequestModal(true)} className="mt-4 bg-[#99111fc2] hover:bg-[#99111f] text-white font-semibold py-2 px-4 rounded shadow">
                                        Request Payment
                                    </button>
                                </div>

                                <div className="bg-[#424242] p-6 rounded-lg flex-grow">
                                    <h2 className="text-lg font-bold mb-4">Bank Details</h2>
                                    {bankDetails.bank_name && bankDetails.account_number && bankDetails.branch_name && bankDetails.account_name ? (
                                        <>
                                            <div className="grid grid-cols-2 gap-4 text-sm">
                                                <div>
                                                    <p className="text-gray-400">Bank Name</p>
                                                    <p className="font-semibold">{bankDetails.bank_name}</p>
                                                </div>
                                                <div>
                                                    <p className="text-gray-400">Account Number</p>
                                                    <p className="font-semibold">{bankDetails.account_number}</p>
                                                </div>
                                                <div>
                                                    <p className="text-gray-400">Branch Name</p>
                                                    <p className="font-semibold">{bankDetails.branch_name}</p>
                                                </div>
                                                <div>
                                                    <p className="text-gray-400">Account Name</p>
                                                    <p className="font-semibold">{bankDetails.account_name}</p>
                                                </div>
                                            </div>
                                        </>
                                    ) : (<></>)}
                                    <div className="mt-4 flex justify-start gap-4">

                                        {bankDetails.bank_name && bankDetails.account_number && bankDetails.branch_name && bankDetails.account_name ?
                                            (
                                                <p
                                                    className="text-[#99111f] hover:underline"
                                                // onClick={() => setShowOtherAccountsModal(true)}
                                                >
                                                    Remove Bank Details
                                                </p>
                                            ) :
                                            (
                                                <p
                                                    className="text-[#99111f] hover:underline bg-white rounded-lg px-4 py-2"
                                                    onClick={() => setShowEditModal(true)}
                                                >
                                                    Add Bank Details
                                                </p>
                                            )}
                                    </div>
                                </div>
                            </div>

                            {/* CTA Button */}
                            {/* <div className="text-center mt-12">
                            <button
                                onClick={handleAffiliateSignup}
                                className="bg-gradient-to-r from-[#ea3333] to-[#b30000] text-white px-8 py-4 rounded-full text-xl font-semibold shadow-lg hover:from-[#b30000] hover:to-[#ea3333] transition duration-200"
                            >
                                Become an Affiliate
                            </button>
                        </div> */}

                            {/* Contact Support */}
                            {/* <div className="text-center mt-8">
                            <p className="text-lg font-medium text-white">
                                Have questions about the affiliate program? Contact our support team at
                                <a href="https://support.eromantic.ai/hc/en-us" className="text-blue-500 ml-1">
                                    support@eromantic.ai
                                </a>.
                            </p>
                        </div> */}
                        </div>
                    </div>

                    {/* Referral Section */}
                    {/* <div className='flex justify-center pt-3'>
                    <p className='text-2xl xl:w-[70%] text-center'>
                        Earn commissions by referring users to Seduced and receiving a share of up to 40% from their plan purchases or renewals.
                    </p>
                </div>
                <div className='w-full flex flex-wrap gap-5 justify-center mt-16'>
                    <div className=' flex flex-col justify-center gap-8'>
                        <EasyTrackingBox name='Lucrative Commissions:' text='Pocket a generous 25% of net revenue for every member you bring on board! With premium plans ranging from $20-$120, your earning potential is seriously high.' />
                        <EasyTrackingBox name='Passive Income Stream:' text='Enjoy recurring revenue month after month, as long as your referrals remain subscribed. Build a base of referrals and watch your income grow.' />
                        <EasyTrackingBox name='Easy Tracking:' text='Our advanced affiliate dashboard gives you real-time insights. See exactly who signs up through your link and track your earnings with crystal clarity.' />
                    </div>
                    <div className='flex flex-col justify-center gap-8'>
                        <div>
                            <img loading ='lazy' src={videoPlay} alt="videoPlay" className='rounded-xl md:w-[630px] 2xl:w-[730px] h-[220px] md:h-[390px]' />
                        </div>
                        <EasyTrackingBox name='Premium Product, High Conversions:' text='You will be promoting a cutting-edge AI image generation solution that is in high demand. Our proven marketing materials help you close sales effortlessly.' />
                    </div>
                </div> */}

                   <div>
                   <div className="flex flex-wrap items-start">
                        <button
                            onClick={() => handleTabClick(1)}
                            className={` px-1 mo:px-4 py-2 mr-2  font-semibold text-base mo:text-lg rounded-lg transition-colors duration-300 ${activeTab === 1 ? 'bg-[#991b1b] text-white' : 'bg-gray-200 text-gray-600'
                                }`}
                        >
                            Transactions
                        </button>
                        <button
                            onClick={() => handleTabClick(2)}
                            style={{ margin: "0" }}
                            className={`px-1 mo:px-4 py-2 font-semibold text-base mo:text-lg rounded-lg transition-colors duration-300 ${activeTab === 2 ? 'bg-[#991b1b] text-white' : 'bg-gray-200 text-gray-600'
                                }`}
                        >
                            Withdrawal
                        </button>
                    </div>

                    {activeTab === 1 && (
                        <>
                            {/* Sales History Section */}
                            <div className="p-4 border-2 border-zinc-600 text-black rounded-lg mt-10">
                                <div className=" flex items-center flex-wrap justify-start gap-10">
                                    <span className="text-lg lg:text-xl xl:text-2xl">Sales history</span>
                                    {/* <div className=" flex flex-wrap items-center justify-center border-2 border-zinc-600">
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    className="px-0 md:px-2 py-1 text-center w-fit bg-[#61616100] rounded text-black"
                                />
                                <span>-</span>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    className="px-0 md:px-2 py-1 text-center w-fit bg-[#61616100] rounded text-black"
                                />
                            </div> */}
                                </div>

                                <div className=" overflow-x-auto sm:rounded-lg mt-4 font-josefin-sans">
                                    <table className="w-full text-left table-auto">
                                        <thead className=" border-t-2 border-zinc-600 text-black">
                                            <tr className='text-lg'>
                                                <th className="px-4 py-2 text-left text-[16px] sm:text-[18px] md:text-[20px]  uppercase tracking-wider whitespace-nowrap">Sr.</th>
                                                <th className="px-4 py-2 text-left text-[16px] sm:text-[18px] md:text-[20px] uppercase tracking-wider whitespace-nowrap">Email</th>
                                                <th className="px-4 py-2 text-left text-[16px] sm:text-[18px] md:text-[20px] uppercase tracking-wider whitespace-nowrap">Debited / Credited On</th>
                                                <th className="px-4 py-2 text-left text-[16px] sm:text-[18px] md:text-[20px] uppercase tracking-wider whitespace-nowrap">Amount</th>
                                                <th className="px-4 py-2 text-left text-[16px] sm:text-[18px] md:text-[20px] uppercase tracking-wider whitespace-nowrap">Commission Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {commissionList.map((item, index) => (
                                                <tr>
                                                    <td className="px-4 py-2 text-[15px] sm:text-base md:text-lg whitespace-nowrap">#{index + 1}</td>
                                                    <td className="px-4 py-2 text-[15px] sm:text-base md:text-lg whitespace-nowrap">{item.user}</td>
                                                    <td className="px-4 py-2 text-[15px] sm:text-base md:text-lg whitespace-nowrap">{item.created_at}</td>
                                                    <td className="px-4 py-2 text-[15px] sm:text-base md:text-lg whitespace-nowrap">${item.amount}</td>
                                                    <td className="px-4 py-2 text-[15px] sm:text-base md:text-lg capitalize whitespace-nowrap">{item.commission_type}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </>
                    )}
                    {activeTab === 2 && (
                        <>
                            {/* Withdrawal History Section */}
                            <div className="p-4 border-2 border-zinc-600 text-black rounded-lg mt-10">
                                <div className="flex items-center flex-wrap justify-start gap-10">
                                    <span className="text-lg lg:text-xl xl:text-2xl">Withdrawal history</span>
                                    {/* <div className=" flex flex-wrap items-center justify-center border-2 border-zinc-600">
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    className="px-0 md:px-2 py-1 text-center w-fit bg-[#61616100] rounded text-black"
                                />
                                <span>-</span>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    className="px-0 md:px-2 py-1 text-center w-fit bg-[#61616100] rounded text-black"
                                />
                            </div> */}
                                </div>

                                <div className="overflow-x-auto sm:rounded-lg mt-4 font-josefin-sans">
                                    <table className="w-full text-left table-auto ">
                                        <thead className=" border-t-2 border-zinc-600 text-black">
                                            <tr className='text-lg'>
                                                <th className="px-4 py-2 text-left text-[16px] sm:text-[18px] md:text-[20px] whitespace-nowrap uppercase tracking-wider">Sr.</th>
                                                <th className="px-4 py-2 text-left text-[16px] sm:text-[18px] md:text-[20px] whitespace-nowrap uppercase tracking-wider">Amount</th>
                                                <th className="px-4 py-2 text-left text-[16px] sm:text-[18px] md:text-[20px] whitespace-nowrap uppercase tracking-wider">Description</th>
                                                <th className="px-4 py-2 text-left text-[16px] sm:text-[18px] md:text-[20px] whitespace-nowrap uppercase tracking-wider">Created At</th>
                                                <th className="px-4 py-2 text-left text-[16px] sm:text-[18px] md:text-[20px] whitespace-nowrap uppercase tracking-wider">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {withdrawalList.map((item, index) => (
                                                <tr>
                                                    <td className="px-4 py-2 text-[15px] sm:text-base md:text-lg whitespace-nowrap">#{index + 1}</td>
                                                    <td className="px-4 py-2 text-[15px] sm:text-base md:text-lg whitespace-nowrap">${item.amount}</td>
                                                    <td className="px-4 py-2 text-[15px] sm:text-base md:text-lg whitespace-nowrap">{item.description?.slice(0, 50)}</td>
                                                    <td className="px-4 py-2 text-[15px] sm:text-base md:text-lg whitespace-nowrap">{item.created_at}</td>
                                                    <td className="px-4 py-2 text-[15px] sm:text-base md:text-lg whitespace-nowrap">
                                                        <span
                                                            className="px-2 py-1 rounded-full text-black capitalize"
                                                            style={{ backgroundColor: item.status === 'pending' ? 'yellow' : item.status == 'completed' ? 'green' : 'red' }}>
                                                            {item.status}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </>
                    )}
                   </div>
                </div>
            </div>

            <Footer />
            {/* Edit Details Modal */}
            {showEditModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg w-96">
                        <h3 className="text-lg font-bold mb-4">Edit Bank Details</h3>
                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm">Bank Name</label>
                                <input
                                    type="text"
                                    name="bank_name"
                                    value={bankDetails.bank_name}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded"
                                />
                            </div>
                            <div>
                                <label className="block text-sm">Account Number</label>
                                <input
                                    type="text"
                                    name="account_number"
                                    value={bankDetails.account_number}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded"
                                />
                            </div>
                            <div>
                                <label className="block text-sm">Branch Name</label>
                                <input
                                    type="text"
                                    name="branch_name"
                                    value={bankDetails.branch_name}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded"
                                />
                            </div>
                            <div>
                                <label className="block text-sm">Account Name</label>
                                <input
                                    type="text"
                                    name="account_name"
                                    value={bankDetails.account_name}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded"
                                />
                            </div>
                        </div>
                        <div className="mt-4 flex justify-between">
                            <button
                                className="bg-[#99111f] text-white px-4 py-2 rounded"
                                onClick={submitBankDetails}
                            >
                                Save Changes
                            </button>
                            <button
                                className="bg-gray-500 text-white px-4 py-2 rounded"
                                onClick={closeModals}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Payment Request Modal */}
            {showPaymentRequestModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg">
                        <h3 className="text-lg font-bold mb-4">Payment Request</h3>

                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm">Request Amount</label>
                                <input
                                    type="number"
                                    name="amount"
                                    value={paymentRequest.amount}
                                    onChange={handlePaymentChange}
                                    className="w-full p-2 border rounded"
                                />
                            </div>
                            <div>
                                <label className="block text-sm">Description</label>
                                <textarea
                                    name="description"
                                    value={paymentRequest.description}
                                    onChange={handlePaymentChange}
                                    className="w-full p-2 border rounded"
                                    rows="3"
                                />
                            </div>
                        </div>

                        <div className="mt-4 flex justify-between">
                            <button
                                className="bg-[#99111f] text-white px-4 py-2 rounded"
                                onClick={submitWithdrawalRequest}
                            >
                                Submit Request
                            </button>
                            <button
                                className="bg-gray-500 text-white px-4 py-2 rounded"
                                onClick={closeModals}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
                  {/* {/ Custom Affilate Link Modal /} */}
            {showCustomizeModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                <div className="bg-white p-6 rounded-lg">
                    <h3 className="text-lg font-bold mb-4">
                    Custom Affilate Link Request
                    </h3>

                    <div className="space-y-4">
                    <div className="flex flex-col space-y-4">
                        <div className="flex items-center space-x-2">
                        <input
                            type="text"
                            name="affiliate_id"
                            value={customAffiliateValue}
                            onChange={(e) => setCustomAffiliateValue(e.target.value)}
                            minLength={16}
                            maxLength={16}
                            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-none focus:ring-0"
                            placeholder="Enter your referral code"
                        />
                        <button
                            type="button"
                            onClick={handleCheckAffiliate}
                            className="p-3 px-6 bg-[#991b1b] text-white rounded-md hover:bg-[#991b1bf1] focus:outline-none focus:ring-2 focus:ring-[#991b1b]"
                        >
                            Check
                        </button>
                        </div>

                        {customAffiliateCheck !== null &&
                        (customAffiliateCheck === true ? (
                            <p className="text-xl text-[#008000]">
                            Referral code is available.
                            </p>
                        ) : (
                            <p className="text-xl text-[#991b1b]">
                            Referral code is not available.
                            </p>
                        ))}
                    </div>
                    </div>

                    <div className="mt-4 flex justify-between">
                    <button
                        className="bg-[#99111f] text-white px-4 py-2 rounded disabled:bg-[#99111fa6]"
                        onClick={handleSubmitCustomAffiliate}
                        disabled={
                        customAffiliateCheck !== true ||
                        customAffiliateCheck === null ||
                        customAffiliateValue === ""
                        }
                    >
                        Submit Request
                    </button>
                    <button
                        className="bg-gray-500 text-white px-4 py-2 rounded"
                        onClick={closeModals}
                    >
                        Cancel
                    </button>
                    </div>
                </div>
                </div>
            )}
        </>
    );
};

export default Referrals;