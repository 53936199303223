import React, { useState } from 'react';
import { AiFillLike } from 'react-icons/ai';
import { FaHeart } from 'react-icons/fa6';
import { IoIosTime } from 'react-icons/io';
import Saved from './Saved';
import ByEromanticai from './ByEromanticai';
import { useNavigate } from "react-router-dom";
import Footer from '../../../frontend/components/Footer';

const Girls = () => {
    const [selected, setSelected] = useState('Saved');

    const Saveddata = [
        { name: 'Saved', icon: <FaHeart size={20} color='#616161' /> },
        { name: 'By EROMANTIC.AI' },
        { name: 'Trending', icon: <AiFillLike size={20} color='#616161' /> },
        { name: 'Most Recent', icon: <IoIosTime size={20} color='#616161' /> },
    ];

    const navigate = useNavigate();
    const NewGenerate = () => {
        navigate("/dashboard");
    }
    const CreatGf = () => {
        navigate("/DreamChat");
    }
    const ChatDream = () => {
        navigate("/Aidreamchat");
    }

    return (
        <>
            <div className='px-2 xl:px-20 pb-20 pt-10 text-black font-josefin-sans'>
                <div className='flex flex-wrap gap-10 items-center justify-center mt-5 mb-10'>
                    <div className="w-80 h-80 border-2 border-zinc-700 rounded-[10px] grid items-center justify-center text-center cursor-pointer" onClick={NewGenerate}>
                        <img loading ='lazy' src="/assets/img/ai.png" alt="Icon" className="w-25 h-25" />
                        <span className="text-2xl font-medium">Generate Image</span>
                    </div>
                    <div className="w-80 h-80 border-2 border-zinc-700 rounded-[10px] grid items-center justify-center text-center cursor-pointer" onClick={CreatGf}>
                        <img loading ='lazy' src="/assets/img/gender.png" alt="Icon" className="w-25 h-25" />
                        <span className="text-2xl font-medium">Create your Girlfrinend</span>
                    </div>
                    <div className="w-80 h-80 border-2 border-zinc-700 rounded-[10px] grid items-center justify-center text-center cursor-pointer" onClick={ChatDream}>
                        <img loading ='lazy' src="/assets/img/chatbot.jpg" alt="Icon" className="w-25 h-[240px]" />
                        <span className="text-2xl font-medium">Chat with your Dream</span>
                    </div>
                </div>

                <div className='flex justify-center md:justify-center items-center  flex-wrap mb-5 '>
                    {Saveddata.map((item, index) => (
                        <div
                            key={index}
                            className={`w-60 md:w-48 m-2 justify-center flex items-center gap-2 px-4 py-2 border-2 ${selected === item.name ? 'bg-[#FF00133D] text-red-500 border-red-700' : 'bg-[#6161614A] text-black border-zinc-600'
                                } rounded-full hover:cursor-pointer hover:bg-[#FF00133D] hover:border-red-700 hover:text-red-500`}
                            onClick={() => setSelected(item.name)}
                        >
                            {item.icon}
                            <p className='text-center'>{item.name}</p>
                        </div>
                    ))}
                </div>

                {selected === 'Saved' ? (
                    <Saved />
                ) : selected === 'By EROMANTIC.AI' ? (
                    <ByEromanticai />
                ) : null}
            </div>
            <Footer />
        </>
    );
};

export default Girls;
