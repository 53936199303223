import React, { useEffect, useState } from 'react';
import Togal from '../../components/Togal';
import { DeleteImg, eye } from '../../../assets';
import UserApiClient from '../../../utilities/apiClients';
import Swal from 'sweetalert2';
import AdminPagination from '../AdminPagination';
import PagenameSearchBox from '../../components/PagenameSearchBox';


const Invoice = () => {
    const [detailModalOpen, setDetailModalOpen] = useState(false);
    const [currentStaff, setCurrentStaff] = useState({});
    const [staffMembers, setStaffMembers] = useState([]);
    const [pageLinks, setPageLinks] = useState({ "next": null, "prev": null });
    const [searchQuery, setSearchQuery] = useState(null);
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(null);
    const [pageSize, setPageSize] = useState(null);

    const fetchData = async (pageLink) => {
        await UserApiClient.get(pageLink && pageLink.includes('?page') ?
            `${pageLink}${"&search=" + (searchQuery != null ? searchQuery : '')}${pageSize != null ? '&page_size=' + pageSize : ''}` :
            `api/v1/admin/user/invoices/${"?search=" + (searchQuery != null ? searchQuery : '')}${pageSize != null ? '&page_size=' + pageSize : ''}`,
            {}, true).then(async res => {
                if (res.success) {
                    setPageLinks({ 'next': res.data.next, 'prev': res.data.previous });
                    setStaffMembers(res.data.results);
                }
            })
    }

    useEffect(() => {
        fetchData();
    }, [])

    const openDetailModal = (staff) => {
        setCurrentStaff(staff);
        setDetailModalOpen(true);
    };
    const closeDetailModal = () => {
        setDetailModalOpen(false);
    };

    const handlePrevNextPage = async (btn) => {
        if (btn === 'next') {
            fetchData(pageLinks.next);
        } else if (btn === 'prev') {
            fetchData(pageLinks.prev);
        }
    };

    useEffect(() => {
        if (searchQuery != null) {
            const timer = setTimeout(() => {
                setDebouncedSearchQuery(searchQuery);
            }, 700);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [searchQuery]);

    useEffect(() => {
        if (debouncedSearchQuery != null) {
            fetchData()
        }
    }, [debouncedSearchQuery]);

    useEffect(() => {
        if (pageSize != null) {
            fetchData()
        }
    }, [pageSize]);

    return (
        <div className="bg-white px-7 pb-20 font-josefin-sans">
                <PagenameSearchBox
                    title="Invoice List"
                    placename="Search invoice"
                    inputValue={searchQuery}
                    onSearchChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                />
                <div className="flex justify-end space-x-4">
                    <select className="py-1.5 px-3 focus:outline-none text-lg rounded h-10" onChange={(e) => setPageSize(e.target.value)}>
                        <option value='20'>20</option>
                        <option value='50'>50</option>
                        <option value='100'>100</option>
                        <option value='500'>500</option>
                        <option value='1000'>1000</option>
                    </select>
                </div>
            <div className="overflow-x-auto rounded-lg">
                <table className="w-full text-left text-gray-500 border border-stone-300 table-auto mt-5">
                    <thead className="text-xl text-black bg-stone-300">
                        <tr>
                            <th scope="col" className="px-2 2xl:px-6 py-3">Sr.</th>
                            <th scope="col" className="px-2 2xl:px-6 py-3">Invoice Id</th>
                            <th scope="col" className="px-2 2xl:px-6 py-3">User</th>
                            <th scope="col" className="px-2 2xl:px-6 py-3">Type</th>
                            <th scope="col" className="px-2 2xl:px-6 py-3">Created At</th>
                            <th scope="col" className="px-2 2xl:px-6 py-3 text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {staffMembers.map((staff, index) => (
                            <tr key={staff.id} className="bg-white text-xl font-normal text-black border-b border-stone-300">
                                <td className="px-2 2xl:px-6 py-4">{index + 1}.</td>
                                <td className="px-2 2xl:px-6 py-4">{staff.invoice_id}.</td>
                                <td className="px-2 2xl:px-6 py-4 cursor-pointer" onClick={() => openDetailModal(staff)}>{staff.user}</td>
                                <td className="px-2 2xl:px-6 py-4">{staff.invoice_type}</td>
                                <td className="px-2 2xl:px-6 py-4">{staff.created_at.split('T')[0]}</td>
                                <td className="px-2 2xl:px-6 py-4 flex-wrap flex gap-5 justify-center items-center">
                                    <img loading='lazy'
                                        src={eye}
                                        alt="eye"
                                        className="cursor-pointer"
                                        onClick={() => openDetailModal(staff)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <AdminPagination pageLinks={pageLinks} handlePrevNextPage={handlePrevNextPage} />

            {detailModalOpen && (
                <div className="fixed inset-0 bg-gray-700 bg-opacity-75 flex justify-center items-center z-50">
                    <div className="relative bg-white rounded-lg shadow-xl p-6 w-full max-w-md  landscape:h-[350px] overflow-auto" style={{scrollbarWidth:"none",scrollBehavior:"smooth"}}>
                        <div className="flex justify-between items-center pb-3">
                            <p className="text-2xl font-bold">Invoice Details</p>
                            <div className="cursor-pointer z-50" onClick={closeDetailModal}>
                                <svg className="fill-current text-gray-700" width="18" height="18" viewBox="0 0 18 18">
                                    <path d="M14.59 3.41L11.18 6.82L14.59 10.23L13.18 11.64L9.77 8.23L6.36 11.64L4.95 10.23L8.36 6.82L4.95 3.41L6.36 2L9.77 5.41L13.18 2L14.59 3.41Z" />
                                </svg>
                            </div>
                        </div>
                        <div className="space-y-2">
                            <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                                <strong className="mb-1">ID:</strong> {currentStaff.id}
                            </p>
                            <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                                <strong className="mb-1">Invoice Id:</strong> {currentStaff.invoice_id}
                            </p>
                            <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                                <strong className="mb-1">User:</strong> {currentStaff.user}
                            </p>
                            <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                                <strong className="mb-1">Invoice Type:</strong> {currentStaff.invoice_type}
                            </p>
                            <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                                <strong className="mb-1">Amount:</strong> {currentStaff.amount}
                            </p>
                            <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                                <strong className="mb-1">Currency:</strong> {currentStaff.currency}
                            </p>
                            <p className="p-2 m-1 flex flex-wrap justify-between border rounded-lg">
                                <strong className="mb-1">Created At:</strong> {currentStaff.created_at.split('T')[0]}
                            </p>
                            {currentStaff.billing_address && Object.entries(currentStaff.billing_address).map(([key, value]) => (
                                value != null && value != '' &&
                                <p key={key} className="p-2 m-1 flex flex-wrap justify-between border rounded-lg capitalize">
                                    <strong className="mb-1">{key.replaceAll('_', ' ')}:</strong> {value}
                                </p>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Invoice;
