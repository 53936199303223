import React, { useState, useRef, useEffect } from 'react';
import { Rectangle92 } from '../../../assets';
import { ErrorMessage, Field, Form, Formik } from "formik";
import { twitterX, instaIcon } from '../../../assets';
import NotifyModal from "./NotifyModal";
import * as Yup from 'yup';
import { BaseApiUrl } from '../../../utilities/enviroment';

const ComingSoonMobile = () => {
  const BASE_URL = BaseApiUrl ; 

  const [isOpen, setIsOpen] = useState(false);

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Please Enter Valid Email"),
  });

  const [width, setWidth] = useState(0);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (selectRef.current) {
        const width = selectRef.current.clientWidth - 7000;
        setWidth(width);
      }
    };

    // Initial width calculation
    handleResize();

    // Listen for resize events
    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await fetch(
          `${BASE_URL}user/subscribe_email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      resetForm();
      if (response.ok) {
        setIsOpen(true);
        console.log("API call successful");
      } else {
        console.error("API call failed");
      }
    } catch (error) {
      console.error("Error occurred while calling API:", error);
    }
  };

  return (
    <div className='relative comingsoon overflow-hidden font-josefin-sans h-[100vh]'>
      <img loading ='lazy' src={Rectangle92} alt='Rectangle92' className='absolute top-0 left-0 w-full h-full object-cover' />
      <div className='absolute top-0 left-0 w-full h-full bg-black opacity-55'></div>

      <div className='flex justify-center text-center'>
        <div className='relative z-10 text-white'>
          <div className="flex items-center justify-center text-center xxs:mt-16 xs:mt-16 xss:mt-12 gap-3">
            <hr className="border-[1px] xxs:w-[48px] xs:w-[52px] xss:w-[52px]" />
            <p className="xxs:text-[28px] xs:text-[30px] xss:text-[40px] font-light text-white">Coming Soon</p>
            <hr className="border-[1px] xxs:w-[48px] xs:w-[52px] xss:w-[52px]" />
          </div>

          <div className='xxs:text-[25px] xs:text-[26px] xss:text-[32px] font-bold leading-relaxed mt-5'>
            <p>Get Notified</p>
            <p>When We Launch</p>
          </div>

          <div className='flex items-center justify-center xxs:mt-7 xs:mt-5'>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={SignupSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className='flex items-center xxs:w-[283.8px] xs:w-[320px] xss:w-[343.8px] h-[47px] rounded-[47px] border-2 border-white bg-transparent'>
                    <Field
                      type='email'
                      name='email'
                      placeholder='Enter your email address'
                      className={`flex-grow h-full rounded-l-[47px] border-none text-white placeholder-white bg-transparent px-3 xs:px-5 focus:outline-none xxs:text-[14px] xs:text-[16px] xss:text-[18px] ${errors.email && touched.email ? 'border-red-500' : ''}`}
                    />
                    <button type="submit" className='text-[13px] xss:text-[14px] xxs:w-[85px] xs:w-[105px] xss:w-[85px] h-[30px] rounded-[47px] bg-white hover:bg-slate-100 text-black mr-2'>
                      Notify Me
                    </button>
                  </div>
                  <div className="pt-2 pl-2">
                    <ErrorMessage name="email" component="div" className="text-red-500" />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <p className='xxs:text-[13px] xss:text-[14px] my-5'>* Get Discounts & Other Goodies For Joining The Waitlist :)</p>

          <div className='flex justify-center items-center xxs:mt-[90px] xs:mt-[100px] xss:mt-[70px]'>
            <div className='border-[2px] border-white rounded-xl px-10 py-3 flex justify-content-between items-center gap-4'>
              <h4 className='text-3xl' style={{ marginTop: '10px' }}>Follow Us</h4>
              <a className='p-1 border-[1px] border-white rounded-full' href='https://x.com/eromanticai' target='_blank'>
                <img loading ='lazy' src={twitterX}
                  style={{ height: '40px', width: '40px' }}
                />
              </a>
              <a className='p-1 border-[1px] border-white rounded-full' href='https://www.instagram.com/eromantic.ai/' target='_blank'>
                <img loading ='lazy' src={instaIcon}
                  style={{ height: '40px', width: '40px' }}
                />
              </a>
            </div>
            {/*             <img loading ='lazy' src={IconCominsoon} alt='IconCominsoon' className='w-[249.64px] xss:w-[279.64px] h-[60px] xss:h-[68px]' /> */}
          </div>

          <div className='mt-32 xs:mt-32 xss:mt-40 flex flex-col justify-center items-center'>
            <hr className='border-1 border-white w-[284px] xs:w-[334px] xss:w-[364px]' />
            <div className='flex text-center justify-center gap-5 xss:gap-7 text-white text-[15px] font-normal my-4 xss:my-5'>

              <a class="text-[14px] lg:text-lg" href="/blogs">Blogs</a>
              <a class="text-[14px] lg:text-lg" href="/terms-of-service">Terms of Service</a>
              <a class="text-[14px] lg:text-lg" href="/privacy-policy">Privacy Policy</a>
              <a class="text-[14px] lg:text-lg" href="/cookie-policy">Cookie Policy</a>
            </div>
          </div>
        </div>
      </div>
      <NotifyModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default ComingSoonMobile;
