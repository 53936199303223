import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteImg } from '../../../assets';
import axios from 'axios';
import { BaseApiUrl } from '../../../utilities/enviroment';

const BASE_URL = BaseApiUrl + "eromantic_api/"; 


const Referral = () => {
    const navigate = useNavigate();
    const [referrals, setReferrals] = useState([]);
    const [filteredReferrals, setFilteredReferrals] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedReferralId, setSelectedReferralId] = useState(null);
    const [referralDetails, setReferralDetails] = useState(null); // State to store referral data

    const handleAddReferral = () => {
        const authToken = localStorage.getItem('authToken');
        if (!authToken) {
            console.error('No auth token found. Please log in.');
            return;
        }
        navigate('/admin/addReferral');
    };

    useEffect(() => {
        const staticReferrals = [
            {
                id: 1,
                first_name: 'John',
                last_name: 'Doe',
                email: 'john.doe@example.com',
                referral_code: 'REF12345',
                status: 'Pending',
                website: 'https://johndoe.com',
                promotion_plan: 'Social media ads, blog posts',
                audience_details: 'Age 25-45, tech enthusiasts, social media',
                referral_source: 'Social media post'
            },
            {
                id: 2,
                first_name: 'Jane',
                last_name: 'Smith',
                email: 'jane.smith@example.com',
                referral_code: 'REF67890',
                status: 'Pending',
                website: 'https://janesmith.com',
                promotion_plan: 'Email marketing, affiliate marketing',
                audience_details: 'Age 18-35, fashion lovers, influencers',
                referral_source: 'Referral from a friend'
            },
            // Add more referral data as needed
        ];

        setReferrals(staticReferrals);
        setFilteredReferrals(staticReferrals);
    }, []);

    const openPopup = (referralId) => {
        const referral = referrals.find(r => r.id === referralId);
        setReferralDetails(referral); // Set referral details
        setSelectedReferralId(referralId);
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setSelectedReferralId(null);
        setReferralDetails(null);
    };

    const handleReject = async () => {
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                console.error('No auth token found. Please log in.');
                return;
            }

            // Update the status to "Rejected"
            setReferrals(referrals.map(referral =>
                referral.id === selectedReferralId
                    ? { ...referral, status: 'Rejected' }
                    : referral
            ));
            closePopup();
        } catch (error) {
            console.error('Error rejecting referral:', error);
        }
    };

    const handleApprove = async () => {
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                console.error('No auth token found. Please log in.');
                return;
            }

            // Update the status to "Pending"
            setReferrals(referrals.map(referral =>
                referral.id === selectedReferralId
                    ? { ...referral, status: 'Pending' }
                    : referral
            ));
            closePopup();
        } catch (error) {
            console.error('Error approving referral:', error);
        }
    };

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filtered = referrals.filter(referral =>
            referral.first_name.toLowerCase().includes(searchTerm) ||
            referral.last_name.toLowerCase().includes(searchTerm) ||
            referral.email.toLowerCase().includes(searchTerm) ||
            referral.referral_code.toLowerCase().includes(searchTerm)
        );
        setFilteredReferrals(filtered);
    };

    return (
        <>
            <div className='h-full bg-white px-7 pb-20 font-josefin-sans'>
                <div className="flex justify-between items-center">
                    <h2 className="text-2xl md:text-3xl font-semibold">Referral List</h2>
                    {/* <button
                        className="rounded-lg text-lg bg-red-800 hover:bg-red-700 text-white py-2 px-4 min-w-44"
                        onClick={handleAddReferral}
                    >
                        Add Referral
                    </button> */}
                </div>

                <div className="mb-4 mt-10">
                    <input
                        type="text"
                        placeholder="Search Referral"
                        onChange={handleSearchChange}
                        className="border p-2 rounded w-full"
                    />
                </div>
                <div className="rounded-lg overflow-x-auto mt-10">
                    <table className="w-full text-left text-gray-500 border border-stone-300">
                        <thead className="text-xl text-black bg-stone-300">
                            <tr>
                                <th scope="col" className="px-6 py-3">Referral ID</th>
                                <th scope="col" className="px-6 py-3">First Name</th>
                                <th scope="col" className="px-6 py-3">Last Name</th>
                                <th scope="col" className="px-6 py-3">Email</th>
                                <th scope="col" className="px-6 py-3">Referral Code</th>
                                <th scope="col" className="px-6 py-3">Status</th>
                                <th scope="col" className="px-6 py-3 text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredReferrals.map((referral) => (
                                <tr key={referral.id} className="bg-white text-black text-xl font-normal border-b border-stone-300">
                                    <td className="px-6 py-4 whitespace-nowrap">{referral.id}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{referral.first_name}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{referral.last_name}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{referral.email}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{referral.referral_code}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`badge rounded-full ${referral.status === 'Pending' ? 'bg-yellow-500' : 'bg-red-500'} text-white py-1 px-3`}>
                                            {referral.status}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 flex gap-5 justify-center items-center">
                                        <img loading ='lazy' src="/static/media/eye.d73b79e56147500ba8d8cd0b93520dfa.svg" onClick={() => openPopup(referral.id)} alt="eye" className="hover:cursor-pointer" />
                                        <img loading ='lazy'
                                            src={DeleteImg}
                                            alt="deleteImg"
                                            className='hover:cursor-pointer'
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Modal */}
                {isPopupOpen && referralDetails && (
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
                        <div className="bg-white p-6 rounded-lg w-2/3 md:w-1/3">
                        <div className='flex justify-between relative'>
                            <h3 className="text-xl font-semibold mb-4">Referral Details</h3>
                            <button className="bg-red-500 text-white h-7 w-7 rounded-full absolute right-[-20px] bottom-[35px] " onClick={closePopup}> x </button>
                        </div>

                            {/* Referral Details */}
                            <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                                <strong className='mb-1'>Website/Social Profile:</strong> {referralDetails.website}
                            </p>
                            <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                                <strong className='mb-1'>Promotion Plan:</strong> {referralDetails.promotion_plan}
                            </p>
                            <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                                <strong className='mb-1'>Audience Details:</strong> {referralDetails.audience_details}
                            </p>
                            <p className='p-2 m-1 flex flex-wrap justify-between border rounded-lg'>
                                <strong className='mb-1'>How Did You Hear About Us:</strong> {referralDetails.referral_source}
                            </p>

                            {/* Commission Section */}
                            <div className="mt-6 border rounded-lg p-2">
                                <h4 className="text-lg font-semibold mb-4 border-b-2">Commission</h4>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                    {/* Commission Inputs */}
                                    <div className="flex flex-col">
                                        <label className="mb-2 font-medium" htmlFor="commission-rate">Subscription Commission</label>
                                        <input
                                            id="commission-rate"
                                            type="text"
                                            className="p-2 border rounded-lg"
                                            placeholder="Enter commission rate"
                                        />
                                    </div>
                                    <div className="flex flex-col">
                                        <label className="mb-2 font-medium" htmlFor="commission-type">Token Commission</label>
                                        <input
                                            id="commission-type"
                                            type="text"
                                            className="p-2 border rounded-lg"
                                            placeholder="Enter commission type"
                                        />
                                    </div>
                                    <div className="flex flex-col">
                                        {/* <label className="mb-2 font-medium" htmlFor="payment-method">Payment Method</label> */}
                                        <input
                                            id="payment-method"
                                            type="text"
                                            className="p-2 border rounded-lg"
                                            placeholder="Enter payment method"
                                        />
                                    </div>
                                    <div className="flex flex-col">
                                        {/* <label className="mb-2 font-medium" htmlFor="commission-duration">Commission Duration</label> */}
                                        <input
                                            id="commission-duration"
                                            type="text"
                                            className="p-2 border rounded-lg"
                                            placeholder="Enter commission duration"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Action Buttons */}
                            <div className="mt-4 flex flex-col md:flex-row flex-wrap gap-1 justify-between items-center">
                                
                                <button
                                    className="bg-yellow-600 text-white  w-[48%] py-2 px-4 rounded"
                                    onClick={handleReject}
                                >
                                    Reject
                                </button>
                                <button
                                    className="bg-green-600 text-white  w-[48%] py-2 px-4 rounded"
                                    onClick={handleApprove}
                                >
                                    Approve
                                </button>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </>
    );
};

export default Referral;
