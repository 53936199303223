import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  aiModal3,
  aiModal_futanari,
  aiModal4,
  aiModal11,
  aiModal12,
  aiModal13,
  aiModal5,
  aiModal6,
  duplicate,
  goldE,
  info,
  boy,
  girls,
} from "../../assets";
import Footer from "../../frontend/components/Footer";
import loaders from "../../frontend/components/loaders";
import Swal from "sweetalert2";
import { BaseApiUrl } from "../../utilities/enviroment";
import CircularWithValueLabel from "./CreateDreamGF/CircularWithValueLabel";
import UserApiClient from "../../utilities/apiClients";

const Replicate = () => {
  const [selectedFeature, setSelectedFeature] = useState("Hair color");
  const [selectedCharacter, setSelectedCharacter] = useState("1 Girl");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
  const [selectedIndexAi, setSelectedIndexAi] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isHovered, setIsHovered] = useState(null);
  const [isCheckedPrivate, setIsCheckedPrivate] = useState(false);
  const [isCheckedPrivatensf, setIsCheckedPrivatensf] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [text, setText] = useState("");
  const [selectedSuggestions, setSelectedSuggestions] = useState([]); // To store selected suggestions
  const { assistant_id } = useParams(); // Assuming the route has assistant_id as a parameter
  const [girlData, setGirlData] = useState(null);
  const [threadid, setThreadid] = useState('');
  const [hoverStates, setHoverStates] = useState();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [generatedImages, setGeneratedImages] = useState([])
  const [hover, setHover] = useState({
    feature: false,
    extension: false,
    character: false,
    model: false,
  });

  const BASE_URL = BaseApiUrl;
  const myref = useRef();

  useEffect(() => {
    const fetchData = async () => {
      const payload = { assistant_id };
      try {
        const authToken = localStorage.getItem("token");
        const response = await axios.get(
          `${BASE_URL}assistant_api/fetch_static_data/`,
          {
            params: payload,
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }

        const data = response.data;
        if (data.Breast_Size !== "") {
          setSelectedCharacter("1 Girl");
        } else {
          setSelectedCharacter("1 Boy");
        }
        setGirlData(data);
        setThreadid(data.thread_id);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (assistant_id) {
      fetchData(); // Only fetch if assistant_id is available
    }
  }, [BASE_URL, assistant_id]);

  const handleImageClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === "popup-overlay") {
      setIsPopupOpen(false);
    }
  };

  // Open modal with selected image
  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  // Suggestions data
  const suggestions = [
    {
      id: 1,
      line: "Brown hair, (large breasts), shag hair, wearing bra, in a desert, Bokeh",
    },
    {
      id: 2,
      line: "Brown hair, (large breasts), shag hair, wearing bikini, in a casino, motion blur",
    },
    {
      id: 3,
      line: "Brown hair, (medium breasts), pixie cut, wearing pink dress, in a strip club, bokeh",
    },
    {
      id: 4,
      line: "Blonde hair, (large breasts), curly hair, wearing dress, in a cruise ship, bokeh",
    },
  ];

  const handleSelect = (num) => {
    if ((num === 6 || num === 8 || num === 12 || num === 16) & isChecked) {
      setIsChecked(false);
      const isMobile = window.innerWidth <= 768;
      toast.error("Upscaling all variations at once may take some time. To speed things up, you can individually upscale images in the 'My Gallery' section.", {
        position: isMobile ? "top-right" : "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (value === num) {
      setValue("");
    } else {
      setValue(num);
    }

    setError(""); // Clear any existing error when a valid number is selected
  };

  const handleCheckboxChangePrivate = () => {
    setIsCheckedPrivate(!isCheckedPrivate);
  };
  const handleCheckboxChangePrivateNSF = () => {
    setIsCheckedPrivatensf(!isCheckedPrivatensf);
  };

  const [selectedTitleAi, setSelectedTitleAi] = useState("Realistic");

  const data = [
    // { img: aiModal8, title: 'Super Realistic Model', model: 'realisticinstav6.safetensors' },
    {
      img: aiModal5,
      title: "Realistic",
      model: "influncerv2_p1.safetensors",
      description:
        "This model generates hyper-realistic human images, capturing subtle facial features, natural lighting, and detailed textures that mirror real-world photography. Ideal for lifelike portraits and high-detail renderings",
    },
    {
      img: aiModal6,
      title: "Hentai",
      model: "metrixhentaip.safetensors",
      description:
        "Specialized for creating anime-inspired content with traditional Hentai aesthetics. It maintains bold outlines, vivid colors, and highly stylized character designs suitable for fans of classic and contemporary Hentai art styles.",
    },
    {
      img: aiModal4,
      title: "Enchant",
      model: "hyperrealisticv6.safetensors",
      description:
        "This model produces images with a hyper-realistic yet magical quality, blending realism with a touch of fantasy. The resulting artwork captures mystical atmospheres, perfect for enchanting portraits or dreamlike scenes with high attention to detail.",
    },
    {
      img: aiModal11,
      title: "Anime HD",
      model: "animeCARTOON_v10.ckpt",
      description:
        "A high-definition anime model optimized for crisp, vibrant, and detailed animation-style images. This model is suited for both fan art and original characters, capturing the essence of Japanese animation with clear lines and expressive colors.",
    },
    {
      img: aiModal12,
      title: "Surreal",
      model: "realisticinstav6.safetensors",
      description:
        "Focusing on surrealism, this model generates lifelike images with an imaginative twist, merging real-world elements with abstract concepts. Perfect for creative compositions that challenge reality while maintaining a high level of detail.",
    },
    {
      img: aiModal_futanari,
      title: "Futanari",
      model: "monstercockv15.Y2ws.safetensors",
      description:
        "A anime model optimized to generate futanari images that consists hermaphrodite characters, generally with female bodies plus a male phallus. Extensions is not available on this modal.",
    },
    {
      img: aiModal13,
      title: "Anime 3D",
      model: "bestanimface.safetensors",
      description:
        "This model generates anime-style 3D renderings, providing depth and a unique stylized realism. Ideal for creating anime characters with a modern 3D aesthetic, bringing additional dimension and detail into traditional anime styles.",
    },
    {
      img: aiModal3,
      title: "Photoreal",
      model: "realisticinstav6.safetensors [ecb478bd5e]",
      description:
        "Aiming for photorealism, this model generates highly detailed, lifelike images that closely resemble actual photography. With a focus on capturing fine textures, natural lighting, and realistic expressions, it's ideal for users looking for a true-to-life rendering style.",
    },
    // { img: aiModal1, title: 'Surreal Anime Model', model: 'bestanimface.safetensors' },
    // { img: aiModal2, title: 'Realistic Anime Model', model: 'anniepornbestwithhuman.safetensors' },
    // { img: aiModal7, title: 'Dark Anime Model', model: 'dksAnimeMerge_dksAnimeV10.safetensors' },
  ];

  // Function to set the selected index based on the default model from the API
  const setDefaultModel = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}api/v1/GetDefaultModel/`,
      );
      const defaultModel = response.data.sd_model_checkpoint;

      // Find the index of the model in the data array
      const defaultIndex = data.findIndex(
        (item) => item.model === defaultModel
      );

      // If the model is found, set it as the selected index
      if (defaultIndex !== -1) {
        setSelectedIndexAi(defaultIndex);
      }
    } catch (error) {
      console.log("Error fetching default model:", error);
      Swal.fire(
        error.response.data.msg ||
        'Our Servers are busy!',
        'Please wait a moment and try again.',
        'warning',
        3000
      );
    }
  };

  useEffect(() => {
    const fetchCharacterImages = async () => {
      try {
        const res = await UserApiClient.get(`assistant_api/character/image/?assistant_id=${assistant_id}&thread_id=${threadid}`, {}, true, true, false);
        if (res.success) {
          setCharacterImages(res.data);
        }
      } catch (error) {
        console.error("Error fetching character images:", error);
      }
    };
    fetchCharacterImages();
  }, [assistant_id, threadid]);

  useEffect(() => {
    // Call the function to fetch the default model when the component is mounted
    setDefaultModel();
  }, []);

  const features = [
    { name: "Hair color" },
    { name: "Haircut" },
    { name: "Wearing" },
    // { name: 'Body type' },
    // { name: 'Nationality' },
    { name: "Environment" },
    // { name: 'Style' },
    { name: "Effects" },
  ];

  const options = {
    Characters: [
      { name: "1 Girl", src: girls },
      { name: "1 Boy", src: boy },
    ],
    "1 Girl": {
      "Hair color": [{ name: "Blonde" }, { name: "Black" }, { name: "Brown" }, { name: "Redhead" }, { name: "Pink" }, { name: "White" }, { name: "Ginger" }, { name: "Golden" }, { name: "blue" }, { name: "green" },],
      Haircut: [{ name: "Pixie Cut" }, { name: "Straight Hair" }, { name: "Bob Cut" }, { name: "Curly Hair" }, { name: "Braids" }, { name: "Bangs" }, { name: "Bald" }, { name: "Dreadlocks hair" }, { name: "Short curly hair" }, { name: "Long straight hair" }, { name: "Afro hair" },],
      Wearing: [{ name: "Lingerie" }, { name: "Fishnets" }, { name: "Swimwear" }, { name: "Bodysuits" }, { name: "Corset" }, { name: "Bikini" }, { name: "Chemise" }, { name: "French Maid" }, { name: "Thong" }, { name: "Yoga Pants" }, { name: "Nightgown" }, { name: "Transparent Clothing" }, { name: "Latex Clothing" },],
      Environment: [{ name: "Street" }, { name: "Swimming Pool" }, { name: "Bathroom" }, { name: "Apartment" }, { name: "Beach" }, { name: "Shopping Mall" }, { name: "Luxurious Castle" }, { name: "Cafe" }, { name: "University" }, { name: "Strip Club" }, { name: "Restaurant" }, { name: "Park" }, // { 'name': 'Spaceship' }, { name: "Mars" }, { name: "Forest" }, { name: "Mountain" }, { name: "Lake" }, { name: "Library" }, // { 'name': 'Concert Venue' }, { name: "Sports Stadium" }, { name: "Cruise Ship" }, { name: "Desert" }, { name: "Farm" }, { name: "Art Gallery" }, { name: "Amusement Park" }, { name: "Ski Resort" }, { name: "Rainforest" }, // { 'name': 'Space Station' }, { name: "Aquarium" }, { name: "Vineyard" }, // { 'name': 'Movie Theater' }, { name: "Fitness Center" }, { name: "Historical Monument" }, { name: "Airport" }, // { 'name': 'Submarine' }, // { 'name': 'Circus Tent' }, { name: "Casino" }, { name: "Underground Cave" }, // { 'name': 'Space Observatory' }, { name: "Abandoned Mansion" }, { name: "Ski Lodge" }, { name: "Botanical Garden" }, { name: "Wildlife Sanctuary" }, { name: "Secret Garden" }, { name: "Floating Island" }, { name: "Whimsical Wonderland" }, // { 'name': 'Mystical Labyrinth' }, // { 'name': 'Neon Utopia' }
      ],
      Effects: [{ name: "Bokeh" }, { name: "Sepia" }, { name: "Vintage Film" }, { name: "Lens Flare" }, { name: "Reflection" }, { name: "Bloom" }, { name: "Depth Of Field" }, { name: "Dream Blur" }, // { 'name': 'Monochrome' }
      ],
    },
    "1 Boy": {
      "Hair color": [{ name: "Blonde" }, { name: "Black" }, { name: "Brown" }, { name: "Redhead" }, { name: "Pink" }, { name: "White" }, { name: "Ginger" }, { name: "Golden" }, { name: "blue" }, { name: "green" },],
      Haircut: [{ name: "Buzz Cut" }, { name: "Crew Cut" }, { name: "Man Bun Hair" }, { name: "Curly Hair" }, { name: "Quiff Hair Cut" }, { name: "Side Part" }, { name: "Dread locks Hair" }, { name: "Loose Wave Hair" }, { name: "Short Crop" }, { name: "Spiky" }, { name: "Mullet" },],
      Wearing: [{ name: "T-shirt" }, { name: "Jeans" }, { name: "Hoodie" }, { name: "Jacket" }, { name: "Shorts" }, { name: "Suit" }, { name: "Sweater" }, { name: "Blazer" }, { name: "Shirt" }, { name: "Tank Top" }, { name: "Polo Shirt" }, { name: "Cargo Pants" }, { name: "Tracksuit" }, { name: "Leather Jacket" }, { name: "V-Neck" },],
      Environment: [{ name: "Street" }, { name: "Swimming Pool" }, { name: "Bathroom" }, { name: "Apartment" }, { name: "Beach" }, { name: "Shopping Mall" }, { name: "Luxurious Castle" }, { name: "Cafe" }, { name: "University" }, { name: "Strip Club" }, { name: "Restaurant" }, { name: "Park" }, { name: "Mars" }, { name: "Forest" }, { name: "Mountain" }, { name: "Lake" }, { name: "Library" }, { name: "Sports Stadium" }, { name: "Cruise Ship" }, { name: "Desert" }, { name: "Farm" }, { name: "Art Gallery" }, { name: "Amusement Park" }, { name: "Ski Resort" }, { name: "Rainforest" }, { name: "Aquarium" }, { name: "Vineyard" }, { name: "Fitness Center" }, { name: "Historical Monument" }, { name: "Airport" }, { name: "Casino" }, { name: "Underground Cave" }, { name: "Abandoned Mansion" }, { name: "Ski Lodge" }, { name: "Botanical Garden" }, { name: "Wildlife Sanctuary" }, { name: "Secret Garden" }, { name: "Floating Island" }, { name: "Whimsical Wonderland" },],
      Effects: [{ name: "Bokeh" }, { name: "Sepia" }, { name: "Vintage Film" }, { name: "Lens Flare" }, { name: "Reflection" }, { name: "Bloom" }, { name: "Depth Of Field" }, { name: "Dream Blur" },],
    },
  };
  const [selectedFeatures, setSelectedFeatures] = useState(options);
  const [selected, setSelected] = useState("medium");
  const [aiselected, setaiSelected] = useState("creative");
  const [MagicSelected, setMagicSelected] = useState("none");
  const [characterImages, setCharacterImages] = useState("");
  const [svgselected, setsvgSelected] = useState("fourth");
  const [otherInput, setOtherInput] = useState(''); // Add state for input value
  const [FaceoptionalInput, setFaceoptionalInput] = useState(null); // Add state for input value
  const [loadingStates, setLoadingStates] = useState({});
  const [status, setStatus] = useState({});

  const handleOtherInputChange = (e) => {
    setOtherInput(e.target.value); // Update state when input changes
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (myref.current) {
      window.scrollTo(0, 0);
      myref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const upscale = async (generation_id, image_file_name, index) => {
    setLoadingStates((prev) => ({ ...prev, [image_file_name]: true }));
    
    console.log("GEN 1",generatedImages)
    try {
      const res = await UserApiClient.post("api/v1/image/upscale/", { generation_id, image_file_name }, false, false, false);
      if (res.success) {
        fetchCharacterImages();
        setStatus((prev) => ({ ...prev, [image_file_name]: 'upscaled' }));
      } else {
        Swal.fire("Oops! An Error Occurred in Upscaling Image. Please Try Again.", "", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Something went wrong during the upscale process.", "error");
    } finally {
      setLoadingStates((prev) => ({ ...prev, [image_file_name]: false }));
    }
  };

  const fetchCharacterImages = async () => {
    try {
      const res = await UserApiClient.get(`assistant_api/character/image/?assistant_id=${assistant_id}&thread_id=${threadid}`, {}, true, true, false);
      if (res.success) {
        setCharacterImages(res.data);
      }
    } catch (error) {
      console.error("Error fetching character images:", error);
    }
  };

    const generate = () => {
    if (myref.current) {
      window.scrollTo(0, 0);
      myref.current.scrollIntoView({ behavior: "smooth" });
    }
    setLoading(true);
    const selectedHairColor =
      typeof selectedFeatures[selectedCharacter]["Hair color"] === "string"
        ? selectedFeatures[selectedCharacter]["Hair color"]
        : null;
    const selectedHaircut =
      typeof selectedFeatures[selectedCharacter]["Haircut"] === "string"
        ? selectedFeatures[selectedCharacter]["Haircut"]
        : null;
    const selectedWearing =
      typeof selectedFeatures[selectedCharacter]["Wearing"] === "string"
        ? selectedFeatures[selectedCharacter]["Wearing"]
        : null;
    const selectedBodyType =
      typeof selectedFeatures[selectedCharacter]["Body type"] === "string"
        ? selectedFeatures[selectedCharacter]["Body type"]
        : null;
    const selectedNationality =
      typeof selectedFeatures[selectedCharacter]["Nationality"] === "string"
        ? selectedFeatures[selectedCharacter]["Nationality"]
        : null;
    const selectedEnvironment =
      typeof selectedFeatures[selectedCharacter]["Environment"] === "string"
        ? selectedFeatures[selectedCharacter]["Environment"]
        : null;
    const selectedStyle =
      typeof selectedFeatures[selectedCharacter]["Style"] === "string"
        ? selectedFeatures[selectedCharacter]["Style"]
        : null;
    const selectedEffects =
      typeof selectedFeatures[selectedCharacter]["Effects"] === "string"
        ? selectedFeatures[selectedCharacter]["Effects"]
        : null;
    const nationality = selectedNationality;
    const Upscale = isChecked;

    const promptString = Object.values({
      hair_color:
        selectedHairColor + " " + selectedHaircut
          ? selectedHairColor + " " + selectedHaircut + " hair"
          : null,

      wearing: selectedWearing ? "Wearing " + selectedWearing : null,
      body_type: selectedBodyType ? selectedBodyType + " body" : null,
      nationality: selectedNationality || null,
      environment: selectedEnvironment ? "in a " + selectedEnvironment : null,
      style: selectedStyle ? selectedStyle + " style" : null,
      effects: selectedEffects ? selectedEffects + " effect" : null,
      extensions: selectedIndex,
      // extensions: selectedIndex ? "(" + selectedIndex + ")" : null,
      other: otherInput || null,
      face_prompt: FaceoptionalInput || null,
    })

      .filter((value) => value !== null)
      .join(", ");
    setSelectedItem(promptString);

    // Other arguments
    var newDate = new Date();

    var datetime =
      newDate.getDate() +
      "_" +
      newDate.getMonth() +
      "_" +
      newDate.getFullYear() +
      "_" +
      newDate.getTime();

    const requestPayload = {
      generation_prompt: promptString,
      steps: selected,
      imageupscale: Upscale,
      nationality: nationality,
      make_ai_listen: aiselected,
      magic: MagicSelected,
      aspect_ratio: svgselected,
      user_name: localStorage.getItem("login"),
      generation_id: "ER_GenImg_" + datetime,
      generation_slug: "ER_" + selectedIndexAi + "_" + promptString.replaceAll(", ", "_").replaceAll(" ", "_"),
      Aimodel: selectedIndexAi,
      Aititle: selectedTitleAi,
      make_private: isCheckedPrivate,
      make_Nesf: isCheckedPrivatensf,
      extensions: selectedIndex,
      face_prompt: FaceoptionalInput,
      assistant_id: girlData.assistant_id,
      variations: value === "" ? "1" : value,
      other: activeTab ? "" : otherInput
      // image_file_name: `ER_image_${timestamp}_${randomString}.png`,
    }
    const authToken = localStorage.getItem("token");
    axios
      .post(
        `${BASE_URL}assistant_api/bot/image_generate/`,
        requestPayload,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        // setGeneratedImages(response.data.images)
        fetchCharacterImages();
        setLoading(false);
        // navigate(`/dashboard/ImageView/ER_GenImg_${datetime}`);
        // navigate(`/dashboard/ImageView/${datetime}/?data=${encodeURIComponent(response.data.images)}`);
        // loaders(false, "heart");
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire(
          'Our Servers are busy!',
          'Please wait a moment and try again.',
          'warning',
          3000
        );
        console.log("Error generating image:", error);
      });
  };
  const [nextButton, setNextButton] = useState(false)
  const choosefeature = (featureName, featureType) => {
    setSelectedFeatures((prev) => {
      const currentFeatures = prev[selectedCharacter]?.[featureType] || {};

      // Check if the feature is already selected
      if (currentFeatures === featureName) {
        const updatedCharacter = { ...prev[selectedCharacter] };
        setNextButton(false)
        delete updatedCharacter[featureType]; // Remove the feature type
        return {
          ...prev,
          [selectedCharacter]: updatedCharacter,
        };
      }

      // Otherwise, select the new feature
      return {
        ...prev,
        [selectedCharacter]: {
          ...prev[selectedCharacter],
          [featureType]: featureName,
        },
      };
    }
    );
    if (currentFeatureIndex + 1 < features.length) {
      setNextButton(true)
    }
    else {
      setNextButton(false)
    }
  };
  const next = () => {
    const nextIndex = currentFeatureIndex + 1;
    if (nextIndex < features.length) {
      setSelectedFeature(features[nextIndex].name); // Set the next feature as selected
      setCurrentFeatureIndex(nextIndex); // Update current feature index
      setNextButton(false)
    } else {
      // Optionally, handle when all features have been selected
      console.log("All features have been selected");
    }
  }

  const deleteImage = async (img, generation_id) => {
    try {
      const res = await UserApiClient.delete("api/v1/image/delete/", { img_url: img, generation_id });
      console.log("Del res",res)
      if (res.success) {
        fetchCharacterImages();
        // Swal.fire("Success!", "Image deleted successfully.", "success");
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const downloadImage = async (url) => {
    try {
      toast.success("Downloading...", {
        position: "top-left",
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        autoClose: 5000,
        pauseOnHover: false,
        draggable: false,
        theme: "colored"
      })
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `generated-image_${Date.now()}.png`;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Failed to download the image:', error);
    }
  };

  const handlesvgClick = (value) => {
    if (svgselected === value) {
      setsvgSelected("");
    } else {
      setsvgSelected(value);
    }
  };
  const handleSuggestionClick = (line) => {
    // If the suggestion is already selected, remove it
    if (selectedSuggestions.includes(line)) {
      setSelectedSuggestions(
        selectedSuggestions.filter((item) => item !== line)
      );
      setOtherInput(text.replace(line, "")); // Remove from textarea
    } else {
      // Add the new suggestion to the list and textarea
      setSelectedSuggestions([...selectedSuggestions, line]);
      setOtherInput(text + " " + line); // Append to textarea
    }
  };

  return (
    <div>
      <div className="grid md:flex justify-center">
        <div className={`${loading || generatedImages && generatedImages.length > 0 || characterImages.length > 0 ? "w-full md:w-1/2 p-4 " : " "}`}>
          <ToastContainer />
          <div className="flex flex-wrap justify-evenly p-0 sm:p-6">
            <div className="p-6">
              <div className="relative flex justify-center">
                <div className="flex w-full absolute justify-between items-center">
                  <p className="font-semibold text-xl py-2 text-grey-500"></p>
                  <div onClick={() => navigate("/dashboard/allmodels")} className="m-4 p-1 bg-[#ff0022b8] rounded-full cursor-pointer hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] hover:scale-105 transition-all duration-300 ease-in-out">
                    <img loading="lazy" className="w-7" src={duplicate} alt="All Creates" />
                  </div>
                </div>
                <img
                  loading="lazy"
                  src={girlData && girlData.Image ? girlData.Image : ""}
                  alt="aiModal3"
                  className="w-96 rounded-lg cursor-pointer"
                  onClick={handleImageClick}
                />
                <div className="absolute bottom-[1px] w-full px-4 bg-[#00000061] flex justify-between items-center rounded-lg">
                  <p className="font-semibold text-xl py-2 text-white">
                    {girlData && girlData.name ? girlData.name : ""}
                  </p>
                </div>
              </div>
            </div>
            {selectedCharacter && (
              <div className={`w-full bg-[#83808052] shadow-xl rounded-lg p-2 md:p-6 ${loading || generatedImages && generatedImages.length > 0 || characterImages.length > 0 ? "" : "sm:w-[50%]"}`}>
                <div className="flex justify-between items-center mb-6">

                  {activeTab === true ? <>
                    <p className={`font-semibold text-xl p-2 text-gray-800 rounded-lg `}>
                      Select Features
                    </p>

                    <button className={`text-md text-white px-3 py-1 rounded-lg bg-gray-800 `} onClick={() => setActiveTab(!activeTab)}>
                      <div className="flex items-center">
                        Try Advanced Prompting
                        <div className="relative">
                          {hover.character && (
                            <span className="absolute w-64 -top-60 -left-60 md:w-96 md:-top-44 md:-left-80 lg:-left-96 lg:top-8  bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10 text-justify">
                              Advanced mode is designed for users skilled in crafting effective prompts. To make the most of this feature, we recommend browsing through the showcased section to find examples that resonate with you and replicating those prompts. Ensure you retain all other settings, including extensions, to achieve your desired outcomes seamlessly.
                            </span>
                          )}
                          <img
                            loading="lazy"
                            src={info}
                            alt="infoBlack"
                            className="ml-2 h-4 md:h-5 cursor-pointer transition-transform duration-200 hover:scale-110"
                            onMouseEnter={() => setHover({ ...hover, character: true })}
                            onMouseLeave={() => setHover({ ...hover, character: false })}
                            onTouchStart={() => setHover({ ...hover, character: true })}
                          />
                        </div>
                      </div>
                    </button>

                  </> : <>
                    <p className={`font-semibold text-xl p-2 text-gray-800 rounded-lg`}>
                      Advanced
                    </p>

                    <button className={`text-xl text-white py-0 px-4 rounded-lg bg-gray-800 `} onClick={() => setActiveTab(!activeTab)}>
                      <i className="fa-solid fa-arrow-left"></i>
                    </button>

                  </>}


                </div>

                {activeTab === true ? (
                  <>
                    <div className="bg-[#bbaeaf] p-3 rounded-lg shadow-sm flex flex-wrap justify-center mo:justify-normal gap-1 sm:gap-2">
                      {features.map((item, index) => (
                        <p
                          key={index}
                          className={`font-semibold !capitalize text-md py-1 px-2 sm:px-3 rounded-lg cursor-pointer transition-transform duration-200 transform ${selectedFeature === item.name
                            ? "bg-gray-800 text-white"
                            : "hover:bg-gray-300"
                            }`}
                          onClick={() => {
                            setSelectedFeature(item.name);
                            setCurrentFeatureIndex(index);
                          }}
                        >
                          {item.name}
                        </p>
                      ))}
                    </div>

                    {selectedFeature && (
                      <div className=" bg-[#b9b9b97a] rounded-lg shadow-sm ">
                        <div
                          className="mt-3 p-3 rounded-lg  flex justify-center flex-wrap gap-2 overflow-scroll"
                          style={{ scrollbarWidth: "none" }}
                        >
                          {options[selectedCharacter][selectedFeature].map((item, index) => (
                            <div
                              key={index}
                              className={`flex justify-center items-center min-w-[100px] sm:w-[140px] border-2 border-gray-400 hover:border-gray-600 hover:scale-105 rounded-full p-2 cursor-pointer transition-all duration-300 ease-in-out transform ${selectedFeatures[selectedCharacter]?.[selectedFeature] === item.name
                                ? "bg-[#202936] text-white"
                                : "bg-white"
                                }`}
                              onClick={() => { choosefeature(item.name, selectedFeature) }}
                            >
                              <p
                                className="font-semibold text-[14px] mo:text-[16px] text-center"
                                style={{ textTransform: "capitalize" }}
                              >
                                {item.name}
                              </p>
                            </div>
                          ))}

                          {["Hair color", "Wearing", "Environment"].includes(selectedFeature) && (
                            <div className="flex items-center justify-between ">
                              <div className="">
                                <input
                                  id="custom"
                                  type="text"
                                  placeholder={`Enter custom ${selectedFeature.toLowerCase()} here`}
                                  value={
                                    typeof selectedFeatures[selectedCharacter]?.[selectedFeature] ===
                                      "string"
                                      ? selectedFeatures[selectedCharacter][selectedFeature]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setNextButton(true)
                                    setSelectedFeatures((prev) => ({
                                      ...prev,
                                      [selectedCharacter]: {
                                        ...prev[selectedCharacter],
                                        [selectedFeature]: e.target.value,
                                      },
                                    }));
                                  }}
                                  className="border border-gray-300 rounded-md p-2 text-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 transition-all shadow-sm capitalize"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        {/* Next button */}
                        {nextButton === true && (
                          <div className="flex justify-end pr-2 pb-2">
                            <button
                              className="bg-red-500 hover:bg-red-600 text-white px-4 py-1 rounded-lg shadow-md transition-all duration-200 focus:outline-none focus:ring-4 focus:ring-red-300"
                              onClick={next}
                            >
                              <i className="fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        )}
                      </div>
                    )}

                    {selectedCharacter && (
                      <div className="bg-[#b9b9b97a] p-1 mo:p-4 rounded-lg shadow-sm mt-6">
                        <h3 className="font-semibold text-xl text-center text-gray-800">
                          Selected Features
                        </h3>
                        <div className="flex flex-wrap justify-center gap-2 mo:gap-4">
                          {Object.keys(options[selectedCharacter])
                            .map((feature, index) => (
                              <div
                                key={index}
                                className="flex flex-col w-auto sm:w-[150px] bg-white rounded-lg shadow-md p-2 mo:p-4 border-l-4 border-gray-400 transition-transform duration-300 ease-in-out transform hover:scale-105"
                              >
                                <span className="font-semibold text-[14px] mo:text-[16px] text-gray-700">
                                  {feature}
                                </span>
                                <span className="text-sm text-gray-500 capitalize">
                                  {selectedFeatures[selectedCharacter]?.[feature]
                                    ? typeof selectedFeatures[selectedCharacter][feature] === "object"
                                      ? selectedFeatures[selectedCharacter][feature].name
                                      : selectedFeatures[selectedCharacter][feature]
                                    : "Not Selected"} {/* Placeholder for deselected features */}
                                </span>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </>
                ) :
                  (
                    <>
                      <div className="w-full">
                        <div className="bg-[#83808052] shadow-xl rounded-lg p-1 md:p-6">
                          <p className="font-semibold text-xl pb-5 text-gray-800">
                            Write Your Custom Prompt Here
                          </p>
                          {/* Textarea */}
                          <textarea
                            value={otherInput}
                            onChange={handleOtherInputChange}
                            rows="4"
                            className="w-full bg-[#b9b9b97a] text-black p-4 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 placeholder-[#5e5e5e]"
                            placeholder="Write something..."
                          ></textarea>

                          {/* Suggestions Box */}
                          <div className="mt-4 p-4 bg-[#b9b9b97a] rounded-lg shadow-md">
                            <h3 className="font-semibold text-gray-700">Suggestions:</h3>
                            <ul className="list-disc pl-5 space-y-2">
                              {suggestions.map((suggestion) => (
                                <li
                                  key={suggestion.id}
                                  className={`cursor-pointer p-2 rounded-lg transition-colors ${selectedSuggestions.includes(suggestion.line)
                                    ? "bg-red-200 text-red-800"
                                    : "hover:bg-gray-200"
                                    }`}
                                  onClick={() => handleSuggestionClick(suggestion.line)}
                                >
                                  {suggestion.line}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }
                <div>
                  <div className="flex justify-between mt-5">
                    <div className="flex items-center gap-5 md:mb-3 lg:mb-0">
                      <input
                        type="checkbox"
                        className="w-7 h-7 rounded-full peer appearance-none border-2 border-red-500 checked:bg-red-500"
                        onClick={handleCheckboxChangePrivateNSF}
                        id="nsfw"
                      />
                      <label className="text-base md:text-md lg:text-lg xl:text-lg" htmlFor="nsfw">
                        Allow NSFW
                      </label>
                    </div>
                    <div className="flex items-center gap-5 md:mb-3 lg:mb-0">
                      {/* <input
                type="radio"
                className='w-7 h-7'
              /> */}
                      <p className="text-base md:text-lg lg:text-xl xl:text-2xl  font-semibold text-gray-800">
                        Make Private
                      </p>
                      <button
                        onClick={handleCheckboxChangePrivate}
                        className={`relative inline-flex items-center h-[2rem] w-[4rem] rounded-full transition-all duration-300 ${isCheckedPrivate ? "bg-[#ab1e1eb5]" : "bg-gray-300"
                          }`}
                      >
                        {/* OFF text */}
                        <span
                          className={`absolute z-10 left-2 text-black transition-all duration-300 ${isCheckedPrivate ? "opacity-0" : "opacity-100"
                            }`}
                          style={{ top: "50%", transform: "translateY(-50%)" }}
                        ></span>

                        {/* ON text */}
                        <span
                          className={`absolute z-10 right-1 text-black font-bold transition-all duration-300 ${isCheckedPrivate ? "opacity-100" : "opacity-0"
                            }`}
                          style={{ top: "50%", transform: "translateY(-50%)" }}
                        >
                          ON
                        </span>

                        <span
                          className={`absolute left-1 top-1 transition-all duration-300 transform ${isCheckedPrivate
                            ? "translate-x-8 bg-white" : "translate-x-0 bg-white"}`}
                          style={{ height: "25px", width: "25px", borderRadius: "50%", }}
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

        </div>

        <div className={`${loading || (generatedImages?.length > 0 || characterImages?.length > 0) ? "w-full md:w-1/2 p-4" : ""}`} ref={myref}>
          <div className={`overflow-auto ${loading || (generatedImages?.length > 0 || characterImages?.length > 0) ? "h-[50rem] sm:h-full md:h-[77rem]" : ""}`} style={{ scrollbarWidth: "none" }}>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
              {loading ? (
                Array.from({ length: value }).map((_, index) => (
                  <div key={index} className="m-1">
                    <div className="w-full rounded-xl overflow-hidden">
                      <div className="flex justify-center bg-[#000] h-80 w-full rounded-xl">
                        <CircularWithValueLabel isLoadingAIResponse={loading} loadingText="loading" percentageShow={true} />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  {(generatedImages?.length > 0 || (characterImages?.length > 0)) ? (
                    [...(generatedImages || []), ...(characterImages || []).flatMap(item => item.image_file_name || [])].map((imageUrl, index) => {
                      // Find the corresponding `item` that contains this image URL
                      const item = characterImages?.find(char => (char.image_file_name || []).includes(imageUrl));
                      const upscaleStatus = item?.upscale?.[item.image_file_name?.indexOf(imageUrl)] || null;

                      // const item = [...(generatedImages || []), ...(characterImages || [])].find(item => item.image_file_name);
                      // const upscaleStatus = item?.upscale ? item.upscale[index] : null;
                      return (
                        <div key={index} className="m-1">
                          <div className="w-full rounded-xl overflow-hidden">
                            <div className="relative bg-[#525151] w-full rounded-xl">
                              {loadingStates[imageUrl] && (
                                <div className="absolute z-[900] h-full w-full flex justify-center items-center">
                                  <CircularWithValueLabel isLoadingAIResponse={loadingStates[imageUrl]} loadingText="loading" percentageShow={true}/>
                                </div>)}
                              <img loading="lazy" className={`${loadingStates[imageUrl] ? 'blur-lg': ''} rounded-xl transition-transform duration-700 ease-in-out group-hover:scale-105 group-hover:shadow-lg `} src={imageUrl} alt={`Img ${index}`} onClick={() => openModal(imageUrl)} 
                               onTouchStart={() => setIsHovered(index)} onMouseEnter={() => setIsHovered(index)}
                               onMouseLeave={() => setIsHovered(null)} />
                              {/* Image above Buttons */}
                              {isHovered === index && (
                                <div 
                                  className="absolute flex justify-between top-1 left-1 right-0 z-10" 
                                  onMouseEnter={() => setIsHovered(index)} 
                                  onTouchStart={() => setIsHovered(index)} 
                                  onMouseLeave={() => setIsHovered(null)}
                                >
                                  <div className="flex">
                                    {!upscaleStatus && (
                                      <button
                                        onClick={() => upscale(item.generation_id, imageUrl, index)}
                                        className="flex items-center mr-1 space-x-2 px-4 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white rounded-full text-xs font-semibold hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600 transition-all duration-300 ease-in-out shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-indigo-300"
                                      >
                                        Upscale
                                      </button>
                                    )}
                                    <button
                                      onClick={() => deleteImage(imageUrl, item.generation_id)}
                                      className="flex items-center justify-center bg-red-700 bg-opacity-90 h-8 w-8 rounded-full p-2 border-2 duration-300 border-red-600 hover:border-red-500"
                                    >
                                      <i className="fa-solid fa-trash-can text-white"></i>
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      onClick={() => downloadImage(imageUrl)}
                                      className="flex items-center justify-center bg-red-700 bg-opacity-90 h-8 w-8 rounded-full p-2 border-2 duration-300 border-red-600 hover:border-red-500"
                                    >
                                      <i className="fa-solid text-white fa-md fa-cloud-arrow-down"></i>
                                    </button>
                                  </div>
                                </div>
                              )}

                              {/* Image above Buttons */}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    null
                    // <div className="col-span-full text-center">
                    //   <p>No images to display</p>
                    // </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

      </div>

      <div className="pb-32 mo:py-10 px-6">
        {" "}
        <div className="flex flex-wrap justify-between">
          <div className="flex flex-col gap-5 lg:gap-12 text-black font-josefin-sans">
            <div className="flex relative gap-5 md:mb-3 lg:mb-0">
              <p className="text-base md:text-[15px] lg:text-[20px] xl:text-[25px] font-semibold text-gray-800">
                Do You Want To Upscale Image
                <p className="flex md:text-[8px] lg:text-[12px] xl:text-[16px]">
                  (Addon 1- Token{" "}
                  <img loading="lazy" className="w-6" src={goldE} alt="goldE" />
                  )
                </p>
              </p>

              {/* Stylish toggle button */}
              <button
                onClick={handleCheckboxChange}
                className={`relative inline-flex items-center h-[2rem] w-[4rem] rounded-full transition-all duration-300 ${isChecked ? "bg-[#ab1e1eb5]" : "bg-gray-300"
                  }`}
                disabled={value === 6 || value === 8 || value === 12 || value === 16}
              >
                <span
                  className={`absolute left-1 top-1 transition-all duration-300 transform ${isChecked
                    ? "translate-x-6 mo:translate-x-8 bg-white"
                    : "translate-x-0 bg-white"
                    }`}
                  style={{ height: "25px", width: "25px", borderRadius: "50%" }}
                ></span>
              </button>

              {/* Info Tooltip */}
              <div className="" onMouseEnter={() => setIsHovered(true)} onTouchStart={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                <img loading="lazy" src={info} alt="info" className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110" />
                {isHovered && (
                  <span className="absolute bg-[#8a8a8a] text-white text-sm top-10 left-[150px] mo:left-[360px] p-2 shadow-lg rounded max-w-xs w-auto z-10">
                    Elevate your images with AI-powered upscaling. Increase
                    resolution for sharper, more detailed visuals, perfect for
                    larger displays.
                  </span>
                )}
              </div>
            </div>

            <div className="flex flex-col items-start">
              <label
                htmlFor="variations"
                className="capitalize text-base sm:text-md md:text-lg lg:text-xl xl:text-2xl font-semibold text-gray-800"
              >
                Number of variations you want (Max 16)
              </label>
              <p className="mt-0 text-sm sm:text-base text-gray-600 capitalize">
                Enter a number to specify the number of image variations you
                want.
              </p>

              <div className="my-4">
                <button
                  className={`${value === 1 ? `border-red-500` : `border-gray-300`
                    }  bg-[#d4d4d8] border-2 mr-1 mt-1  rounded-lg p-2 w-14 sm:w-18 md:w-22 text-center text-sm`}
                  onClick={() => handleSelect(1)}
                >
                  1
                </button>
                <button
                  className={`${value === 2 ? `border-red-500` : `border-gray-300`
                    }  bg-[#d4d4d8] border-2 mr-1 mt-1  rounded-lg p-2 w-14 sm:w-18 md:w-22 text-center text-sm`}
                  onClick={() => handleSelect(2)}
                >
                  2
                </button>
                <button
                  className={`${value === 3 ? `border-red-500` : `border-gray-300`
                    }  bg-[#d4d4d8] border-2 mr-1 mt-1  rounded-lg p-2 w-14 sm:w-18 md:w-22 text-center text-sm`}
                  onClick={() => handleSelect(3)}
                >
                  3
                </button>
                <button
                  className={`${value === 4 ? `border-red-500` : `border-gray-300`
                    }  bg-[#d4d4d8] border-2 mr-1 mt-1  rounded-lg p-2 w-14 sm:w-18 md:w-22 text-center text-sm`}
                  onClick={() => handleSelect(4)}
                >
                  4
                </button>
                <button
                  className={`${value === 6 ? `border-red-500` : `border-gray-300`
                    }  bg-[#d4d4d8] border-2 mr-1 mt-1  rounded-lg p-2 w-14 sm:w-18 md:w-22 text-center text-sm`}
                  onClick={() => handleSelect(6)}
                >
                  6
                </button>
                <button
                  className={`${value === 8 ? `border-red-500` : `border-gray-300`
                    }  bg-[#d4d4d8] border-2 mr-1 mt-1  rounded-lg p-2 w-14 mt-2sm:w-18 md:w-22 text-center text-sm`}
                  onClick={() => handleSelect(8)}
                >
                  8
                </button>
                <button
                  className={`${value === 12 ? `border-red-500` : `border-gray-300`
                    }  bg-[#d4d4d8] border-2 mr-1 mt-1  rounded-lg p-2 w-14 mt-2sm:w-18 md:w-22 text-center text-sm`}
                  onClick={() => handleSelect(12)}
                >
                  12
                </button>
                <button
                  className={`${value === 16 ? `border-red-500` : `border-gray-300`
                    }  bg-[#d4d4d8] border-2 mr-1 mt-1  rounded-lg p-2 w-14 mt-2sm:w-18 md:w-22 text-center text-sm`}
                  onClick={() => handleSelect(16)}
                >
                  16
                </button>
              </div>
              {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            </div>
          </div>

          <div className="flex flex-col gap-5">
            <div className="flex items-center gap-5">
              <p className="text-base md:text-[15px] lg:text-[20px] xl:text-[25px] font-semibold text-gray-800">
                Aspect Ratio{" "}
                <span className="text-md text-gray-600">
                  (square, horizontal, vertical)
                </span>
              </p>
            </div>
            <div className="bg-[#d4d4d8] w-full sm:w-[75%] justify-center rounded-[20px] flex flex-wrap sm:flex-nowrap items-center gap-5 text-center mt-2 p-5">
              {/* First SVG */}
              <div className=" group flex flex-col items-center justify-center gap-4 ">
                <svg
                  onClick={() => handlesvgClick("first")}
                  className={`cursor-pointer fill-none stroke-[#ef0808c9] transition-opacity duration-300 ${svgselected === "first" ? "opacity-[1]" : "opacity-[0.4]"
                    } hover:scale-[1.1] transition-transform duration-300`}
                  width="29"
                  height="40"
                  viewBox="0 0 29 42"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="1.5"
                    y="1.5"
                    width="26"
                    height="39"
                    rx="6.5"
                    strokeWidth="2"
                  ></rect>
                </svg>
                <span
                  className={`${svgselected === "first" ? "opacity-100" : "opacity-0"
                    } text-[#ef0808c9] text-xs  group-hover:opacity-100 transition-opacity duration-300`}
                >
                  480 X 680
                </span>
              </div>

              <div className=" group flex flex-col items-center justify-center gap-4 ">
                <svg
                  onClick={() => handlesvgClick("second")}
                  className={`cursor-pointer fill-none stroke-[#ef0808c9] transition-opacity duration-300 ${svgselected === "second" ? "opacity-[1]" : "opacity-[0.4]"
                    } hover:scale-[1.1] transition-transform duration-300`}
                  width="60"
                  height="40"
                  viewBox="0 0 62 42"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="1.5"
                    y="1.5"
                    width="59"
                    height="39"
                    rx="6.5"
                    strokeWidth="2"
                  ></rect>
                </svg>
                <span
                  className={`${svgselected === "second" ? "opacity-100" : "opacity-0"
                    } text-[#ef0808c9] text-xs  group-hover:opacity-100 transition-opacity duration-300`}
                >
                  512 x 1024
                </span>
              </div>

              {/* Third SVG */}
              <div className=" group flex flex-col items-center justify-center gap-4 ">
                <svg
                  onClick={() => handlesvgClick("third")}
                  className={`cursor-pointer fill-none stroke-[#ef0808c9]  transition-opacity duration-300 ${svgselected === "third" ? "opacity-[1]" : "opacity-[0.4]"
                    } hover:scale-[1.1] transition-transform duration-300`}
                  width="40"
                  height="40"
                  viewBox="0 0 42 42"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="1.5"
                    y="1.5"
                    width="39"
                    height="39"
                    rx="6.5"
                    strokeWidth="2"
                  ></rect>
                </svg>
                {/* Text for First */}
                <span
                  className={`${svgselected === "third" ? "opacity-100" : "opacity-0"
                    } text-[#ef0808c9] text-xs  group-hover:opacity-100 transition-opacity duration-300 `}
                >
                  512 x 512
                </span>
              </div>

              {/* Fourth SVG */}
              <div className=" group flex flex-col items-center justify-center gap-4 ">
                <svg
                  onClick={() => handlesvgClick("fourth")}
                  className={`cursor-pointer fill-none stroke-[#ef0808c9] transition-opacity duration-300 ${svgselected === "fourth" ? "opacity-[1]" : "opacity-[0.4]"
                    } hover:scale-[1.1] transition-transform duration-300`}
                  width="29"
                  height="40"
                  viewBox="0 0 29 42"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="1.5"
                    y="1.5"
                    width="26"
                    height="39"
                    rx="6.5"
                    strokeWidth="2"
                  ></rect>
                  <path
                    d="M5.399 22.194V21.459L9.158 16.65H10.292L6.5645 21.459L6.029 21.291H11.8985V22.194H5.399ZM9.4835 24V22.194L9.515 21.291V19.695H10.502V24H9.4835ZM14.7627 19.821C14.5667 19.821 14.3987 19.7545 14.2587 19.6215C14.1187 19.4815 14.0487 19.3065 14.0487 19.0965C14.0487 18.8865 14.1187 18.715 14.2587 18.582C14.3987 18.449 14.5667 18.3825 14.7627 18.3825C14.9517 18.3825 15.1127 18.449 15.2457 18.582C15.3857 18.715 15.4557 18.8865 15.4557 19.0965C15.4557 19.3065 15.3857 19.4815 15.2457 19.6215C15.1127 19.7545 14.9517 19.821 14.7627 19.821ZM14.7627 24.063C14.5667 24.063 14.3987 23.9965 14.2587 23.8635C14.1187 23.7235 14.0487 23.5485 14.0487 23.3385C14.0487 23.1285 14.1187 22.957 14.2587 22.824C14.3987 22.691 14.5667 22.6245 14.7627 22.6245C14.9517 22.6245 15.1127 22.691 15.2457 22.824C15.3857 22.957 15.4557 23.1285 15.4557 23.3385C15.4557 23.5485 15.3857 23.7235 15.2457 23.8635C15.1127 23.9965 14.9517 24.063 14.7627 24.063ZM20.2916 24.084C19.7736 24.084 19.2696 24.0035 18.7796 23.8425C18.2966 23.6815 17.9011 23.4645 17.5931 23.1915L18.0761 22.362C18.3211 22.593 18.6396 22.782 19.0316 22.929C19.4236 23.076 19.8401 23.1495 20.2811 23.1495C20.8411 23.1495 21.2716 23.0305 21.5726 22.7925C21.8736 22.5545 22.0241 22.236 22.0241 21.837C22.0241 21.564 21.9576 21.326 21.8246 21.123C21.6916 20.92 21.4606 20.766 21.1316 20.661C20.8096 20.549 20.3651 20.493 19.7981 20.493H18.1286L18.5171 16.65H22.6646V17.5635H18.9056L19.4516 17.049L19.1471 20.0835L18.6011 19.5795H20.0186C20.7536 19.5795 21.3451 19.674 21.7931 19.863C22.2411 20.052 22.5666 20.3145 22.7696 20.6505C22.9726 20.9795 23.0741 21.361 23.0741 21.795C23.0741 22.215 22.9726 22.6 22.7696 22.95C22.5666 23.293 22.2586 23.5695 21.8456 23.7795C21.4396 23.9825 20.9216 24.084 20.2916 24.084Z"
                    fill="white"
                  ></path>
                </svg>
                {/* Text for Fourth */}
                <span
                  className={`${svgselected === "fourth" ? "opacity-100" : "opacity-0"
                    } text-[#ef0808c9] text-xs  group-hover:opacity-100 transition-opacity duration-300 `}
                >
                  512 X 768
                </span>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={!loading ? generate : (e) => e.preventDefault()}
          className={`${loading ? "opacity-50 cursor-not-allowed" : ""} w-full h-[80px] mt-5 rounded-xl text-xl lg:text-3xl bg-gradient-to-r from-[#99111f] via-[#d43e44] to-[#ff5c6c] py-4 text-center flex items-center gap-5 justify-center relative overflow-hidden group shadow-xl hover:shadow-2xl transition-all duration-500 ease-out`}
        >
          {/* <span className="relative z-10 text-white font-bold">Generate for 1.00</span> */}
          <span className="relative z-10 text-white font-bold">
            Generate for{" "}
            {(2 + (isChecked ? 1 : 0)) * (value ? parseInt(value, 10) : 1) +
              (selectedIndex ? 0.5 : 0) *
              (value ? parseInt(value, 10) : 1)}
            {selectedIndex ? "" : ".00"}
          </span>
          <span className="absolute inset-0 bg-gradient-to-r from-[#e63d3d] to-[#ff5c6c] opacity-0 group-hover:opacity-80 animate-glow-background"></span>
          <img
            loading="lazy"
            className="w-12 transition-transform duration-300 group-hover:scale-[1.3] group-hover:rotate-0 group-hover:glow-icon"
            src={goldE}
            alt="goldE"
          />
          <span className="absolute inset-0 rounded-xl border-4 border-transparent group-hover:border-[#99111f] opacity-0 group-hover:opacity-100 animate-glow-border"></span>
        </button>
      </div>

      {isPopupOpen && (
        <div
          id="popup-overlay"
          className="fixed  inset-0 bg-black mt-15 bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto"
          onClick={handleOutsideClick}
        >
          <div className="relative bg-gray-300 w-full mo:w-[28%] rounded-lg border-2 border-[#7d2424b3]">
            {/* Close button */}
            <button
              className="absolute w-5 top-1 right-2 bg-red-600 text-white rounded-full "
              onClick={handleClosePopup}
            >
              X
            </button>

            {/* Full image inside the popup */}
            <img
              src={girlData && girlData.Image ? girlData.Image : ""}
              alt="Full View"
              className="w-full h-auto rounded-lg"
            />
          </div>
        </div>
      )}
      {/* Modal */}
      {isModalOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={closeModal} // Close the modal when clicking the overlay
        >
          <div
            className="relative"
            onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking inside
          >
            <button
              className="absolute w-5 top-1 right-2 bg-red-600 text-white rounded-full "
              onClick={closeModal}
            >
              X
            </button>
            <img
              src={selectedImage}
              alt="Full-sized view"
              className="max-w-full max-h-full object-contain rounded-xl"
            />
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Replicate;
