import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { lock, multiUser, plan, lockWhite, multiUserWhite, planWhite } from '../../../../assets';
import SiteDetail from './SiteDetail';
import SeoContent from './SeoContent';

const SeoSetting = () => {
    const [activeComponent, setActiveComponent] = useState('SiteDetail');

    const buttonData = [
        {
            imgd: multiUser,
            imga: multiUserWhite,
            name: 'SiteDetail',
        },
        {
            imgd: lock,
            imga: lockWhite,
            name: 'SeoContent',
        },
        {
            imgd: plan,
            imga: planWhite,
            name: 'Plans',
        },
    ];

    const renderComponent = () => {
        switch (activeComponent) {
            case 'SiteDetail':
                return <SiteDetail />;
            case 'SeoContent':
                return <SeoContent />;
            // case 'Plans':
            //     return <Plans />;
            // case 'Coupon':
            //     return <Coupon />;
            default:
                return null;
        }
    };
    // /admin/SiteDetail
    return (
        <>
            <div className='h-full bg-white px-7 py-20 font-josefin-sans'>
                <div className='flex gap-4 flex-wrap'>
                    {buttonData.map((item, index) => (
                        <button
                            key={index}
                            className={`flex justify-start gap-5 rounded-lg ${activeComponent === item.name ? 'bg-red-800 text-white' : 'bg-white hover:bg-stone-300'} text-black text-lg py-2 px-5 lg:w-52`}
                            onClick={() => setActiveComponent(item.name)}
                        >
                            <div>
                                {activeComponent === item.name ? <img loading ='lazy' src={item.imga} alt={item.name} /> : <img loading ='lazy' src={item.imgd} alt={item.name} />}
                            </div>
                            <p >{item.name}</p>
                        </button>
                    ))}
                </div>
                {renderComponent()}

            </div>
        </>
    )
}

export default SeoSetting