

import React, { useState, useEffect, useRef } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import UserApiClient from "../../utilities/apiClients";
import Swal from "sweetalert2";
import loaders from "../../frontend/components/loaders";
import { alert } from "../../assets";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CircularWithValueLabel from "../dashboardPages/CreateDreamGF/CircularWithValueLabel";
import { toast, ToastContainer } from "react-toastify";

const GenerationImagesBox = () => {
  const navigate = useNavigate();

  const [loadingStates, setLoadingStates] = useState({});
  const [imageData, setImageData] = useState([]);
  const [imageDataLen, setImageDataLen] = useState(0);
  const [showMoreBtn, setShowMoreBtn] = useState(true);
  const [upscalecurrenttag, setUpscalecurrenttag] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoadingsingleimage, setIsLoadingsingleimage] = useState(true);
  const [errorisOpen, setErrorIsOpen] = useState(false);
  const [hover, setHover] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState("");
  const modalRef = useRef(null);
  const [pageLinks, setPageLinks] = useState({ "next": null, "prev": null });


  const errorCloseModal = () => {
    setErrorIsOpen(false); // Close the modal
    navigate('/'); // Navigate to the root ("/") route
  };
  const errorOpenModal = () => setErrorIsOpen(true);

  // Handler for when the image finishes loading
  const handleImageLoad = () => {
    setIsLoadingsingleimage(false);
  };

  // Handle input change and update search query state
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  // Filter image data based on search query (search within the generation_prompt field)
  const filteredItems = imageData.filter((item) =>
    item.generation_prompt.toLowerCase().includes(searchQuery)
  );

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const upscale = async (generation_id, image_file_name, imgIndex, index) => {
    try {
        setLoadingStates((prev) => ({ ...prev, [image_file_name]: true }));
        const res = await UserApiClient.post(
            "api/v1/image/upscale/",
            { generation_id, image_file_name },
            false,
            false,
            false
        );

        if (res.success) {
            setImageData((prevData) => {
                const updatedData = [...prevData];
                const updatedImageFileNames = [...updatedData[index].image_file_name];
                updatedImageFileNames[imgIndex] = res.data.images;

                updatedData[index] = {
                    ...updatedData[index],
                    image_file_name: updatedImageFileNames,
                };
                return updatedData;
            });
            setUpscalecurrenttag(imgIndex);
        } else {
            Swal.fire(
                "Oops! An Error Occurred in Upscaling Image. Please Try Again.",
                "",
                "error"
            );
        }
    } catch (error) {
        Swal.fire("Error", "Something went wrong during the upscale process.", "error");
    } finally {
        setLoadingStates((prev) => ({ ...prev, [image_file_name]: false }));
    }
};



  const fetchImageData = async (pageLink) => {
    loaders(true, "heart");
  
    const pageUrl = typeof pageLink === 'string' ? pageLink : `api/v1/get_allGenerated_image/?page_size=10`;
  
    await UserApiClient.get(pageUrl, {}, false).then(async (res) => {
      if (res.success) {
        const newImages = res.data.results; 
  
        if (!newImages || newImages.length === 0) {
          Swal.fire({
            title: "No more images to load!",
            icon: "info",
            confirmButtonColor: "#990013",
            timer: 3000,
          });
          setShowMoreBtn(false);
          loaders(false, "heart");
          return;
        }
  
        setImageData((prevData) => {
          const newData = [...prevData];
          newImages.forEach((newImage) => {
            const duplicate = newData.some(
              (image) => image.generation_id === newImage.generation_id
            );
            if (!duplicate) {
              newData.push(newImage);
            }
          });
          return newData;
        });
  
        setPageLinks({ next: res.data.next, prev: res.data.previous });
        setImageDataLen(newImages.length);
        loaders(false, "heart");
      } else {
        loaders(false, "heart");
        Swal.fire({
          title: "Oops! You don't have any images generated...",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#990013",
          backdrop: "#b1b1b1eb",
          timer: 3000,
        }).then((res) => {
          if (res.isConfirmed) {
            navigate("/dashboard");
          }
        });
      }
    });
  };

  useEffect(() => {
    const subscription = localStorage.getItem('subscription_type');
    // const FreeWeek = localStorage.getItem('FWeek');
    if (subscription === "free") {
      errorOpenModal();
      return;
    }
    fetchImageData();
    window.scrollTo(0, 0);
  }, []);

  const deleteImage = async (imageUrl, generation_id, imgIndex) => {
    try {
      const res = await UserApiClient.delete("api/v1/image/delete/", { img_url: imageUrl, generation_id });
      if (res.success) {
  
        setImageData((prevData) => {
          return prevData.map((item) => {
            if (item.generation_id === generation_id) {
              // Remove the specific imageUrl from the image_file_name array
              item.image_file_name = item.image_file_name.filter((img, index) => index !== imgIndex);
            }
            return item;
          }).filter((item) => item.image_file_name.length > 0); // Optional: filter out objects with no images left
        });
  
        setUpdateTrigger(`${generation_id}-${imgIndex}`);
        Swal.fire("Success!", "Image deleted successfully.", "success");
        setShowMoreBtn(true);
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };
  

  const downloadImage = async (url) => {
    try {
      toast.success("Downloading...",{
        position:"top-left",
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        autoClose:5000,
        pauseOnHover:false,
        draggable:false,
        theme:"colored"
      })
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `generated-image_${Date.now()}.png`;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Failed to download the image:', error);
    }
  };
  // if (loading && !currentLoadingImageId) return <div>Loading...</div>;

  return (
    <>
    <ToastContainer/>
      <div className="p-0 md:p-10">
        <div className="m-0 mo:m-2 px-3 py-2 text-black w-full mo:w-1/2 border-2 border-zinc-600 flex items-center gap-5 bg-white rounded-full">
          <IoSearchOutline color="black" size={23} />
          <input
            type="text"
            placeholder="Search anything"
            className="bg-white border-0 w-full mo:w-1/2 rounded-full focus:outline-none text-xl"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 mt-16">
          {filteredItems.map((item, index) => {
            return item.image_file_name.map((imageUrl, imgIndex) => {
              const isLoading = loadingStates[imageUrl];
              return (
                <div className={`relative group rounded-2xl overflow-hidden `} key={`${index}-${imgIndex}`} onMouseEnter={() => setHover(`${index}-${imgIndex}`)} onMouseLeave={() => setHover(null)} onTouchStart={() => setHover(`${index}-${imgIndex}`)}>
                  <div className="relative w-full h-full rounded-2xl">
                    {isLoadingsingleimage && (
                      <div className="absolute z-[900] top-[20%] left-[30%] flex justify-center items-center">
                        {/* <div className="loader"></div> */}
                        <CircularWithValueLabel isLoadingAIResponse={isLoadingsingleimage} loadingText="loading" percentageShow={true}/>
                      </div>
                    )}
                    {isLoading && (
                      <div className="absolute z-[900] top-[20%] left-[30%] flex justify-center items-center">
                        {/* <div className="loader"></div> */}
                        <CircularWithValueLabel isLoadingAIResponse={isLoading} loadingText="loading" percentageShow={true}/>
                      </div>
                    )}
                    <img
                      loading="lazy"
                      className={`h-[500px] w-full rounded-2xl object-cover object-center p-2 transition-transform duration-300 ease-in-out ${hover === `${index}-${imgIndex}` ? 'transform scale-105 shadow-lg' : ''}`}
                      src={`${imageUrl}?auto=format&fit=crop`}
                      // onClick={() => handleImageView(item.generation_id)}
                      alt={`gallery-photo-${imgIndex}`}
                      style={{ filter: isLoading ? "blur(8px)" : "none" }}
                      onLoad={handleImageLoad}
                      // onClick={() => handleImagechatClick(imageUrl)}
                      onClick={() => navigate(`/dashboard/generatedImage/${item.generation_id}?imgIndex=${imgIndex}`)}
                    />
                  </div>
                  {hover === `${index}-${imgIndex}` && (
                    <>
                      {upscalecurrenttag === imgIndex || item.upscale[imgIndex] ? (
                        <div className="absolute -top-1 left-0 right-0">
                          <div className="flex items-center justify-center h-14 px-2 py-1 bg-black bg-opacity-70 text-white rounded-t-2xl text-xs font-semibold  transition-all duration-300 ease-in-out shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-indigo-300">
                            <i className="fa-solid text-yellow-500 fa-star"></i>
                            <p className="ml-1">UPSCALED</p>
                          </div>
                          <div className="absolute top-2 right-0 left-0 flex justify-between items-center px-4 ">
                            <Tooltip title="Delete" slotProps={{
                              popper: {
                                sx: {
                                  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                  {
                                    margin: '0px',
                                  },
                                }
                              }
                            }}>
                              <button onClick={() => deleteImage(imageUrl, item.generation_id, imgIndex)} className="flex items-center justify-center bg-red-700 bg-opacity-90 h-10 w-10 rounded-full p-2 border-2 duration-300 border-red-600 hover:border-red-500">
                                <i className="fa-solid fa-trash-can text-white"></i>
                              </button>
                            </Tooltip>
                            <Tooltip title="Download" slotProps={{
                              popper: {
                                sx: {
                                  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                  {
                                    margin: '0px',
                                  },
                                }
                              }
                            }}>
                              <button onClick={() => downloadImage(imageUrl)} className="flex items-center justify-center bg-red-700 bg-opacity-90 h-10 w-10 rounded-full p-2 border-2 duration-300 border-red-600 hover:border-red-500">
                                {/* <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth={0} /><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" /><g id="SVGRepo_iconCarrier"> <path d="M12 16L12 8" stroke="#ff8a8a" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /> <path d="M9 13L11.913 15.913V15.913C11.961 15.961 12.039 15.961 12.087 15.913V15.913L15 13" stroke="#ff8a8a" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /> <path d="M3 15L3 16L3 19C3 20.1046 3.89543 21 5 21L19 21C20.1046 21 21 20.1046 21 19L21 16L21 15" stroke="#ff8a8a" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /> </g></svg> */}
                                <i className="fa-solid text-white fa-lg fa-cloud-arrow-down"></i>
                              </button>

                            </Tooltip>
                          </div>
                        </div>
                      ) : (
                        <div className="absolute top-2 left-2 right-2 flex justify-between items-center">

                          <div className="flex gap-1">
                            <button
                              className="flex items-center space-x-2 px-4 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white rounded-full text-xs font-semibold hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600 transition-all duration-300 ease-in-out shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-indigo-300"
                              onClick={() => upscale(item.generation_id, imageUrl, imgIndex, index)}
                            >
                              <span>Upscale</span>
                            </button>
                            <Tooltip title="Delete" slotProps={{
                              popper: {
                                sx: {
                                  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                  {
                                    margin: '0px',
                                  },
                                }
                              }
                            }}>
                              <button onClick={() => deleteImage(imageUrl, item.generation_id, imgIndex)} className="flex items-center justify-center bg-red-700 bg-opacity-90 h-10 w-10 rounded-full p-2 border-2 duration-300 border-red-600 hover:border-red-500">
                                <i className="fa-solid fa-trash-can text-white"></i>
                              </button>
                            </Tooltip>
                          </div>
                          <Tooltip title="Download" slotProps={{
                            popper: {
                              sx: {
                                [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                {
                                  margin: '0px',
                                },
                              }
                            }
                          }}>
                            <button onClick={() => downloadImage(imageUrl)} className="flex items-center justify-center bg-red-700 bg-opacity-90 h-10 w-10 rounded-full p-2 border-2 duration-300 border-red-600 hover:border-red-500">
                              <i className="fa-solid text-white fa-lg fa-cloud-arrow-down"></i>
                              {/* <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth={0} /><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" /><g id="SVGRepo_iconCarrier"> <path d="M12 16L12 8" stroke="#ff8a8a" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /> <path d="M9 13L11.913 15.913V15.913C11.961 15.961 12.039 15.961 12.087 15.913V15.913L15 13" stroke="#ff8a8a" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /> <path d="M3 15L3 16L3 19C3 20.1046 3.89543 21 5 21L19 21C20.1046 21 21 20.1046 21 19L21 16L21 15" stroke="#ff8a8a" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /> </g></svg> */}
                            </button>
                          </Tooltip>
                        </div>

                      )}
                      <div className="absolute bottom-2 left-2 right-2 bg-black bg-opacity-70 opacity-100 transition-opacity duration-300 p-4 text-white border-1 border-gray-400 rounded-[10px]">
                        <div className="flex justify-between w-full gap-2">
                          <Tooltip title={item.generation_prompt} placement="top">
                            <p className="text-sm sm:text-sm md:text-md">
                              {item.generation_prompt.slice(0, 50)}...
                            </p>
                          </Tooltip>

                          {/* <h2 className="text-red-800 text-xl text-wrap">
                          @{item.user_name}
                        </h2> */}

                          {item.Aititle ? <div className="flex flex-col items-start">
                            <h2 className="text-xs sm:text-sm  text-red-400">MODEL</h2>
                            <p className="text-white text-sm sm:text-base ">
                              {item.Aititle}
                            </p>
                          </div>
                            : null}
                          {item.extensions ? <div className="flex flex-col items-start">
                            <h2 className="text-xs sm:text-sm  text-red-400">EXTENSIONS</h2>
                            <h2 className="text-white text-sm sm:text-base ">
                              {item.extensions}
                            </h2>
                          </div> : null}


                        </div>
                      </div>
                    </>
                  )}
                </div>

              );
            });
          })}
        </div>

        {imageDataLen === 0 ?
          <>
            <div className="flex-wrap lg:flex items-center justify-between p-10 rounded-lg shadow-lg bg-[#d5d5d5]">
              <div className="flex-wrap lg:flex gap-4">
                <div className="logo flex items-center justify-center">
                  <i className="fa-solid fa-heart-circle-plus text-red-600 text-4xl" />
                </div>
                <div className="flex flex-col items-center text-center lg:text-left lg:items-start gap-2">
                  <h2 className="text-gray-900 text-2xl font-semibold">
                    Create Your Personalized Characters
                  </h2>
                  <p className="text-gray-800 text-sm">
                    Explore the power of AI to design unique images and characters
                    tailored to your preferences.
                  </p>
                </div>
              </div>
              <div className="text-center lg:text-right">
                <button onClick={() => navigate("/dashboard")} className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out">
                  Start Creating
                </button>
              </div>
            </div>
          </>
          :
          <>
            {showMoreBtn && pageLinks.next && (
              <div className="flex items-center w-full justify-center mt-10">
                <button
                  className="bg-red-800 [w-75%] text-white font-medium rounded-2xl py-2 px-4"
                  onClick={() => fetchImageData(pageLinks.next)}
                >
                  <i className="fa-solid fa-angles-down mr-1"></i> Load More
                </button>
              </div>
            )}
          </>
        }

      </div>

      {errorisOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-lg">
          <div className="modal-dialog modal-lg bg-white rounded-lg overflow-hidden shadow-xl max-w-[16rem] mo:max-w-xl w-full">
            {/* Modal Content */}
            <div className="modal-wrap flex-wrap mo:flex relative">
              {/* Image Container - 50% Width */}
              <div className="w-full mo:w-1/2">
                {/* Desktop Image */}
                <img
                  src={alert}
                  alt="Modal Img"
                  className="login-img hidden md:block w-full h-full object-cover"
                />
                {/* Mobile Image */}
                <img
                  src={alert}
                  alt="Modal Img Mobile"
                  className="login-img-mob sm:hidden w-full"
                />
              </div>

              {/* Content Container - 50% Width */}
              <div className="modal-content p-2 w-full mo:w-1/2 grid justify-center">
                {/* Modal Header */}
                <div className="modal-header flex justify-between items-start">
                  <h4 className="modal-title text-uppercase text-xl"></h4>
                  <button
                    type="button"
                    className="btn-close text-xl text-[#990013]"
                    onClick={errorCloseModal}
                  >
                    <i className="fa-regular fa-circle-xmark"></i>
                  </button>
                </div>

                {/* Modal Body */}
                <div className="modal-body justify-center">
                  <div className="container-fluid">
                    <div className="row mb-4">
                      <div className="col-12 ps-4">
                        <h2 className="text-[#990013] font-bold text-uppercase text-2xl">
                          PLEASE UPGRADE
                        </h2>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 ps-4">
                        <span className="text-muted text-gray-900 text-md">
                          Upgrade to a premium membership to unlock access to all features!
                        </span>
                      </div>
                    </div>
                    <div className="row justify-center p-3 gap-1">
                      <div className="col-12">
                        <button onClick={() => navigate('/priceplan')} className="btn btn-primary w-full py-3 text-white bg-[#990012ab] hover:bg-[#990013] rounded-md">
                          Upgrade
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Modal Overlay */}
            {/* <div
              className="fixed inset-0"
              onClick={errorCloseModal}
            ></div> */}
          </div>
        </div>
      )}

      {/* Modal (fullscreen image view) */}
      {isModalOpen && selectedImage && (
        <div
          ref={modalRef}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
        >
          <div className="relative w-[35rem] max-h-full p-4">
            <button
              onClick={closeModal}
              className="absolute top-8 right-8 text-white text-md w-8 h-8 bg-[#808080] p-[5px] font-bold rounded-full"
            >
              X
            </button>
            <img loading='lazy'
              className="max-w-full max-h-screen object-contain"
              src={selectedImage}
              alt="Fullscreen View"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default GenerationImagesBox;
