

import React, { useState, useEffect } from 'react';
import "react-multi-carousel/lib/styles.css";
import axios from 'axios';
import { aiModal1, aiModal2, aiModal3, aiModal4, aiModal5, aiModal6, aiModal7} from '../../assets';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import '../../../src/swipper.css';
import Swal from 'sweetalert2';
import { BaseApiUrl } from '../../utilities/enviroment';

const SelectAIModel = () => {
    const [selectedIndex, setSelectedIndex] = useState(null);

    // const responsive = {
    //     desktop: {
    //         breakpoint: { max: 3000, min: 1280 },
    //         items: 4,
    //         partialVisibilityGutter: 40
    //     },
    //     tablet: {
    //         breakpoint: { max: 1024, min: 464 },
    //         items: 2,
    //         partialVisibilityGutter: 30
    //     },
    //     mobile: {
    //         breakpoint: { max: 464, min: 0 },
    //         items: 1,
    //         partialVisibilityGutter: 30
    //     }
    // };
    const BASE_URL = BaseApiUrl;

    const data = [
        // { img: aiModal8, title: 'Super Realistic Model', model: 'realisticinstav6.safetensors [ecb478bd5e]' },
        { img: aiModal4, title: 'Hyper Realistic Model', model: 'hyperrealisticv6.safetensors' },
        { img: aiModal5, title: 'Photo Realistic Model', model: 'influncerv2_p1.safetensors' },
        { img: aiModal1, title: 'Surreal Anime Model', model: 'bestanimface.safetensors' },
        { img: aiModal2, title: 'Realistic Anime Model', model: 'anniepornbestwithhuman.safetensors' },
        { img: aiModal3, title: 'Realistic Human Model', model: 'realisticinstav6.safetensors' },
        { img: aiModal6, title: 'Hentai Anime Model', model: 'metrixhentaip.safetensors' },
        { img: aiModal7, title: 'Dark Anime Model', model: 'dksAnimeMerge_dksAnimeV10.safetensors' },
    ];

    // Function to set the selected index based on the default model from the API
    const setDefaultModel = async () => {
        try {
            const response = await axios.get(`${BASE_URL}api/v1/GetDefaultModel/`);
            const defaultModel = response.data.sd_model_checkpoint;

            // Find the index of the model in the data array
            const defaultIndex = data.findIndex(item => item.model === defaultModel);

            // If the model is found, set it as the selected index
            if (defaultIndex !== -1) {
                setSelectedIndex(defaultIndex);
            }
        } catch (error) {
            console.log("Error fetching default model:", error);
            Swal.fire(error.response.data.msg || 'Our Servers are busy!',
                'Please wait a moment and try again.',
                'warning', 3000)
        }
    };

    useEffect(() => {
        // Call the function to fetch the default model when the component is mounted
        setDefaultModel();
    }, []);

    const handleModelSelection = (index) => {
        setSelectedIndex(index);

        const selectedModel = data[index].model;

        let requestData = {
            "sd_model_checkpoint": selectedModel
        };

        axios.post(`${BASE_URL}api/v1/sd_model_setup/`, requestData)
            .then((response) => {
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
                Swal.fire(error.response.data.msg || 'Our Servers are busy!',
                    'Please wait a moment and try again.',
                    'warning', 3000)
            });
    };

    return (
        <>
            <div className='pt-10 md:pt-20'>
                <div className="container">
                    <p className='text-center  font-semibold text-3xl xl:text-4xl pb-5'>Select AI Model</p>
                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        loop={true}
                        slidesPerView={'auto'}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 100,
                            modifier: 2.5,
                        }}
                        pagination={{ el: '.swiper-pagination', clickable: true }}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                            clickable: true,
                        }}
                        modules={[EffectCoverflow, Pagination, Navigation]}
                        className="swiper_container"
                    >
                        {data.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className='relative' onClick={() => handleModelSelection(index)}><img loading='lazy' src={item.img} alt={item.title} /></div>
                                <div className='absolute bg-[#d3b7b79c] border-1 rounded-full p-3 sm:text-sm md:text-lg lg:text-xl xxl:text-2xl bottom-1 left-1'>{item.title}</div>
                                <div onClick={() => handleModelSelection(index)} className={`absolute text-white border-1 rounded-2xl p-3 sm:text-sm md:text-lg lg:text-xl xxl:text-2xl bottom-1 right-1  ${selectedIndex === index ? 'bg-[#0e7a36bf]' : 'bg-[#9d2d2de0]'}`}>
                                    {selectedIndex === index ? "Selected" : "Select Model"}</div>
                            </SwiperSlide>
                        ))}

                        <div className="slider-controler">
                            <div className="swiper-button-prev slider-arrow">
                                <span className="hover-text prev-text">Prev</span>
                            </div>
                            <div className="swiper-button-next slider-arrow">
                                <span className="hover-text next-text">Next</span>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </Swiper>
                </div>


            </div>

            {/* <div className='pt-10 md:pt-20'>
                <div className='flex justify-center gap-5 pb-2'>
                    <p className='font-semibold text-3xl xl:text-4xl pb-5'>Select AI Model</p>
                    <img loading ='lazy' src={info} alt="infoBlack" className='h-6' />
                </div>
                <div>
                    <Carousel
                        partialVisible={true}
                        responsive={responsive}
                        style={{ zIndex: 1 }}
                    >
                        {
                            data.map((item, index) => (
                                <div
                                    key={index}
                                    className='relative flex flex-col items-center cursor-pointer'
                                    onClick={() => handleModelSelection(index)}
                                >
                                    <img loading ='lazy'
                                        src={item.img}
                                        alt={`aiModal${index}`}
                                        className={`w-[300px] h-[360px] xl:w-[270px] xl:h-[360px] rounded-lg ${selectedIndex === index ? 'border-4 border-red-600 shadow-lg shadow-red-600' : ''}`}
                                    />
                                    <div
                                        className={`w-[300px] xl:w-[270px] absolute bottom-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 rounded-b-lg ${selectedIndex === index ? 'bg-red-600' : ''}`}
                                        style={{ left: "3.5rem" }}
                                    >
                                        {item.title}
                                    </div>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            </div> */}
        </>
    );
}

export default SelectAIModel;
