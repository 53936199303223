import React, { useEffect, useState } from 'react';
import UserApiClient from '../../utilities/apiClients';
import Swal from 'sweetalert2';
import { eye, DeleteImg } from '../../assets';

const AdminNotificationPage = () => {
  const [notifications, setNotifications] = useState([]);
  const [newNotification, setNewNotification] = useState('');
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [currentNote, setCurrentNote] = useState({});

  const fetchNotification = async () => {
    await UserApiClient.get('api/v1/notification/', false).then(async (res) => {
      if (res.success) {
        setNotifications(res.data);
      }
    })
  }

  const handleAddNotification = async () => {
    if (newNotification.trim() !== '') {
      await UserApiClient.post('api/v1/notification/', { 'text': newNotification }, false).then(async (res) => {
        if (res.success) {
          await fetchNotification();
          setNewNotification("");
          await Swal.fire(`Notification added.`, "", "success", 3000);
        }
      })
    }
  };

  const handleRemove = async (id) => {
    await Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert this!`,
      icon: "question",
      showCancelButton: true,
      cancelButtonColor: "grey",
      confirmButtonColor: "#d33",
      confirmButtonText: `Yes!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await UserApiClient.delete(`api/v1/notification/${id}/`, {}, false).then(async (res) => {
          if (res.success) {
            await fetchNotification();
            setNewNotification("");
            await Swal.fire(`Notification removed.`, "", "success", 3000);
          }
        });
      }
    });
  };

  const openDetailModal = (data) => {
    setCurrentNote(data);
    setDetailModalOpen(true);
  };

  const closeDetailModal = () => {
    setDetailModalOpen(false);
  };

  useEffect(() => {
    fetchNotification();
  }, [])

  return (
    <div className="flex flex-col items-center py-8 px-4">
      <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 mb-6">Notifications</h1>

      {/* Add New Notification */}
      <div className="w-full max-w-2xl mb-8 w-100">
        <h2 className=" text-lg md:text-xl font-semibold text-gray-700 mb-4">Add New Notification</h2>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <textarea
            className="w-full p-4 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter notification message..."
            rows="4"
            value={newNotification}
            onChange={(e) => setNewNotification(e.target.value)}
          ></textarea>
          <button
            onClick={handleAddNotification}
            className=" w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Add Notification
          </button>
        </div>
      </div>

      {/* Display Current Notifications */}
      <div className="w-full max-w-2xl mb-8">
        <h2 className="text-lg md:text-xl font-semibold text-gray-700 mb-4">Current Notifications</h2>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <ul className="space-y-4">
            {notifications.map((notif) => (
              <li key={notif.id} className="flex justify-between items-center p-4 bg-gray-100 rounded-lg">
                <span className="text-gray-800">{notif.text}</span>
                <div className='flex gap-4 cursor-pointer'>
                  <img loading='lazy' src={eye} alt="View" onClick={() => openDetailModal(notif)} />
                  <img loading='lazy' src={DeleteImg} alt="Delete" onClick={() => handleRemove(notif.id)} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {detailModalOpen && (
        <div className="fixed inset-0 bg-gray-700 bg-opacity-75 flex justify-center items-center">
          <div className="relative bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold">Notification details</p>
              <div className="cursor-pointer z-50" onClick={closeDetailModal}>
                <svg className="fill-current text-gray-700" width="18" height="18" viewBox="0 0 18 18">
                  <path d="M14.59 3.41L11.18 6.82L14.59 10.23L13.18 11.64L9.77 8.23L6.36 11.64L4.95 10.23L8.36 6.82L4.95 3.41L6.36 2L9.77 5.41L13.18 2L14.59 3.41Z" />
                </svg>
              </div>
            </div>
            <div className="bg-white rounded-lg text-left">
              <p className="font-semibold text-xl">ID</p>
              <p className="mb-4 font-font-light text-md mx-2">{currentNote.id}</p>
              <p className="font-semibold text-xl">Text</p>
              <p className="mb-4 font-font-light text-md mx-2">{currentNote.text}</p>
              <p className="font-semibold text-xl">Created At</p>
              <p className="mb-4 font-font-light text-md mx-2">{currentNote.created_at}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminNotificationPage;
