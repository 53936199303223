import React, {useEffect, useState} from "react";
// import { Logo } from "../../assets";

const LandingPopup = ({ onClose }) => {
    // const navigate = useNavigate();
    const [showConditionsInfo, setShowConditionsInfo] = useState();
    const [visited, setVisited] = useState();

    const handleBackgroundClick = (e) => {
        // Prevent click events from bubbling up to the background
        e.stopPropagation();
        window.location.href = "https://www.google.com";
    };

    const handleLogin = () => {
        setShowConditionsInfo(true);
        localStorage.setItem("hasVisited", "true");
      };
      
      useEffect(()=>{
        const visited = localStorage.getItem("hasVisited");
        // console.log("visitedvisited",visited)
        setVisited(visited);
      },[])

    return (
        <>
        {/* <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 font-josefin-sans z-[1100]"
        // onClick={handleBackgroundClick} 
        >
            <div
                className="bg-white p-0 rounded-lg shadow-lg w-12/12 w-11/12 lg:w-11/12 xl:w-4/6 2xl:w-3/6"
                onClick={(e) => e.stopPropagation()} // Stop clicks from propagating to the background
            >
                <div className='pb-4 flex justify-center items-center'>
                    <img loading ='lazy' src={Logo} alt="logo" className='w-56 md:w-72' />
                </div>

                <hr className="border-[1.2px] w-full  border-[#b1b0b0]" />

                <div className="mx-9 text-[14px] lg:text-[18px]">
                    <p className="text-[#FF0013] py-4 "><span className="font-bold">Warning: </span>This site is intended for adults only and contains AI-generated adult imagery.</p>
                    <p className="text-[#222222] my-4">
                        By entering this website, you confirm that you are at least 18 years old. By using the site, you agree to our Terms of Service. Our Privacy Policy outlines how we collect and use your data. We use cookies for basic analytics and spam detection.
                    </p>
                    <p className="text-[#222222] mt-10">Any content generated on this website that resembles real people is purely coincidental.</p>
                    <div className="flex justify-center my-4 gap-5">
                        <button
                            onClick={handleLogin}
                            type="button"
                            className="text-white bg-[#FF0013] hover:bg-[#ff0013] rounded-[55px] text-[18px] xl:text-[20px] px-3 xl:px-5 text-center w-[150px] xl:w-[180px] h-[40px] xl:h-[48px] xl:font-normal mt-4 lg:mt-6 xl:mt-12"
                        >
                            I Confirm
                        </button>
                        <button
                            onClick={handleBackgroundClick}
                            type="button"
                            className="hover:text-white text-[#FF0013] hover:bg-[#FF0013] border border-[#FF0013] rounded-[55px] text-[18px] xl:text-[20px] px-3 xl:px-5 text-center xl:w-[240px] h-[40px] xl:h-[48px] xl:font-normal mt-4 lg:mt-6 xl:mt-12"
                        >
                            Leave The Website
                        </button>
                    </div>
                </div>
            </div>
        </div> */}

        <div id="conditionMsg" className={`fixed bottom-24 md:bottom-0 left-0 w-full p-4 lg:p-5 bg-[#ff001196] backdrop-blur-lg rounded-tr-xl rounded-tl-xl ${showConditionsInfo || visited ? 'hidden' : ''}`} style={{zIndex : '9999'}}>
        <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-6">
          {/* Left Section - Terms */}
          <div className="flex-1 lg:w-4/12 flex items-start">
            <div className="mr-3 lg:mr-5">
              <span className="bg-[#ff0011bb] rounded-full text-white px-3 py-1 text-base">
                18+
              </span>
            </div>
            <div className="flex flex-col justify-center">
              <h4 className="mb-1 lg:mb-3 text-white text-xl font-semibold">
                Warning
                <p className="text-sm text-[#]">This site is intended for adults only and contains AI-generated adult imagery.</p>
              </h4>
              <div className="hidden lg:block text-sm text-white">
              By entering this website, you confirm that you are at least 18 years old. By using the site, you agree to our Terms of Service. Our Privacy Policy outlines how we collect and use your data. We use cookies for basic analytics and spam detection.
              </div>
            </div>
          </div>
      
          {/* Right Section - Cookie Info */}
          <div className="flex-1 lg:w-4/12 flex items-start">
            <div className="mr-3 lg:mr-5">
              <span className="text-2xl">🍪</span>
            </div>
            <div className="flex flex-col justify-center">
              <h4 className="mb-1 text-white lg:mb-3 text-xl font-semibold">
                Notice
              </h4>
              <p className="text-sm text-white">
                Accept all cookies to enjoy access.
              </p>
              <p className="text-sm text-white">
                Any content generated on this website that resembles real people is purely coincidental.
              </p>
            </div>
          </div>
      
          {/* Action Buttons */}
          <div className="flex flex-col lg:w-3/12 items-center justify-center space-y-2">
            <button onClick={handleLogin} className="btn btn-outline w-full text-sm px-4 py-2 border bg-white rounded-md">
                Accept All
            </button>
            <button onClick={handleBackgroundClick} className="btn btn-outline w-full text-sm px-4 py-2 border bg-white rounded-md">
                Leave The Website
            </button>
          </div>
        </div>
        </div>
      </>
      
    );
};

export default LandingPopup;
