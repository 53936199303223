import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PagenameSearchBox from '../components/PagenameSearchBox';
import { remaneUserProfile, tableUser1 } from '../../assets';
import UserApiClient from '../../utilities/apiClients';
import Swal from 'sweetalert2';


const Usersdetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [totalSubCommission, setTotalSubCommission] = useState(0);
    const [totalTokenCommission, setTotalTokenCommission] = useState(0);
    const [totalWithdrawal, setTotalWithdrawal] = useState(0);
    const [userDetails, setUserDetails] = useState({});
    const [commissionList, setCommissionList] = useState([]);
    const [withdrawalList, setWithdrawalList] = useState([]);
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [usersubscription, setUserSubscription] = useState({});
    const [activeTab, setActiveTab] = useState(1);

    // Handle tab click to change the active tab
    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    // Function to handle navigation back to the user list
    const handleBlog = () => {
        navigate('/admin/allUser')
    }

    const fetchData = async () => {
        await UserApiClient.get(`api/v1/admin/user/${id}/`, {}, true).then(async res => {
            if (res.success) {
                setUserDetails(res.data);
                setUserSubscription(res.data.subscription)
            }
        })
    }

    const fetchCommissionData = async () => {
        await UserApiClient.get(`api/v1/admin/user/commissions/?user_id=${id}`, {}, true).then(async res => {
            if (res.success) {
                setCommissionList(res.data);
            }
        });
    }

    const fetchWithdrawalData = async () => {
        await UserApiClient.get(`api/v1/admin/user/withdrawals/?user_id=${id}`, {}, true).then(async res => {
            if (res.success) {
                setWithdrawalList(res.data.results);
            }
        });
    }

    const fetchSubscriptionData = async () => {
        await UserApiClient.get(`api/v1/admin/user/invoices/?user_id=${id}`, {}, true).then(async res => {
            if (res.success) {
                setSubscriptionList(res.data.results);
            }
        });
    }

    const handleAddToken = async (e) => {
        Swal.fire({
            title: "Add Token",
            html: `
                <input id="token" value="0" type="number" class="p-2 border-black border-2 rounded">
            `,
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonColor: "#d33",
            confirmButtonText: "Submit",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                const token = Swal.getPopup().querySelector("#token").value;
                // Validate the fields
                if (!token) {
                    Swal.showValidationMessage(`token fields is required`);
                    return;
                }
                if (parseInt(token) < 1) {
                    Swal.showValidationMessage(`token must be greater then 1`);
                    return;
                }

                // Create FormData object to handle file uploads
                const formData = new FormData();
                formData.append("token", token);

                const res = await UserApiClient.post(`api/v1/admin/user/add-token/${id}/`, formData, true, undefined, undefined);
                if (res.success) {
                    await fetchData();
                    const data = await res.data;
                    return { data };
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed && result.value.data) {
                Swal.fire({
                    title: result.value.data.message,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        });
    }
    const handleRemoveSubscription = async (e) => {
        await Swal.fire({
            title: "Are you sure?",
            text: `You won't be able to revert this!`,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            confirmButtonText: `Yes!`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await UserApiClient.delete(`api/v1/admin/user/change-subscription/${id}/`, {}, true).then(async (res) => {
                    if (res.success) {
                        await fetchData();
                        Swal.fire(res.data.message, "", "success", 3000);
                    }
                });
            }
        });
    }

    const handleChangeSubscription = async (e) => {
        Swal.fire({
            title: "Select Subscription",
            html: `
                <select name="plan" class="p-2 border-black border-2 rounded" id="plan">
                    <option value="bronze_subscription">Bronze Subscription</option>
                    <option value="silver_subscription">Silver Subscription</option>
                    <option value="gold_subscription">Gold Subscription</option>
                </select>
            `,
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonColor: "#d33",
            confirmButtonText: "Submit",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                const plan = Swal.getPopup().querySelector("#plan").value;
                // Validate the fields
                if (!plan) {
                    Swal.showValidationMessage(`plan fields is required`);
                    return;
                }

                // Create FormData object to handle file uploads
                const formData = new FormData();
                formData.append("plan", plan);

                const res = await UserApiClient.post(`api/v1/admin/user/change-subscription/${id}/`, formData, true, undefined, undefined);
                if (res.success) {
                    await fetchData();
                    const data = await res.data;
                    return { data };
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed && result.value.data) {
                Swal.fire({
                    title: result.value.data.message,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        });
    }

    useEffect(() => {
        fetchData();
        fetchCommissionData();
        fetchWithdrawalData();
        fetchSubscriptionData();
    }, [])

    useEffect(() => {
        var subCommissionAmount = 0;
        var tokenCommissionAmount = 0;
        var WithdrawalAmount = 0;
        commissionList.map(item => {
            if (item.commission_type === 'subscription') {
                subCommissionAmount += item.amount
            } else if (item.commission_type === 'token') {
                tokenCommissionAmount += item.amount
            }
        })
        withdrawalList.map(item => {
            WithdrawalAmount += item.status === 'completed' ? item.amount : 0
        })
        setTotalSubCommission(subCommissionAmount);
        setTotalTokenCommission(tokenCommissionAmount);
        setTotalWithdrawal(WithdrawalAmount);
    }, [commissionList, withdrawalList])


    return (
        <>
            <div className='h-full  px-7 pb-20 font-josefin-sans'>
                <PagenameSearchBox title='User Profile' btnname='Back to user list' btnfunction={handleBlog} />

                <div className="rounded-lg bg-red-800 w-full pb-4 mt-12">
                    <div className="flex flex-wrap justify-between items-start p-5 xl:px-16 pb-8 gap-14 border-b border-white">
                        <div className="text-white w-full md:w-auto">
                            <div className="flex justify-start pb-4">
                                <img
                                    loading="lazy"
                                    src={userDetails.profile_pic}
                                    alt=""
                                    className="!w-20 !h-20 rounded-full"
                                />
                            </div>
                            <div className="text-lg md:text-xl">
                                <p className="mb-1">
                                    <strong>Email:</strong> {userDetails.email}
                                </p>
                                <p className="mb-1">
                                    <strong>User Since:</strong> {userDetails.date_joined?.split("T")[0]}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-wrap gap-4">
                            {usersubscription.subscription_type === 'free' ? (
                                <button
                                    onClick={handleChangeSubscription}
                                    className="rounded-lg text-lg bg-black hover:bg-white duration-300 text-white hover:text-black py-2 px-4 min-w-[150px]"
                                >
                                    Add Subscription
                                </button>
                            ) : usersubscription.added_by_admin ? (
                                <button
                                    onClick={handleRemoveSubscription}
                                    className="rounded-lg text-lg bg-black hover:bg-white duration-300 text-white hover:text-black py-2 px-4 min-w-[150px]"
                                >
                                    Remove Subscription
                                </button>
                            ) : null}
                            <button
                                onClick={handleAddToken}
                                className="rounded-lg text-lg bg-black hover:bg-white duration-300 text-white hover:text-black py-2 px-4 min-w-[150px]"
                            >
                                Add Token
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-between gap-4 mx-5 mt-4 text-white text-lg sm:text-xl">
                        <p>
                            <strong>Wallet Balance:</strong> ${userDetails.balance || 0}
                        </p>
                        <p>
                            <strong>Sub. Commission:</strong> ${totalSubCommission || 0}
                        </p>
                        <p>
                            <strong>Token Commission:</strong> ${totalTokenCommission || 0}
                        </p>
                        <p>
                            <strong>Total Withdrawal:</strong> ${totalWithdrawal || 0}
                        </p>
                    </div>
                </div>

                <div className="rounded-lg bg-[#838383] w-full pb-16 mt-12">
                    <div className="flex flex-wrap items-center justify-center gap-4 p-4">
                        <button
                            onClick={() => handleTabClick(1)}
                            className={`px-4 py-2 font-semibold md:text-base lg:text-lg rounded-lg transition-colors duration-300 w-full sm:w-[45%] md:w-[25%] lg:w-[20%] ${activeTab === 1 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'}`}
                        >
                            Details
                        </button>
                        <button
                            onClick={() => handleTabClick(2)}
                            className={`px-4 py-2 font-semibold md:text-base lg:text-lg rounded-lg transition-colors duration-300 w-full sm:w-[45%] md:w-[25%] lg:w-[20%] ${activeTab === 2 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'}`}
                        >
                            Withdrawals
                        </button>
                        <button
                            onClick={() => handleTabClick(3)}
                            className={`px-4 py-2 font-semibold md:text-base lg:text-lg rounded-lg transition-colors duration-300 w-full sm:w-[45%] md:w-[25%] lg:w-[20%] ${activeTab === 3 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'}`}
                        >
                            Commissions
                        </button>
                        <button
                            onClick={() => handleTabClick(4)}
                            className={`px-4 py-2 font-semibold md:text-base lg:text-lg rounded-lg transition-colors duration-300 w-full sm:w-[45%] md:w-[25%] lg:w-[20%] ${activeTab === 4 ? 'bg-[#000] text-white' : 'bg-gray-200 text-gray-600'}`}
                        >
                            Invoices
                        </button>
                    </div>
                    {activeTab === 1 && (
                        <div className="mt-5 px-4">
                            <div className="p-6 bg-white rounded-lg shadow-md">
                                <h2 className="text-2xl font-semibold text-gray-800 mb-4">User Profile</h2>
                                <div className="space-y-4">
                                    {[
                                        { label: 'ID', value: userDetails.id },
                                        { label: 'Affiliate ID', value: userDetails.affiliate_id },
                                        { label: 'Balance', value: `$${userDetails.balance || 0}` },
                                        { label: 'Date Joined', value: new Date(userDetails.date_joined).toLocaleDateString() },
                                        { label: 'Email', value: userDetails.email },
                                        { label: 'Active', value: userDetails.is_active ? 'Yes' : 'No' },
                                        { label: 'Affiliated', value: userDetails.is_affiliated ? 'Yes' : 'No' },
                                        { label: 'Tokens', value: usersubscription.token },
                                        { label: 'Subscription Type', value: usersubscription.subscription_type },
                                        { label: 'Ref Commission', value: `$${userDetails.ref_commission}` },
                                        { label: 'Ref Commission Raise', value: `$${userDetails.ref_commission_raise}` },
                                        { label: 'Token Commission', value: `$${userDetails.token_commission}` },
                                        { label: 'Token Commission Raise', value: `$${userDetails.token_commission_raise}` },
                                    ].map((item, index) => (
                                        <div key={index} className="flex justify-between border rounded-lg p-3">
                                            <span className="font-medium text-gray-600">{item.label}:</span>
                                            <span className="text-gray-800">{item.value}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    {activeTab === 2 && renderTable('Withdrawal List', withdrawalList, ['Email', 'Amount', 'Created At', 'Status'])}
                    {activeTab === 3 && renderTable('Commission List', commissionList, ['Email', 'Amount', 'Commission Type', 'Created At'])}
                    {activeTab === 4 && renderTable('Invoice List', subscriptionList, ['Invoice Id', 'Invoice Type', 'Email', 'Amount', 'Created At'])}
                </div>

            </div>
        </>
    );
};

const renderTable = (title, data, columns) => (
    <div className="mt-5 px-4">
        <div className="p-6 bg-white rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-black">{title}</h3>
            <div className="overflow-x-auto rounded-lg mt-4">
                <table className="w-full text-left text-gray-500 border border-stone-300">
                    <thead className="text-xl text-black bg-stone-300">
                        <tr>
                            <th className="px-6 py-3">Sr.</th>
                            {columns.map((col, index) => (
                                <th key={index} className="px-6 py-3">{col}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index} className="bg-white text-black text-xl font-normal border-b border-stone-300">
                                <td className="px-6 py-4">{index + 1}.</td>
                                {columns.map((col, colIndex) => (
                                    <td key={colIndex} className="px-6 py-4">
                                        {item[col.toLowerCase().replace(/ /g, '_')]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
);


export default Usersdetail;
