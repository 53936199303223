import React, { useState } from 'react'
import { img1 } from '../../../assets'
import { AiFillLike } from 'react-icons/ai';
import DiscoverImagesBox from '../../components/DiscoverImagesBox';

const CreatorsProfile = () => {
    const [isFollowing, setIsFollowing] = useState(false);

    const handleFollowToggle = () => {
        setIsFollowing(!isFollowing);
    };

    return (
        <>
            <div className="mt-20 px-2 lg:px-20 pb-20 bg-black pt-10 text-white font-josefin-sans">

                <div className='flex flex-wrap justify-center items-center gap-10 md:gap-16 lg:mt-14'>
                    <img loading ='lazy' src={img1} alt="img4" className='w-52 h-52 rounded-full' />
                    <div>
                        <div className='flex items-center justify-center gap-7 md:gap-32 mb-6'>
                            <p className='text-white text-2xl md:text-3xl font-semibold'>@gagan2058</p>
                            <button
                                className='w-40 bg-[#84848466] border-2 border-zinc-500 rounded-lg px-3 py-1'
                                onClick={handleFollowToggle}
                            >
                                <p className='text-center flex justify-center items-center gap-2'>
                                    <AiFillLike color='white' /><span>{isFollowing ? 'Unfollow' : 'Follow'}</span>
                                </p>
                            </button>
                        </div>
                        <div className='flex flex-col justify-center'>
                            <div className='flex items-center justify-center flex-wrap gap-3 md:gap-10 mb-3'>
                                <div className='flex items-center gap-3'>
                                    <p className='text-white text-xl font-semibold'>3522</p>
                                    <p className='text-zinc-500 text-xl font-semibold'>Generations</p>
                                </div>
                                <div className='flex items-center gap-3'>
                                    <p className='text-white text-xl font-semibold'>3522</p>
                                    <p className='text-zinc-500 text-xl font-semibold'>Likes</p>
                                </div>
                                <div className='flex items-center gap-3'>
                                    <p className='text-white text-xl font-semibold'>3522</p>
                                    <p className='text-zinc-500 text-xl font-semibold'>followers</p>
                                </div>
                            </div>
                            <p className='text-zinc-500 text-lg font-normal text-center'>Joined 3 Jan 2024</p>
                        </div>
                    </div>
                </div>

                <div className='flex flex-wrap justify-center items-center gap-5 mt-5 lg:mt-12 mb-10'>
                    <button className='w-72 lg:w-96 rounded-full bg-[#FF001F3B] border-2 border-red-600 text-red-600 px-5 py-3'>
                        <p className='text-center w-full'>Public Generations</p>
                    </button>
                    <button className='w-72 lg:w-96 rounded-full bg-[#6161614A] border-2 border-zinc-600 text-zinc-600 px-5 py-3'>
                        <p className='text-center w-full'>Most Liked</p>
                    </button>
                </div>

                <div className=''>
                    <DiscoverImagesBox />
                </div>
            </div>
        </>
    )
}

export default CreatorsProfile
