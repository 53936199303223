import React, { useState } from 'react';

import {Rectangle48 } from '../../assets';
import { Link, useNavigate } from 'react-router-dom';
import UserApiClient from '../../utilities/apiClients';
import Swal from 'sweetalert2';

const ResetPassword = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({})
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  
    const handleFormSubmit = async (e) => {
      e.preventDefault();
  
      await UserApiClient.post('api/v1/password-reset/', formData).then(async res => {
        if (res.success && res.data) {
          e.target.reset();
          await Swal.fire({
            title: res.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })
          navigate("/login")
        }
      })
    };

    return (
        <div>
            <div>
                <div className='bg-white w-full flex justify-between font-josefin-sans h-full'>
                    <div className='hidden md:block md:w-[45%]'><img loading ='lazy' src={Rectangle48} alt='Rectangle47' className=' w-full object-cover h-[100vh]' /></div>
                    <div className='relative w-full md:w-[55%] flex justify-center items-center h-[100vh]'>
                        <button
                            type="button"
                            className="btn absolute top-3 left-3 text-lg text-[#ff0022] cursor-pointer flex items-center"
                            onClick={() => navigate('/')}
                        >
                            <i className="fa-solid fa-circle-arrow-left mr-2"></i>Back
                        </button>
                        <div className="text-black flex flex-col items-center">
                            <img loading ='lazy' src="/assets/img/logowh.png" alt="Icon" className="w-[20rem] rounded-xl mb-5" />
                            <h4 className="font-sans text-black text-[30px] md:text-[26px] xl:text-[33px] font-semibold leading-snug tracking-normal text-blue-gray-900">
                                Reset password
                            </h4>
                            <p className='text-[18px] lg:text-[20px] text-center w-[300px] lg:w-[467px] mt-5'>Enter your e-mail and we will send you the instructions to reset password</p>
                            <form className="mt-5 lg:mt-8 mb-2 w-[288px] md:w-[330px] lg:w-[400px] xl:w-[500px]" onSubmit={handleFormSubmit}>
                                <div className="flex flex-col gap-6 mb-1">
                                    <div className="relative h-11 md:h-12 lg:h-14 w-full min-w-[288px] md:min-w-[300px]">
                                        <input placeholder="Enter your email address" type="email" name='email' onChange={handleChange} required
                                            className="peer h-full w-full rounded-md border-2 border-blue-gray-200 bg-transparent px-5 py-3 font-sans text-lg font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-2 focus:border-gray-500 focus:outline-0" />
                                    </div>
                                </div>

                                <button
                                    className="mt-6 block w-full select-none rounded-lg bg-[#FF0013] py-2.5 lg:py-3 px-6 text-center align-middle font-sans text-[20px] lg:text-[23px] xl:text-[25px] font-semibold text-white shadow-md transition-all hover:shadow-lg focus:opacity-85 active:opacity-85"
                                    type="submit">
                                        Reset Password 
                                </button>

                                <div>
                                    <div className='text-[17px] lg:text-[19px] text-center mt-5'>Just Remember? <span className='text-[#FF0013] cursor-pointer' ><Link to={'/login'}><span className='text-[#FF0013] cursor-pointer'>Login</span></Link> </span></div>
                                    <div className='text-[17px] lg:text-[19px] text-center mt-2 2xl:mt-5'>Don't have an account yet? <Link to={'/join'}><span className='text-[#FF0013] cursor-pointer'>Sign Up</span></Link></div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default ResetPassword;
