import React, { useState } from 'react';
import UserApiClient from '../../../utilities/apiClients';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const TemplateTwo = () => {
  const navigate = useNavigate();
  const [templateUrl, setTemplateUrl] = useState('');
  const [templateCategory, setTemplateCategory] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [SectionOne, SetSectionOne] = useState({});
  const [SectionTwo, SetSectionTwo] = useState({});
  const [SectionThree, SetSectionThree] = useState({});
  const [SectionFour, SetSectionFour] = useState({});
  const [SectionFive, SetSectionFive] = useState({});

  const handleSectionOne = (e) => {
    const { name, value } = e.target;
    if (e.target.type === 'file') {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          SetSectionOne((prevState) => ({ ...prevState, [name]: file }));
        };
        if (file instanceof Blob) {
          reader.readAsDataURL(file);
        }
      }
    } else {
      SetSectionOne((prevState) => ({ ...prevState, [name]: value, }));
    }
  }

  const handleSectionTwo = (e) => {
    const { name, value } = e.target;
    if (e.target.type === 'file') {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          SetSectionTwo((prevState) => ({ ...prevState, [name]: file }));
        };
        if (file instanceof Blob) {
          reader.readAsDataURL(file);
        }
      }
    } else {
      SetSectionTwo((prevState) => ({ ...prevState, [name]: value, }));
    }
  }

  const handleSectionThree = (e) => {
    const { name, value } = e.target;
    if (e.target.type === 'file') {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          SetSectionThree((prevState) => ({ ...prevState, [name]: file }));
        };
        if (file instanceof Blob) {
          reader.readAsDataURL(file);
        }
      }
    } else {
      SetSectionThree((prevState) => ({ ...prevState, [name]: value, }));
    }
  }

  const handleSectionFour = (e) => {
    const { name, value } = e.target;
    if (e.target.type === 'file') {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          SetSectionFour((prevState) => ({ ...prevState, [name]: file }));
        };
        if (file instanceof Blob) {
          reader.readAsDataURL(file);
        }
      }
    } else {
      SetSectionFour((prevState) => ({ ...prevState, [name]: value, }));
    }
  }

  const handleSectionFive = (e) => {
    const { name, value } = e.target;
    if (e.target.type === 'file') {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          SetSectionFive((prevState) => ({ ...prevState, [name]: file }));
        };
        if (file instanceof Blob) {
          reader.readAsDataURL(file);
        }
      }
    } else {
      SetSectionFive((prevState) => ({ ...prevState, [name]: value, }));
    }
  }
  const handleSubmit = (e) => {
    if (templateUrl === '' || !templateUrl) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!!!',
        text: 'Please fill the url of template',
        timer: 3000
      });
      return
    }
    const section_data = new FormData();

    for (const key in SectionOne) {
      section_data.append('section_one_' + key, SectionOne[key]);
    }
    for (const key in SectionTwo) {
      section_data.append('section_two_' + key, SectionTwo[key]);
    }
    for (const key in SectionThree) {
      section_data.append('section_three_' + key, SectionThree[key]);
    }
    for (const key in SectionFour) {
      section_data.append('section_four_' + key, SectionFour[key]);
    }
    for (const key in SectionFive) {
      section_data.append('section_five_' + key, SectionFive[key]);
    }
    section_data.append('url', templateUrl)
    section_data.append('category', templateCategory)
    section_data.append('name', templateName)

    UserApiClient.post(`api/v1/admin/template/two/`, section_data, true).then(async res => {
      if (res.success) {
        await Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Template Saved.',
          timer: 3000
        });
        navigate("/admin/template");
      }
    });
  }


  return (
    <div className='bg-[#f5f5f5]'>
      <div className=" p-8 space-y-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-24">
          <div className="flex flex-col">
            <label htmlFor="heading1" className="font-bold text-2xl text-gray-800">Page Url</label>
            <input type="text" name="url" value={templateUrl} onChange={(e) => setTemplateUrl(e.target.value)}
              className="p-2 border-2 border-[#dbdbdb] rounded focus:!outline-[0] focus:!ring-[0] focus:!border-[0]" placeholder="Enter Template Url (e.g. template-demo)" />
          </div>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
            <div className='flex flex-col'>
              <label htmlFor="heading1" className="font-bold text-2xl text-gray-800 mb-1">Category</label>
              <input type="text" name="url" value={templateCategory} onChange={(e) => setTemplateCategory(e.target.value)}
                className="m-1 p-2 border-2 border-[#dbdbdb] rounded focus:!outline-[0] focus:!ring-[0] focus:!border-[0]" placeholder="Enter Category" />
            </div>
            <div className='flex flex-col'>
              <label htmlFor="heading1" className="font-bold text-2xl text-gray-800 mb-1">Page Name</label>
              <input type="text" name="url" value={templateName} onChange={(e) => setTemplateName(e.target.value)}
                className="m-1 p-2 border-2 border-[#dbdbdb] rounded focus:!outline-[0] focus:!ring-[0] focus:!border-[0]" placeholder="Enter Page Name" />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-24">
          {/* Section 1 */}
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-gray-700">Section 1</h2>
            <div className="flex flex-col space-y-4">
              <label htmlFor="heading1" className="font-medium text-gray-600">Heading</label>
              <input
                id="heading1"
                type="text"
                name="heading"
                onChange={handleSectionOne}
                className="p-2 border border-[#dbdbdb] rounded-md focus:!outline-[0] focus:!ring-[0] focus:!border-[0]"
                placeholder="Enter heading"
              />
            </div>
            <div className="flex flex-col space-y-4">
              <label htmlFor="content1" className="font-medium text-gray-600">Content</label>
              <textarea
                id="content1"
                rows="4"
                name="content"
                onChange={handleSectionOne}
                className="p-2 border border-[#dbdbdb] rounded-md focus:!outline-[0] focus:!ring-[0] focus:!border-[0]"
                placeholder="Enter content"
              />
            </div>
            <div className="flex flex-col space-y-4">
              <label htmlFor="image1" className="font-medium text-gray-600">Upload Image 1</label>
              <input
                id="image1"
                type="file"
                accept="image/*"
                name="image1"
                multiple={true}
                onChange={handleSectionOne}
                className="p-2 border border-[#dbdbdb] bg-white rounded-md focus:!outline-[0] focus:!ring-[0] focus:!border-[0]"
              />
            </div>
            <div className="flex flex-col space-y-4">
              <label htmlFor="image2" className="font-medium text-gray-600">Upload Image 2</label>
              <input
                id="image2"
                type="file"
                accept="image/*"
                name="image2"
                onChange={handleSectionOne}
                className="p-2 border border-[#dbdbdb] bg-white rounded-md focus:!outline-[0] focus:!ring-[0] focus:!border-[0]"
              />
            </div>
          </div>

          {/* Section 2 */}
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-gray-700">Section 2</h2>
            <div className="flex flex-col space-y-4">
              <label htmlFor="heading2" className="font-medium text-gray-600">Heading</label>
              <input
                id="heading2"
                type="text"
                name="heading1"
                onChange={handleSectionTwo}
                className="p-2 border border-[#dbdbdb] rounded-md focus:!outline-[0] focus:!ring-[0] focus:!border-[0]"
                placeholder="Enter heading"
              />
            </div>
            <div className="flex flex-col space-y-4">
              <label htmlFor="content2" className="font-medium text-gray-600">Content</label>
              <textarea
                id="content2"
                rows="4"
                name="content1"
                onChange={handleSectionTwo}
                className="p-2 border border-[#dbdbdb] rounded-md focus:!outline-[0] focus:!ring-[0] focus:!border-[0]"
                placeholder="Enter content"
              />
            </div>
            <div className="flex flex-col space-y-4">
              <label htmlFor="image3" className="font-medium text-gray-600">Upload Image</label>
              <input
                id="image3"
                type="file"
                accept="image/*"
                name="image1"
                onChange={handleSectionTwo}
                className="p-2 border border-[#dbdbdb] bg-white rounded-md focus:!outline-[0] focus:!ring-[0] focus:!border-[0]"
              />
            </div>
          </div>

          {/* Section 3 */}
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-gray-700">Section 3</h2>
            <div className="flex flex-col space-y-4">
              <label htmlFor="heading3" className="font-medium text-gray-600">Heading</label>
              <input
                id="heading3"
                type="text"
                name="heading"
                onChange={handleSectionThree}
                className="p-2 border border-[#dbdbdb] rounded-md focus:!outline-[0] focus:!ring-[0] focus:!border-[0]"
                placeholder="Enter heading"
              />
            </div>
            <div className="flex flex-col space-y-4">
              <label htmlFor="content3" className="font-medium text-gray-600">Content</label>
              <textarea
                id="content3"
                rows="4"
                name="content"
                onChange={handleSectionThree}
                className="p-2 border border-[#dbdbdb] rounded-md focus:!outline-[0] focus:!ring-[0] focus:!border-[0]"
                placeholder="Enter content"
              />
            </div>
            <div className="flex flex-col space-y-4">
              <label htmlFor="image4" className="font-medium text-gray-600">Upload Image</label>
              <input
                id="image4"
                type="file"
                accept="image/*"
                name="image1"
                onChange={handleSectionThree}
                className="p-2 border border-[#dbdbdb] bg-white rounded-md focus:!outline-[0] focus:!ring-[0] focus:!border-[0]"
              />
            </div>
          </div>

          {/* Section 4 */}
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-gray-700">Section 4</h2>
            <div className="flex flex-col space-y-4">
              <label htmlFor="heading4" className="font-medium text-gray-600">Heading</label>
              <input
                id="heading4"
                type="text"
                name="heading"
                onChange={handleSectionFour}
                className="p-2 border border-[#dbdbdb] rounded-md focus:!outline-[0] focus:!ring-[0] focus:!border-[0]"
                placeholder="Enter heading"
              />
            </div>
            <div className="flex flex-col space-y-4">
              <label htmlFor="content4" className="font-medium text-gray-600">Content</label>
              <textarea
                id="content4"
                rows="4"
                name="content"
                onChange={handleSectionFour}
                className="p-2 border border-[#dbdbdb] rounded-md focus:!outline-[0] focus:!ring-[0] focus:!border-[0]"
                placeholder="Enter content"
              />
            </div>
            <div className="flex flex-col space-y-4">
              <label htmlFor="video" className="font-medium text-gray-600">Video Link</label>
              <input
                id="video"
                type="text"
                name="video"
                onChange={handleSectionFour}
                className="p-2 border border-[#dbdbdb] rounded-md focus:!outline-[0] focus:!ring-[0] focus:!border-[0]"
                placeholder="Enter video link"
              />
            </div>
          </div>

          {/* Section 5 */}
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-gray-700">Section 5</h2>
            <div className="flex flex-col space-y-4">
              <label htmlFor="heading5" className="font-medium text-gray-600">Heading</label>
              <input
                id="heading5"
                type="text"
                name="heading"
                onChange={handleSectionFive}
                className="p-2 border border-[#dbdbdb] rounded-md focus:!outline-[0] focus:!ring-[0] focus:!border-[0]"
                placeholder="Enter heading"
              />
            </div>
            <div className="flex flex-col space-y-4">
              <label htmlFor="content5" className="font-medium text-gray-600">Content</label>
              <textarea
                id="content5"
                rows="4"
                name="content"
                onChange={handleSectionFive}
                className="p-2 border border-[#dbdbdb] rounded-md focus:!outline-[0] focus:!ring-[0] focus:!border-[0]"
                placeholder="Enter content"
              />
            </div>
          </div>
        </div>
      </div>

      <div className=' w-full text-center p-4'>
        {/* <button class="rounded-lg text-lg bg-red-800 hover:bg-red-700 text-white py-2 px-4 min-w-44">Reset</button> */}
        <button className="rounded-lg text-lg bg-red-800 hover:bg-red-700 text-white py-2 px-4 w-[50%]" onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default TemplateTwo;
