import { useState, useEffect, useRef } from "react";
// import axios from 'axios';
// import { RiCompassDiscoverLine, RiAiGenerate } from "react-icons/ri";
// import { TbGenderDemigirl, TbPremiumRights, TbArrowGuide, TbGenderDemiboy } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import UserApiClient from "../../utilities/apiClients";
import { alert } from "../../assets"
import UserApiClient from "../../utilities/apiClients";
import Swal from "sweetalert2";

export const Sidebar = ({ isOpen, setActiveTab, setIsOpen }) => {
  const userData = useSelector(state => state.user);
  const user = userData.user;
  const [active, setActive] = useState(1);
  const [Userlogger, setUserlogger] = useState(null);
  const [usercheck, setUsercheck] = useState(null);
  // const [errors, setErrors] = useState({});
  const [isAffiliated, setIsAffiliated] = useState(false);
  const goto = (index) => setActive(index);
  const navigate = useNavigate();

  const [errorisOpen, setErrorIsOpen] = useState(false);
  const [subscriptionType, SetSubscriptiontype] = useState(null);
  const errorCloseModal = () => setErrorIsOpen(false);
  const errorOpenModal = () => setErrorIsOpen(true);
  const users = useSelector(state => state.user)

  useEffect(() => {
    setUsercheck(localStorage.getItem('hasVisited'));
    setUserlogger(localStorage.getItem('token') && userData.isAuthenticated);
  }, []);

  const closeSidebar = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        closeSidebar();
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const sidebarRef = useRef(null);

  useEffect(() => {
    if (userData.isAuthenticated && Userlogger) {
      setIsAffiliated(user.is_affiliated);
      const subscription = localStorage.getItem('subscription_type');
      SetSubscriptiontype(subscription);
    }
  }, [userData, Userlogger]);

  const LatestCharacter = () => {
    UserApiClient.get("assistant_api/bot_fetch/", {}, false, true, false
    ).then(async (res) => {
      if (res.data) {
        if(Userlogger){
          navigate(`/dashboard/generate/${res.data.data[0].assistant_id}`)
        }else{
            navigate("/login")
          } 
      } else {
        Swal.fire(
          'Our Servers are busy!',
          'Please wait a moment and try again.',
          'warning',
          3000
        );
      }
    });
  }

  const location = useLocation();
  const isAidreamGFchat = location.pathname.startsWith('/AidreamGFchat');
  const AiChat = location.pathname.includes('aichat');

  const navItems = [
    { label: "Home", icon: <i className="fa-solid fa-house"></i>, link: "/" },
    { label: "Create AI Companion", icon: <i className="fa-solid fa-children"></i>, link: Userlogger ? "/CreateDreamGF" : "/login" },
    { label: "Porn Generator", icon: <i className="fa-solid fa-heart-circle-plus"></i>, link: Userlogger ? "/dashboard" : "/login" },
    { label: "Chat", icon: <i className="fa-solid fa-comments"></i>, link: Userlogger ? "/aichat" : "/login" },
    // { label: "Generations", icon: <i className="fa-solid fa-camera-retro"></i>, link: Userlogger ? `/dashboard/generate/${assistant_id}` : "/login" },
    { label: "Generations", icon: <i className="fa-solid fa-camera-retro"></i>},
    { label: "My Creations", icon: <i className="fa-solid fa-shield-heart"></i>, link: Userlogger ? "/dashboard/mycreation" : "/login" },
    { label: "Discover", icon: <i className="fa-solid fa-compass"></i>, link: Userlogger ? "/dashboard/discover" : "/login" },
    { label: "My Gallery", icon: <i className="fa-solid fa-file-circle-plus"></i>, link: Userlogger ? "/dashboard/generations" : "/login" },
    { label: "Pricing", icon: <i className="fa-solid fa-square-poll-vertical"></i>, link: Userlogger ? "/priceplan" : "/login" },
    {
      label: "Affiliates",
      icon: <i className="fa-solid fa-user-plus"></i>,
      link: isAffiliated
        ? (Userlogger ? "/dashboard/referrals" : "/login")
        : (Userlogger ? "/dashboard/affiliate-signup" : "/login")
    },
    { label: "Support", icon: <i className="fa-solid fa-headset"></i>, link: "https://support.eromantic.ai/hc/en-us", isExternal: true },

  ];

  return (
    <>
      <div className="">
        <aside style={{ background: 'linear-gradient(135deg, rgb(255 255 255 / 81%), rgb(161 145 145) 40%, rgb(109 109 109 / 80%) 70%) center center / cover', top: (isAidreamGFchat || AiChat) ? "0px" : "65px" }} className={`sidebar ${isOpen ? 'sidewidth' : ''}`}>
          {/* <aside style={{ background: 'linear-gradient(135deg, rgb(255 255 255 / 81%), rgb(161 145 145) 40%, rgb(109 109 109 / 80%) 70%) center center / cover', ...(isAidreamGFchat ? { top: '0' } : {}) }} className={`sidebar ${isOpen ? 'sidewidth' : ''}`}> */}
          <div className="inner">
            <nav className="menu">
              {navItems.map((item, index) => (
                <div key={item.label} style={{ textDecoration: 'none' }}>
                  <button
                    className={active === index ? "active justify-start" : " justify-start"}
                    onClick={() => {
                      goto(index);
                      if (item.onClick) {
                        item.onClick();
                      } else if (item.isExternal) {
                        window.open(item.link, "_blank"); // Opens the link in a new tab
                      } else {
                        navigate(item.link); // Navigates for internal routes
                      }
                      closeSidebar()
                      if (item.label === "Generations") { LatestCharacter() };
                    }}
                    style={{
                      backgroundColor: item.backgroundColor ? item.backgroundColor : '',
                      borderRight: item.borderRight ? item.borderRight : '',
                      borderTopRightRadius: item.borderTopRightRadius ? item.borderTopRightRadius : '', // Apply top-right border radius
                      borderBottomRightRadius: item.borderBottomRightRadius ? item.borderBottomRightRadius : '', // Apply bottom-right border radius
                      paddingRight: item.borderRight ? '10px' : '' // Add padding to avoid overlapping with the border
                    }}
                  >
                    <div className="w-[10%] flex justify-center"><span className="text-[18px] xl:text-[22px] text-black">{item.icon}</span></div>
                    <div className=""><p className="text-[13px] xl:text-[14px] text-black">{item.label}</p></div>
                  </button>

                </div>
              ))}
            </nav>
          </div>
        </aside>
      </div>
      <div className="">
        <aside className={`sidebarmob ${isOpen ? 'sidewidthmob' : ''}`}>
          <div className="inner">
            <nav className="menu">
              {navItems.map((item, index) => (
                <div key={item.label} style={{ textDecoration: 'none' }}>
                  <button
                    className={active === index ? "active" : ""}
                    onClick={() => {
                      goto(index);
                      if (item.onClick) {
                        item.onClick();
                      } else if (item.isExternal) {
                        window.open(item.link, "_blank"); // Opens the link in a new tab
                      } else {
                        navigate(item.link); // Navigates for internal routes
                      }
                      closeSidebar()
                      if (item.label === "Generations") { LatestCharacter() };
                    }}
                    style={{
                      backgroundColor: item.backgroundColor ? item.backgroundColor : '',
                      borderRight: item.borderRight ? item.borderRight : '',
                      borderTopRightRadius: item.borderTopRightRadius ? item.borderTopRightRadius : '', // Apply top-right border radius
                      borderBottomRightRadius: item.borderBottomRightRadius ? item.borderBottomRightRadius : '', // Apply bottom-right border radius
                      paddingRight: item.borderRight ? '10px' : '' // Add padding to avoid overlapping with the border
                    }}
                  >
                    <div className="w-[10%] flex justify-center"><span className="text-[18px] xl:text-[24px] text-black">{item.icon}</span></div>
                    <div className=""><p className="text-[13px] xl:text-[14px] text-black">{item.label}</p></div>
                  </button>

                </div>
              ))}
            </nav>
          </div>
        </aside>
      </div>


      {errorisOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-lg">
          <div className="modal-dialog modal-lg bg-white rounded-lg overflow-hidden shadow-xl max-w-[16rem] mo:max-w-xl w-full">
            {/* Modal Content */}
            <div className="modal-wrap flex-wrap mo:flex relative">
              {/* Image Container - 50% Width */}
              <div className="w-full mo:w-1/2">
                {/* Desktop Image */}
                <img
                  src={alert}
                  alt="Modal Img"
                  className="login-img hidden md:block w-full h-full object-cover"
                />
                {/* Mobile Image */}
                <img
                  src={alert}
                  alt="Modal Img Mobile"
                  className="login-img-mob sm:hidden w-full"
                />
              </div>

              {/* Content Container - 50% Width */}
              <div className="modal-content p-2 w-full mo:w-1/2 grid justify-center">
                {/* Modal Header */}
                <div className="modal-header flex justify-between items-start">
                  <h4 className="modal-title text-uppercase text-xl"></h4>
                  <button
                    type="button"
                    className="btn-close text-xl"
                    onClick={errorCloseModal}
                  >
                    <i className="fa-regular fa-circle-xmark"></i>
                  </button>
                </div>

                {/* Modal Body */}
                <div className="modal-body justify-center">
                  <div className="container-fluid">
                    <div className="row mb-4">
                      <div className="col-12 ps-4">
                        <h2 className="text-[#990013] font-bold text-uppercase text-2xl">
                          PLEASE UPGRADE
                        </h2>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 ps-4">
                        <span className="text-muted text-md">
                          Upgrade to a premium membership to unlock access to all features!
                        </span>
                      </div>
                    </div>
                    <div className="row justify-center p-3 gap-1">
                      <div className="col-12">
                        <button onClick={() => navigate('/priceplan')} className="btn btn-primary w-full py-3 text-white bg-[#990012ab] hover:bg-[#990013] rounded-md">
                          Upgrade
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Modal Overlay */}
            {/* <div
              className="fixed inset-0"
              onClick={errorCloseModal}
            ></div> */}
          </div>
        </div>
      )}

    </>
  );
};

export default Sidebar;
