import React, { useState, cloneElement,useEffect } from 'react';
import { Helmet } from "react-helmet";
import UserApiClient from '../../utilities/apiClients';

function Seodata({ children }) {

    const [metaData, setMetaData] = useState('');

    const fetchSeoData = () => {

        const path = window.location.pathname.replaceAll('/', '');
        // const lastSegment = path.split('/').filter(Boolean).pop();
        UserApiClient.get(`api/v1/admin/meta-content/${path}/`, {}, false, true, false).then(async (res) => {

            if (res.success) {
                setMetaData(res.data)
            }
        });
    };

    useEffect(() => {
        fetchSeoData();
    }, []);

    return (
        <>
            <div>
                <Helmet>
                    <title>
                        {metaData.title}
                    </title>
                    <meta
                        name="description"
                        content={metaData.description}
                    />
                    <meta
                        name="keywords"
                        content={metaData.keywords}
                    />
                </Helmet>
            </div>
            {React.Children.map(children, child =>
                cloneElement(child)
            )}
        </>
    );
}

export default Seodata;
